import { useContext, useState } from "react";
import { SUBSCRIBE_TAB } from "../core/const";
import { PersonalDetailsContext } from "../core/PersonalDetailsProvider";
import FilterChips from "./FilterChips";
import Filters from "./Filters";
import ReferralFilterChip from "./ReferralFilterChip";
import ReferralFilters from "./ReferralFilters";

const FilterWrapper = ({ loading, options }: any) => {
  const { activeTab } = useContext(PersonalDetailsContext);
  const [searchValue, setSearchValue] = useState("");

  // RENDER COMPONENT
  const renderComponent = (option: any) => {
    switch (option) {
      case SUBSCRIBE_TAB.subscribeDetails:
        return (
          <>
            {/* ALL FILTERS */}
            <Filters
              loading={loading}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />

            {/* FILTER CHIPS */}
            <FilterChips loading={loading} setSearchValue={setSearchValue} />
          </>
        );
      case SUBSCRIBE_TAB.referral:
        return (
          <>
            <ReferralFilters
              options={options}
              loading={loading}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />

            {/* FILTER CHIPS */}
            <ReferralFilterChip
              loading={loading}
              setSearchValue={setSearchValue}
            />
          </>
        );
      default:
        return null;
    }
  };

  return renderComponent(activeTab);
};

export default FilterWrapper;
