import { IKTSVG } from "components/ui/IKTSVG";
import { INITIAL_REFERRAL_DETAILS } from "helpers/const";
import { addCommaInNumber } from "helpers/Functions";
import { size } from "lodash";
import moment from "moment";
import { useContext } from "react";
import { Tooltip } from "react-tooltip";
import filter from "../../../../assets/media/icons/other_icons/filter.svg";
import { default as refreshSVG } from "../../../../assets/media/icons/other_icons/refresh.svg";
import { PersonalDetailsContext } from "../core/PersonalDetailsProvider";
import FilterChipBox from "./FilterChipBox";

function ToggleElemOpen() {
  const submenuWrapper: any = document.querySelector("#filtersMob");
  submenuWrapper.classList.add("activeFIlter");
  document.body.classList.add("overflow-hidden");
}

const ReferralFilterChip = ({ loading, setSearchValue }: any) => {
  const {
    referralFilterData: filterData,
    setReferralFilterData,
    referralMeta: paginateData,
  } = useContext(PersonalDetailsContext);

  // RESET ALL
  const handleRefresh = () => {
    setReferralFilterData(INITIAL_REFERRAL_DETAILS);
    setSearchValue("");
  };

  // HANDLE RESET
  const handleOnReset = (index: number, name: string, isArr: any) => {
    setReferralFilterData((current: any) => {
      if (name === "query") {
        setSearchValue("");
      }
      return {
        ...current,
        [name]: Array.isArray(isArr)
          ? current?.[name]?.filter(
              (user: any, indexNumber: number) => index !== indexNumber
            )
          : isArr,
        page: 1,
        per_page: 20,
      };
    });
  };

  return (
    <div
      className="flex flex-wrap customStyle relative bg-white  border-t max-sm:justify-between"
      id={`salesFilter`}
    >
      <div className="py-4 px-5 font-semibold text-sm13 sm:border-r text-center w-full sm:w-auto flex items-center max-sm:w-[calc(100%-9rem)]">
        {/* TOTAL RESULT */}
        <div
          className={`${loading ? "shimmer-effect" : ""} inline-block rounded`}
        >
          <span className="">
            {paginateData?.total > 0
              ? `${addCommaInNumber(paginateData?.total)} ${
                  paginateData?.total == 1 ? "result" : "results"
                }`
              : "0 result"}
          </span>
        </div>
      </div>
      <div
        className="filterBtn max-md:flex max-md:items-center hidden p-2.5 text-sm12 border-l max-sm:order-2 max-sm:max-w-[9rem]"
        id="filterBtn"
        onClick={ToggleElemOpen}
      >
        <button className="group inline-flex group items-center text-white hover:text-white bg-violet-500  hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5">
          <IKTSVG
            path={filter}
            className="fill-white mr-1.5"
            svgClassName="w-4 h-4"
          />
          Filter results
        </button>
      </div>
      {/* CHIPS */}
      <div className="flex items-center justify-center sm:justify-start px-5 py-3 gap-x-2.5 flex-1 filterChipArea overflow-auto flex-nowrap whitespace-nowrap scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full md:min-h-[3.375rem] max-sm:flex-1 max-sm:order-3 max-sm:border-t max-sm:justify-start">
        {loading ? (
          <div className="flex gap-5">
            <div
              className={`flex !rounded overflow-hidden ${
                loading && "shimmer-effect"
              }`}
              style={{ height: "1.625rem", width: "9.375rem" }}
            >
              &nbsp;
            </div>
          </div>
        ) : (
          (size(filterData?.teams) > 0 ||
            size(filterData?.referralTeam) > 0 ||
            size(filterData?.referralStatus) > 0 ||
            filterData?.startDate ||
            filterData?.endDate ||
            filterData?.query) && (
            <button
              type="button"
              className={`group border text-center bg-white rounded w-6 h-6 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition ${
                loading && "shimmer-effect"
              }`}
              onClick={handleRefresh}
              data-tooltip-id="reset-all"
            >
              <Tooltip
                id={`reset-all`}
                content={`Reset`}
                place="top"
                variant="light"
                className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
              />
              <IKTSVG
                path={refreshSVG}
                className="fill-violet-500 group-hover:fill-white transition"
                svgClassName="mx-[.313rem] w-3 h-3"
              />
            </button>
          )
        )}
        {!loading && (
          <>
            {/* SEARCH FIELD */}
            {filterData?.query && (
              <FilterChipBox
                title={filterData?.query}
                indexNumber={0}
                handleOnClick={(data: any) => {
                  handleOnReset(data, "query", "");
                }}
              />
            )}

            {/* TEAMS */}
            {size(filterData?.teams) > 0 &&
              filterData?.teams?.map((item: any, index: number) => {
                return (
                  <FilterChipBox
                    title={item?.name}
                    indexNumber={index}
                    handleOnClick={(data: any) => {
                      handleOnReset(data, "teams", []);
                    }}
                  />
                );
              })}

            {/* REFERRAL TEAM */}
            {size(filterData?.referralTeam) > 0 &&
              filterData?.referralTeam?.map((item: any, index: number) => {
                return (
                  <FilterChipBox
                    title={item?.name}
                    indexNumber={index}
                    handleOnClick={(data: any) => {
                      handleOnReset(data, "referralTeam", []);
                    }}
                  />
                );
              })}

            {/* REFERRAL STATUS */}
            {size(filterData?.referralStatus) > 0 &&
              filterData?.referralStatus?.map((item: any, index: number) => {
                return (
                  <FilterChipBox
                    title={item?.name}
                    indexNumber={index}
                    handleOnClick={(data: any) => {
                      handleOnReset(data, "referralStatus", []);
                    }}
                  />
                );
              })}

            {/* START DATE */}
            {filterData?.startDate && (
              <FilterChipBox
                title={`${moment(filterData?.startDate).format("DD/MM/YYYY")}`}
                indexNumber={0}
                handleOnClick={(data: any) => {
                  handleOnReset(data, "startDate", null);
                }}
              />
            )}

            {/* END DATE */}
            {filterData?.endDate && (
              <FilterChipBox
                title={`${moment(filterData?.endDate).format("DD/MM/YYYY")}`}
                indexNumber={0}
                handleOnClick={(data: any) => {
                  handleOnReset(data, "endDate", null);
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ReferralFilterChip;
