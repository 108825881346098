import Messages from "helpers/Messages";
import _, { size } from "lodash";
import { memo, useContext, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import CheckSVG from "../../../../assets/media/icons/other_icons/check.svg";
import ReloadIconSVG from "../../../../assets/media/icons/other_icons/refresh.svg";
import CrossSVG from "../../../../assets/media/icons/standard_icons/cross.svg";
import { LayoutContext } from "../../../../components/core/LayoutProvider";
import DropDownField from "../../../../components/formComponent/DropDownField";
import TextInput from "../../../../components/formComponent/TextInput";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import {
  decimalNumber,
  groupTeamMemberCommon,
  removeCommaFromNumber,
  updatePPEPartners,
} from "../../../../helpers/Functions";
import { showAlert } from "../../../../helpers/ShowAlert";
import { InventoryContext } from "../../core/InventoryProvider";
import {
  bulkPayloadCreate,
  bulkUpdate,
  changedSelectedCount,
  processInBatches,
} from "../../core/_functions";
import { handleBulk, handleListingTransfer } from "../../core/_request";

const BulkOperations = memo(
  ({ list, setList, teamsOptions, isGodAdmin, loadMoreListing }: any) => {
    const layout = useContext(InventoryContext);
    const gloabalLayout = useContext(LayoutContext);
    const target_currency = gloabalLayout.currencyLabel;

    //   VARIABLES & STATES
    let isBulkUpdateBtnDisabled: boolean = false;

    const initialUpdatePricing = {
      manuplatePrice: "",
      percentageOrAbsolute: "",
      updatePricingValue: "",
    };
    const [updatePricingState, setUpdatePricingState] =
      useState<any>(initialUpdatePricing);

    const manupulatePriceOptions = [
      { id: 0, name: "Increase price" },
      { id: 1, name: "Decrease price" },
    ];

    const uncertainOptionsForUpdatePricing = [
      {
        id: 0,
        name: "Absolute",
        labelName: isGodAdmin
          ? "Amount"
          : `(${gloabalLayout?.currencySymbol}) Absolute`,
      },
      { id: 1, name: "Percentage", labelName: "(%) Percentage" },
    ];

    //   DISABLED BUTTON CONDITION
    if (layout?.tableAlert?.actionTitle === "Update pricing") {
      if (
        updatePricingState?.manuplatePrice &&
        updatePricingState?.percentageOrAbsolute &&
        updatePricingState?.updatePricingValue > 0
      ) {
        isBulkUpdateBtnDisabled = false;
      } else {
        isBulkUpdateBtnDisabled = true;
      }
    } else {
      isBulkUpdateBtnDisabled = false;
    }

    // HANDLE BULK UPDATE PRICING
    const bulkUpdatePriceHandle = (data: any) => {
      setUpdatePricingState((current: any) => {
        return {
          ...current,
          [data.target.name]: data?.target?.value,
        };
      });
    };

    const updateVisibility = (data: any) => {
      let vis: any;
      let oldvis: any;
      setList((prev: any) => {
        return prev.map((event: any) => {
          return {
            ...event,
            eventRecords: event.eventRecords?.map((eventRecord: any) => {
              if (eventRecord?.id === data?.id) {
                vis = eventRecord.visibility;
                oldvis = eventRecord.oldVisibility;
                return {
                  ...eventRecord,
                  visibility: 0,
                  selected: false,
                  oldVisibility: 0,
                  loader: false,
                  isInputChange: false,
                  replacible: {},
                  deleteLoader: false,
                };
              } else {
                return eventRecord;
              }
            }),
          };
        });
      });

      setTimeout(() => {
        layout.setisBulkActionLoader(false);
      }, 3000);

      setTimeout(() => {
        setList((prev: any) => {
          const updatedEvents = prev.map((event: any) => {
            const updatedEventRecords = event.eventRecords?.map(
              (eventRecord: any) => {
                if (eventRecord?.id === data?.id) {
                  return {
                    ...eventRecord,
                    visibility: vis,
                    oldVisibility: 0,
                  };
                } else {
                  return eventRecord;
                }
              }
            );

            return {
              ...event,
              eventRecords: updatedEventRecords,
            };
          });
          return updatedEvents;
        });
      }, 200);
    };

    const updateState = (
      bulkUpdateStatus: any,
      inputChange: boolean = false,
      isSelected: boolean = false,
      payloadId: any = false,
      externalArr: any,
      ticketTypeChangedIds: any = {}
    ) => {
      setList((prev: any) => {
        return prev?.map((event: any) => {
          return {
            ...event,
            eventRecords: event?.eventRecords?.map((record: any) => {
              // MATCHED THE RECORDS
              let matchedRecord = externalArr?.find(
                (item: any) => item?.listing_id && record?.id
              );
              if (matchedRecord) {
                // UPDATE THE ID IF CHANGED IN PAYLOAD
                payloadId &&
                  Object.entries(payloadId).forEach(([key, value]) => {
                    if (record.id === key && record.id !== value) {
                      record.id = value;
                    }
                  });
                return {
                  ...record,
                  bulkUpdate: bulkUpdateStatus,
                  ...(!isSelected && { selected: false }),
                  ...(!inputChange &&
                    record?.sell_price !== "0" && { isInputChange: false }),
                  ...(ticketTypeChangedIds?.[record.id] === 1 && {
                    listing_ticket_files: [],
                    ticketFiles: [],
                    mobile_links: [],
                  }),
                };
              } else {
                return record;
              }
            }),
          };
        });
      });
    };

    /**
     * CONFIRMED BULK ACTION
     */

    const confirmedBulkAction = async (
      { action, checkedIds }: any,
      updatePriceDate: any
    ) => {
      layout.setTableAlert({});
      let checkedIdsArr: any = checkedIds.split(",");
      let isError = false;
      let isPPEEnabled = false;

      if (action) {
        if (action === "delete" || action === "listing-transfer") {
          layout.setUpdateAllLoader(true);
          const alertMessage = `${changedSelectedCount(
            list
          )} listing(s) have been scheduled for ${
            action === "delete" ? "deletion" : "transfer ownership"
          } `;
          showAlert(alertMessage, false);

          list?.forEach((event: any) => {
            event?.eventRecords?.forEach((record: any) => {
              if (checkedIdsArr?.includes(record?.id)) {
                record.deleteLoader =
                  isGodAdmin && action === "listing-transfer" ? false : true;
                isGodAdmin &&
                  action === "listing-transfer" &&
                  (record.bulkLoader = true);
              }
            });
          });
        }

        if (isGodAdmin && action === "listing-transfer") {
        } else {
          setList((events: any) => {
            return events?.map((event: any) => {
              let eventRecords: any = event?.eventRecords;
              if (
                action === "delete" ||
                (action === "listing-transfer" && !isGodAdmin)
              ) {
                // sessionStorage.setItem(
                //   "checkedIds",
                //   JSON.stringify(checkedIdsArr)
                // );

                let allSelectedlisting = event?.eventRecords?.filter(
                  (record: any) => record.selected
                );
                const countOfQuantityAvailable = allSelectedlisting?.reduce(
                  (count: number, record: any) =>
                    count + (record?.quantity_available || 0),
                  0
                );
                const countOfStatusYes =
                  allSelectedlisting?.filter(
                    (record: any) => record.status === "yes"
                  )?.length || 0;

                const countOfStatusNo =
                  allSelectedlisting?.filter(
                    (record: any) => record.status === "no"
                  )?.length || 0;

                let total_listings = allSelectedlisting?.length
                  ? event?.total_listings - allSelectedlisting?.length
                  : event?.total_listings;

                let total_qty_available = countOfQuantityAvailable
                  ? event?.total_qty_available - countOfQuantityAvailable
                  : event?.total_qty_available;

                let total_unpublished_listings = countOfStatusNo
                  ? event?.total_unpublished_listings - countOfStatusNo
                  : event?.total_unpublished_listings;

                let total_published_listings = countOfStatusYes
                  ? event?.total_published_listings - countOfStatusYes
                  : event?.total_published_listings;
                return {
                  ...event,
                  requireSelectAll: {},
                  eventRecords: event?.eventRecords?.map((record: any) => {
                    if (record?.selected) {
                      return {
                        ...record,
                        deleteLoader: true,
                        // fadeOut: true,
                      };
                    } else {
                      return record;
                    }
                  }),
                  total_listings: total_listings,
                  total_qty_available: total_qty_available,
                  total_unpublished_listings: total_unpublished_listings,
                  total_published_listings: total_published_listings,
                };
              } else if (action !== "delete") {
                sessionStorage.removeItem("checkedIds");

                eventRecords = event?.eventRecords?.map((record: any) => {
                  // PUBLISH ALL - BULK ACTION
                  if (
                    action === "publish-all" &&
                    checkedIds?.includes(record?.id) &&
                    record?.status !== "yes"
                  ) {
                    return {
                      ...record,
                      ...(record?.isInputChange && { ...record?.replacible }),
                      bulkLoader: true,
                      // deleteLoader: true,
                    };
                  }
                  // UNPUBLISH ALL - BULK ACTION
                  else if (
                    action === "unpublish-all" &&
                    checkedIds?.includes(record?.id) &&
                    record?.status === "yes"
                  ) {
                    sessionStorage.removeItem("checkedIds");
                    return {
                      ...record,
                      ...(record?.isInputChange && { ...record?.replacible }),
                      bulkLoader: true,
                      // deleteLoader: true,
                    };
                  }
                  // UDPATE PRICING - BULK ACTION
                  else if (
                    action === "update-pricing" &&
                    checkedIds?.includes(record?.id)
                  ) {
                    const increaseOrDecrease =
                      updatePriceDate?.manuplatePrice?.name;
                    const percentageOrAbsolute =
                      updatePriceDate?.percentageOrAbsolute?.name;
                    const updateAmount = Number(
                      updatePriceDate?.updatePricingValue
                    );
                    let proceedPrice: any;

                    if (record?.isInputChange) {
                      if (typeof record?.replacible?.sell_price === "object") {
                        proceedPrice = Number(
                          removeCommaFromNumber(
                            Number(record?.replacible?.sell_price?.value)
                          )
                        );
                      } else {
                        proceedPrice = Number(
                          removeCommaFromNumber(record?.replacible?.sell_price)
                        )
                          ? Number(
                              removeCommaFromNumber(
                                record?.replacible?.sell_price
                              )
                            )
                          : 0;
                      }
                    } else {
                      if (typeof record?.sell_price === "object") {
                        proceedPrice = Number(
                          removeCommaFromNumber(
                            Number(record?.sell_price?.value)
                          )
                        );
                      } else {
                        proceedPrice = Number(
                          removeCommaFromNumber(record?.sell_price)
                        )
                          ? Number(removeCommaFromNumber(record?.sell_price))
                          : 0;
                      }
                    }
                    // console.log(record);

                    // UPDATING PRICE LOGIC
                    if (increaseOrDecrease === "Increase price") {
                      if (percentageOrAbsolute === "Percentage") {
                        // INCREASE BY PERCENTAGE
                        proceedPrice = proceedPrice * (1 + updateAmount / 100);
                      } else if (percentageOrAbsolute === "Absolute") {
                        // INCREASE BY ABSOLUTE VALUE
                        proceedPrice = proceedPrice + updateAmount;
                      }
                    } else if (increaseOrDecrease === "Decrease price") {
                      if (percentageOrAbsolute === "Percentage") {
                        // DECREASE BY PERCENTAGE
                        proceedPrice = proceedPrice * (1 - updateAmount / 100);
                      } else if (percentageOrAbsolute === "Absolute") {
                        // DECREASE BY ABSOLUTE VALUE
                        proceedPrice = proceedPrice - updateAmount;
                      }
                    }

                    // ENSURE NEGATIVE VALUE DOES NOT APPLY IN PROCEED PRICE
                    proceedPrice = Math.max(proceedPrice, 0);

                    if (
                      Number(
                        removeCommaFromNumber(decimalNumber(proceedPrice))
                      ) === 0
                    ) {
                      isError = true;
                    }
                    if (
                      record?.price_per_exchange === 1 ||
                      record?.price_per_exchange === true
                    ) {
                      isPPEEnabled = true;
                    }

                    let ppeErrors =
                      record?.price_per_exchange === 1 ||
                      record?.price_per_exchange === true ||
                      Number(
                        removeCommaFromNumber(decimalNumber(proceedPrice))
                      ) === 0
                        ? true
                        : false;

                    let final = {
                      ...record,
                      ...(record?.isInputChange && {
                        ...record?.replacible,
                        isInputChange: false,
                      }),
                      ...(proceedPrice === 0 && { selected: false }),
                      // ...(proceedPrice > 0 && {
                      sell_price:
                        record?.price_per_exchange === 1 ||
                        record?.price_per_exchange === true
                          ? record?.sell_price
                          : proceedPrice?.toString(),
                      // })
                      // ,
                      price_per_exchange: record.price_per_exchange,
                      selected: record.selected,
                      ppeErrors: ppeErrors,
                      fieldErrors:
                        Number(
                          removeCommaFromNumber(decimalNumber(proceedPrice))
                        ) === 0
                          ? {
                              ...record?.fieldErrors,
                              sell_price:
                                "Proceed value field is required more than zero",
                            }
                          : {},
                      isInputChange: proceedPrice === 0 ? true : false,

                      ...((record.price_per_exchange === 1 ||
                        record?.price_per_exchange === true) &&
                        isError === false && {
                          ppePartners: updatePPEPartners(
                            record["ppePartners"],
                            null,
                            null,
                            "ppePartners",
                            { value: proceedPrice, symbol: target_currency }
                          ),
                          ticket_exchange_prices: updatePPEPartners(
                            record["ppePartners"],
                            null,
                            null,
                            "ticket_exchange_prices",
                            { value: proceedPrice, symbol: target_currency }
                          ),
                        }),
                    };

                    let errors: any = [
                      {
                        errors: [
                          ...(final?.errors && _.size(final?.errors) > 0
                            ? final?.errors?.[0]?.errors
                            : []),
                          ...(final?.fieldErrors &&
                          _.size(final?.fieldErrors) > 0
                            ? Object.keys(final?.fieldErrors)
                            : []),
                        ],
                        exchange: final?.errors?.[0]?.exchange,
                      },
                    ];

                    final = {
                      ...final,
                      ...(_.size(errors?.[0]?.errors) > 0 && {
                        errors: errors,
                        ppeErrors: ppeErrors,
                      }),
                    };

                    if (proceedPrice > 0) {
                      // updateRecordForPrice(event, final, true, checkedIdsArr);
                    }
                    setTimeout(() => {
                      layout.setisBulkActionLoader(false);
                    }, 3000);

                    sessionStorage.removeItem("checkedIds");
                    return final;
                  } else {
                    return {
                      ...record,
                      selected: false,
                    };
                  }
                });

                // DISPLAY SCHEDULED MESSAGE - BEFORE PUSHER
                if (action === "publish-all") {
                  // layout.setisBulkActionLoader(true);

                  const alertMessage = `${changedSelectedCount(
                    list
                  )} listing(s) have been scheduled for publication`;
                  showAlert(alertMessage, false);
                } else if (action === "unpublish-all") {
                  // layout.setisBulkActionLoader(true);

                  const alertMessage = `${changedSelectedCount(
                    list
                  )} listing(s) have been scheduled for unpublished`;
                  showAlert(alertMessage, false);
                } else if (action === "update-pricing") {
                  // layout.setisBulkActionLoader(true);
                  if (isError || isPPEEnabled) {
                    const alertMessage = isPPEEnabled
                      ? Messages?.bulkEditRestrictPPE
                      : `Please solve errors`;
                    showAlert(alertMessage, true);
                    // layout.setisBulkActionLoader(true);
                  } else {
                    const alertMessage = `${changedSelectedCount(
                      list
                    )} listing(s) have been scheduled for update`;
                    showAlert(alertMessage, false);
                    layout.setisBulkActionLoader(true);
                  }
                }
              }
              return {
                ...event,
                eventRecords: eventRecords,
              };
            });
          });
        }

        let timeoutId;

        if (action === "delete" || action === "listing-transfer") {
          // Set the timeout and save the ID
          timeoutId = setTimeout(() => {
            setList((events: any) => {
              let final = events?.map((event: any) => {
                return {
                  ...event,
                  eventRecords: event?.eventRecords?.filter(
                    (record: any) => !record.deleteLoader
                  ),
                };
              });
              final = final?.filter(
                (event: any) => event?.total_listings > 0
                // event?.total_listings > 0 &&
                // (event.eventRecords === undefined ||
                //   event.eventRecords.length > 0)
              );
              layout.setUpdateAllLoader(false);
              return final;
            });
          }, 700);

          if (isGodAdmin && action === "listing-transfer") {
            layout.setUpdateAllLoader(false);
            timeoutId = setTimeout(() => {
              setList((events: any) => {
                const final = events?.map((event: any) => ({
                  ...event,
                  eventRecords: event?.eventRecords?.map((record: any) => ({
                    ...record,
                    bulkLoader: false,
                    user_name:
                      (record?.selected && teamMemberSelected?.name) ||
                      record?.user_name,
                    selected: false,
                  })),
                }));

                return final;
              });
            }, 700);
          }

          // TRANSER OWNERSHIP - API
          action === "listing-transfer" &&
            handleListingTransfer({
              user_id: teamMemberSelected?.id,
              checked_ids: checkedIdsArr,
            }).then((response: any) => {
              if (
                response?.data?.errors &&
                Object.keys(response?.data?.errors).length === 0
              ) {
                // showAlert(response?.message, false);
              } else if (response?.data?.errors) {
                Object.values(response?.data?.errors).forEach((error: any) => {
                  showAlert(error, true);
                });
              }
              if (response) {
                setTimeout(() => {
                  let emptyRecords: any = [];

                  setList((current: any) => {
                    let filteredEvents = current?.filter((event: any) => {
                      return (
                        Array.isArray(event.eventRecords) &&
                        event.eventRecords.length === 0
                      );
                    });

                    emptyRecords = filteredEvents;
                    return current;
                  });

                  emptyRecords &&
                    size(emptyRecords) > 0 &&
                    emptyRecords?.map(async (item: any) => {
                      await Promise.all(loadMoreListing(item, "pusher"));
                    });
                }, 2000);
              }
            });
        }

        // BULK PRICE UPDATE - API
        if (action === "update-pricing") {
          timeoutId = setTimeout(async () => {
            let validRecordsArray: any = [];

            setList((events: any) => {
              let final = events?.map((event: any) => {
                let eventRecords = event?.eventRecords?.filter(
                  (item: any) =>
                    Number(item?.sell_price) > 0 && item?.ppeErrors === false
                );
                return {
                  ...event,
                  eventRecords: eventRecords,
                };
              });
              validRecordsArray = final;
              return events;
            });

            let result = bulkPayloadCreate(validRecordsArray, "selected");
            let batchSize: any = 100;
            const dataLength = result.length;

            if (result?.length > 0) {
              updateState(true, true, true, false, result);
              if (dataLength > batchSize) {
                const { mergedObject, is_ticket_type_change }: any =
                  await processInBatches(result, batchSize);
                if (mergedObject) {
                  updateState(
                    false,
                    false,
                    false,
                    mergedObject,
                    result,
                    is_ticket_type_change
                  );
                  layout.setisBulkActionLoader(false);
                }
              } else {
                const response: any = await bulkUpdate(result);
                if (response?.data?.status) {
                  let payloadId = response?.data?.payload;
                  updateState(false, false, false, payloadId, result);
                  layout.setisBulkActionLoader(false);
                }
              }
            }
          }, 1000);
        }

        // if (action === "Transfer ownership") {
        // }
      }

      layout.setTableAlert({});
      action !== "update-pricing" &&
        action !== "listing-transfer" &&
        handleBulk({ checked_ids: checkedIdsArr, action }).then(
          (response: any) => {
            if (Object.keys(response?.data?.errors).length === 0) {
              // showAlert(response?.message, false);
            } else {
              Object.values(response?.data?.errors).forEach((error: any) => {
                showAlert(error, true);
              });
            }
            let timeoutId: any;
            if (response) {
              // NEW CODE FETCHING NEW RECORD
              timeoutId = setTimeout(async () => {
                let recordsToLoad: any = [];

                setList((events: any) => {
                  let updatedEvents = events?.map((event: any) => {
                    return {
                      ...event,
                      eventRecords: event?.eventRecords?.filter(
                        (record: any) => !record.deleteLoader
                      ),
                    };
                  });

                  let eventsWithEmptyRecords = updatedEvents?.filter(
                    (event: any) =>
                      event?.total_listings > 0 &&
                      event?.eventRecords?.length === 0
                  );

                  recordsToLoad.push(...eventsWithEmptyRecords);

                  return updatedEvents;
                });

                if (recordsToLoad.length > 0) {
                  // Assuming loadMoreListing returns a Promise
                  await Promise.all(
                    recordsToLoad.map((obj: any) =>
                      loadMoreListing(obj, "pusher")
                    )
                  );
                }
              }, 100);
            }
          }
        );
    };

    const [teamMemberSelected, setTeamMemberSelected] = useState<any>();
    const [teamMemberOption, setTeamMemberOption] = useState<any>();

    useEffect(() => {
      if (layout.teamMembers) {
        let getAuthData: any = localStorage.getItem("authData") as string;
        //  Cookies.get("authData") as string;
        let allAutData = getAuthData ? JSON.parse(getAuthData) : {};
        const options = layout.transferOwnershipTeamMembers?.filter(
          (obj: any) => obj?.id !== allAutData?.id
        );

        const godAdminOption = list
          ?.flatMap((item: any) =>
            item?.eventRecords
              ?.filter((record: any) => record?.selected)
              .map((record: any) => ({
                team_id: record.team_id,
                user_id: record.user_id,
              }))
          )
          .filter((obj: any) => obj !== undefined);

        const filterOption = teamsOptions?.filter(
          (obj: any) => obj.id === godAdminOption?.[0]?.team_id
        );
        const newOption = groupTeamMemberCommon(filterOption);

        setTeamMemberOption(
          isGodAdmin
            ? newOption[0]?.options?.filter(
                (option: any) => option.id !== godAdminOption?.[0].user_id
              )
            : options
        );
      }
    }, [layout.teamMembers, list, teamsOptions, isGodAdmin]);

    return (
      <>
        <div
          id="bulk-main"
          className="flex flex-wrap bg-indigo-500 bg-opacity-[7%] min-h-[3.125rem]"
        >
          <div className="py-2.5 lg:py-4 px-5 font-semibold text-sm13 sm:border-r border-b text-left w-full lg:w-auto">
            {layout?.tableAlert?.actionTitle}
          </div>
          <div className="flex items-center justify-start lg:justify-start flex-wrap flex-auto gap-x-5 gap-y-3 py-2.5 px-5 text-xs border-b">
            <div className="flex items-center lg:text-center">
              {layout?.tableAlert?.actionTitle === "Update pricing" ? (
                <>
                  <div className="flex gap-2.5 text-left max-md:flex-wrap">
                    <div className="max-md:w-full">
                      <DropDownField
                        options={manupulatePriceOptions}
                        isClearable={true}
                        name="manuplatePrice"
                        placeholder="Increase / Decrease"
                        value={updatePricingState?.manuplatePrice}
                        handleOnChange={bulkUpdatePriceHandle}
                      />
                    </div>

                    <div className="max-md:w-full">
                      <DropDownField
                        options={uncertainOptionsForUpdatePricing}
                        isClearable={true}
                        name="percentageOrAbsolute"
                        placeholder={`Pct. /  ${
                          isGodAdmin ? "Amount" : "Absolute"
                        }`}
                        getOptionLabel="labelName"
                        isCapital={true}
                        isValueCapital={true}
                        value={updatePricingState?.percentageOrAbsolute}
                        handleOnChange={bulkUpdatePriceHandle}
                      />
                    </div>
                    <div className="max-md:w-full">
                      <TextInput
                        name="updatePricingValue"
                        value={updatePricingState?.updatePricingValue}
                        autoComplete="off"
                        required={false}
                        type="number"
                        label="Proceed price"
                        id="updatePricingValue"
                        disabled={
                          updatePricingState?.manuplatePrice &&
                          updatePricingState?.percentageOrAbsolute
                            ? false
                            : true
                        }
                        handleOnChange={bulkUpdatePriceHandle}
                        handleClearValue={() => {
                          setUpdatePricingState((current: any) => {
                            return {
                              ...current,
                              updatePricingValue: "",
                            };
                          });
                        }}
                        inputClassName="placeholder:truncate block px-2.5 font-medium w-full h-[1.875rem] text-xs transition bg-white border border-gray-300 appearance-none rounded focus:outline-none  focus:ring-0 focus:border-indigo-500  peer peer-focus:bg-white autofill:bg-indigo-500 form"
                        labelClassName="absolute text-xxs text-gray-400 duration-300 transform -translate-y-4 font-medium top-2 left-3 z-10 origin-[0] bg-white peer-focus:bg-white px-2 peer-focus:px-2 peer-focus:text-indigo-500 peer-placeholder-shown:-translate-y-1/2  peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-placeholder-shown:text-xs peer-focus:text-xxs peer-placeholder-shown:text-gray-500 peer-focus:-translate-y-4"
                      />
                    </div>
                  </div>
                </>
              ) : layout?.tableAlert?.actionTitle === "Transfer ownership" ? (
                <>
                  <div className="flex gap-x-2.5 text-left">
                    <div>
                      <DropDownField
                        options={teamMemberOption}
                        isClearable={true}
                        name="transfer_owenership"
                        placeholder="Transfer ownership"
                        value={teamMemberSelected}
                        handleOnChange={(e: any) => {
                          setTeamMemberSelected(e?.target?.value);
                        }}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <p>{layout?.tableAlert?.message}</p>
              )}
            </div>
            <div className="flex items-center gap-2.5">
              {layout?.tableAlert?.actionTitle === "Update pricing" && (
                <button
                  type="button"
                  data-tooltip-id={`reset-bulk-action`}
                  className="group flex items-center justify-center h-6 w-6 border border-gray-300 hover:border-indigo-500 text-white bg-white hover:bg-indigo-500 transition rounded"
                  onClick={() => {
                    setUpdatePricingState(initialUpdatePricing);
                  }}
                >
                  <IKTSVG
                    className="fill-violet-500 group-hover:fill-white transition"
                    svgClassName="w-3 h-3"
                    path={ReloadIconSVG}
                  />
                  <Tooltip
                    id={`reset-bulk-action`}
                    content={`Reset`}
                    place="top"
                    variant="light"
                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10 "
                  />
                </button>
              )}
              <button
                type="button"
                data-tooltip-id={`cancel-bulk-action`}
                className="group flex items-center justify-center h-6 w-6 border border-gray-300 hover:border-indigo-500 text-white bg-white hover:bg-indigo-500 transition rounded"
                onClick={() => {
                  layout.setTableAlert({});
                  setUpdatePricingState(initialUpdatePricing);
                }}
              >
                <IKTSVG
                  className="fill-violet-500 group-hover:fill-white transition"
                  path={CrossSVG}
                  svgClassName="w-[.5625rem] h-[.5625rem]"
                />
                <Tooltip
                  id={`cancel-bulk-action`}
                  content={`Cancel`}
                  place="top"
                  variant="light"
                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10 "
                />
              </button>
              <button
                type="button"
                data-tooltip-id={`confirm-bulk-action`}
                disabled={isBulkUpdateBtnDisabled}
                className={`flex items-center justify-center h-6 w-6  ${
                  isBulkUpdateBtnDisabled ||
                  (layout?.tableAlert?.actionTitle === "Transfer ownership" &&
                    _.size(teamMemberSelected) === 0)
                    ? "bg-gray-300 pointer-events-none"
                    : "bg-violet-500 hover:bg-indigo-500"
                }  transition rounded`}
                onClick={() => {
                  //   if (layout?.tableAlert?.actionTitle === "Update pricing") {
                  //     confirmedBulkActionForUpdatePrice(
                  //       layout?.tableAlert?.confirm,
                  //       updatePricingState
                  //     );
                  //     setUpdatePricingState(initialUpdatePricing);
                  //   } else {
                  //     updateRecord(layout?.tableAlert?.confirm);
                  //   }
                  confirmedBulkAction(
                    layout?.tableAlert?.confirm,
                    updatePricingState
                  );
                }}
              >
                <IKTSVG
                  className={`${
                    isBulkUpdateBtnDisabled ? "fill-gray-400" : "fill-white"
                  }`}
                  svgClassName="w-3 h-3"
                  path={CheckSVG}
                />
                <Tooltip
                  id={`confirm-bulk-action`}
                  content={`Confirm`}
                  place="top"
                  variant="light"
                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10 "
                />
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
);

export default BulkOperations;
