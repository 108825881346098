import { LISTINGS_UTILS } from "helpers/const";
import { useState } from "react";
import PageTitle from "../../components/core/PageTitle";
import Inventory from "./Inventory";
import { InventoryProvider } from "./core/InventoryProvider";

const InventoryWrapper = () => {
  const [list, setList] = useState([]);
  const [publishStatus, setPublishStatus] = useState<any>([]);
  const [currentRecordId, setCurrentRecordId] = useState<any>();
  const [query, setQuery] = useState<any>("");

  return (
    <InventoryProvider>
      <div className="main-content-wrap bot_spacing">
        <PageTitle title={LISTINGS_UTILS?.myListings.name} />
        <Inventory
          list={list}
          setList={setList}
          publishStatus={publishStatus}
          setPublishStatus={setPublishStatus}
          currentRecordId={currentRecordId}
          setCurrentRecordId={setCurrentRecordId}
          setQuery={setQuery}
          query={query}
        />
      </div>
    </InventoryProvider>
  );
};

export default InventoryWrapper;
