/* eslint-disable @typescript-eslint/no-unused-vars */
import { LISTINGS_UTILS } from "helpers/const";
import { useState } from "react";

const AddInventoryButton = ({
  handleOnClick,
  classNames,
  eventLength,
  isActive = false,
}: any) => {
  // let eventLength = layout && layout.events.length > 0;
  const [addInventory, setAddInventory] = useState(true);

  return (
    <button
      type="button"
      className={`relative inline-flex items-center z-10 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5  ${
        eventLength
          ? "pointer-events-none !text-gray-400 bg-gray-100 "
          : " bg-violet-500 border border-violet-500 hover:border-indigo-500 "
      } ${
        isActive
          ? "!bg-violet-500 text-white border !border-violet-500  hover:!bg-indigo-500 hover:!border-indigo-500"
          : "text-white hover:bg-indigo-500"
      } ${classNames}`}
      onClick={(data: any) => {
        setAddInventory((pre: any) => !pre);
        handleOnClick(addInventory);
      }}
    >
      Add to {LISTINGS_UTILS?.myListings.name}
    </button>
  );
};

export default AddInventoryButton;
