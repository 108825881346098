export const EyeIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="10.909"
    viewBox="0 0 16 10.909"
    {...props}
  >
    <path
      id="eye"
      d="M9,7.773a2.182,2.182,0,1,0,2.182,2.182A2.182,2.182,0,0,0,9,7.773m0,5.818a3.636,3.636,0,1,1,3.636-3.636A3.636,3.636,0,0,1,9,13.591M9,4.5A8.6,8.6,0,0,0,1,9.955a8.594,8.594,0,0,0,16,0A8.6,8.6,0,0,0,9,4.5Z"
      transform="translate(-1 -4.5)"
    />
  </svg>
);
