export const SALES_NEST_ROUTES = {
  PENDING: {
    path: "pending",
    allow: "*",
  },
  AWAITING_DELIVERY: {
    path: "awaiting-delivery",
    allow: "*",
  },
  DELIVERED: {
    path: "delivered",
    allow: "*",
  },
  COMPLETED: {
    path: "completed",
    allow: "*",
  },
  CANCELLED: {
    path: "cancelled",
    allow: "*",
  },
  OUT_TO_OFFER: {
    path: "out-to-offer",
    allow: "godAdmin",
  },
};

export const ACTIVE_TAB_KEY = {
  [`/sales/${SALES_NEST_ROUTES.PENDING.path}`]: "new",
  [`/sales/${SALES_NEST_ROUTES.AWAITING_DELIVERY.path}`]: "to_be_fulfilled",
  [`/sales/${SALES_NEST_ROUTES.DELIVERED.path}`]: "fulfilled",
  [`/sales/${SALES_NEST_ROUTES.COMPLETED.path}`]: "completed",
  [`/sales/${SALES_NEST_ROUTES.CANCELLED.path}`]: "cancelled",
  [`/sales/${SALES_NEST_ROUTES.OUT_TO_OFFER.path}`]: "out_to_offer",
};

export const TAB_WISE_ROUTE = Object.fromEntries(
  Object.entries(ACTIVE_TAB_KEY).map(([key, value]) => [value, key])
);

export const SALES_REDIRECT_ROUTES: any = {
  Pending: `/sales/${SALES_NEST_ROUTES.PENDING.path}`,
  "Awaiting Delivery": `/sales/${SALES_NEST_ROUTES.AWAITING_DELIVERY.path}`,
  Delivered: `/sales/${SALES_NEST_ROUTES.DELIVERED.path}`,
  Completed: `/sales/${SALES_NEST_ROUTES.COMPLETED.path}`,
  Cancelled: `/sales/${SALES_NEST_ROUTES.CANCELLED.path}`,
  OutToOffer: `/sales/${SALES_NEST_ROUTES.OUT_TO_OFFER.path}`,
};

export const COLUMN_NOT_FOR_GOD_ADMIN: string[] = [
  "Team name",
  "Team member",
  "Marketplace value",
  "Settlement/net payout value",
  "Marketplace",
  "Marketplace ID",
  "Internal replacement status",
  "Internal order status",
  "Referral amount",
];
