import { LayoutContext } from "components/core/LayoutProvider";
import HeaderCell from "pages/subscribe/personal_details/table/component/HeaderCell";
import { useContext } from "react";

const TableHeaderRow = ({ setTeamApiFilter, teamApiFilter }: any) => {
  const globalLayout = useContext(LayoutContext);

  const handleOnSort = (key: any) => {
    setTeamApiFilter((preValue: any) => {
      return {
        ...preValue,
        order_by: key,
        sort_order:
          preValue &&
          Object.keys(preValue).length > 0 &&
          preValue.order_by !== key
            ? "desc"
            : preValue.sort_order === "desc"
            ? "asc"
            : preValue.sort_order === "asc"
            ? undefined
            : "desc",
        page: 1,
        per_page: 20,
      };
    });
  };

  return (
    <tr className="border-b  bg-white ">
      <HeaderCell
        title={"Name"}
        width="w-[12rem] min-w-[12rem]"
        arrow={true}
        postKey="firstname"
        sortFor="secondary_user"
        onClick={handleOnSort}
        orderForSort={teamApiFilter}
        padding="!pl-5"
      />

      <HeaderCell
        title={"Email"}
        width="w-[15rem] min-w-[15rem]"
        arrow={true}
        postKey="email"
        padding="!pl-0"
        sortFor="secondary_user"
        onClick={handleOnSort}
        orderForSort={teamApiFilter}
      />
      <HeaderCell
        title={"Phone number"}
        width="w-[15rem] min-w-[15rem]"
        arrow={false}
        postKey="name"
        padding="!pl-0"
        sortFor="secondary_user"
      />

      <th className="hidden md:sticky relative right-0 ml-auto  p-0 md:w-[4rem] md:max-w-[4rem] md:min-w-[4rem] w-[4rem] max-w-[4rem] min-w-[4rem]"></th>
      {globalLayout?.isMobile && (
        <th className="sticky right-0 ml-auto  p-0 w-10 min-w-[2.5rem]">
          <div
            className="py-[.4375rem] px-4 flex justify-end h-[2.5rem]  bg-white max-md:pointer-events-none"
            id=""
          ></div>
        </th>
      )}
    </tr>
  );
};

export default TableHeaderRow;
