import { convertIntoDateFormat } from "./_functions";

export interface IVcTransactionParams {
  transaction_type?: { id?: number };
  team_id?: any;
  page: number;
  per_page: number;
  transaction_date: any;
  export_to_csv?: number;
  export_to_pdf?: number;
  order_by: any;
  sort_order: any;
}

export const mapVcTransactionsData = (
  id: number,
  filters: IVcTransactionParams,
  debouncedSearch: any
) => {
  let params: any = {
    id,
    page: filters?.page ?? 1,
    per_page: filters?.per_page ?? 20,
  };
  if (filters?.transaction_type?.id)
    params.transaction_type = filters?.transaction_type?.id;

  if (filters?.transaction_date) {
    params.from_transaction_date = convertIntoDateFormat(
      filters?.transaction_date?.startDate
    );

    params.to_transaction_date = convertIntoDateFormat(
      filters?.transaction_date?.endDate
    );
  }

  if(filters?.team_id?.id) params.team_id = [filters?.team_id?.id]

  if (debouncedSearch) params.query = debouncedSearch;

  if (filters?.export_to_csv === 1) params.export_to_csv = 1;

  if (filters?.export_to_pdf === 1) params.export_to_pdf = 1;

  if(filters?.order_by) params.order_by = filters?.order_by;

  if(filters?.sort_order) params.sort_order = filters?.sort_order;

  return params;
};
