 import { LayoutContext } from "components/core/LayoutProvider";
import DatePickerField from "components/formComponent/DatePickerField";
import MultiDropDownField from "components/formComponent/MultiDropDownField";
import { handleSelectDeselectAll } from "helpers/Functions";
import { useContext, useRef } from "react";
import { SellerLevelsProviderContex } from "../core/SellerLevelsProvider";

const MovementFilters = ({ loading, setFiltersData }: any) => {
  const layout = useContext(SellerLevelsProviderContex);
  const globalLayout = useContext(LayoutContext);
  const teamMemberSelectedRef = useRef<any>();

  const handleOnSelection = (
    data: any,
    fieldName: string,
    options: any,
    visibleOptions: any = null
  ) => {
    if (teamMemberSelectedRef && teamMemberSelectedRef?.current) {
      teamMemberSelectedRef.current.blur();
    }
    handleSelectDeselectAll(
      data,
      fieldName,
      options,
      visibleOptions,
      layout.setSellerLevelMovementFilters
    );
  };

  return (
    <div className={` rounded flex gap-4 w-full`}>
      <div
        className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem]`}
      >
        <div className={`${loading && "shimmer-effect"} rounded`}>
          <DatePickerField
            name="dateRange"
            placeholder="Transaction date"
            disabledKeyboardNavigation={false}
            value={layout.sellerLevelMovementFilters?.dateRange}
            isDateRange={true}
            handleOnChange={(data: any) => {
              layout.setSellerLevelMovementFilters((currentValue: any) => {
                return {
                  ...currentValue,
                  [data?.target?.name]: data?.target?.value,
                  page: 1,
                };
              });
            }}
            format="dd/MM/yy"
          />
        </div>
      </div>
      <div
        className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.3125rem]`}
      >
        <div className={`${loading && "shimmer-effect"} rounded`}>
          <MultiDropDownField
            name="team_id"
            classNamePrefix={"form_multiDropDown"}
            placeholder="Team members"
            value={layout.sellerLevelMovementFilters?.team_id || []}
            handleOnChange={(data: any) => {
              layout.setSellerLevelMovementFilters((prev: any) => {
                return {
                  ...prev,
                  team_id: data?.target?.value,
                  page: 1,
                };
              });
            }}
            options={globalLayout?.allowedAccess?.teams || []}
            // isMenuPortalTarget={orderIsLoading ? false : true}
            selectedRef={teamMemberSelectedRef}
            isMultiSelection={true}
            handleOnSelection={(data: any, visibleOptions: any) =>
              handleOnSelection(
                data,
                "team_id",
                globalLayout?.allowedAccess?.teams,
                visibleOptions
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

export default MovementFilters;
