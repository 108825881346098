import { size } from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { TableVirtuoso } from "react-virtuoso";
import fileSVG from "../../../assets/media/icons/other_icons/file.svg";
import processSVG from "../../../assets/media/icons/other_icons/pure-process.svg";
import angleLeftSVG from "../../../assets/media/icons/standard_icons/angle_left.svg";
import angleRightSVG from "../../../assets/media/icons/standard_icons/angle_right.svg";
import cross from "../../../assets/media/icons/standard_icons/cross.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import {
  addCommaInNumber,
  decimalNumber,
  removeCommaFromNumber,
} from "../../../helpers/Functions";
import { showAlert } from "../../../helpers/ShowAlert";
import {
  SYMBOLS,
  TX_ACCOUNT_ORDER_PAYOUT_SELLER,
} from "../../../helpers/const";
import TooltipPortal from "../../tx_trade/table/component/TooltipPortal";
import HeaderCell from "../Table/component/HeaderCell";
import TableRow from "../Table/component/TableRow";
import { TxAccountContext } from "../core/TxAccountProvider";
import {
  getTXAccountOrdersListings,
  getTXAccountPayoutListings,
} from "../core/_request";
import { downloadFile } from "../core/functions";
import DraftInvoiceItemContent from "./DraftInvoiceItemContent";
import ExcludeContainerPayout from "./ExcludeContainerPayout";
import NoDataAvailable from "./NoDataAvailable";
import OrderFooterPayoutSeller from "./OrderFooterPayoutSeller";
import PayoutAccordionHeader from "./PayoutAccordionHeader";
import PayoutsAccordian from "./PayoutsAccordian";
import PopupDetailBox from "./PopupDetailBox";
import TableShimmer from "./TableShimmer";

const Table = ({ style, ...props }: any) => (
  <table
    {...props}
    className="w-full text-xs text-left  min-w-[87.5rem]"
    style={{
      ...style,
      //width: 700
    }}
  />
);
const TableHead = ({ style, ...props }: any) => (
  <thead
    {...props}
    className="text-xs text-gray-400 "
    style={{
      ...style,
      // position: "static",
    }}
  />
);

const OrderPayoutSeller = () => {
  const layout = useContext(TxAccountContext);
  let { listing_data } = layout.orderPayoutSeller;
  const filterData = listing_data?.filterData;
  const orderFilters = layout?.orderFilters;

  // WALLET VS ORDER TOTAL - ARRAY
  let walletRevenueArr = filterData?.currency_payout_totals?.map(
    (item: any, index: number) => {
      let currency =
        layout.ordersOverViewDetails?.overview?.tixstock_currency_balances?.find(
          (payout: any) => payout.currency === item?.currency
        );
      return {
        ...item,
        total: item?.total === 0 ? "0.00" : removeCommaFromNumber(item?.total),
        ...(currency
          ? {
              balance: currency?.balance
                ? removeCommaFromNumber(currency?.balance).toFixed(2)
                : "0.00",
            }
          : {}),
      };
    }
  );

  const checkTotalsVsBalance = (data: any) => {
    return data.some(
      ({ total, balance }: any) =>
        parseFloat(removeCommaFromNumber(total)) >=
          removeCommaFromNumber(balance) ||
        parseFloat(removeCommaFromNumber(total)) < 0
    );
  };

  const isDisabled =
    layout.orderPayoutSeller?.authorize_pay_next_step ||
    layout.orderPayoutSeller?.finish;

  const [excludeOrderValue, setExcludeOrderValue] = useState<any>({
    excludeOrder: "",
    excludeOrderFilterChip: [],
    payoutReference: "",
  });
  const [dataList, setDataList] = useState<any>(listing_data?.list);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [payoutId, setPayoutId] = useState({
    status: false,
    id: "",
  });
  const [viewData, setViewData] = useState<any>();
  const [payOutFilters, setPayoutFiltersData] = useState<any>({
    page: 1,
    per_page: 20,
  });
  const [paginateData, setPaginateData] = useState<any>({
    current_page: layout.ordersOverViewDetails?.meta?.current_page,
    last_page: layout.ordersOverViewDetails?.meta?.last_page,
  });
  const [isExportLoading, setIsExportLoading] = useState<boolean>(false);

  const scroll = (scrollOffset: any) => {
    let replaceTable: any = document.getElementById(
      "sales-repalcement-lazyload"
    );
    replaceTable && (replaceTable.scrollLeft += scrollOffset);
  };

  const newData = layout.orderFilters;

  const fetchMoreData = async (data: any) => {
    const { page, ...rest } = newData;
    const orderFilters = {
      page: data?.page,
      ...rest,
    };

    try {
      const response = await getTXAccountOrdersListings({
        orderFilters,
        exclude_order_ids: layout.orderPayoutSeller?.excludesOrderId,
      });
      if (response) {
        setIsLoading(false);
        setPaginateData((pre: any) => {
          return {
            ...pre,
            current_page: response?.meta?.current_page,
            last_page: response?.meta?.last_page,
          };
        });
        setDataList((pre: any) => {
          return [...pre, ...response?.data?.orders];
        });
      }
    } catch (error) {
      // Handle error here if needed
      console.error("Error fetching more data:", error);
      throw error; // Rethrow the error to be caught by the caller
    }
  };

  // PAYOUT VIEW - API
  const { isFetching: isLoading } = useQuery(
    [`payout-view-page-order-footer`, payoutId?.id],
    () =>
      getTXAccountPayoutListings({
        id: payoutId?.id,
        payOutFilters,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: payoutId?.status ? true : false,
      cacheTime: 0,
      onSettled(data, err) {
        setViewData({
          data: data?.data?.[0],
          meta: data?.meta,
        });
      },
    }
  );

  // GET TXACCOUNT FOR ORDERS LISTING
  const { refetch, isLoading: payoutSellerLoading } = useQuery(
    [`TX-ACCOUNT-ORDERS-LISTING-PAYOUT`, layout.orderFilters],
    () =>
      getTXAccountOrdersListings({
        orderFilters: layout.orderFilters,
        exclude_order_ids: layout.orderPayoutSeller?.excludesOrderId,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      cacheTime: 0,
      enabled:
        layout.orderFilters?.order_by && layout.orderFilters?.sort_order
          ? true
          : false,
      onSettled: (data, err) => {
        setDataList(data?.data?.orders);
      },
    }
  );

  const [height, setHeight] = useState<any | undefined>(undefined);
  useEffect(() => {
    const thead = document.querySelector("#tableId11 table thead");
    const theadHeight: any = thead?.scrollHeight;
    const dataLength = payoutId?.status
      ? size(viewData?.data?.items)
      : size(dataList);

    const dataHeight = theadHeight + dataLength * 45;
    setHeight(dataLength > 10 ? 400 : dataHeight);
  });

  const exportToCsv = async (e: any) => {
    e.preventDefault();
    setIsExportLoading(true);
    await getTXAccountOrdersListings({
      orderFilters,
      exclude_order_ids: layout.orderPayoutSeller?.excludesOrderId,
      export_to_view_csv: 1,
      get_teams: 1,
    }).then((response: any) => {
      if (response?.data && size(response?.data) > 0) {
        downloadFile({
          data: response?.data?.replace(/\\n/g, "\n"),
          fileName: "seller_payout.csv",
          fileType: "text/csv",
        });
      } else if (response?.errors && size(response?.errors) > 0) {
        showAlert(response?.errors?.title, true);
      } else {
        showAlert("Something went wrong!", true);
      }
      setIsExportLoading(false);
    });
  };

  // let timeout: any;
  // const renderCall = () => {
  //   timeout = setTimeout(() => {
  //     const divElement: any = document.querySelector("#payout-inner-lazyload");
  //     const divElement1: any = document.querySelector(
  //       "#sales-repalcement-lazyload"
  //     );
  //     const thead = document.querySelector("#tableId11 table thead");
  //     const theadHeight: any = thead?.scrollHeight;
  //     const nomoreListing = document.getElementById("NoMoreListing");
  //     let nomoreListingHeight = nomoreListing?.clientHeight;
  //     // setTimeout(() => {
  //     //   nomoreListingHeight = nomoreListing?.clientHeight;
  //     // }, 2000);

  //     const dataHeight = theadHeight + dataLength * 45;
  //     console.log(dataLength);

  //     console.log(dataHeight);
  //     let elemHeight = dataHeight + theadHeight + 1;
  //     console.log(elemHeight, "elemHeight");

  //     if (divElement || divElement1) {
  //       let elemHeight = dataHeight + theadHeight + 1;
  //       console.log(elemHeight, "elemHeight");

  //       setHeight(elemHeight);
  //     } else {
  //       setTimeout(renderCall, 100);
  //     }
  //   }, 500);
  // };
  // useLayoutEffect(() => {
  //   renderCall();
  //   window.addEventListener("scroll", renderCall);
  //   // Clear the timeout in case the component unmounts or the effect re-runs
  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // });

  return (
    <>
      <div className="main-content-wrap bg-white">
        <div className="inner_wrap flex flex-col lg2:h-[calc(100vh-3.8125rem)]">
          <div className="offcanvas-header flex justify-between border-b">
            <h5
              className="offcanvas-title mb-0 leading-normal text-sm15 font-semibold py-2 pr-5 pl-[1.875rem]"
              id="offcanvasRightLabel"
            >
              Payout
            </h5>

            {/* CLOSE DRAFT */}
            <button
              type="button"
              className="flex items-center justify-center w-9 border-l box-content group"
              data-tooltip-id={`close-draft`}
              onClick={() => {
                layout?.setOrderPayoutSeller(TX_ACCOUNT_ORDER_PAYOUT_SELLER);
              }}
            >
              <TooltipPortal
                id={`close-draft`}
                content={"Close"}
                place="left"
                className="text-center  !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-100 max-w-[9rem] cursor-default "
              />
              <IKTSVG
                path={cross}
                className="fill-violet-500 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
                svgClassName="w-2.5 h-2.5"
              />
            </button>
          </div>

          <div className="offcanvas-body max-h-full w-full flex flex-col overflow-hidden h-full">
            <div className="inner_body flex flex-wrap h-full">
              {/* LEFT */}
              <div className="left_body border-r lg2:w-1/2 w-full h-full scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full overflow-auto p-5 flex flex-col">
                <div className="totle_order mb-5 flex justify-between items-center w-full">
                  <h5 className="font-semibold text-sm14 leading-4 ">
                    {payoutId?.status
                      ? viewData?.data?.items?.length
                        ? `${addCommaInNumber(
                            viewData?.data?.items?.length
                          )} Payouts`
                        : "0 Payout"
                      : addCommaInNumber(filterData?.orders?.value) + " orders"}
                  </h5>

                  {payoutId?.status && (
                    <button
                      type="button"
                      className="flex items-center justify-center w-9  box-content group"
                      data-tooltip-id={`close-draft`}
                      onClick={() => {
                        setPayoutId(() => ({
                          status: false,
                          id: "",
                        }));
                      }}
                    >
                      <TooltipPortal
                        id={`close-draft`}
                        content={"Close"}
                        place="left"
                        className="text-center  !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-100 max-w-[9rem] cursor-default "
                      />
                      <IKTSVG
                        path={cross}
                        className="fill-violet-800 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
                        svgClassName="w-2.5 h-2.5"
                      />
                    </button>
                  )}
                </div>

                {viewData?.data?.items?.length === 0 ? (
                  <NoDataAvailable />
                ) : (
                  <div className="left_table border h-full rounded overflow-hidden">
                    {payoutSellerLoading ? (
                      <TableShimmer loader={payoutSellerLoading} />
                    ) : (
                      <div className="accordion-body relative h-full">
                        <div
                          className={`order-replacement-table accordion-payout-table  overflow-auto scrollbar-thin scrollbar-thumb-violet-800
                        scrollbar-thumb-rounded-full hover-style2 lg2:!h-full invoice-table anim-fade`}
                          id={`tableId11`}
                          style={{
                            height: `${height}px`,
                          }}
                        >
                          {payoutId?.status ? (
                            isLoading && payOutFilters?.page === 1 ? (
                              Array.from({ length: 10 }, (v, i) => (
                                <div
                                  className={`accordion font-medium  mb-2.5 py-2${
                                    i === 0 && "mt-5"
                                  } `}
                                >
                                  <div
                                    key={i}
                                    className={`accordion-item bg-white !rounded overflow-hidden ${
                                      isLoading && "shimmer-effect"
                                    } `}
                                    style={{ height: "40px" }}
                                  ></div>
                                </div>
                              ))
                            ) : (
                              // ACCORDION

                              <TableVirtuoso
                                data={viewData?.data?.items}
                                endReached={() => {
                                  if (
                                    viewData?.meta?.current_page <
                                    viewData?.meta?.last_page
                                  ) {
                                    setPayoutFiltersData((current: any) => {
                                      let nextPage: number =
                                        viewData?.meta?.current_page + 1;
                                      return {
                                        ...current,
                                        page: nextPage,
                                      };
                                    });
                                  }
                                }}
                                overscan={200}
                                totalCount={viewData?.data?.items?.length}
                                id={"payout-inner-lazyload"}
                                itemContent={(index, data) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <PayoutsAccordian
                                        item={data}
                                        index={index}
                                      />
                                    </React.Fragment>
                                  );
                                }}
                                fixedHeaderContent={() => (
                                  <PayoutAccordionHeader />
                                )}
                              />
                            )
                          ) : (
                            // TABLE
                            <>
                              {filterData?.orders?.value > 0 && (
                                <TableVirtuoso
                                  data={dataList}
                                  overscan={200}
                                  totalCount={dataList?.length}
                                  id={"sales-repalcement-lazyload"}
                                  endReached={() => {
                                    if (
                                      paginateData?.last_page >
                                        paginateData?.current_page &&
                                      !loading
                                    ) {
                                      setIsLoading(true);
                                      let nextPage: number =
                                        paginateData?.current_page + 1;

                                      if (nextPage) {
                                        fetchMoreData({
                                          page: nextPage,
                                          per_page: 20,
                                        });
                                      }
                                    }
                                  }}
                                  components={{
                                    Table: Table,
                                    TableHead: TableHead,
                                    TableRow: (props) => {
                                      return (
                                        <TableRow
                                          {...props}
                                          excludeOrders={
                                            excludeOrderValue?.excludeOrderFilterChip
                                          }

                                          // hoveredId={hoveredId}
                                          // setHoveredId={setHoveredId}
                                          // showOrder={showOrder}
                                        />
                                      );
                                    },
                                    TableFoot: (props) => {
                                      if (
                                        dataList?.length ===
                                          paginateData?.total &&
                                        paginateData?.current_page > 1
                                      ) {
                                        return (
                                          <tfoot
                                            className={`text-center text-sm13 py-3 px-2.5 bg-white border-t`}
                                            id={`NoMoreListing`}
                                          >
                                            <tr className="border-b bg-white">
                                              <td
                                                colSpan={11}
                                                align="center"
                                                className="h-[2.8125rem]"
                                              >
                                                No more items to show
                                              </td>
                                            </tr>
                                          </tfoot>
                                        );
                                      } else {
                                        return null;
                                      }
                                    },
                                  }}
                                  fixedHeaderContent={() => (
                                    <tr className="bg-white">
                                      <HeaderCell
                                        title="Team"
                                        width="min-w-[7.5rem]"
                                        arrow={true}
                                        postKey="name"
                                        padding="pl-4"
                                      />
                                      <HeaderCell
                                        title="Team member"
                                        width="min-w-[7.5rem]"
                                        arrow={true}
                                        postKey="username"
                                      />
                                      {/* <HeaderCell
                              title="Team member"
                              width="min-w-[7.5rem]"
                              arrow={false}
                              postKey="team_member"
                            /> */}

                                      <HeaderCell
                                        title="Marketplace"
                                        width="min-w-[7.5rem]"
                                        arrow={true}
                                        postKey="exchange_title"
                                      />

                                      <HeaderCell
                                        title="Order ID"
                                        width="min-w-[7.5rem]"
                                        arrow={false}
                                        postKey="order_id"
                                      />
                                      <HeaderCell
                                        title="Marketplace ID"
                                        width="min-w-[7.5rem]"
                                        arrow={false}
                                        postKey="order_id"
                                      />

                                      <HeaderCell
                                        title={"Order date"}
                                        width="min-w-[7.5rem]"
                                        arrow={true}
                                        postKey="order_date_time"
                                      />

                                      <HeaderCell
                                        title="Proceed price"
                                        width="min-w-[7.5rem]"
                                        arrow={true}
                                        postKey="transaction_value"
                                      />

                                      <HeaderCell
                                        // title="Order value"
                                        title="Marketplace …	"
                                        width="min-w-[7.5rem]"
                                        arrow={false}
                                        postKey="transaction_value"
                                      />

                                      <HeaderCell
                                        title="Seller payout"
                                        width="min-w-[7.5rem]"
                                        arrow={false}
                                        postKey="tixstock_revenue"
                                      />

                                      <HeaderCell
                                        title="Tixstock rev …"
                                        width="min-w-[7.5rem]"
                                        arrow={false}
                                        postKey="event_description"
                                      />

                                      <HeaderCell
                                        title="Penalty amount"
                                        width="min-w-[7.5rem]"
                                        arrow={false}
                                        postKey="tixstock_revenue"
                                      />

                                      <HeaderCell
                                        title="Event"
                                        width="min-w-[14.375rem]"
                                        arrow={true}
                                        postKey="event_description"
                                      />

                                      <HeaderCell
                                        title="Venue"
                                        width="min-w-[10.625rem]"
                                        arrow={true}
                                        postKey="event_venue"
                                      />

                                      <HeaderCell
                                        title="Event date"
                                        width="min-w-[7.5rem]"
                                        arrow={true}
                                        postKey="event_date"
                                      />

                                      <HeaderCell
                                        title="Ticket details"
                                        width="min-w-[10.625rem]"
                                        arrow={true}
                                        postKey="ticket_section"
                                      />

                                      <HeaderCell
                                        title="Qty"
                                        width="min-w-[3.4375rem]"
                                        arrow={true}
                                        postKey="ticket_quantity"
                                      />

                                      <HeaderCell
                                        title="Ticket type"
                                        width="min-w-[7.5rem]"
                                        arrow={true}
                                        postKey="ticket_type"
                                      />

                                      <HeaderCell
                                        title="Order status	"
                                        width="min-w-[7.5rem]"
                                        arrow={true}
                                        postKey="order_status"
                                      />

                                      <HeaderCell
                                        title="Internal orde …"
                                        width="min-w-[7.5rem]"
                                        arrow={false}
                                        postKey="paid"
                                      />

                                      <HeaderCell
                                        title="Payment status"
                                        width="min-w-[7.5rem]"
                                        arrow={true}
                                        postKey="paid"
                                      />
                                      <HeaderCell
                                        title="Marketplace inv …"
                                        width="min-w-[7.5rem]"
                                        arrow={false}
                                        postKey="paid"
                                      />

                                      <th className="sticky right-0 ml-auto  p-0 w-[5.0625rem]">
                                        <div
                                          className="py-[.4375rem] px-4 flex justify-end h-[2.5rem] left-shad border-l bg-white"
                                          id="left-shad-list"
                                        >
                                          <div className="arrow_buttons flex rounded ">
                                            <button
                                              className="group rounded-l transition px-[.5625rem] py-2"
                                              type="button"
                                              onClick={(e: any) => scroll(-120)}
                                            >
                                              <IKTSVG
                                                className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                                                path={angleLeftSVG}
                                                svgClassName="w-1.5 h-[.5625rem]"
                                              />
                                            </button>
                                            <button
                                              className="group rounded-r transition px-[.5625rem] py-2"
                                              type="button"
                                              onClick={(e: any) => scroll(120)}
                                            >
                                              <IKTSVG
                                                className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                                                path={angleRightSVG}
                                                svgClassName="w-1.5 h-[.5625rem]"
                                              />
                                            </button>
                                          </div>
                                        </div>
                                      </th>
                                    </tr>
                                  )}
                                  fixedFooterContent={() => {
                                    return (
                                      <tr className="border-b bg-white">
                                        <td colSpan={0}>Hi</td>
                                      </tr>
                                    );
                                  }}
                                  itemContent={(sIndex: any, list: any) => {
                                    return (
                                      <DraftInvoiceItemContent
                                        sIndex={sIndex}
                                        list={list}
                                      />
                                    );
                                  }}
                                />
                              )}
                              {/* SHIMMER - LOADER */}
                              {loading && (
                                <div
                                  id={`child2`}
                                  className={`order-[10000000] m-1 z-99999999 draft_invoice_lazyload
                `}
                                >
                                  <div className="bg-gray-100 rounded mr-1 mb-2 w-full shimmer-effect append-child h-[1.875rem] !overflow-hidden"></div>
                                  <div className="bg-gray-100 rounded mr-1 mt-1 mb-2 w-full shimmer-effect append-child h-[1.875rem]  !overflow-hidden"></div>
                                  {/* <div className="bg-gray-100 rounded mr-1 mb-2 w-full shimmer-effect append-child h-[1.875rem]  !overflow-hidden"></div> */}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>

              {/* RIGHT SIDE -  DETAILS */}
              <div className="right_body border-r lg2:w-1/2 w-full h-full scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full overflow-auto p-5">
                <div className="totle_order mb-5 flex justify-between">
                  <h5 className="font-semibold text-sm14 leading-4">
                    Payment details
                  </h5>
                  {!layout?.orderPayoutSeller?.finish && (
                    <button
                      type="button"
                      className={`${
                        isExportLoading || size(layout?.orderList) === 0
                          ? "pointer-events-none bg-gray-100 text-gray-400"
                          : "text-violet-800 hover:text-white bg-gray-100 hover:bg-indigo-500"
                      } group inline-flex group items-center  transition font-medium rounded text-sm13 px-2.5 py-0.5`}
                      onClick={(event) => {
                        !isExportLoading && exportToCsv(event);
                      }}
                    >
                      {isExportLoading ? (
                        <IKTSVG
                          className={`flex items-center justify-center fill-violet-500 transition animate-spin group-hover:fill-white mr-1`}
                          path={processSVG}
                        />
                      ) : (
                        <IKTSVG
                          className={`min-w-[0.75rem] max-w-[0.75rem] mr-2 flex items-center justify-center ${
                            size(layout?.orderList) === 0
                              ? "fill-gray-300"
                              : "fill-violet-500 group-hover:fill-white"
                          } `}
                          path={fileSVG}
                          svgClassName="w-[.6875rem] h-[.8438rem]"
                        />
                      )}
                      Export to CSV
                    </button>
                  )}
                </div>
                <div className="right_block ">
                  {layout?.orderPayoutSeller?.finish && (
                    <div className=" after_border flex flex-wrap pb-5 -mx-2.5">
                      {/* PAID STATUS */}
                      <PopupDetailBox
                        label="Payout status"
                        headerValue={"Paid"}
                        isDisabled={true}
                        disabledColor="success"
                        successValues={new Date()}
                      />
                    </div>
                  )}

                  <div
                    className={`flex flex-wrap gap-y-5 ${
                      layout?.orderPayoutSeller?.finish ? "pt-5" : ""
                    } -mx-2.5`}
                  >
                    {/* TEAM & PAYOUT VALUES */}
                    {filterData?.payoutResponse &&
                      size(filterData?.payoutResponse) > 0 &&
                      filterData?.payoutResponse?.map(
                        (item: any, index: number) => {
                          return (
                            <div
                              key={`filterData-${index}`}
                              className="flex w-full sm:flex-row flex-col gap-2 sm:gap-0"
                            >
                              <PopupDetailBox
                                label="Team"
                                headerValue={item?.teamName?.name}
                                isDisabled={isDisabled}
                                // labelValue={item?.team_order_counts}
                              />

                              {/* TOTAL SELLER PAYOUT BREAKDOWN */}
                              <div className="text-xs text-left border rounded overflow-hidden sm:w-full mx-2.5">
                                {size(item?.breakdown) > 0 &&
                                  item?.breakdown.map(
                                    (breakdown: any, index: number) => {
                                      return (
                                        <div
                                          className="whitespace-nowrap md:w-auto font-medium min-w-[8.125rem] border -m-px"
                                          key={breakdown?.key}
                                        >
                                          <div className="w-full flex -mt-px">
                                            <div className="w-1/2 whitespace-nowrap overflow-hidden text-ellipsis text-gray-400  border-r p-1">
                                              {breakdown?.label} :
                                            </div>
                                            <div className="w-1/2 p-1 whitespace-nowrap overflow-hidden text-ellipsis">
                                              {`${
                                                item?.currency && index !== 0
                                                  ? SYMBOLS[item?.currency]
                                                  : ""
                                              }${
                                                index === 0
                                                  ? breakdown?.value
                                                  : addCommaInNumber(
                                                      decimalNumber(
                                                        breakdown?.value
                                                      )
                                                    )
                                              }`}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                                <div className="whitespace-nowrap md:w-auto font-medium min-w-[8.125rem] -m-px">
                                  <div className="w-full flex border -mt-px">
                                    <div className="w-1/2 whitespace-nowrap overflow-hidden text-ellipsis text-gray-400  border-r p-1">
                                      {`Total seller payout`} :
                                    </div>
                                    <div className="w-1/2 p-1 whitespace-nowrap overflow-hidden text-ellipsis flex justify-between">
                                      <span>{`${
                                        item?.currency
                                          ? SYMBOLS[item?.currency]
                                          : ""
                                      }${addCommaInNumber(
                                        decimalNumber(item?.payout_total)
                                      )}`}</span>
                                      {removeCommaFromNumber(
                                        item?.payout_total_excluded
                                      ) > 0 && (
                                        <span className="except_count px-2 py-1 bg-rose-500 text-xxs font-semibold rounded text-white leading-3">
                                          {removeCommaFromNumber(
                                            item?.payout_total_excluded
                                          ) > 0
                                            ? `${SYMBOLS[item?.currency]}-${
                                                item?.payout_total_excluded
                                              }`
                                            : ""}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}

                    {/* EVENT DATE */}
                    {filterData?.eventDate?.startDate && (
                      <PopupDetailBox
                        label="Event date"
                        headerValue={`${moment(
                          filterData?.eventDate?.startDate
                        ).format("DD/MM/YYYY")} - ${moment(
                          filterData?.eventDate?.endDate
                        ).format("DD/MM/YYYY")}`}
                        isDisabled={isDisabled}
                      />
                    )}

                    {/* ORDERS */}
                    <PopupDetailBox
                      label="Orders"
                      headerValue={filterData?.orders?.value}
                      isDisabled={isDisabled}
                      countValue={
                        filterData?.orders?.excludes
                          ? `-${filterData?.orders?.excludes}`
                          : ""
                      }
                    />
                  </div>

                  {/* WALLET COMPARISON */}
                  <div className="flex flex-col mt-4">
                    <div className="overflow-x-auto">
                      <div className="inline-block min-w-full py-2 ">
                        <div className="overflow-hidden">
                          <table className="min-w-full text-left text-sm font-light text-surface dark:text-white border rounded">
                            <thead className="border-b border-neutral-200 font-medium dark:border-white/10">
                              <tr>
                                <th scope="col" className="px-6 py-4"></th>
                                {walletRevenueArr?.map(
                                  (item: any, index: number) => (
                                    <th
                                      key={index}
                                      scope="col"
                                      className="px-6 py-4"
                                    >
                                      {item.currency}
                                    </th>
                                  )
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="border-b border-neutral-200 transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-white/10 dark:hover:bg-neutral-600">
                                <td className="whitespace-nowrap px-6 py-4 font-semibold">
                                  Total balance
                                </td>
                                {walletRevenueArr?.map(
                                  (item: any, index: number) => (
                                    <td
                                      key={index}
                                      className="whitespace-nowrap px-6 py-4 font-medium"
                                    >
                                      {item.balance
                                        ? addCommaInNumber(item.balance)
                                        : "0.00"}
                                    </td>
                                  )
                                )}
                              </tr>
                              <tr className="border-b border-neutral-200 transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-white/10 dark:hover:bg-neutral-600">
                                <td className="whitespace-nowrap px-6 py-4 font-semibold">
                                  Total payout
                                </td>
                                {walletRevenueArr?.map(
                                  (item: any, index: number) => (
                                    <td
                                      key={index}
                                      className="whitespace-nowrap px-6 py-4 font-medium"
                                    >
                                      {addCommaInNumber(item.total)}
                                    </td>
                                  )
                                )}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* EXCLUDE CONTAINER */}
                <ExcludeContainerPayout
                  excludeOrderValue={excludeOrderValue}
                  setExcludeOrderValue={setExcludeOrderValue}
                  setDataList={setDataList}
                  isDisabled={isDisabled}
                  refetch={() => {
                    refetch();
                    setPaginateData((current: any) => {
                      return {
                        ...current,
                        current_page: 1,
                      };
                    });
                  }}
                  setPayoutId={(data: any) =>
                    setPayoutId((current: any) => {
                      return {
                        ...current,
                        id: data,
                        status: true,
                      };
                    })
                  }
                />
              </div>
            </div>
          </div>

          {/* FOOTER */}
          <OrderFooterPayoutSeller
            excludeOrderValue={excludeOrderValue}
            ordersCount={filterData?.orders?.value}
            isDisabled={checkTotalsVsBalance(walletRevenueArr)}
          />
        </div>
      </div>
    </>
  );
};

export default OrderPayoutSeller;
