import { useState } from "react";
import SVG from "react-inlinesvg";
import PurpleCheckSVG from "../../assets/media/icons/other_icons/purple-check.svg";
import CrossSVG from "../../assets/media/icons/standard_icons/cross.svg";
import { IKTSVG } from "../ui/IKTSVG";
import DropDownField from "./DropDownField";

const PhoneWithCountryCode = ({
  formik,
  countryOptions,
  dropdownHandleChange,
  handleOnChange,
  dropDownName,
  inputName,
  inputValue,
  dropDownValue,
  touchedError,
  key,
  id,
  handleOnFocus = () => {},
  placeholder = "",
  inputPlaceholder = "",
  className = "",
  menuPortalTarget = false,
  isDisabled = false,
  tickMarkVisible = true,
}: any) => {
  const [isFocued, setIsFocused] = useState<boolean>(false);

  return (
    <div className={`event_box rounded ${className}`}>
      <div className={`country-code`} key={key}>
        <div
          className={`dropdown relative flex phone_field  border rounded  items-center ${
            touchedError
              ? "border-rose-500 not-valid"
              : isFocued && "border border-indigo-500 focused sd"
          } [&_label]:hover:!text-indigo-500 `}
        >
          {/* COUNTRY DROPDOWN */}
          <DropDownField
            options={countryOptions}
            placeholder={""}
            isCapital={true}
            isValueCapital={true}
            isSearchable={true}
            isClearable={false}
            tabIndex={-1}
            name={dropDownName}
            value={dropDownValue}
            handleOnChange={(data: any) => dropdownHandleChange(data)}
            MenuIsOpenHandler={(data: any) => setIsFocused(data)}
            isMenuPortalTarget={menuPortalTarget}
            divClassName={`max-w-[7.5rem]`}
          />

          {/* INPUT FIELD */}
          <div className="relative w-full flex-1">
            <input
              type="text"
              className={`${
                touchedError
                  ? "!border-rose-500 !bg-white"
                  : isFocued && "border !border-indigo-500"
              } peer flex-1 placeholder:truncate block px-2.5 font-medium w-full h-10 text-xs transition bg-white border border-none appearance-none  focus:outline-none focus:ring-0 focus:border-none focus:bg-violet-300/50 peer rounded-r form`}
              name={inputName}
              id={id}
              value={inputValue}
              onFocus={(data: any) => {
                let event = {
                  target: { name: data.target.name, value: data.target.value },
                };
                handleOnFocus(event, true);
                setIsFocused(true);
              }}
              onBlur={(data: any) => {
                let event = {
                  target: { name: data.target.name, value: data.target.value },
                };
                handleOnFocus(event, false);
                setIsFocused(false);
              }}
              onChange={(data: any) => {
                handleOnChange(data);
              }}
              placeholder={placeholder}
            />
            {inputPlaceholder && (
              <label
                htmlFor={id}
                className={`${
                  !touchedError &&
                  !isFocued &&
                  inputValue &&
                  "!left-[-5.625rem]"
                } ${
                  inputValue && "!left-[-5.625rem]"
                } peer-focus:left-[-5.625rem] peer-focus-within:left-[-5.625rem] w-max pointer-events-none left-[.4375rem] flex items-center text-label !leading-[1.5] absolute text-xxs text-gray-400 duration-300 transform -translate-y-4 font-medium top-[.5625rem]  z-10 origin-[0] !bg-transparent px-1 pl-[.1875rem] peer-placeholder-shown:pl-1 peer-focus:px-[.1875rem] peer-focus:text-indigo-500  peer-placeholder-shown:-translate-y-1/2  peer-placeholder-shown:top-1/2 peer-focus:top-[.5625rem] peer-placeholder-shown:text-xs peer-focus:text-xxs peer-placeholder-shown:text-gray-500 peer-focus:-translate-y-4 peer-hover:text-indigo-500 peer-focus:bg-transparent before:transition-all  peer-placeholder-shown:before:opacity-0 peer-focus:before:opacity-100 peer-placeholder-shown:bg-white
                 ${
                   isDisabled &&
                   "!text-gray-400 !top-[.5625rem] !text-xxs !left-[-5.625rem] !-translate-y-4 before:!opacity-100"
                 } `}
              >
                {inputPlaceholder}
              </label>
            )}
            {tickMarkVisible &&
              (inputValue && isFocued ? (
                <button
                  type="button"
                  className={`w-5 h-5 absolute top-1/2 -translate-y-1/2 right-2.5 flex items-center justify-center fill-violet-500 group-hover:fill-white bg-white rounded-full   cursor-pointer hover:bg-violet-500 hover:fill-white transition-all
                    `}
                  onMouseDown={(e: any) => {
                    e.preventDefault();
                    formik.setFieldValue("phone", "");
                    formik.setFieldValue("phone_number", "");
                  }}
                >
                  <SVG src={CrossSVG} className={"w-2 h-2"} />
                </button>
              ) : (
                !formik.errors.phone &&
                !isFocued &&
                inputValue && (
                  <IKTSVG
                    className={`flex items-center justify-center transition absolute top-1/2 -translate-y-1/2 right-3 `}
                    path={PurpleCheckSVG}
                    svgClassName={`w-[1.125rem] ${
                      isDisabled && "[&_path]:!stroke-gray-400"
                    }`}
                  />
                )
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneWithCountryCode;
