const UnLockIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.571"
      height={15}
      viewBox="0 0 13.571 15"
      {...props}
    >
      <path
        id="lock_open_right_FILL1_wght400_GRAD0_opsz24"
        d="M161.429-905a1.376,1.376,0,0,1-1.009-.42,1.376,1.376,0,0,1-.42-1.009v-7.143a1.375,1.375,0,0,1,.42-1.009,1.376,1.376,0,0,1,1.009-.42h5v-1.429a3.442,3.442,0,0,1,1.045-2.527A3.442,3.442,0,0,1,170-920a3.442,3.442,0,0,1,2.527,1.045,3.442,3.442,0,0,1,1.045,2.527h-1.429a2.067,2.067,0,0,0-.625-1.518,2.066,2.066,0,0,0-1.518-.625,2.066,2.066,0,0,0-1.518.625,2.067,2.067,0,0,0-.625,1.518V-915H170a1.376,1.376,0,0,1,1.009.42,1.376,1.376,0,0,1,.42,1.009v7.143a1.376,1.376,0,0,1-.42,1.009A1.376,1.376,0,0,1,170-905Z"
        transform="translate(-160 920)"
        fill="#6f6cff"
      />
    </svg>
  );
};

export default UnLockIcon;
