import { IKTSVG } from "components/ui/IKTSVG";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import close from "../../../../assets/media/icons/other_icons/Clear.svg";
import Tabs from "./Tabs";

interface PropsType {
  headerFilters: any;
  filters?: any;
  countFilters?: any;
  filterChips: any;
  headerFilter?: any;
}

const SellerLevelHeader = ({
  headerFilters: HeaderFilters,
  filters: Filters,
  filterChips: FilterChips,
  countFilters: CountFilters,
  headerFilter,
}: PropsType) => {
  const location = useLocation();
  const path =
    location.pathname.split("/")[location.pathname.split("/").length - 1];
  function ToggleElemClose() {
    const submenuWrapper: any = document.querySelector("#filtersMob");
    submenuWrapper.classList.remove("activeFIlter");
    document.body.classList.remove("overflow-hidden");
  }

  const heightRef1: any = useRef();
  const toggleRef: any = useRef();
  const stickyElement = document.getElementById("topFilters");
  const [IsToggle, setIsToggle] = useState<any>(true);
  const [overViewHeight, setOverViewHeight] = useState<any>();

  // STICKY HEADER - CODE
  // useEffect(() => {
  //   if (stickyElement) {
  //     if (stickyElement) {
  //       const initialPosition = stickyElement.getBoundingClientRect().top;
  //       let MarginTopTotle = 0;
  //       let topPosition = 0;
  //       const recalculateMarginTopTotle = () => {
  //         const accordion = document.getElementById(
  //           "inventoryaccordion"
  //         ) as HTMLElement;
  //         const accordionStyle = window.getComputedStyle(accordion);
  //         const accordionheight: any = parseFloat(
  //           accordionStyle.getPropertyValue("height")
  //         );

  //         const filter = document.getElementById(
  //           "filters_block"
  //         ) as HTMLElement;
  //         const heightheightRef2 = filter.offsetHeight;

  //         const salesFilter: any =
  //           document.getElementById("salesFilter")?.clientHeight;

  //         const HeaderHeight: any =
  //           document.querySelector("header")?.clientHeight;

  //         var computedStyle = window.getComputedStyle(stickyElement);
  //         var paddingBottom = computedStyle.paddingBottom;
  //         var paddingBottomValue = parseInt(paddingBottom, 10);
  //         // topPosition = heightheightRef2 + salesFilter + HeaderHeight + 20;
  //         // topPosition =
  //         //   heightheightRef2 +
  //         //   salesFilter +
  //         //   HeaderHeight +
  //         //   paddingBottomValue -
  //         //   1;

  //         if (window.matchMedia("(max-width: 767px)").matches) {
  //           topPosition = HeaderHeight;
  //         } else {
  //           topPosition =
  //             heightheightRef2 +
  //             salesFilter +
  //             HeaderHeight +
  //             paddingBottomValue -
  //             1;
  //         }

  //         // Recalculate MarginTopTotle based on updated heights or values
  //         const heightheightRef1 = heightRef1?.current?.offsetHeight;
  //         MarginTopTotle =
  //           IsToggle === true
  //             ? heightheightRef1 + accordionheight - HeaderHeight
  //             : HeaderHeight + heightheightRef1;
  //       };

  //       const makeSticky = function () {
  //         const HeaderHeight: any =
  //           document.querySelector("header")?.clientHeight;
  //         const sticky_head: any = document.getElementById("sticky-head");
  //         const stickyShadow: any = document.getElementById("sticky-shadow");
  //         const sticky_headHeight: any = sticky_head?.clientHeight;

  //         recalculateMarginTopTotle();
  //         if (window.scrollY + HeaderHeight >= initialPosition) {
  //           stickyElement.classList.add("sticky");
  //           stickyElement.style.top =
  //             IsToggle === true
  //               ? `-${MarginTopTotle}px`
  //               : `${MarginTopTotle}px`;
  //           sticky_head && (sticky_head.style.top = topPosition + "px");
  //           stickyShadow.style.top = topPosition + sticky_headHeight + "px";
  //         } else {
  //           stickyElement.classList.remove("sticky");
  //           stickyElement.style.top = `0px`;
  //           sticky_head && (sticky_head.style.top = "0px");
  //           stickyShadow.style.top = "0px";
  //         }
  //       };
  //       const handleWindowResize = () => {
  //         // Handle logic when the window is resized
  //         makeSticky(); // Update sticky behavior based on new values
  //         recalculateMarginTopTotle(); // Recalculate MarginTopTotle on window resize
  //       };
  //       window.addEventListener("scroll", makeSticky);
  //       window.addEventListener("change", makeSticky);
  //       window.addEventListener("blur", makeSticky);
  //       // bulk_order?.addEventListener("click", makeSticky);

  //       setTimeout(() => {
  //         document.addEventListener("resize", handleWindowResize);
  //       }, 200);
  //       return () => {
  //         window.removeEventListener("scroll", makeSticky);
  //         window.removeEventListener("change", makeSticky);
  //         window.removeEventListener("blur", makeSticky);
  //         document.removeEventListener("resize", handleWindowResize);
  //       };
  //     }
  //   }
  // }, [toggleRef, IsToggle, overViewHeight, stickyElement]);

  useEffect(() => {
    const stickyElement = document.getElementById("topFilters") as HTMLElement;
    const stickyElementStyle = window.getComputedStyle(stickyElement);
    const stickyElementheight: any =
      stickyElementStyle.getPropertyValue("height");

    const filter = document.getElementById("filters_block") as HTMLElement;
    const heightheightRef2 = filter?.offsetHeight;

    if (stickyElement) {
      // const heightheightRef2 = heightRef2?.current?.offsetHeight;

      const initialPosition = stickyElement.getBoundingClientRect().top;
      const accordion = document.getElementById("inventoryaccordion");

      const accorAttr = accordion?.getAttribute("data-te-collapse-show");

      let MarginTopTotle = 0;
      let topPosition = 0;

      // const MarginTopTotle =
      //     IsToggle === true
      //       ? heightheightRef1 + overViewHeight - HeaderHeight
      //       : heightheightRef1 - HeaderHeight;

      const recalculateMarginTopTotle = () => {
        const accordion = document.getElementById(
          "inventoryaccordion"
        ) as HTMLElement;
        const accordionStyle = window.getComputedStyle(accordion);
        const accordionheight: any = parseFloat(
          accordionStyle.getPropertyValue("height")
        );
        const filter = document.getElementById("filters_block") as HTMLElement;
        const heightheightRef2 = filter.offsetHeight;
        // Recalculate MarginTopTotle based on updated heights or values
        const heightheightRef1 = heightRef1?.current?.offsetHeight;
        const HeaderHeight: any =
          document.querySelector("header")?.clientHeight;
        const salesFilter: any =
          document.getElementById("salesFilter")?.clientHeight;

        var computedStyle = window.getComputedStyle(stickyElement);
        var paddingBottom = computedStyle.paddingBottom;
        var paddingBottomValue = parseInt(paddingBottom, 10);

        const mediaQuery = window.matchMedia("(max-width: 767px)");

        // console.log(paddingBottomValue);
        if (window.matchMedia("(max-width: 767px)").matches) {
          topPosition = HeaderHeight;
        } else {
          topPosition =
            heightheightRef2 +
            salesFilter +
            HeaderHeight +
            paddingBottomValue -
            1;
        }

        MarginTopTotle =
          IsToggle === true
            ? heightheightRef1 + accordionheight - HeaderHeight
            : HeaderHeight - heightheightRef1;
      };

      const makeSticky = function () {
        const HeaderHeight: any =
          document.querySelector("header")?.clientHeight;
        const sticky_head: any = document.getElementById("sticky-head");
        const stickyShadow: any = document.getElementById("sticky-shadow");
        const sticky_headHeight: any = sticky_head?.clientHeight;

        recalculateMarginTopTotle();
        if (window.scrollY + HeaderHeight >= initialPosition) {
          if (stickyElement) stickyElement.classList.add("sticky");
          stickyElement.style.top =
            IsToggle === true ? `-${MarginTopTotle}px` : `${MarginTopTotle}px`;
          if (sticky_head) sticky_head.style.top = topPosition + "px";
          if (stickyShadow)
            stickyShadow.style.top = topPosition + sticky_headHeight + "px";
        } else {
          if (stickyElement) stickyElement.classList.remove("sticky");
          stickyElement.style.top = `0px`;
          if (sticky_head) sticky_head.style.top = "0px";
          if (stickyShadow) stickyShadow.style.top = "0px";
        }
      };
      const handleWindowResize = () => {
        // Handle logic when the window is resized
        makeSticky(); // Update sticky behavior based on new values
        recalculateMarginTopTotle(); // Recalculate MarginTopTotle on window resize
      };

      // if (window.scrollY > 200) {
      //   makeSticky();
      // }

      window.addEventListener("scroll", makeSticky);
      window.addEventListener("change", makeSticky);
      window.addEventListener("blur", makeSticky);

      setTimeout(() => {
        document.addEventListener("resize", handleWindowResize);
      }, 200);
      return () => {
        window.removeEventListener("scroll", makeSticky);
        window.removeEventListener("change", makeSticky);
        window.removeEventListener("blur", makeSticky);
        document.removeEventListener("resize", handleWindowResize);
      };
    }
    // });
  }, [toggleRef, IsToggle, overViewHeight]);

  const filter_scroll: any = useRef();
  const scroll_filter = (scrollOffset: any) => {
    filter_scroll.current.scrollLeft += scrollOffset;
  };
  useEffect(() => {
    function hideArrowScroll() {
      const left_sc: any = document.getElementById("scroll_left");
      const right_sc: any = document.getElementById("scroll_right");
      const filter_scroll: any = document.getElementById("filter_scroll");
      const maxScrollLeft: any =
        filter_scroll?.scrollWidth - filter_scroll?.clientWidth;

      if (left_sc) {
        if (filter_scroll?.scrollLeft === 0) {
          left_sc.style.display = "none";
        } else {
          left_sc.style.display = "flex";
        }
      }
      if (right_sc) {
        if (Math.round(filter_scroll?.scrollLeft) >= maxScrollLeft) {
          right_sc.style.display = "none";
        } else {
          right_sc.style.display = "flex";
        }
      }
    }
    const filter_scroll = document.getElementById("filter_scroll"); // Make sure filter_scroll is defined and references the correct element
    filter_scroll?.addEventListener("scroll", hideArrowScroll);
    hideArrowScroll();
    return () => {
      filter_scroll?.removeEventListener("scroll", hideArrowScroll); // Cleanup: Remove event listener when component unmounts
    };
  }, []);

  return (
    <div
      className={`topFilters sticky-element top-[60px] z-[11] pb-5 bg-gray-200 max-md:!static `}
      id="topFilters"
    >
      <div className="top-accor shadow-op2">
        {/* {renderHeaderFilter()} */}
        {
          <HeaderFilters heightRef1={heightRef1} setIsToggle={setIsToggle}>
            {headerFilter}
          </HeaderFilters>
        }
        <div
          className="bg-white  accordion-collapse font-medium collapse show"
          id="inventoryaccordion"
          data-te-collapse-item
          data-te-collapse-show
          aria-labelledby="headingOne"
        >
          {/* TABS */}
          <Tabs />
        </div>

        {CountFilters && (
          <div
            className={`tab_vontent opacity-100 transition-opacity duration-150 ease-linear block bg-white`}
            // id={bodyId}
            role="tabpanel"
            aria-labelledby={`tabs`}
            data-te-tab-active
          >
            {/*first layer*/}
            <div className="relative px-5 pt-3">
              <div
                id={`scroll_right`}
                className="arrow_slide absolute top-1/2 -translate-y-1/2 w-6 h-6 flex items-center justify-center right-0 shadow-op1 bg-white rounded-l group hover:bg-indigo-500 cursor-pointer"
                // onClick={() => scrollLeft()}
                onClick={() => scroll_filter(50)}
              >
                {/* <IKTSVG
            path={AngleRight}
            svgClassName="w-1.5 h-[.5625rem]"
            className=" group-hover:fill-white"
          /> */}
              </div>
              <div
                id={`scroll_left`}
                className="arrow_slide absolute top-1/2 -translate-y-1/2 w-6 h-6 flex items-center justify-center left-0 shadow-op1 bg-white rounded-r group hover:bg-indigo-500 cursor-pointer"
                onClick={() => scroll_filter(-50)}
                // onClick={() => scroll_filter(50)}
              >
                {/* <IKTSVG
            path={AngleLeft}
            svgClassName="w-1.5 h-[.5625rem]"
            className=" group-hover:fill-white"
          /> */}
              </div>
              <div className="divide-gray-200 bg-white divide-y-2">
                <div
                  // ref={filter_scroll}
                  id={`filter_scroll`}
                  className="flex flex-nowrap pt-3 -mx-2.5  overflow-x-auto whitespace-nowrap scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full"
                >
                  {CountFilters}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* SECOND LAYER FILTERS */}
        {Filters && (
          <div
            id="filtersMob"
            className="filtersMob max-md:flex max-md:flex-col"
          >
            <div
              className="flex flex-wrap  gap-y-2.5 py-4 px-[.9375rem] bg-white max-md:pt-14 max-md:flex-1 max-md:overflow-auto max-md:content-start"
              id={`filters_block`}
            >
              <div
                className="closePopup hidden max-md:flex"
                id="closePopup"
                onClick={ToggleElemClose}
              >
                <IKTSVG
                  path={close}
                  className="w-10 absolute top-[1px] right-0 h-10 flex items-center justify-center cursor-pointer z-[1] bg-white"
                  svgClassName="w-4 h-4"
                />
              </div>
              {/* {<Filters loading={loading} />} */}
              {Filters}
            </div>
          </div>
        )}

        {/*THIRD LAYER*/}
        <div
          className="flex flex-wrap customStyle relative bg-white  border-t max-sm:justify-between"
          id={`salesFilter`}
        >
          {FilterChips}
        </div>
      </div>
    </div>
  );
};

export default SellerLevelHeader;
