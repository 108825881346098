import { LayoutContext } from "components/core/LayoutProvider";
import NoDataComponent from "components/tableComponent/NoDataComponent";
import ProgressBardLoader from "components/ui/ProgressBardLoader";
import { LISTINGS_UTILS, QUERIES, SYMBOLS } from "helpers/const";
import { addCommaInNumber } from "helpers/Functions";
import _ from "lodash";
import useDebounce from "pages/add_inventory/core/useDebounce";
import { ConnectorContext } from "pages/API_connector/core/ConnectorProvider";
import {
  getApiConnectorListing,
  getApiConnectorListingCounts,
} from "pages/API_connector/core/requests";
import { ListingTable } from "pages/API_connector/table/ListingTable";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import ListingFilters from "./ListingFilters";

// get is all events are selected or not
const getAllEventsSelected = (selectedListings: any, data: any) => {
  return selectedListings?.length === data?.length;
};

// handle all event selection
const handleSelectAll = (setSelectedListings: any, data: any) => (e: any) => {
  if (e.target.checked) {
    const selectList = data.map((listing: any) => listing?.tixstock_id);
    setSelectedListings(selectList);
  } else {
    setSelectedListings([]);
  }
};

const getColumns: any = (
  layout: any,
  data: any,
  selectedListings: any,
  setSelectedListings: any,
  lastSelectedIndex: any,
  setLastSelectedIndex: any
) => [
  {
    title: (
      <div className="group sticky left-0 icon_td  min-w-[2rem]  w-8 bg-white h-[2.8125rem] z-[999999999]">
        <div className="flex td_block items-center justify-center p-4 text-center sticky left-0 no-scroll checkbox_td bg-inherit">
          <input
            id={`all-selected`}
            name="all_selected"
            type="checkbox"
            className="w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-0 focus:ring-offset-0 relative"
            checked={getAllEventsSelected(selectedListings, data)}
            onChange={handleSelectAll(setSelectedListings, data)}
          />
        </div>
      </div>
    ),
    width: "w-8",
    isArrow: true,
    postKey: "",
    padding: "",
    getValue: (list: any, sIndex: any) => (
      <div
        className={`group sticky left-0 icon_td min-w-[2rem] w-8  h-[2.8125rem] z-[999999999] ${
          list?.errors && list?.errors?.length > 0 ? "bg-[#FCEDF2]" : "bg-white"
        }`}
      >
        <div
          className="flex td_block items-center justify-center p-4 text-center sticky left-0 no-scroll checkbox_td bg-inherit"
          id="left-shad-list"
        >
          <input
            onChange={handleListSelectChange(
              setSelectedListings,
              list?.tixstock_id
            )}
            name="selected"
            checked={selectedListings.some(
              (listingId: any) => listingId === list?.tixstock_id
            )}
            onClick={(e) =>
              handleShift(
                e,
                sIndex,
                layout,
                data,
                setSelectedListings,
                lastSelectedIndex,
                setLastSelectedIndex
              )
            }
            type="checkbox"
            className="w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-0 focus:ring-offset-0 relative"
          />
        </div>
      </div>
    ),
  },
  {
    title: "Event",
    width: "12rem",
    isArrow: false,
    postKey: "event",
    padding: "pl-5",
    getValue: (list: any) => list?.event,
  },
  {
    title: "Tixstock ID",
    width: "8rem",
    isArrow: false,
    postKey: "tixstock_id",
    padding: "",
    getValue: (list: any) => list?.tixstock_id,
  },
  {
    title: "External ID",
    width: "8rem",
    isArrow: false,
    postKey: "external_id",
    padding: "",
    getValue: (list: any) => list?.external_id,
  },
  {
    title: "User Name",
    width: "8rem",
    isArrow: false,
    postKey: "name",
    padding: "",
    getValue: (list: any) => list?.user_name,
  },
  {
    title: "Team Name",
    width: "8rem",
    isArrow: false,
    postKey: "team_name",
    padding: "",
    getValue: (list: any) => list?.team_name,
  },
  {
    title: "Ticket Type",
    width: "8rem",
    isArrow: false,
    postKey: "ticket_type",
    padding: "",
    getValue: (list: any) => list?.ticket_type,
  },
  {
    title: "Quantity Available",
    width: "8rem",
    isArrow: false,
    postKey: "quantity_available",
    padding: "",
    getValue: (list: any) => list?.quantity_available,
  },

  {
    title: "Section",
    width: "8rem",
    isArrow: false,
    postKey: "section",
    padding: "",
    getValue: (list: any) => list?.section,
  },
  {
    title: "Row",
    width: "8rem",
    isArrow: false,
    postKey: "row",
    padding: "",
    getValue: (list: any) => list?.row,
  },
  {
    title: "First Seat",
    width: "8rem",
    isArrow: false,
    postKey: "first_seat",
    padding: "",
    getValue: (list: any) => list?.first_seat,
  },
  {
    title: "Face Value",
    width: "8rem",
    isArrow: false,
    postKey: "face_value",
    padding: "",
    getValue: (list: any) =>
      `${SYMBOLS[list?.face_value_currency]}${addCommaInNumber(
        list?.face_value
      )}`,
  },
  {
    title: "Sell Price",
    width: "8rem",
    isArrow: false,
    postKey: "sell_price",
    padding: "",
    getValue: (list: any) =>
      `${SYMBOLS[list?.sell_price_currency]}${addCommaInNumber(
        list?.sell_price
      )}`,
  },
  {
    title: "External Status",
    width: "8rem",
    isArrow: false,
    postKey: "external_status",
    padding: "",
    getValue: (list: any) => list?.external_status,
  },
];

// handle event onClick
const handleShift = (
  e: any,
  index: number,
  layout: any,
  data: any,
  setSelectedListings: any,
  lastSelectedIndex: any,
  setLastSelectedIndex: any
) => {
  if (e.shiftKey && lastSelectedIndex !== null) {
    const startIndex = Math.min(lastSelectedIndex, index);
    const endIndex = Math.max(lastSelectedIndex, index);
    const newListingIds = data
      ?.slice(startIndex, endIndex)
      ?.map((listing: any) => listing?.tixstock_id);

    setSelectedListings((prev: any) => {
      return _.uniq([...prev, ...newListingIds]);
    });
  } else {
    setLastSelectedIndex(index);
  }
};

// handle single event selection
const handleListSelectChange =
  (setSelectedListings: any, id: any) => (e: any) => {
    if (e.target.checked) {
      setSelectedListings((pre: any) => {
        return [...pre, id];
      });
    } else {
      setSelectedListings((pre: any) => {
        return pre?.filter((listingId: any) => listingId !== id);
      });
    }
  };

export const ListingTab = () => {
  const {} = useContext(ConnectorContext);

  const [shimmerLoading, setShimmerLoading] = useState(true);
  const [orderState, setOrderState] = useState({});
  const [lastSelectedIndex, setLastSelectedIndex] = useState<any>(null);
  const [selectedListings, setSelectedListings] = useState([]);
  const layout = useContext(ConnectorContext);
  const coreLayout = useContext(LayoutContext);
  const navigate = useNavigate();

  const [publishStatus, setPublishStatus] = useState<any>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let filters = { ...layout.listingFilters };
  let sorting = orderState;

  let clientsEventsDataMemo: any = useMemo(() => {
    return [filters, sorting];
  }, [filters, sorting]);

  let eventsFilterData: any = useDebounce(
    JSON.stringify(clientsEventsDataMemo),
    200
  );

  const { data, isLoading } = useQuery(
    [`${QUERIES.API_CONNECTOR_LISTING}`, ...JSON.parse(eventsFilterData)],
    () =>
      getApiConnectorListing({
        id: layout.connectorViewState?.data?.id,
        filters: layout.listingFilters,
        sorting,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      onSettled: () => setShimmerLoading(false),
    }
  );

  const { data: countsData, isLoading: isCountLoading } = useQuery(
    [],
    () =>
      getApiConnectorListingCounts({ id: layout.connectorViewState?.data?.id }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      onSettled: () => setShimmerLoading(false),
    }
  );

  useEffect(() => {
    const getListingData = async () => {
      if (!isLoading) {
        if (data?.data?.length > 0) {
          layout.setListingData((pre: any) => {
            if (data?.meta?.current_page > 1) {
              return [...pre, ...data?.data];
            } else {
              return [...data?.data];
            }
          });
        } else {
          layout.setListingData([]);
        }
      }
    };
    getListingData();

    return () => {
      // setListingData([]);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading]);

  //Starting shimmer loader
  useEffect(() => {
    layout.setListingFilters((prev: any) => {
      return {
        ...prev,
        page: 1,
        per_page: 20,
      };
    });
    setShimmerLoading(true);
    setSelectedListings([]);
  }, [layout.listingFilters?.listingStatuses]);

  const handleEditListings = () => {
    coreLayout.setEditListings(selectedListings);

    navigate(LISTINGS_UTILS?.myListings.url);
  };

  const handleDeleteListings = () => {};

  // const height = document?.getElementById(
  //   "api-connector-listing-filters-acc"
  // )?.clientHeight;

  // console.log(height, "height");

  const listingFiltersRef = useRef();
  const previousHeight = useRef(0);
  const [height, setHeight] = useState(0);
  const [tableHeight, setTableHeight] = useState(0);

  useEffect(() => {
    const element = listingFiltersRef.current;

    if (!element) return;

    let debounceTimer: any;
    const debounce = (fn: any, delay: any) => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        fn();
      }, delay);
    };

    // Create a ResizeObserver to monitor the height changes
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const newHeight = entry.contentRect.height;

        // Check if the height has changed significantly to update
        if (newHeight !== previousHeight.current) {
          debounce(() => {
            setHeight(newHeight); // Update height
            previousHeight.current = newHeight; // Update the previous height
            // console.log(newHeight, "height");
          }, 100); // Debouncing by 100ms (adjust if needed)
        }
      }
    });
    resizeObserver.observe(element);

    // Cleanup observer when component unmounts
    return () => {
      resizeObserver.unobserve(element);
    };
  }, []);

  useEffect(() => {
    const calculateTableHeight = () => {
      const headerHeight =
        document.getElementsByTagName("header")?.[0].clientHeight || 0;

      const popupHeaderTitleId =
        document.getElementById("api-connector-header-title")?.clientHeight ||
        0;

      const topHeight =
        document.getElementById("topFilters")?.clientHeight || 0;
      const newTableHeight =
        document.body.clientHeight -
        headerHeight -
        popupHeaderTitleId -
        topHeight -
        height -
        20 * (coreLayout.htmlFontSize / 25);

      setTableHeight(newTableHeight); // Divide by 16 to adjust for rem units or use raw px
    };

    // Initial calculation
    calculateTableHeight();

    // Setup ResizeObserver to observe document body for size changes
    const resizeObserver = new ResizeObserver(calculateTableHeight);
    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.disconnect();
    };
  }, [coreLayout.htmlFontSize, height]); // Empty dependency array to ensure it only runs once on mount

  return (
    <>
      <div className="flex flex-col border-b bg-white w-full">
        <ListingFilters
          list={layout.listingData}
          layout={layout}
          isLoading={shimmerLoading}
          countsData={countsData?.data}
          publishStatus={publishStatus}
          setPublishStatus={setPublishStatus}
          selectedListings={selectedListings}
          handleEditListings={handleEditListings}
          handleDeleteListings={handleDeleteListings}
          paginateData={data?.meta}
          listingFiltersRef={listingFiltersRef}
          setSelectedListings={setSelectedListings}
        />
      </div>
      <div
        id="eventstab"
        className="bg-gray-200 min-h-[calc(100vh-10.25rem)] max-h-[calc(100vh-10.25rem)] content-block relative before:content before:absolute before:top-0 before:left-0 before:w-full before:h-16 before:-z-[1]  before:bg-gradient-to-b before:to-gray-650"
      >
        {shimmerLoading ? (
          <>
            {Array.from({ length: 10 }, (v, i) => (
              <div
                className={`accordion font-medium  mb-2.5 mx-5 ${
                  i === 0 && "mt-5"
                } `}
                key={i}
              >
                <div
                  key={i}
                  className={`accordion-item bg-white !rounded overflow-hidden ${
                    isLoading && "shimmer-effect"
                  } `}
                  style={{ height: "40px" }}
                ></div>
              </div>
            ))}
          </>
        ) : layout.listingData.length > 0 && !shimmerLoading ? (
          <div className="inner-main-content p-5 max-md:px-0 h-auto flex flex-col">
            <ListingTable
              data={layout.listingData}
              filters={layout.listingFilters}
              setFilters={layout.setListingFilters}
              columns={getColumns(
                layout,
                layout.listingData,
                selectedListings,
                setSelectedListings,
                lastSelectedIndex,
                setLastSelectedIndex
              )}
              paginateData={data?.meta}
              orderState={orderState}
              setOrderState={setOrderState}
              rowSelection={true}
              withErros={true}
              isLoading={isLoading}
              height={`${tableHeight}px`}
            />
          </div>
        ) : (
          <div className="p-4">
            <NoDataComponent isPadding={true} />
          </div>
        )}
        {shimmerLoading && (
          <ProgressBardLoader
            LoadingText={"Loading Client's Listing data"}
            secondCounter={2.5}
          />
        )}
      </div>
    </>
  );
};
