/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useMemo, useRef, useState } from "react";
// import { Footer, Select } from "flowbite-react";

import _, { size } from "lodash";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import collapseSVG from "../../assets/media/icons/other_icons/Collapse.svg";
import deleteSVG from "../../assets/media/icons/other_icons/Delete.svg";
import settingSVG from "../../assets/media/icons/other_icons/setting.svg";
import arrowDownSVG from "../../assets/media/icons/standard_icons/arrowDown.svg";
import crossSVG from "../../assets/media/icons/standard_icons/cross.svg";
import pinSVG from "../../assets/media/icons/standard_icons/pin-icon.svg";
import { LayoutContext } from "../../components/core/LayoutProvider";
import NoDataComponent from "../../components/tableComponent/NoDataComponent";
import { IKTSVG } from "../../components/ui/IKTSVG";
import ProgressBardLoader from "../../components/ui/ProgressBardLoader";
import { groupTeamMemberCommon } from "../../helpers/Functions";
import { QUERIES } from "../../helpers/const";
import useDebounce from "../add_inventory/core/useDebounce";
import HeaderfilterContainer from "./component/HeaderfilterContainer";
import ReportsFilter from "./component/ReportsFilter";
import TabBody from "./component/TabBody";
import { ReportsContext } from "./core/ReportsProvider";
import {
  getReportsListing,
  getReportsOptions,
  getReportsheaderOptions,
} from "./core/requests";
import Footer from "./layout/footer";
import DataTable from "./table/DataTable";

const Reports = () => {
  // const tableRef: any = useRef();
  // Table horizontal scroll
  // const scroll = (scrollOffset: any) => {
  //   tableRef.current.scrollLeft += scrollOffset;
  // };
  const layout = useContext(ReportsContext);
  const coreLayout = useContext(LayoutContext);
  const authUser = coreLayout.authUser;
  const access = coreLayout.allowedAccess?.allowed_access;
  const userSpecific = access?.some(
    (item: any) => item.id === "user-specific-data" && item.status === true
  );
  const isAddInventory = access?.some(
    (item: any) => item.id === "inventory" && item.status === true
  );

  let filters = { ...layout.reportsFilterHandle };
  let countFilter = { ...layout.reportsFilterHandle, page: undefined };

  delete filters.algoliaPage;

  let orders = layout.orders;
  let sorting = layout.orderState;
  const [tabValue, setTabValue] = useState<any>("");
  const [query, setQuery] = useState<any>("");
  const [algoliaQuery, setAlgoliaQuery] = useState<any>("");
  const [reportsOptions, setReportsOptions] = useState<any>();
  const debouncedSearch = useDebounce(query, 1200);
  const navigate = useNavigate();
  const [headerOptions, setHeaderOptions] = useState<any>();
  const bulkActionRef: any = useRef(null);
  const bulkAction1Ref: any = useRef(null);
  const [startListing, setStartListing] = useState<boolean>(false);
  const [teamsOptions, setTeamsOptions] = useState<any>();
  const [groupTeamMember, setGroupTeamMember] = useState<any>();
  const [customReportLoading, setReportLoading] = useState(true);
  const [customReportTableLoading, setReportTableLoading] = useState(true);
  const teamOptionsData = coreLayout?.allowedAccess?.teams;
  const isGodAdmin = coreLayout?.allowedAccess?.account_info?.is_god_admin;

  // PAGINATION SET TO FIRST PAGE ON FILTER CHANGE
  useEffect(() => {
    layout.setReportsFilterHandle((prev: any) => {
      return {
        ...prev,
        page: 1,
        per_page: 20,
        query,
      };
    });

    // if (optionsLoading || reportsLoading || reportsOptionsLoading) {
    setReportTableLoading(true);
    setReportLoading(true);
    setShimmerLoader(true);
    setShimmerLoaderCount(true);
    // }
    if (document.body.classList.contains("overflow-hidden")) {
      document.body.classList.remove("overflow-hidden");
    }
  }, [
    layout.reportsFilterHandle?.team_id &&
      size(layout.reportsFilterHandle?.team_id) > 0,
    layout.reportsFilterHandle?.team_member &&
      size(layout.reportsFilterHandle?.team_member) > 0,
    layout.reportsFilterHandle?.venue,
    layout.reportsFilterHandle?.internal_order_statuses &&
      size(layout.reportsFilterHandle?.internal_order_statuses) > 0,
    layout.reportsFilterHandle?.orderDateRange?.startDate,
    layout.reportsFilterHandle?.eventDateRange?.startDate,
    layout.reportsFilterHandle?.payment_status &&
      size(layout.reportsFilterHandle?.payment_status) > 0,
    layout.reportsFilterHandle?.event_id,
    layout.reportsFilterHandle?.order_statuses &&
      size(layout.reportsFilterHandle?.order_statuses) > 0,
    layout.reportsFilterHandle?.category_ids &&
      size(layout.reportsFilterHandle?.category_ids) > 0,
    layout.reportsFilterHandle?.performer_ids &&
      size(layout.reportsFilterHandle?.performer_ids) > 0,
    layout.orderState,
    layout.reportsFilterHandle?.order_exchanges &&
      size(layout.reportsFilterHandle?.order_exchanges) > 0,
    layout.reportsFilterHandle?.store_id,
    query,
  ]);

  // GET REPORTS OPTIONS
  const { data: optionsData, isLoading: optionsLoading } = useQuery(
    QUERIES.REPORTS_OPTIONS,
    () => getReportsOptions(),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      onError: (err) => {},
    }
  );

  let reportsDataMemo: any = useMemo(() => {
    return [tabValue, debouncedSearch, filters, sorting];
  }, [tabValue, debouncedSearch, filters, sorting]);

  let reportData: any = useDebounce(JSON.stringify(reportsDataMemo), 200);

  // GET REPORTS LISTING
  const {
    isLoading: reportsLoading,
    data: listingData,
    isFetching: isReportFetching,
  } = useQuery(
    [`${QUERIES.REPORTS_LISTING}`, ...JSON.parse(reportData)],
    () =>
      getReportsListing({
        tabValue,
        debouncedSearch,
        filters,
        sorting,
        // ...(userSpecific && authUser?.id && { team_member: [authUser?.id] }),
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: !optionsLoading && startListing ? true : false,
      onError: (err) => {},
      onSettled: (err) => {
        setTimeout(() => {
          setReportTableLoading(false);
          setShimmerLoader(false);
          setReportLoading(false);
          setShimmerLoader(false);
          setShimmerLoaderCount(false);
        }, 200);
      },
    }
  );
  // GET REPORTS count
  const { isLoading: reportsOptionsLoading, data: reportsOptionData } =
    useQuery(
      [`report-options`, tabValue, debouncedSearch, countFilter, sorting],
      () =>
        getReportsheaderOptions({
          tabValue,
          debouncedSearch,
          filters: countFilter,
          sorting,
        }),
      {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: false,
        enabled: !optionsLoading && startListing ? true : false,
        onError: (err) => {},
        onSettled: (err) => {
          setShimmerLoaderCount(false);
        },
      }
    );
  // ON ENTER - SEARCH QUERY (ALGOLIA)
  const handleKeyDown = (event: any) => {
    if (event?.target?.value && event.key === "Enter") {
      event.preventDefault();
      setQuery(event?.target?.value);
    }
  };

  // METHOD FOR HANDLING CLOSE ON OUTSIDE CLICK FOR FILTERS
  const outSideClick = (e: any) => {
    if (!bulkActionRef?.current?.contains(e.target)) {
      let bulkAction: any = document.querySelectorAll(
        'span[data-te-target="#bulkaction"]:not([data-te-collapse-collapsed])'
      )?.[0];
      if (bulkAction) {
        bulkAction.click();
      }
    }
  };

  // METHOD FOR HANDLING CLOSE ON OUTSIDE CLICK FOR COLUMNS
  const columnOutSideClick = (e: any) => {
    if (!bulkAction1Ref?.current?.contains(e.target)) {
      let bulkAction: any = document.querySelectorAll(
        'span[data-te-target="#bulkaction1"]:not([data-te-collapse-collapsed])'
      )?.[0];
      if (bulkAction) {
        bulkAction.click();
      }
    }
  };

  //ADDING & REMOVING LISTENERS FOR OUTSIDE CLICK FOR DROPDOWNS
  useEffect(() => {
    document.addEventListener("mousedown", outSideClick);
    document.addEventListener("mousedown", columnOutSideClick);
    return () => {
      document.removeEventListener("mousedown", outSideClick);
      document.removeEventListener("mousedown", columnOutSideClick);
    };
  }, []);

  useEffect(() => {
    if (reportsOptionData?.data) {
      setHeaderOptions(reportsOptionData?.data);
    }
  }, [reportsOptionData, tabValue]);

  // GET ORDERS FOR LISTING
  useEffect(() => {
    // BEFORE LAZY LOAD
    // if (listingData?.data?.orders && listingData?.data?.orders.length > 0) {
    //   layout.setOrders(
    //     listingData?.data?.orders.map((order: any) => {
    //       return {
    //         ...order,
    //         selected: false,
    //       };
    //     })
    //   );
    // }

    // AFTER LAZY LOAD
    const updateList = async () => {
      if (!isReportFetching) {
        if (listingData?.data?.orders?.length > 0) {
          const newData = await Promise.all(
            listingData?.data?.orders.map((order: any) => {
              return {
                ...order,
                selected: false,
              };
            })
          );

          layout.setOrders((pre: any) => {
            if (listingData?.meta?.current_page > 1) {
              return _.uniqBy([...pre, ...newData], "order_id");
            } else {
              return [...newData];
            }
          });
        } else {
          layout.setOrders([]);
        }
      }
    };
    updateList();

    // if (listingData?.data?.orders?.length > 0) {
    //   layout.setOrders((pre: any) => {
    //     const newData = listingData?.data?.orders?.map((order: any) => {
    //       return {
    //         ...order,
    //         selected: false,
    //       };
    //     });
    //     if (listingData?.meta?.current_page > 1) {
    //       return _.uniqBy([...pre, ...newData], "order_id");
    //     } else {
    //       return [...newData];
    //     }
    //   });
    // }else {
    //   layout.setOrders([]);
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingData, layout.orderState, isReportFetching]);

  // grouping team member

  useEffect(() => {
    if (_.size(teamOptionsData) > 0 && isGodAdmin) {
      const options = groupTeamMemberCommon(teamOptionsData);
      setGroupTeamMember(options);
    }
  }, [teamOptionsData, isGodAdmin]);

  useEffect(() => {
    if (isGodAdmin) {
      setReportsOptions({
        ...optionsData?.data,
        team_members:
          _.size(layout.reportsFilterHandle?.team_id) > 0
            ? groupTeamMemberCommon(layout.reportsFilterHandle?.team_id)
            : groupTeamMember,
      });
    } else {
      setReportsOptions(optionsData?.data);
    }
  }, [optionsData, isGodAdmin, layout.reportsFilterHandle?.team_id]);

  // LOAD REPORTS OPTIONS
  useEffect(() => {
    if (optionsData && !isGodAdmin) {
      // setReportsOptions(optionsData?.data);
      layout.setReportsFilterHandle((currentValue: any) => {
        let userLogged = optionsData?.data?.team_members?.find(
          (loggedUser: any) => loggedUser?.is_logged_in
        );
        return {
          per_page: 20,
          ...(userSpecific && { specific: authUser?.id }),
          ...(!userSpecific && {
            team_member: !startListing
              ? userLogged
                ? [userLogged]
                : []
              : currentValue?.team_member,
          }),
          // team_member: userLogged ? [userLogged] : [] ,
        };
      });
      //   setTimeout(() => {
      //     setStartListing(true);
      //   }, 800);
    }
  }, [optionsData, userSpecific, authUser, isGodAdmin]);

  useEffect(() => {
    document.body.classList.add("bg-gray-200");
  }, []);

  useEffect(() => {
    if (reportsOptions?.team_members) {
      setTimeout(() => {
        setStartListing(true);
      }, 1000);
    }
  }, [reportsOptions?.team_members]);

  // get team otpions
  useEffect(() => {
    if (teamOptionsData?.length > 0) {
      setTeamsOptions(teamOptionsData);
    }
  }, [teamOptionsData?.length > 0]);

  // useEffect(() => {
  //   if (
  //     (reportsLoading && filters?.page === 1) ||
  //     (isReportFetching && filters?.page === 1)
  //   ) {
  //     setTimeout(() => {
  //       setTimeout(() => {
  //         setReportTableLoading(false);
  //       }, 200);
  //       setSecondCounter(1);
  //     }, 1000);
  //   }
  // }, [reportsLoading, filters, isReportFetching]);

  // useEffect(() => {
  //   if (reportsLoading) {
  //     setTimeout(() => {
  //       setTimeout(() => {
  //         setReportLoading(false);
  //       }, 200);
  //       setSecondCounter(1);
  //     }, 1000);
  //   }
  // }, [reportsLoading]);

  const [shimmerLoader, setShimmerLoader] = useState<any>(true);
  const [shimmerLoaderCount, setShimmerLoaderCount] = useState<any>(true);
  const [secondCounter, setSecondCounter] = useState(2.5);

  // useEffect(() => {
  //   if (reportsLoading || isReportFetching) {
  //     setTimeout(() => {
  //       setShimmerLoader(false);
  //     }, 1000);
  //   }
  // }, [reportsLoading, isReportFetching]);

  // useEffect(() => {
  //   if (customReportLoading) {
  //     setSecondCounter((pre: any) => pre + 1);
  //   }
  // }, [customReportLoading]);

  /*for sticky event bar*/
  const heightRef1: any = useRef();
  const heightRef2: any = useRef();
  const toggleRef: any = useRef();
  const [IsToggle, setIsToggle] = useState<any>(true);

  const toggle = document.querySelector(
    '[data-te-target="#inventoryaccordion"]'
  );

  const [overViewHeight, setOverViewHeight] = useState<any>();
  useEffect(() => {
    let height3: any;
    const element: any = document.querySelector("#inventoryaccordion");
    setTimeout(() => {
      height3 = IsToggle === true ? element?.offsetHeight : 0;
      setOverViewHeight(height3);
    }, 400);
  }, [IsToggle]);

  useEffect(() => {
    const stickyElement = document.getElementById("topFilters") as HTMLElement;
    const stickyElementStyle = window.getComputedStyle(stickyElement);
    const stickyElementheight: any =
      stickyElementStyle.getPropertyValue("height");

    const filter = document.getElementById("filters_block") as HTMLElement;
    const heightheightRef2 = filter.offsetHeight;

    if (stickyElement) {
      // const heightheightRef2 = heightRef2?.current?.offsetHeight;

      const initialPosition = stickyElement.getBoundingClientRect().top;
      const accordion = document.getElementById("inventoryaccordion");

      const accorAttr = accordion?.getAttribute("data-te-collapse-show");

      let MarginTopTotle = 0;
      let topPosition = 0;

      // const MarginTopTotle =
      //     IsToggle === true
      //       ? heightheightRef1 + overViewHeight - HeaderHeight
      //       : heightheightRef1 - HeaderHeight;

      const recalculateMarginTopTotle = () => {
        const accordion = document.getElementById(
          "inventoryaccordion"
        ) as HTMLElement;
        const accordionStyle = window.getComputedStyle(accordion);
        const accordionheight: any = parseFloat(
          accordionStyle.getPropertyValue("height")
        );
        // Recalculate MarginTopTotle based on updated heights or values
        const heightheightRef1 = heightRef1?.current?.offsetHeight;
        const HeaderHeight: any =
          document.querySelector("header")?.clientHeight;
        const filter = document.getElementById("filters_block") as HTMLElement;
        const heightheightRef2 = filter.offsetHeight;
        const salesFilter: any =
          document.getElementById("report_filter")?.clientHeight;

        var computedStyle = window.getComputedStyle(stickyElement);
        var paddingBottom = computedStyle.paddingBottom;
        var paddingBottomValue = parseInt(paddingBottom, 10);

        if (window.matchMedia("(max-width: 767px)").matches) {
          topPosition = HeaderHeight;
        } else {
          topPosition =
            heightheightRef2 +
            salesFilter +
            HeaderHeight +
            paddingBottomValue -
            1;
        }
        MarginTopTotle =
          IsToggle === true
            ? heightheightRef1 + accordionheight - HeaderHeight
            : HeaderHeight - heightheightRef1;
      };

      const makeSticky = function () {
        const HeaderHeight: any =
          document.querySelector("header")?.clientHeight;
        const sticky_head: any = document.getElementById("sticky-head");
        const stickyShadow: any = document.getElementById("sticky-shadow");
        const sticky_headHeight: any = sticky_head?.clientHeight;

        if (window.scrollY + HeaderHeight >= initialPosition) {
          stickyElement.classList.add("sticky");
          stickyElement.style.top =
            IsToggle === true ? `-${MarginTopTotle}px` : `${MarginTopTotle}px`;
          sticky_head.style.top = topPosition + "px";
          stickyShadow.style.top = topPosition + sticky_headHeight + "px";
        } else {
          stickyElement.classList.remove("sticky");
          stickyElement.style.top = `0px`;
          if (sticky_head) sticky_head.style.top = "0px";
          stickyShadow.style.top = "0px";
        }
        recalculateMarginTopTotle();
      };
      const handleWindowResize = () => {
        // Handle logic when the window is resized
        recalculateMarginTopTotle(); // Recalculate MarginTopTotle on window resize
        makeSticky(); // Update sticky behavior based on new values
      };

      // if (window.scrollY > 200) {
      //   makeSticky();
      // }

      window.addEventListener("scroll", makeSticky);
      window.addEventListener("change", makeSticky);
      window.addEventListener("blur", makeSticky);

      setTimeout(() => {
        document.addEventListener("resize", handleWindowResize);
      }, 200);
      return () => {
        window.removeEventListener("scroll", makeSticky);
        window.removeEventListener("change", makeSticky);
        window.removeEventListener("blur", makeSticky);
        document.removeEventListener("resize", handleWindowResize);
      };
    }
    // });
  }, [toggleRef, IsToggle, overViewHeight]);
  /* end sticky event */

  return (
    <>
      <div className="main-content-wrap">
        <div
          className={`topFilters sticky-element top-[60px] z-[11] pb-5 bg-gray-200 max-md:!static`}
          id="topFilters"
        >
          <div className="accordion font-medium shadow-op2">
            <div
              className="accordion-item !rounded-none bg-white border-b border-gray-200"
              ref={heightRef1}
            >
              <div
                className="flex  justify-between accordion-header mb-0"
                id="headingOne"
              >
                <div className="flex flex-wrap justify-between w-full pl-2 pr-5 py-2">
                  {/* <div className="flex flex-wrap" id="filters">
                    <div className="ba_item relative" ref={bulkActionRef}>
                      <span
                        className="sel_item block py-0.5 pr-6 pl-3 text-sm13 font-medium [&[aria-expanded='true']]:text-indigo-500 cursor-pointer"
                        data-te-collapse-init
                        data-te-collapse-collapsed
                        data-te-target="#bulkaction"
                        aria-expanded="false"
                        aria-controls="bulkaction"
                      >
                        Filters
                      </span>
                      <div
                        id="bulkaction"
                        className="!visible ba_dd absolute top-full bg-white rounded shadow-lt left-0 min-w-[15rem] w-full hidden z-20"
                        data-te-collapse-item
                        aria-labelledby="bulkaction"
                        data-te-parent="#filters"
                      >
                        <DraggableFilters />
                      </div>
                    </div>
                    <div className="ba_item relative" ref={bulkAction1Ref}>
                      <span
                        className="sel_item block py-0.5 pr-6 pl-3 text-sm13 font-medium [&[aria-expanded='true']]:text-indigo-500 cursor-pointer"
                        data-te-collapse-init
                        data-te-collapse-collapsed
                        data-te-target="#bulkaction1"
                        aria-expanded="false"
                        aria-controls="bulkaction1"
                      >
                        Columns
                      </span>
                      <div
                        id="bulkaction1"
                        className="!visible ba_dd absolute top-full bg-white rounded shadow-lt left-0 min-w-[15rem] w-full hidden z-20"
                        data-te-collapse-item
                        aria-labelledby="bulkaction1"
                        data-te-parent="#filters"
                      >
                        <DraggableColumns />
                      </div>
                    </div>
                  </div> */}
                  <HeaderfilterContainer />
                </div>
                <div>
                  <button
                    className="group relative flex items-center py-4 px-5 text-base text-gray-800 text-left bg-white border-l transition focus:outline-none group h-full md:h-auto [&[aria-expanded='false'] > .icon ]:rotate-180"
                    type="button"
                    data-te-collapse-init
                    data-te-toggle="collapse"
                    data-te-target="#inventoryaccordion"
                    aria-expanded="true"
                    aria-controls="inventoryaccordion"
                    onClick={() =>
                      setIsToggle(IsToggle === false ? true : false)
                    }
                  >
                    <IKTSVG
                      className="flex items-center justify-center group-[.collapsed]:rotate-180  fill-violet-500 hover:fill-indigo-500 transition group-[[data-te-collapse-collapsed]]:rotate-180"
                      path={collapseSVG}
                    />
                  </button>
                </div>
              </div>
            </div>
            <div
              className="bg-white accordion-collapse font-medium collapse show"
              id="inventoryaccordion"
              data-te-collapse-item
              data-te-collapse-show
              aria-labelledby="headingOne"
            >
              <TabBody
                bodyId="reports"
                isFirst={true}
                data={{ revenue: headerOptions }}
                reportsLoading={shimmerLoaderCount || reportsOptionsLoading}
              >
                {/* MARKETPLACES COUNTS */}
                {/* <div className="px-5 pt-3 border-b bg-gray-100/50">
              <div className="divide-gray-200 divide-y-2">
                <div className="flex flex-wrap pt-3 -mx-2.5">
                  {isArray(headerOptions?.revenue?.exchanges_records) &&
                    headerOptions?.revenue?.exchanges_records.map(
                      (exchanges_record: any) => {
                        return (
                          <ReportsMarketCountBoxes
                            name={exchanges_record?.exchanges_name}
                            price={exchanges_record?.price}
                            countValue={exchanges_record?.orders_count}
                            postKey={exchanges_record?.exchanges_id}
                            isCheckbox={true}
                            allOptions={reportsOptions?.order_exchanges}
                          />
                        );
                      }
                    )}
                </div>
              </div>
            </div> */}
              </TabBody>
            </div>
            <ReportsFilter
              query={query}
              options={reportsOptions}
              debouncedSearch={debouncedSearch}
              paginateData={listingData?.meta}
              handleQuery={(data: any) => {
                setQuery(data);
              }}
              handleAlgoliaValue={(data: any) => setAlgoliaQuery(data)}
              handleKeyDown={(event: any) => handleKeyDown(event)}
              teamsOptions={teamsOptions}
              isGodAdmin={isGodAdmin}
              reportsLoading={shimmerLoader}
            />
          </div>
        </div>
        {/* reportsLoading: {JSON.stringify(reportsLoading)}
        <br />
        customReportTableLoading: {JSON.stringify(customReportTableLoading)} */}
        {customReportTableLoading ? (
          <>
            {Array.from({ length: 10 }, (v, i) => (
              <div
                className={`accordion font-medium  mb-2.5 mx-5 ${
                  i === 0 && "mt-5"
                } `}
                key={i}
              >
                <div
                  key={i}
                  className={`accordion-item bg-white !rounded overflow-hidden ${
                    customReportTableLoading && "shimmer-effect"
                  } `}
                  style={{ height: "40px" }}
                ></div>
              </div>
            ))}
          </>
        ) : (
          !customReportTableLoading &&
          orders?.length > 0 && (
            <DataTable
              data={orders}
              tabValue={tabValue}
              paginateData={listingData?.meta}
              reportsLoading={reportsLoading}
              secoundCounter={customReportTableLoading}
              reportsOptions={reportsOptions}
            />
          )
        )}
        {orders?.length === 0 && !customReportTableLoading && (
          <>
            <NoDataComponent />
          </>
        )}
      </div>
      <div
        data-te-modal-init
        className={`offcanvas new_rep_temp offcanvas-end fixed bottom-0 top-[2.75rem] md:top-[3.75rem] right-0 shadow-lg bg-clip-padding outline-none transition duration-300 ease-in-out font-medium md:max-w-[43.125rem] max-w-[calc(100%-3.75rem)] w-full`}
        tabIndex={-1}
        id="detailCanvas"
        aria-labelledby="detailCanvasLabel"
      >
        <div className="  bg-white shadow-3xl w-full h-full ml-auto">
          <div className="offcanvas-header flex justify-between border-t border-b">
            <h5
              className="offcanvas-title mb-0 leading-normal text-sm15 font-semibold py-2 px-5"
              id="offcanvasRightLabel"
            >
              Create a new report template
            </h5>
            <button
              type="button"
              className="flex items-center justify-center w-9 border-l box-content group"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <IKTSVG
                className="fill-violet-800 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
                path={crossSVG}
              />
            </button>
          </div>
          <div className="offcanvas-body h-full w-full flex flex-col overflow-y-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full p-5 mb-1">
            <div className="s_input w-full border-b pb-5">
              <input
                type="text"
                className="block px-2.5 font-medium h-8 text-xs transition bg-white border border-gray-300 appearance-none rounded focus:outline-none focus:ring-0 focus:border-indigo-500 focus:bg-violet-300/50 peer w-full"
              />
            </div>
            <div className="s_res my-5">
              <span className=" mb-5 block text-sm14">
                The template will be saved with the filters:
              </span>
              <div className="flex items-center justify-center sm:justify-start gap-x-2.5 flex-auto">
                <div className="flex py-1 px-2.5 text-sm12 rounded-full bg-gray-100">
                  Harry Styles Glasgow
                  <button className="ml-4">
                    <IKTSVG path={crossSVG} svgClassName="h-2 w-2" />
                  </button>
                </div>
                <div className="flex py-1 px-2.5 text-sm12 rounded-full bg-gray-100">
                  Harry Styles Manchester
                  <button className="ml-4">
                    <IKTSVG path={crossSVG} svgClassName="h-2 w-2" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className=" sticky bottom-0 right-0 w-full flex justify-start px-5 py-3 bg-white z-10 border-t">
            <div className="l_btn gap-2.5 flex flex-wrap">
              <button
                className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white 
                  bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
              >
                Cancel
              </button>
              <button className="relative z-10 border border-green-600 hover:text-green-600 active:text-white text-white hover:bg-white bg-green-600 active:bg-green-600 transition font-semibold rounded text-sm13 px-2.5 py-0.5 uppercase ">
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        data-te-modal-init
        className={`offcanvas rep_temp_set offcanvas-end fixed bottom-0 top-[2.75rem] md:top-[3.75rem] right-0 shadow-lg bg-clip-padding outline-none transition duration-300 ease-in-out font-medium md:max-w-[43.125rem] max-w-[calc(100%-3.75rem)] w-full`}
        tabIndex={-1}
        id="rep_temp_set"
        aria-labelledby="repTempSetLabel"
      >
        <div className="  bg-white shadow-3xl w-full h-full ml-auto">
          <div className="offcanvas-header flex justify-between border-t border-b">
            <div className="flex">
              <div className="flex items-center justify-center w-10 border-r">
                <IKTSVG
                  className="fill-violet-800 group-hover:fill-indigo-500 transition rotate-90"
                  path={arrowDownSVG}
                />
              </div>
              <h5
                className="offcanvas-title mb-0 leading-normal text-sm15 font-semibold py-2 px-5"
                id="offcanvasRightLabel"
              >
                Create a new report template
              </h5>
            </div>
            <button
              type="button"
              className="flex items-center justify-center w-9 border-l box-content group"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <IKTSVG
                className="fill-violet-800 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
                path={crossSVG}
              />
            </button>
          </div>
          <div className="offcanvas-body h-full w-full flex flex-col overflow-y-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full p-5 mb-1">
            <div className="s_input w-full border-b pb-5">
              <input
                type="text"
                className="block px-2.5 font-medium h-8 text-xs transition bg-white border border-gray-300 appearance-none rounded focus:outline-none focus:ring-0 focus:border-indigo-500 focus:bg-violet-300/50 peer w-full"
              />
            </div>
            <div className="s_res my-5">
              <span className=" mb-5 block text-sm14">
                This template has been saved with the filters:
              </span>
              <div className="flex items-center justify-center sm:justify-start gap-x-2.5 flex-auto">
                <div className="flex py-1 px-2.5 text-sm12 rounded-full bg-gray-100">
                  Harry Styles Glasgow
                  <button className="ml-4">
                    <IKTSVG path={crossSVG} svgClassName="h-2 w-2" />
                  </button>
                </div>
                <div className="flex py-1 px-2.5 text-sm12 rounded-full bg-gray-100">
                  Harry Styles Manchester
                  <button className="ml-4">
                    <IKTSVG path={crossSVG} svgClassName="h-2 w-2" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className=" sticky bottom-0 right-0 w-full flex justify-start px-5 py-3 bg-white z-10 border-t">
            <div className="l_btn gap-2.5 flex flex-wrap">
              <button
                className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white 
                  bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
              >
                Cancel
              </button>
              <button className="relative z-10 border border-green-600 hover:text-green-600 active:text-white text-white hover:bg-white bg-green-600 active:bg-green-600 transition font-semibold rounded text-sm13 px-2.5 py-0.5 uppercase ">
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        data-te-modal-init
        className={`offcanvas rep_temp offcanvas-end fixed bottom-0 top-[2.75rem] md:top-[3.75rem] right-0 shadow-lg bg-clip-padding outline-none transition duration-300 ease-in-out font-medium md:max-w-[43.125rem] max-w-[calc(100%-3.75rem)] w-full`}
        tabIndex={-1}
        id="rep_temp"
        aria-labelledby="repTempLabel"
      >
        <div className="  bg-white shadow-3xl w-full h-full ml-auto">
          <div className="offcanvas-header flex justify-between border-t border-b">
            <h5
              className="offcanvas-title mb-0 leading-normal text-sm15 font-semibold py-2 px-5"
              id="offcanvasRightLabel"
            >
              Report templates
            </h5>

            <button
              type="button"
              className="flex items-center justify-center w-9 border-l box-content group"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <IKTSVG
                className="fill-violet-800 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
                path={crossSVG}
              />
            </button>
          </div>
          <div className="offcanvas-body h-full w-full flex flex-col overflow-y-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full p-5 mb-1">
            <div className="fav_list mb-5 border-b pb-2.5">
              <ul>
                <li className="flex flex-wrap justify-between bg-sky-blue-500/5 rounded mb-2.5">
                  <div className="list_name px-2.5 py-1.5 text-sm13 font-medium">
                    <h5>MUFC monthly</h5>
                  </div>
                  <div className="list_setting flex items-center mr-2.5 ml-auto">
                    <div className="pinned flex items-center pr-4 border-r h-full py-1.5">
                      <IKTSVG
                        className="fill-sky-blue-500 group-hover:fill-indigo-500 h-3 w-2 transition mr-1.5"
                        path={pinSVG}
                      />
                      <h5 className="text-sm12 text-sky-blue-500">Pinned</h5>
                    </div>
                    <div className="flex items-center pl-2">
                      <button
                        type="button"
                        className="flex items-center justify-center box-content group mr-2.5"
                      >
                        <IKTSVG
                          className="fill-violet-500 group-hover:fill-indigo-500  transition"
                          path={settingSVG}
                        />
                      </button>
                      <button
                        type="button"
                        className="flex items-center justify-center box-content group"
                      >
                        <IKTSVG
                          className="fill-violet-500 group-hover:fill-indigo-500  transition"
                          path={deleteSVG}
                        />
                      </button>
                    </div>
                  </div>
                </li>
                <li className="flex flex-wrap justify-between bg-sky-blue-500/5 rounded mb-2.5">
                  <div className="list_name px-2.5 py-1.5 text-sm13 font-medium">
                    <h5>MUFC monthly</h5>
                  </div>
                  <div className="list_setting flex items-center mr-2.5 ml-auto">
                    <div className="pinned flex items-center pr-4 border-r h-full py-1.5">
                      <IKTSVG
                        className="fill-sky-blue-500 group-hover:fill-indigo-500 h-3 w-2 transition mr-1.5"
                        path={pinSVG}
                      />
                      <h5 className="text-sm12 text-sky-blue-500">Pinned</h5>
                    </div>
                    <div className="flex items-center pl-2">
                      <button
                        type="button"
                        className="flex items-center justify-center box-content group mr-2.5"
                      >
                        <IKTSVG
                          className="fill-violet-500 group-hover:fill-indigo-500  transition"
                          path={settingSVG}
                        />
                      </button>
                      <button
                        type="button"
                        className="flex items-center justify-center box-content group"
                      >
                        <IKTSVG
                          className="fill-violet-500 group-hover:fill-indigo-500  transition"
                          path={deleteSVG}
                        />
                      </button>
                    </div>
                  </div>
                </li>
                <li className="flex flex-wrap justify-between bg-sky-blue-500/5 rounded mb-2.5">
                  <div className="list_name px-2.5 py-1.5 text-sm13 font-medium">
                    <h5>MUFC monthly</h5>
                  </div>
                  <div className="list_setting flex items-center mr-2.5 ml-auto">
                    <div className="pinned flex items-center pr-4 border-r h-full py-1.5">
                      <IKTSVG
                        className="fill-sky-blue-500 group-hover:fill-indigo-500 h-3 w-2 transition mr-1.5"
                        path={pinSVG}
                      />
                      <h5 className="text-sm12 text-sky-blue-500">Pinned</h5>
                    </div>
                    <div className="flex items-center pl-2">
                      <button
                        type="button"
                        className="flex items-center justify-center box-content group mr-2.5"
                      >
                        <IKTSVG
                          className="fill-violet-500 group-hover:fill-indigo-500  transition"
                          path={settingSVG}
                        />
                      </button>
                      <button
                        type="button"
                        className="flex items-center justify-center box-content group"
                      >
                        <IKTSVG
                          className="fill-violet-500 group-hover:fill-indigo-500  transition"
                          path={deleteSVG}
                        />
                      </button>
                    </div>
                  </div>
                </li>
                <li className="flex flex-wrap justify-between bg-sky-blue-500/5 rounded mb-2.5">
                  <div className="list_name px-2.5 py-1.5 text-sm13 font-medium">
                    <h5>MUFC monthly</h5>
                  </div>
                  <div className="list_setting flex items-center mr-2.5 ml-auto">
                    <div className="pinned flex items-center pr-4 border-r h-full py-1.5">
                      <IKTSVG
                        className="fill-sky-blue-500 group-hover:fill-indigo-500 h-3 w-2 transition mr-1.5"
                        path={pinSVG}
                      />
                      <h5 className="text-sm12 text-sky-blue-500">Pinned</h5>
                    </div>
                    <div className="flex items-center pl-2">
                      <button
                        type="button"
                        className="flex items-center justify-center box-content group mr-2.5"
                      >
                        <IKTSVG
                          className="fill-violet-500 group-hover:fill-indigo-500  transition"
                          path={settingSVG}
                        />
                      </button>
                      <button
                        type="button"
                        className="flex items-center justify-center box-content group"
                      >
                        <IKTSVG
                          className="fill-violet-500 group-hover:fill-indigo-500  transition"
                          path={deleteSVG}
                        />
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="def_list mb-5 pb-2.5">
              <ul>
                <li className="flex flex-wrap justify-between bg-gray-100 rounded mb-2.5">
                  <div className="list_name px-2.5 py-1.5 text-sm13 font-medium">
                    <h5>MUFC monthly</h5>
                  </div>
                  <div className="list_setting flex items-center mr-2.5 ml-auto">
                    <div className="pinned flex items-center pr-4 border-r h-full py-1.5">
                      <IKTSVG
                        className="fill-violet-800 h-3 w-2 transition mr-1.5"
                        path={pinSVG}
                      />
                      <h5 className="text-sm12">Pin to favourites</h5>
                    </div>
                    <div className="flex items-center pl-2">
                      <button
                        type="button"
                        className="flex items-center justify-center box-content group mr-2.5"
                      >
                        <IKTSVG
                          className="fill-violet-500 group-hover:fill-indigo-500  transition"
                          path={settingSVG}
                        />
                      </button>
                      <button
                        type="button"
                        className="flex items-center justify-center box-content group"
                      >
                        <IKTSVG
                          className="fill-violet-500 group-hover:fill-indigo-500  transition"
                          path={deleteSVG}
                        />
                      </button>
                    </div>
                  </div>
                </li>
                <li className="flex flex-wrap justify-between bg-gray-100 rounded mb-2.5">
                  <div className="list_name px-2.5 py-1.5 text-sm13 font-medium">
                    <h5>MUFC monthly</h5>
                  </div>
                  <div className="list_setting flex items-center mr-2.5 ml-auto">
                    <div className="pinned flex items-center pr-4 border-r h-full py-1.5">
                      <IKTSVG
                        className="fill-violet-800 h-3 w-2 transition mr-1.5"
                        path={pinSVG}
                      />
                      <h5 className="text-sm12">Pin to favourites</h5>
                    </div>
                    <div className="flex items-center pl-2">
                      <button
                        type="button"
                        className="flex items-center justify-center box-content group mr-2.5"
                      >
                        <IKTSVG
                          className="fill-violet-500 group-hover:fill-indigo-500  transition"
                          path={settingSVG}
                        />
                      </button>
                      <button
                        type="button"
                        className="flex items-center justify-center box-content group"
                      >
                        <IKTSVG
                          className="fill-violet-500 group-hover:fill-indigo-500  transition"
                          path={deleteSVG}
                        />
                      </button>
                    </div>
                  </div>
                </li>
                <li className="flex flex-wrap justify-between bg-gray-100 rounded mb-2.5">
                  <div className="list_name px-2.5 py-1.5 text-sm13 font-medium">
                    <h5>MUFC monthly</h5>
                  </div>
                  <div className="list_setting flex items-center mr-2.5 ml-auto">
                    <div className="pinned flex items-center pr-4 border-r h-full py-1.5">
                      <IKTSVG
                        className="fill-violet-800 h-3 w-2 transition mr-1.5"
                        path={pinSVG}
                      />
                      <h5 className="text-sm12">Pin to favourites</h5>
                    </div>
                    <div className="flex items-center pl-2">
                      <button
                        type="button"
                        className="flex items-center justify-center box-content group mr-2.5"
                      >
                        <IKTSVG
                          className="fill-violet-500 group-hover:fill-indigo-500  transition"
                          path={settingSVG}
                        />
                      </button>
                      <button
                        type="button"
                        className="flex items-center justify-center box-content group"
                      >
                        <IKTSVG
                          className="fill-violet-500 group-hover:fill-indigo-500  transition"
                          path={deleteSVG}
                        />
                      </button>
                    </div>
                  </div>
                </li>
                <li className="flex flex-wrap justify-between bg-gray-100 rounded mb-2.5">
                  <div className="list_name px-2.5 py-1.5 text-sm13 font-medium">
                    <h5>Community Shield 2022: Liverpool vs Manchester City</h5>
                  </div>
                  <div className="list_setting flex items-center mr-2.5 ml-auto">
                    <div className="pinned flex items-center pr-4 border-r h-full py-1.5">
                      <IKTSVG
                        className="fill-violet-800 h-3 w-2 transition mr-1.5"
                        path={pinSVG}
                      />
                      <h5 className="text-sm12">Pin to favourites</h5>
                    </div>
                    <div className="flex items-center pl-2">
                      <button
                        type="button"
                        className="flex items-center justify-center box-content group mr-2.5"
                      >
                        <IKTSVG
                          className="fill-violet-500 group-hover:fill-indigo-500  transition"
                          path={settingSVG}
                        />
                      </button>
                      <button
                        type="button"
                        className="flex items-center justify-center box-content group"
                      >
                        <IKTSVG
                          className="fill-violet-500 group-hover:fill-indigo-500  transition"
                          path={deleteSVG}
                        />
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <div className=" h-60 pr-5 pb-6">
          <Graphs />
        </div> */}
      <Footer />
      {/* {reportsLoading && <Loader />} */}

      {customReportTableLoading && (
        <ProgressBardLoader
          LoadingText={"Loading your report data"}
          secondCounter={secondCounter}
        />
      )}

      {/* )} */}
    </>
  );
};
export default Reports;
