export const SUBSCRIBE_TAB = {
  subscribeDetails: "Subscriber Details",
  referral: "Referrals",
};

export const SUBSCRIBE_ROUTE = {
  subscribeDetails: "/subscribe-details",
  referral: "/referrals",
};

export const SUBSCRIBE_DETAILS_ACTION_OPTIONS = [
  {
    id: "1",
    name: "Active",
    label: "Active",
  },
  {
    id: "3",
    name: "Declined",
    label: "Declined",
  },
];
export const SUBSCRIBE_REFERRAL_ACTION_OPTIONS = [
  {
    id: "1",
    name: "Active",
    label: "Active",
  },
  {
    id: "0",
    name: "Inactive",
    label: "Inactive",
  },
];
