export const TX_TAB = {
  wallet: "Wallet",
  virtualCard: "Virtual Cards",
};

export const TX_PAY_TAB_ROUTES = {
  wallet: "/wallet",
  virtualCard: "/virtual-cards",
};

export const COLOR_NAME_ID: any = {
  Purple: "dark-purple",
  Green: "dark-green",
  Blue: "dark-blue",
  Teal: "dark-parrot-green",
  Violet: "dark-purple-blue",
};

export const TX_PAY_TAB_ROUTES_WITH_PERMISSION = [
  {
    route: TX_PAY_TAB_ROUTES.wallet,
    hasPermission: (access: any) =>
      (access || [])?.some(
        (item: any) => (item.id === "txpay" && item.status === true) || false
      ),
  },
  {
    route: TX_PAY_TAB_ROUTES.virtualCard,
    hasPermission: (access: any) =>
      access?.some(
        (item: any) =>
          (item.id === "virtual-cards" && item.status === true) || false
      ),
  },
];
