import clsx from "clsx";
import { IKTSVG } from "components/ui/IKTSVG";
import { KTSVG } from "components/ui/KTSVG";
import { getExtension } from "helpers/AssetHelpers";
import { Tooltip } from "react-tooltip";
import Eye from "../../../assets/media/icons/other_icons/Eye.svg";

const AdditionalFileView = ({
  eventRecord,
  eventIndex,
  eventRecordIndex,
  pdfView,
  deletePdfRightSide,
  newFIleAllSaved,
}: any) => {
  return (
    <div className="pre-upload-addtional-file">
      <div
        className={clsx(
          "px-2  border border-solid rounded rounded-t-none -mt-[.0625rem] w-full text-xs mb-2.5 bg-gray-100/50 flex grid-cols-[25%_1fr_2.5rem_2.5rem] md:grid-cols-[1fr_17%_1fr_2.5rem_2.5rem]",
          "gap-2.5 pb-2",
          eventRecord?.additionalFile &&
            "!bg-green-600 !bg-opacity-[7%] !border-green-700 !border-solid "
        )}
      >
        <div className={`flex items-center  gap-2 pt-2 `}>
          <p>Additional file</p>
        </div>
        <div className="single_drag flex flex-wrap items-center single_drag flex flex-wrap col-span-full items-center flex-1  ">
          <div className="w-full mt-2  !border-solid !border-green-500 hover:!border-indigo-500 hover:bg-violet-50 transition">
            <div className="w-full flex justify-between !border-solid !border-green-500 hover:!border-indigo-500 hover:bg-violet-50 transition !border-solid !border-green-500 hover:!border-indigo-500 hover:bg-violet-50 transition border border-dashed rounded bg-white px-2 py-[3px] flex-1 transition ">
              {/* {eventRecord?.additionalFile?.[0]?.file} */}
              {`file.${getExtension(eventRecord?.additionalFile?.[0]?.path)}`}

              <span className={`flex s-center gap-x-2 `}>
                <button
                  type="button"
                  id={`view-${eventIndex}-${eventRecordIndex}-additionalFile`}
                  onClick={() => {
                    pdfView(eventRecord?.additionalFile?.[0]);
                  }}
                >
                  <IKTSVG
                    className="fill-violet-500 hover:fill-indigo-500 transition"
                    path={Eye}
                    svgClassName="w-4 h-4"
                  />
                  <Tooltip
                    anchorId={`view-${eventIndex}-${eventRecordIndex}-additionalFile`}
                    content="Preview"
                    place="top"
                    variant="light"
                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                  />
                </button>
                {!newFIleAllSaved && (
                  <button
                    type="button"
                    onClick={() => {
                      deletePdfRightSide(
                        eventRecord?.additionalFile,
                        "main-additionalFile",
                        eventRecordIndex,
                        eventIndex
                      );
                    }}
                    id={`delete-${eventIndex}-${eventRecordIndex}-additionalFile`}
                  >
                    <KTSVG
                      className="fill-violet-500 hover:fill-indigo-500 transition"
                      path={"other_icons/Delete.svg"}
                      svgClassName="w-2.5 h-[.8125rem]"
                    />

                    <Tooltip
                      anchorId={`delete-${eventIndex}-${eventRecordIndex}-additionalFile`}
                      content="Remove"
                      place="top"
                      variant="light"
                      className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                    />
                  </button>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalFileView;
