import Collapse from "assets/media/icons/other_icons/Collapse";
import ComponentSVG from "components/ui/typography/ComponentSVG";
import HeaderfilterContainer from "./HeaderfilterContainer";

const HeaderFilterWrapper = () => {
  return (
    <div className="flex accordion-header mb-0 max-md:hidden" id="headingOne">
      <div className="pl-2 pr-5 py-2 flex max-md:hidden">
        <HeaderfilterContainer />
      </div>
      <button
        className=" group relative flex items-center p-[.9375rem] text-base text-gray-800 text-left bg-white border-l transition focus:outline-none group ml-auto [&[aria-expanded='false'] > .icon ]:rotate-180"
        type="button"
        data-te-collapse-init
        data-te-target="#collapseOne"
        aria-expanded="true"
        aria-controls="collapseOne"
        id="collapse-btn"
      >
        <ComponentSVG className="flex items-center justify-center  fill-violet-500 hover:fill-indigo-500 transition group-[[data-te-collapse-collapsed]]:rotate-180 }">
          <Collapse />
        </ComponentSVG>
      </button>
    </div>
  );
};

export default HeaderFilterWrapper;
