
import { useContext } from "react";
import { PersonalDetailsContext } from "../core/PersonalDetailsProvider";
import SubscribeTab from "./SubscribeTab";
import { SUBSCRIBE_ROUTE, SUBSCRIBE_TAB } from "../core/const";

const TabWrapper = () => {
  const { activeTab, setActiveTab } = useContext(PersonalDetailsContext);

  return (
    <>
      {Object.entries(SUBSCRIBE_TAB).map(([key, labelText]) => {
        const route =
          labelText === SUBSCRIBE_TAB.subscribeDetails
            ? `/subscribe${SUBSCRIBE_ROUTE?.subscribeDetails}`
            : `/subscribe${SUBSCRIBE_ROUTE?.referral}`;
        return (
          <SubscribeTab
            key={key}
            labelText={labelText}
            setActiveTab={setActiveTab}
            route={route}
            tab={activeTab}
          />
        );
      })}
    </>
  );
};

export default TabWrapper;
