import { IKTSVG } from "../IKTSVG";

interface TabInterface {
  imgPath?: any;
  title?: string;
  handleTab?: any;
  isActiveTab?: Boolean;
  tabKey?: any;
  className?: any;
}

export const Tab: React.FC<TabInterface> = ({
  imgPath,
  title,
  handleTab,
  isActiveTab = false,
  tabKey,
  className = "",
}) => {
  return (
    <>
      <div
        className={`${
          isActiveTab
            ? "bg-opacity-100 z-10 after:bg-menuCorner after:bg-contain after:absolute after:left-full after:bottom-0 after:w-2 after:h-2 after:transition after:duration-200 after:brightness-0 after:invert after:-rotate-90 before:bg-menuCorner before:bg-contain before:absolute before:right-full before:bottom-0 before:w-2 before:h-2 before:transition before:duration-200 before:brightness-0 before:invert before:rotate-180"
            : "bg-opacity-60"
        } relative single-tab lg:px-4 px-2.5 py-[.5625rem]  min-w-[10.625rem lg:whitespace-normal whitespace-nowrap flex justify-between items-center bg-white rounded-t-lg cursor-pointer mr-[1px] max-w-[50%] w-full gap-2 ${className} hover:bg-opacity-100`}
        onClick={() => handleTab(tabKey)}
        key={tabKey}
      >
        <h4 className={`lg:text-sm15 text-sm14 font-semibold `}>{title}</h4>
        {imgPath && (
          <IKTSVG
            className={`123 flex items-center justify-center fill-${
              isActiveTab ? "indigo" : "violet"
            }-500 transition`}
            path={imgPath}
            svgClassName={"w-4 h-4"}
          />
        )}
      </div>
    </>
  );
};
