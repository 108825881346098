const DataCell = ({
  value,
  subValue,
  classname = "",
  width = "",
  padding,
  noCapitalize,
  noEllips = false,
  evtName = false,
  handleCellClick,
}: any) => {
  return (
    <>
      <td className={`p-1.5 font-medium min-h-10 h-10  ${padding} ${evtName}`}>
        <span className={`${noEllips ? " " : "ellips-text"}`}>
          <p
            className={`${width} ${classname} ${
              noCapitalize === "yes" ? "" : ""
            } `}
            title={value ? value : ""}
            onClick={handleCellClick}
          >
            {value || "-"}
          </p>
          {subValue && (
            <small className="text-xxs text-gray-500">{subValue}</small>
          )}
        </span>
      </td>
    </>
  );
};

export default DataCell;
