/* eslint-disable array-callback-return */
import { size } from "lodash";
import React, { useContext, useEffect } from "react";
import close from "../../../../assets/media/icons/other_icons/Clear.svg";
import { CrossIcon } from "../../../../assets/media/icons/other_icons/CrossIcon";
import { SearchIcon } from "../../../../assets/media/icons/standard_icons/SearchIcon";
import { LayoutContext } from "../../../../components/core/LayoutProvider";
import DatePickerField from "../../../../components/formComponent/DatePickerField";
import MultiDropDownField from "../../../../components/formComponent/MultiDropDownField";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import { INITIAL_PERSONAL_DETAILS } from "../../../../helpers/const";
import { handleSelectDeselectAll } from "../../../../helpers/Functions";
import { PersonalDetailsContext } from "../core/PersonalDetailsProvider";

const Filters = ({ loading, searchValue, setSearchValue }: any) => {
  const layout = useContext(PersonalDetailsContext);
  const coreLayout = useContext(LayoutContext);
  const filter = layout.filterData;
  const filterOptions = layout.filterOptions;
  const filterData = layout?.filterData;
  const { activeTab } = layout;

  useEffect(() => {
    setSearchValue("");
  }, [activeTab]);

  // HEADER FILTER
  const getFilterItems = () =>
    layout.filterItems?.filter((obj: any) => obj?.checked === true);

  // HANDLE CHANGE
  const handleOnChange = (data: any) => {
    layout.setFilterData((current: any) => {
      return {
        ...current,
        [data?.target?.name]: data?.target?.value,
        page: 1,
        per_page: 20,
      };
    });
  };

  // BULK SELECT / DESELECT
  const handleOnSelection = (
    data: any,
    fieldName: string,
    options: any,
    visibleOptions: any
  ) => {
    handleSelectDeselectAll(
      data,
      fieldName,
      options,
      visibleOptions,
      layout.setFilterData
    );
  };

  // RESET ALL
  const handleRefresh = () => {
    layout.setFilterData(INITIAL_PERSONAL_DETAILS);
    setSearchValue("");
  };

  function ToggleElemClose() {
    const submenuWrapper: any = document.querySelector("#filtersMob");
    submenuWrapper.classList.remove("activeFIlter");
    document.body.classList.remove("overflow-hidden");
  }

  const clearDisable =
    size(filterData?.teams) > 0 ||
    size(filterData?.statuses) > 0 ||
    size(filterData?.risk_levels) > 0 ||
    size(filterData?.register_date) > 0 ||
    size(filterData?.seller_levels) > 0 ||
    size(filterData?.user_type) > 0 ||
    filterData?.query;

  const userTypeOptions = [
    {
      id: 1,
      name: "Buyer",
      level: "Buyer",
    },
    {
      id: 0,
      name: "Seller",
      level: "Seller",
    },
  ];

  return (
    <div id="filtersMob" className="filtersMob max-md:flex max-md:flex-col">
      <div
        className="flex flex-wrap border-t gap-y-2.5 py-4 px-[.9375rem] bg-white max-md:pt-14 max-md:flex-1 max-md:overflow-auto max-md:content-start"
        id={`filters_block`}
      >
        <div
          className="closePopup hidden max-md:flex"
          id="closePopup"
          onClick={ToggleElemClose}
        >
          <IKTSVG
            path={close}
            className="w-10 absolute top-[1px] right-0 h-10 flex items-center justify-center cursor-pointer z-[1] bg-white"
            svgClassName="w-4 h-4"
          />
        </div>
        {/* SEARCH WITH NAME */}
        <div className={`w-full lg2:w-1/3 lg:w-1/2 xl:w-[25%]  px-[.3125rem]`}>
          <div
            className={`relative rounded flex-1 max-w-full md:mb-0 ${
              loading && "shimmer-effect"
            } `}
          >
            <div className="w-full">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                <button
                  type="button"
                  onClick={() => searchValue && setSearchValue("")}
                >
                  <div className="fill-violet-500">
                    {searchValue ? (
                      <CrossIcon className="w-3.5" />
                    ) : (
                      <SearchIcon className="h-3.5 w-3.5" />
                    )}
                  </div>
                </button>
              </div>
              <input
                type="text"
                name="query"
                defaultValue={""}
                id="simple-search-personal"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="Search name"
                className="bg-indigo-500/5 border font-medium border-indigo-500/10 placeholder-gray-500 text-md rounded focus:ring-violet-700 focus:border-ring-violet-700 text-sm15 block w-full h-[1.875rem] pl-9 py-1  dark:bg-gray-700 dark:border-gray-600  dark:placeholder-gray-400 dark:text-white "
                onKeyDown={(event: any) => {
                  if (event?.target?.value && event.key === "Enter") {
                    event.preventDefault();
                    let eventObj = {
                      target: {
                        name: "query",
                        value: event?.target?.value,
                      },
                    };
                    handleOnChange(eventObj);
                  }
                }}
              />
            </div>
          </div>
        </div>

        {getFilterItems()?.map((columnItem: any, index: number) => {
          switch (columnItem.id) {
            case 0:
              return (
                <React.Fragment key={index}>
                  {/* ALL TEAMS */}
                  <div
                    className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 rounded xl:w-[12.5%] min-w-[13.125rem] px-[.3125rem]   `}
                  >
                    <div className={`rounded ${loading && "shimmer-effect"}  `}>
                      <MultiDropDownField
                        classNamePrefix={"form_multiDropDown"}
                        placeholder="Teams"
                        options={coreLayout?.allowedAccess?.teams}
                        name="teams"
                        value={filter?.teams}
                        handleOnChange={(data: any) => {
                          handleOnChange(data);
                        }}
                        handleOnSelection={(data: any, visibleOptions: any) => {
                          handleOnSelection(
                            data,
                            "teams",
                            coreLayout?.allowedAccess?.teams,
                            visibleOptions
                          );
                        }}
                      />
                    </div>
                  </div>
                </React.Fragment>
              );

            case 2:
              return (
                <React.Fragment key={index}>
                  {/* STATUS */}
                  <div
                    className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 rounded xl:w-[12.5%] min-w-[13.125rem] px-[.3125rem]   `}
                  >
                    <div
                      className={` rounded ${loading && "shimmer-effect"}  `}
                    >
                      <MultiDropDownField
                        classNamePrefix={"form_multiDropDown"}
                        placeholder="Team status"
                        options={filterOptions?.statuses}
                        name="statuses"
                        value={filter?.statuses}
                        handleOnChange={(data: any) => {
                          handleOnChange(data);
                        }}
                        handleOnSelection={(data: any, visibleOptions: any) => {
                          handleOnSelection(
                            data,
                            "statuses",
                            filterOptions?.statuses,
                            visibleOptions
                          );
                        }}
                      />
                    </div>
                  </div>
                </React.Fragment>
              );
            // case 3:
            //   return (
            //     <React.Fragment key={index}>
            //       {/* RISK LEVEL */}
            //       <div
            //         className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 rounded xl:w-[12.5%] min-w-[13.125rem] px-[.3125rem]   `}
            //       >
            //         <div
            //           className={` rounded ${loading && "shimmer-effect"}  `}
            //         >
            //           <MultiDropDownField
            //             classNamePrefix={"form_multiDropDown"}
            //             placeholder="Risk level"
            //             options={filterOptions?.risk_levels}
            //             name="risk_levels"
            //             value={filter?.risk_levels}
            //             handleOnChange={(data: any) => {
            //               handleOnChange(data);
            //             }}
            //             handleOnSelection={(data: any, visibleOptions: any) => {
            //               handleOnSelection(
            //                 data,
            //                 "risk_levels",
            //                 filterOptions?.risk_levels,
            //                 visibleOptions
            //               );
            //             }}
            //           />
            //         </div>
            //       </div>
            //     </React.Fragment>
            //   );
            case 4:
              return (
                <React.Fragment key={index}>
                  {/* REGISTERED DATE */}
                  <div
                    className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 rounded xl:w-[12.5%] min-w-[13.125rem] px-[.3125rem]   `}
                  >
                    <div
                      className={` rounded ${loading && "shimmer-effect"}  `}
                    >
                      <DatePickerField
                        name="register_date"
                        placeholder="Registered date"
                        disabledKeyboardNavigation={false}
                        value={filter?.register_date}
                        isDateRange={true}
                        handleOnChange={(data: any) => handleOnChange(data)}
                        format="dd/MM/yy"
                      />
                    </div>
                  </div>
                </React.Fragment>
              );
            case 5:
              return (
                <React.Fragment key={index}>
                  {/* SELLER LEVEL */}
                  <div
                    className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 rounded xl:w-[12.5%] min-w-[13.125rem] px-[.3125rem]   `}
                  >
                    <div
                      className={` rounded ${loading && "shimmer-effect"}  `}
                    >
                      {" "}
                      <MultiDropDownField
                        classNamePrefix={"form_multiDropDown"}
                        placeholder="Seller levels"
                        options={layout.levelLists?.levelOptions}
                        name="seller_levels"
                        value={filter?.seller_levels}
                        handleOnChange={(data: any) => {
                          handleOnChange(data);
                        }}
                        handleOnSelection={(data: any, visibleOptions: any) => {
                          handleOnSelection(
                            data,
                            "seller_levels",
                            layout.levelLists?.levelOptions,
                            visibleOptions
                          );
                        }}
                      />
                    </div>
                  </div>
                </React.Fragment>
              );
            case 6:
              return (
                <React.Fragment key={index}>
                  {/* user type */}
                  <div
                    className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 rounded xl:w-[12.5%] min-w-[13.125rem] px-[.3125rem]   `}
                  >
                    <div
                      className={` rounded ${loading && "shimmer-effect"}  `}
                    >
                      {" "}
                      <MultiDropDownField
                        classNamePrefix={"form_multiDropDown"}
                        placeholder="User type"
                        options={userTypeOptions}
                        name="user_type"
                        value={filter?.user_type}
                        handleOnChange={(data: any) => {
                          handleOnChange(data);
                        }}
                        handleOnSelection={(data: any, visibleOptions: any) => {
                          handleOnSelection(
                            data,
                            "user_type",
                            userTypeOptions,
                            visibleOptions
                          );
                        }}
                      />
                    </div>
                  </div>
                </React.Fragment>
              );

            case 7:
              return (
                <React.Fragment key={index}>
                  <div
                    className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 rounded xl:w-[12.5%] min-w-[13.125rem] px-[.3125rem]   `}
                  >
                    <div
                      className={` rounded ${loading && "shimmer-effect"}  `}
                    >
                      <MultiDropDownField
                        classNamePrefix={"form_multiDropDown"}
                        placeholder="Demo status"
                        options={layout?.demoStatusOptions}
                        name="demo_statuses"
                        value={filter?.demo_statuses}
                        handleOnChange={(data: any) => {
                          handleOnChange(data);
                        }}
                        handleOnSelection={(data: any, visibleOptions: any) => {
                          handleOnSelection(
                            data,
                            "demo_statuses",
                            userTypeOptions,
                            visibleOptions
                          );
                        }}
                      />
                    </div>
                  </div>
                </React.Fragment>
              );
          }
        })}
      </div>
      <div className="bottomBtns hidden max-md:flex sticky bottom-0 left-0 w-full px-5 py-2.5 shadow-3xl">
        <button
          className={`border  hover:border-indigo-500  hover:text-white  hover:bg-indigo-500 transition rounded text-sm13 py-0.5 px-2.5 mr-2.5 my-0.5 ${
            clearDisable
              ? "bg-white text-violet-800 border-gray-300"
              : "bg-gray-100 text-gray-400 pointer-events-none border-gray-100"
          }`}
          onClick={handleRefresh}
        >
          Clear
        </button>

        <button
          className="border border-violet-500 hover:border-indigo-500 text-white bg-violet-500 hover:bg-indigo-500 transition rounded text-sm13 py-0.5 px-2.5 mr-2.5 my-0.5"
          onClick={() => {
            ToggleElemClose();
          }}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default Filters;
