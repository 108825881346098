import React from "react";

const TableRow = React.memo(({ ...props }: any) => {
  return (
    <>
      {false ? (
        <tr {...props} className="shimmer-effect highlight" />
      ) : (
        <tr
          {...props}
          className={`!border-b bg-white
          `}
        />
      )}
    </>
  );
});

export default TableRow;
