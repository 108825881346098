/* eslint-disable jsx-a11y/role-supports-aria-props */
import { useState } from "react";
import { KTSVG } from "../../../components/ui/KTSVG";
import { SYMBOLS } from "../../../helpers/const";

const PayoutsAccordian = ({ item, index }: any) => {
  const [isAccordianOpen, setIsAccordianOpen] = useState<any>({
    index: null,
    status: false,
  });

  return (
    <>
      <table className="w-full text-xs text-left border-b" key={index}>
        <thead
          className="group [&:not([data-te-collapse-collapsed])]:bg-indigo-500/5"
          data-te-collapse-init
          data-te-collapse-collapsed
          data-te-target={`collapseTwo5-${index}`}
          aria-expanded="false"
          aria-controls={`collapseTwo5-${index}`}
        >
          <tr
            onClick={() => {
              setIsAccordianOpen({
                index: index,
                status: !isAccordianOpen?.status,
              });
            }}
          >
            <>
              <td
                style={{ width: "8.125rem" }}
                className="py-3 pr-1.5 pl-4 whitespace-nowrap font-medium min-w-[8.125rem]"
              >
                {item.date}
              </td>
              <td
                style={{ width: "7.5rem" }}
                className="py-3 px-1.5 font-medium min-w-[7.5rem]"
              >
                <span className="whitespace-nowrap text-ellipsis overflow-hidden w-[6.625rem] block">
                  {!item?.is_referral ? item.item_id : ""}
                </span>
              </td>
              <td
                style={{ width: "7.5rem" }}
                className="py-3 px-1.5 whitespace-nowrap font-medium min-w-[7.5rem]"
              >
                {
                  SYMBOLS[
                    item?.is_referral
                      ? item?.currency
                      : item?.item_details?.ticket_currency
                  ]
                }
                {item.amount}
              </td>
              <td className="py-3 px-1.5 font-medium">
                <span
                  className="whitespace-nowrap text-ellipsis overflow-hidden w-[12.5rem] block"
                  title={
                    item?.is_referral ? item?.title : item.item_details?.event
                  }
                >
                  {item?.is_referral ? item?.title : item.item_details?.event}
                </span>
              </td>
              <td
                style={{ width: "2.5rem" }}
                className="ml-auto border-l font-medium group px-4 py-2.5 min-w-[2.5rem] cursor-pointer"
              >
                <KTSVG
                  className={`flex items-center justify-center  fill-violet-500 hover:fill-indigo-500 transition
               shrink-0  ${
                 isAccordianOpen?.index === index && isAccordianOpen?.status
                   ? "rotate-[-180deg]"
                   : "rotate-0"
               }  duration-200 ease-in-out motion-reduce:transition-none `}
                  path="standard_icons/arrowDown.svg"
                />
              </td>
            </>
          </tr>
        </thead>
        <tbody
          id={`collapseTwo5-${index}`}
          className={` ${
            isAccordianOpen?.index === index && isAccordianOpen?.status
              ? "!visible"
              : "hidden"
          } accordion-collapse bg-gray-100/50 text-gray-500 border-t`}
        >
          {item.item_statement?.length > 0 ? (
            item.item_statement.map((order: any) => {
              return (
                <tr className="border-t">
                  <td className="py-3 pr-1.5 pl-4">
                    {order?.order_datetime ?? ""}
                  </td>
                  <td className="py-3 px-1.5">{order?.id ?? ""}</td>
                  <td className="py-3 px-1.5">
                    {
                      SYMBOLS[
                        item?.is_referral
                          ? order?.currency
                          : item?.item_details?.ticket_currency
                      ]
                    }
                    {order?.amount}
                  </td>
                  <td className="py-3 px-1.5">{order?.title}</td>
                  <td></td>
                </tr>
              );
            })
          ) : (
            <tr className="border-t w-full no-result-row">
              <td className="py-3 pr-1.5 pl-4"></td>
              <td className="py-3 px-1.5"></td>
              <td className="py-3 px-1.5">No results</td>
              <td className="py-3 px-1.5"></td>
              <td></td>
            </tr>
          )}
        </tbody>
      </table>
      {/* ))} */}
    </>
  );
};

export default PayoutsAccordian;
