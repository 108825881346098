import { PUBLISH_STATUS } from "helpers/const";
import { ConnectorContext } from "pages/API_connector/core/ConnectorProvider";
import { useContext } from "react";
import { addCommaInNumber } from "../../../../helpers/Functions";
// import { InventoryContext } from "../../core/InventoryProvider";

const ListingInfo = ({
  listName,
  listCount,
  ticketCount,
  isCheckbox,
  isFirstLabel,
  isLastLabel,
  postKey,
  disabled,
  isLoading,
  isLow,
}: any) => {
  //   const layout = useContext(InventoryContext);
  const layout = useContext(ConnectorContext);
  let checked = layout?.listingFilters?.listingStatuses
    ? layout?.listingFilters?.listingStatuses.some(
        (data: any) => data?.id === postKey
      )
    : false;

  return (
    <>
      <label
        className={`w-full sm:w-1/2 lg:w-1/3 mb-5 px-2.5 ${
          isFirstLabel ? "pl-5" : ""
        } ${isLastLabel ? "pr-5" : ""} ${
          disabled ? "pointer-events-none" : isCheckbox ? `cursor-pointer` : ``
        }`}
        htmlFor={postKey}
      >
        <div
          className={`${
            isLoading && "shimmer-effect"
          } outline outline-1 outline-gray-200 bg-white group px-2.5 pt-[.4375rem] pb-[.5625rem] h-full min-w-[11.25rem]
          ${
            isCheckbox &&
            "hover:bg-indigo-500/5 hover:border-indigo-500 hover:text-indigo-500"
          }
           ${
             checked && "active-style"
           } [&.active-style]:bg-indigo-500/5 [&.active-style]:border-indigo-500`}
        >
          <div className="flex justify-between">
            <h3
              className={`md:text-lg md:leading-6 ${
                checked && "active-style"
              } [&.active-style]:text-indigo-500`}
            >
              {listCount ? addCommaInNumber(listCount) : 0}
            </h3>
            {ticketCount > 0 && !isLow && (
              <h5>
                <span
                  className={`flex items-center bg-gray-100 text-xxs font-semibold px-2 py-0.5 rounded mt-[.3125rem] ml-2.5 ${
                    checked && "active-style"
                  } [&.active-style]:text-indigo-500 [&.active-style]:bg-indigo-500/5`}
                >
                  {ticketCount ? addCommaInNumber(ticketCount) : 0} tickets
                </span>
              </h5>
            )}
            {false && (
              <div className="low-meter flex h-full rounded mt-[.3125rem] p-1 bg-gray-100 gap-x-[.0938rem]">
                <span className="w-[.1563rem] h-3 bg-gray-400 rounded-l-[1px] "></span>
                <span className="w-[.1563rem] h-3 bg-gray-400"></span>
                <span className="w-[.1563rem] h-3 bg-gray-400"></span>
                <span className="w-[.1563rem] h-3 bg-gray-400 rounded-r-[1px]"></span>
              </div>
            )}
          </div>
          <div className="flex justify-between items-center pt-1.5">
            <p
              className={`text-xs text-gray-500 ${
                isCheckbox && "group-hover:text-indigo-500"
              } ${checked && "active-style"} [&.active-style]:text-indigo-500`}
            >
              {listName}
            </p>

            {isCheckbox && (
              <label className="false  form-check flex flex-row-reverse justify-between items-center rounded border-gray-300">
                <div className="flex">
                  <input
                    className="form-check-input h-3 w-3 rounded-sm bg-gray-100 border-gray-300 checked:bg-indigo-500 transition ml-2 cursor-pointer border checked:border-indigo-500 hover:border-indigo-500 group-hover:border-indigo-500 focus:ring-0 focus:ring-offset-0"
                    type="checkbox"
                    name={postKey}
                    id={postKey}
                    checked={checked}
                    disabled={disabled}
                    onChange={(e: any) => {
                      if (e?.target?.checked) {
                        layout?.setListingFilters((pre: any) => {
                          const status = PUBLISH_STATUS.find(
                            (status) => status.id === postKey
                          );

                          const newStatuses = Array?.isArray(
                            pre?.listingStatuses
                          )
                            ? [...pre.listingStatuses, status]
                            : [status];
                          return {
                            ...pre,
                            listingStatuses: newStatuses,
                          };
                        });
                      } else {
                        layout?.setListingFilters((pre: any) => {
                          const statuses = pre?.listingStatuses?.filter(
                            (status: any) => status.id !== postKey
                          );
                          return {
                            ...pre,
                            listingStatuses: statuses,
                          };
                        });
                      }
                    }}
                    value={
                      PUBLISH_STATUS.find((status) => status.id === postKey)
                        ?.name
                    }
                  />
                </div>
              </label>
            )}
          </div>
        </div>
      </label>
    </>
  );
};

export default ListingInfo;
