import { LayoutContext } from "components/core/LayoutProvider";
import { isArray } from "lodash";
import { useContext, useMemo } from "react";
import {
  FULL_WIDTH_KEYS,
  LEVEL_CHECK_COLOR,
  NEXT_LEVEL_KEY,
  SELLER_LEVEL_KEY,
  SORT_ORDER,
  ZERO_WIDTH_KEYS,
} from "../const";
import {
  checkIfSuperSeller,
  formatCurrencyAmount,
  getUserSellerLevel,
} from "../utils";
import { useTargetGTV } from "./useTargetGTV";

export const useProcessInfo = (amount: any, currentDate: any) => {
  const { allowedAccess, currencyLabel } = useContext(LayoutContext);

  const memoizedAccess = useMemo(
    () => allowedAccess?.allowed_access ?? [],
    [allowedAccess?.allowed_access]
  );

  // seller levels and their associated amounts
  const levelThresholds = useMemo(
    () =>
      getUserSellerLevel(
        allowedAccess?.seller_levels,
        allowedAccess?.team_data?.seller_level
      ),
    [allowedAccess?.seller_levels, allowedAccess?.team_data?.seller_level]
  );

  // logged in user seller level
  const userSellerLevel = useMemo(
    () => allowedAccess?.team_data?.seller_level?.title ?? "",
    [allowedAccess?.team_data?.seller_level?.title]
  );

  // get seller level based on amount
  const activeSellerLevel: any = useMemo(
    () =>
      Object.keys(levelThresholds).find(
        (key) =>
          (amount >= levelThresholds[key] &&
            amount < levelThresholds[NEXT_LEVEL_KEY[key]]) ||
          (key === SELLER_LEVEL_KEY.SUPER_SELLER &&
            amount >= levelThresholds[key])
      ),
    [levelThresholds, amount]
  );

  // user current gtv based on seller level
  const userSellerLevelGTV = useMemo(
    () => levelThresholds[userSellerLevel] ?? 0,
    [userSellerLevel, levelThresholds]
  );

  const nextLevel = useMemo(
    () => NEXT_LEVEL_KEY[activeSellerLevel] ?? "",
    [activeSellerLevel]
  );

  // user next gtv based on seller level
  const nextLevelGtv = useMemo(
    () => levelThresholds[nextLevel] ?? 0,
    [levelThresholds, nextLevel]
  );

  // Get the current GTV based on the seller's level; if not found, default to 0
  const activeSellerLevelGTV = useMemo(
    () => levelThresholds[activeSellerLevel] ?? 0,
    [levelThresholds, activeSellerLevel]
  );

  const userSellerNextLevelGTV = useMemo(
    () => levelThresholds[NEXT_LEVEL_KEY[userSellerLevel] ?? ""] ?? 0,
    [levelThresholds, userSellerLevel]
  );

  const { targetAmount, targetLevel, targetPercentage } = useTargetGTV(
    levelThresholds,
    currentDate,
    userSellerNextLevelGTV,
    userSellerLevel
  );

  // next level require GTV
  const requireGTV = useMemo(() => {
    const requireGTV =
      activeSellerLevel === userSellerLevel
        ? userSellerNextLevelGTV
        : userSellerLevelGTV;
    const requireAmount = Math.max(requireGTV - amount, 0);
    return formatCurrencyAmount(currencyLabel, requireAmount);
  }, [
    activeSellerLevel,
    amount,
    currencyLabel,
    userSellerLevel,
    userSellerLevelGTV,
    userSellerNextLevelGTV,
  ]);

  // show congrats msg only for LEVEL 1 and SUPER SELLER
  const isEligibleForCongrats = useMemo(
    () =>
      ZERO_WIDTH_KEYS[SELLER_LEVEL_KEY.LEVEL3].includes(activeSellerLevel) &&
      FULL_WIDTH_KEYS[SELLER_LEVEL_KEY.SUPER_SELLER]?.includes(
        userSellerLevel
      ) &&
      activeSellerLevel !== userSellerLevel,
    [activeSellerLevel, userSellerLevel]
  );

  // show not track msg only if GTV is lesser than current lelev GTV required
  const isBelowRequiredGTV = useMemo(
    () => amount < targetAmount,
    [amount, targetAmount]
  );

  // Get active seller level wise percentage
  const levelProgressPercentage = useMemo(() => {
    const uptoNextLevelGTV = nextLevelGtv - activeSellerLevelGTV;
    const gtv = amount - activeSellerLevelGTV;

    return (gtv / uptoNextLevelGTV) * 100;
  }, [amount, nextLevelGtv, activeSellerLevelGTV]);

  const showTargetGTV = useMemo(() => {
    return amount < targetAmount;
  }, [targetAmount, amount]);

  // require GtV Message as per level
  const requireGtVMessage = useMemo(() => {
    if (
      checkIfSuperSeller(activeSellerLevel) &&
      checkIfSuperSeller(userSellerLevel)
    )
      return "";

    if (
      (!isBelowRequiredGTV &&
        activeSellerLevel === SELLER_LEVEL_KEY.SUPER_SELLER) ||
      ZERO_WIDTH_KEYS[userSellerLevel]?.includes(activeSellerLevel)
    ) {
      return `You have unlocked ${activeSellerLevel}`;
    } else {
      const action =
        isBelowRequiredGTV ||
        FULL_WIDTH_KEYS[userSellerLevel]?.includes(activeSellerLevel)
          ? `maintain ${userSellerLevel}`
          : `unlock ${nextLevel}`;
      return `You require ${requireGTV} to ${action}`;
    }
  }, [
    isBelowRequiredGTV,
    activeSellerLevel,
    userSellerLevel,
    requireGTV,
    nextLevel,
  ]);

  const sellerLeveldata = useMemo(() => {
    if (allowedAccess?.seller_levels && isArray(allowedAccess?.seller_levels))
      return allowedAccess?.seller_levels.sort(
        (a: any, b: any) =>
          SORT_ORDER.indexOf(a.title) - SORT_ORDER.indexOf(b.title)
      );

    return [];
  }, [allowedAccess?.seller_levels]);

  const initialSlide = useMemo(() => {
    const levelIndex = sellerLeveldata.findIndex(
      (level: any) => level.title === userSellerLevel
    );

    return levelIndex < 0 ? 0 : levelIndex;
  }, [sellerLeveldata, userSellerLevel]);

  const userLevelIndex = useMemo(
    () => LEVEL_CHECK_COLOR.findIndex(({ level }) => level === userSellerLevel),
    [userSellerLevel]
  );

  return {
    allowedAccess,
    targetAmount,
    userSellerLevel,
    memoizedAccess,
    targetLevel,
    targetPercentage,
    isEligibleForCongrats,
    levelProgressPercentage,
    showTargetGTV,
    requireGtVMessage,
    activeSellerLevel,
    isBelowRequiredGTV,
    currencyLabel,
    levelThresholds,
    sellerLeveldata,
    initialSlide,
    userLevelIndex,
  };
};
