import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useQuery } from "react-query";
import { TableVirtuoso } from "react-virtuoso";
import { LayoutContext } from "../../../../components/core/LayoutProvider";
import NoMoreItemInTable from "../../../../components/tableComponent/NoMoreItemInTable";
import ProgressBardLoader from "../../../../components/ui/ProgressBardLoader";
import { handleScroll } from "../../../../helpers/Functions";
import { QUERIES } from "../../../../helpers/const";
import { getAddress } from "../../../settings/core/_request";
import FullViewForm from "../component/popup/FullViewForm";
import { PersonalDetailsContext } from "../core/PersonalDetailsProvider";
import { SUBSCRIBE_DETAILS_ACTION_OPTIONS } from "../core/const";
import { getUserFormData, userStutusAction } from "../core/requests";
import ItemContent from "./component/ItemContent";
import TableActionButtons from "./component/TableActionButtons";
import TableHeaderRow from "./component/TableHeaderRow";
import TableRow from "./component/TableRow";

const Table = ({ style, ...props }: any) => (
  <table
    {...props}
    className="w-full text-xs text-left  min-w-[87.5rem] last-auto"
    style={{
      ...style,
    }}
  />
);
const TableHead = ({ style, ...props }: any) => (
  <thead
    {...props}
    className="text-xs text-gray-400 last-auto"
    style={{
      ...style,
      position: "static",
    }}
  />
);

const DataTable = ({
  loading,
  setLazyLoadLoading,
  lazyLoadLoading,
  listRefetch,
  setLoaderForActionUpdate,
  loaderForActionUpdate,
  setViewForm,
  viewForm,
}: any) => {
  const layout = useContext(PersonalDetailsContext);
  const globalLayout = useContext(LayoutContext);
  const stickyTableRef: any = useRef();
  const virtuosoRef: any = useRef();
  const tableRef: any = useRef();
  const [height, setHeight] = useState<any | undefined>(undefined);
  const [virtHeight, setVirtHeight] = useState<any>();
  const [addressData, setAddressData] = useState<any>();

  const paginateData = layout.meta;
  const data = layout.listings;

  /*On scroll sticky head list scroll virtuoso*/
  const handleScrollHead = () => {
    const scrollLeft = stickyTableRef.current.scrollLeft;
    tableRef.current.scrollLeft = scrollLeft;
  };

  /*On scroll virtuoso list scroll stickyhead*/
  const handleScrollVirtuoso = () => {
    const scrollLeft = tableRef.current.scrollLeft;
    stickyTableRef.current.scrollLeft = scrollLeft;
  };

  let timeout: any;
  const nomoreListing = document.getElementById("NoMoreListing");

  const renderCall = () => {
    timeout = setTimeout(() => {
      const divElement: any = document.querySelector("#sales-lazyload");
      const thead = document.querySelector("#tableId1 table thead");
      const theadHeight = thead?.scrollHeight;
      const nomoreListing = document.getElementById("NoMoreListing");
      let nomoreListingHeight = nomoreListing?.clientHeight;

      if (divElement) {
        if (divElement.offsetHeight) {
          let elemHeight = divElement.offsetHeight + theadHeight + 1;
          if (nomoreListingHeight) {
            elemHeight =
              divElement.offsetHeight + theadHeight + nomoreListingHeight;
          }

          setHeight(elemHeight);
        } else {
          setTimeout(renderCall, 100);
        }
      }
    }, 500);
  };

  useLayoutEffect(() => {
    renderCall();
    window.addEventListener("scroll", renderCall);
    return () => {
      clearTimeout(timeout);
    };
  });

  useEffect(() => {
    setTimeout(() => {
      const divElement: any = document.querySelector("#sales-lazyload");
      const elemHeight = divElement?.offsetHeight;
      setVirtHeight(elemHeight);
    }, 300);
  }, [data, loading, layout?.filterData]);

  // RE-RENDER HEIGHT ON RESIZING RESOLUTION
  useEffect(() => {
    renderCall();
  }, [
    globalLayout?.htmlFontSize,
    data?.length === paginateData?.total && paginateData?.current_page > 1,
    nomoreListing,
  ]);

  useEffect(() => {
    setTimeout(() => {
      if (globalLayout?.htmlFontSize) {
        renderCall();
      }
    }, 1000);
  }, [globalLayout?.htmlFontSize]);

  // HANDLE VIEW POPUP
  const handleViewForm = (list: any, index: any) => {
    document.body.style.overflow = "hidden";
    document.body.style.height = "100%";
    setViewForm({
      status: false,
      apiTrigger: true,
      id: list?.id,
      mainData: list,
    });
  };

  // CLOSE POPUP
  const handleClosePopup = () => {
    listRefetch();
    document.body.style.overflow = "";
    document.body.style.height = "";
    setViewForm({
      status: false,
      data: undefined,
      apiTrigger: false,
      id: "",
      mainData: undefined,
    });
  };

  const fetchAddressAndUserFormData = (id: any) => {
    return Promise.all([getAddress(id), getUserFormData(id)]);
  };

  const { isFetching, refetch } = useQuery(
    [QUERIES.ADDRESSBOOK],
    () => fetchAddressAndUserFormData(viewForm?.id),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: viewForm?.apiTrigger,
      onSettled(data, error) {
        let addressResponse = data?.[0];
        let userFromResponse = data?.[1];

        setViewForm((current: any) => {
          return {
            ...current,
            status: true,
            data: userFromResponse?.data?.[0],
          };
        });
        if (addressResponse?.data?.status) {
          setAddressData(addressResponse?.data);
        }
      },
    }
  );

  useEffect(() => {
    const handleScrollTable = () => {
      const EventAccordion = document.getElementById(
        "EventAccordion"
      ) as HTMLElement;

      const topfilterTop: any = document.getElementById("topFilters");

      const topValue: any = topfilterTop?.style?.top; // Retrieve the current 'top' value
      // if (topValue.includes("-")) {
      // Check if the value contains a negative sign
      const currentValue = parseInt(topValue); // Extract the numeric value
      const newValue = Math.abs(currentValue); // Make it positive
      topfilterTop.style.top = `${newValue}px`; // Apply the new positive value back as a string
      // }
      const windScroll: number = window.scrollY;

      const headingOne: any =
        document.getElementById("headingOne")?.clientHeight;
      const inventoryaccordion: any =
        document.getElementById("inventoryaccordion")?.clientHeight;
      // const totleScroll = headingOne + inventoryaccordion + 16;
      // const currentTop = parseInt(topfilterTop.style.top || "0");
      const salesFilter =
        document.getElementById("salesFilter")?.offsetHeight || 0;
      let totleScroll = 0;
      if (window.matchMedia("(max-width: 767px)").matches) {
        totleScroll = headingOne + inventoryaccordion + salesFilter + 20;
      } else {
        totleScroll = headingOne + inventoryaccordion + 16;
      }

      const stickyHead: any = document.getElementById("sticky-head");
      //code to adjust sticky header based on filter chip Area scrollbar
      const filterChipArea = document.querySelector(
        ".filterChipArea"
      ) as HTMLElement;
      let filterChipAreaWidth = 0;
      let width = 0;

      if (width > filterChipAreaWidth && width != 0) {
        // stickyHead?.classList.remove("top-[14.625rem]");
        stickyHead?.classList.add("sticky-header-scroll");
        // stickyHead?.classList.remove("xl:top-[12.375rem]");
        // stickyHead?.classList.remove("xl:top-[12.625rem]");
      }

      const filters_block =
        document.getElementById("filters_block")?.offsetHeight || 0;
      // const salesFilter =
      //   document.getElementById("salesFilter")?.offsetHeight || 0;
      const HeaderHeight: any = document.querySelector("header")?.clientHeight;
      const topPosition = filters_block + salesFilter + HeaderHeight;
      const stickyShadow: any = document.getElementById("sticky-shadow");
      if (windScroll > totleScroll) {
        EventAccordion?.classList?.add("-mt-10");
        stickyHead?.classList.remove("opacity-0");
        stickyHead?.classList.remove("h-0");
        stickyHead?.classList.add("h-[2.5625rem]");
        stickyShadow.classList.add("!block");
      } else if (windScroll < totleScroll) {
        EventAccordion?.classList?.remove("-mt-10");
        stickyHead?.classList.add("opacity-0");
        stickyHead?.classList.add("h-0");
        stickyHead?.classList.remove("h-[2.5625rem]");
        stickyShadow.classList.remove("!block");
      }
    };
    document.addEventListener("scroll", handleScrollTable);
    document.addEventListener("resize", handleScrollTable);
  }, []);

  useEffect(() => {
    const parentDiv: any = document.getElementById("custom-loader");
    if (
      paginateData?.current_page === paginateData?.last_page &&
      paginateData?.current_page !== undefined &&
      paginateData?.last_page !== undefined
    ) {
      if (parentDiv) {
        parentDiv.classList.add("hidden");
      }
    }
    if (
      paginateData?.current_page !== paginateData?.last_page &&
      paginateData?.current_page < paginateData?.last_page
    ) {
      if (parentDiv) {
        parentDiv.classList.add("visible");
        parentDiv.classList.remove("hidden");
      }
    }
  }, [paginateData]);

  const handleOnStatus = async (data: any, listData: any, index: any) => {
    layout.setListings((pre: any) => {
      return pre?.map((obj: any, innerIndex: any) => {
        if (innerIndex === index) {
          return {
            ...obj,
            status: data?.target?.value,
          };
        }
        return obj;
      });
    });

    if (data) {
      try {
        setLoaderForActionUpdate(true);

        const response = await userStutusAction({
          listData,
          action: data?.target?.value,
        });
        if (response) {
          listRefetch();
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  useEffect(() => {
    setTimeout(() => {
      const divElement: any = document.querySelector("#tracklisting-lazyload");
      const tHeadHeight: any = document.querySelector(
        "#tracklisting-lazyload thead"
      )?.clientHeight;
      if (divElement) {
        const elemHeight = divElement.offsetHeight + tHeadHeight + 1;
        setHeight(`${elemHeight}`);
      }
    }, 500);
  }, [data]);

  // auto disabled left right table button
  useEffect(() => {
    const element = document.getElementById("tableId1");
    const stickyHead = document.getElementById("sticky-head");

    const scrollHandler = () => handleScroll(element, stickyHead);
    if (element) {
      element.addEventListener("scroll", scrollHandler);
      scrollHandler(); // Initial check
      return () => {
        element.removeEventListener("scroll", scrollHandler);
      };
    }
  }, []);

  return (
    <div
      className="accordion mb-4 font-medium transition-all relative md:px-5 max-lg:!mr-0"
      id={`EventAccordion`}
    >
      <div
        id="sticky-head"
        className={`sticky sales-report-sticky-head z-10 opacity-0 h-0  overflow-auto scrollbar-none top-[14.625rem]`}
        ref={stickyTableRef}
        onScroll={handleScrollHead}
      >
        <table className="w-full text-xs text-left  min-w-[87.5rem] absolute left-0 top-0 last-auto">
          <thead className="text-xs text-gray-400 ">
            <TableHeaderRow
              stickyTableRef={stickyTableRef}
              tableRef={tableRef}
            />
          </thead>
        </table>
      </div>
      <span id="sticky-shadow" className="z-10 hidden"></span>
      <div className="accordion-item bg-white  !rounded overflow-hidden">
        <div
          id="EventCollapseBody"
          className="accordion-collapse collapse show"
          aria-labelledby="EventCollapseBody"
          data-te-collapse-show
        >
          <div className="accordion-body relative">
            <div
              className="inventory-table sales-table overflow-x-auto scrollbar-none scrollbar-thumb-transparent scrollbar-thumb-rounded-full hover-style2"
              ref={tableRef}
              id={`tableId1`}
              onScroll={handleScrollVirtuoso}
              style={{ height: height > 41 ? `${height}px` : "auto" }}
            >
              <TableVirtuoso
                data={data}
                endReached={() => {
                  if (
                    paginateData?.current_page < paginateData?.last_page &&
                    !lazyLoadLoading
                  ) {
                    setLazyLoadLoading(true);
                    layout.setFilterData((current: any) => {
                      let nextPage: number = paginateData?.current_page + 1;
                      return {
                        ...current,
                        page: nextPage,
                        per_page: 20,
                      };
                    });
                  }
                }}
                useWindowScroll
                overscan={200}
                totalCount={data?.length}
                atBottomThreshold={100}
                id={"sales-lazyload"}
                components={{
                  Table: Table,
                  TableHead: TableHead,
                  TableRow: (props) => {
                    return <TableRow {...props} />;
                  },
                  TableFoot: (props) => {
                    if (
                      data?.length === paginateData?.total &&
                      paginateData?.current_page > 1
                    ) {
                      return <NoMoreItemInTable />;
                    } else {
                      return null;
                    }
                  },
                }}
                fixedFooterContent={() => {
                  return (
                    <tr className="border-b bg-white">
                      <td colSpan={0}>Hi</td>
                    </tr>
                  );
                }}
                fixedHeaderContent={() => (
                  <TableHeaderRow
                    stickyTableRef={stickyTableRef}
                    tableRef={tableRef}
                  />
                )}
                itemContent={(sIndex: any, list: any) => {
                  const matchedView = false;
                  return (
                    <React.Fragment key={sIndex}>
                      <ItemContent list={list} />
                      <TableActionButtons
                        list={list}
                        sIndex={sIndex}
                        matchedView={matchedView}
                        handleViewForm={handleViewForm}
                        mainOptions={SUBSCRIBE_DETAILS_ACTION_OPTIONS}
                        handleOnChangeStatus={(
                          data: any,
                          listData: any,
                          index: any
                        ) => handleOnStatus(data, listData, index)}
                      />
                    </React.Fragment>
                  );
                }}
                ref={virtuosoRef}
              />
            </div>
          </div>

          <div
            id="custom-loader"
            className={`accordion bg-white font-medium pt-2.5 border-t `}
          >
            <div
              className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
              style={{ height: "30px" }}
            ></div>
            <div
              className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
              style={{ height: "30px" }}
            ></div>
          </div>
        </div>
      </div>

      {/* VIEW FORM */}
      {viewForm?.status && (
        <FullViewForm
          viewForm={viewForm}
          handleClosePopup={handleClosePopup}
          addressProp={addressData}
          refetchAddress={refetch}
          listRefetch={listRefetch}
          refetch={refetch}
        />
      )}

      {(loaderForActionUpdate || isFetching) && (
        <ProgressBardLoader
          secondCounter={1}
          LoadingText={isFetching ? "" : "Status updated"}
        />
      )}
    </div>
  );
};

export default DataTable;
