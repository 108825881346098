/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, ReactElement, useEffect, useRef } from "react";
import searchIcon from "../../assets/media/icons/other_icons/searchIcon.svg";
import DropDownField from "../formComponent/DropDownField";
import { IKTSVG } from "./IKTSVG";

type Props = {
  className?: string;
  rounded?: "rounded" | "rounded-top" | "rounded-bottom";
  children?: React.ReactNode;
  title?: string;
  width?: number;
  data?: any;
  options?: any;
  options2?: any;
  handleOnChange?: any;
  handleOnChange2?: any;
  value?: any;
  value2?: any;
  threeDot?: boolean;
  handleClick?: any;
  name?: string;
  name2?: string;
  placeholder?: string;
  cardTitle?: string;
  isPad?: string;
  borderBottom?: string;
  isClearable?: boolean;
  isCapital?: boolean;
  isGodAdmin?: boolean;
  isLoading?: boolean;
  categoryLoading?: boolean;
  handleOnTXTradeButtonClick?: any;
  Filters?: ReactElement | null;
};

const Card: FC<Props> = (props: any) => {
  const {
    className = "",
    rounded = "",
    children,
    title,
    data,
    options,
    options2,
    handleOnChange,
    handleOnChange2,
    value,
    value2,
    threeDot,
    handleClick,
    name,
    name2,
    placeholder,
    cardTitle,
    isClearable = false,
    isPad = false,
    borderBottom = false,
    isCapital = false,
    isGodAdmin = false,
    isLoading = false,
    categoryLoading = false,
    handleOnTXTradeButtonClick = () => {},
    Filters = <></>,
  } = props;

  const selectRef = useRef<any>(null);
  const selectRef1 = useRef<any>(null);
  const selectRef2 = useRef<any>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        selectRef1?.current?.onMenuClose();
        selectRef2?.current?.onMenuClose();
      }
    };

    // Attach the event listener
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`h-full bg-white rounded ${
        !isLoading && "shadow-op1"
      } dark:bg-gray-800 dark:border-gray-700`}
    >
      {/* Card Header */}
      <div
        className={`relative flex justify-between bg-white rounded-t px-5 pt-5 pb-2 -mb-2 gap-2 ${
          isPad && "pb-5 border-b"
        } ${borderBottom && "border-b"} shimmer-title `}
      >
        {/* Card Title */}
        <div
          className={`flex  flex-wrap items-center gap-2 ${
            title === "TX Trade" && "justify-between w-full"
          }`}
        >
          <h6
            className={`text-sm15 font-semibold leading-6  ${
              threeDot && "cursor-pointer hover:underline"
            }`}
            // onClick={handleClick}
            onClick={threeDot ? handleClick : undefined}
          >
            {title}
          </h6>
          {/* Card dropdown */}
          {data && (
            <div className="flex flex-wrap gap-y-2.5 " ref={selectRef}>
              {options2 && (
                <DropDownField
                  // className="block overflow-ellipsis overflow-hidden w-auto py-1 text-xs font-medium text-gray-900 border-1.5 border-gray-300 rounded focus:ring-indigo-300 focus:border-indigo-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  className={`h-TwentyFour mr-2`}
                  handleOnChange={handleOnChange2}
                  name={name2}
                  placeholder={``}
                  options={options2}
                  value={value2}
                  isClearable={false}
                  selectInputRef={selectRef2}
                />
              )}

              <DropDownField
                // className="block overflow-ellipsis overflow-hidden w-auto py-1 text-xs font-medium text-gray-900 border-1.5 border-gray-300 rounded focus:ring-indigo-300 focus:border-indigo-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                className={`h-TwentyFour ${
                  name === "topSellingEvents" &&
                  "max-sm:min-w-[6.25rem] min-w-[14rem]"
                } ${categoryLoading && "shimmer-effect"} `}
                handleOnChange={handleOnChange}
                name={name}
                placeholder={placeholder}
                options={options}
                value={value}
                isClearable={isClearable}
                selectInputRef={selectRef1}
                isCapital={isCapital}
              />
            </div>
          )}
          {title === "TX Trade" && (
            <button
              type="button"
              className={`
               flex
               gap-2
               relative inline-flex items-center z-10 text-white bg-violet-500 hover:bg-indigo-500 transition  rounded text-sm13 px-2.5 py-0.5 `}
              onClick={handleOnTXTradeButtonClick}
            >
              <IKTSVG
                path={searchIcon}
                className="fill-white"
                svgClassName="w-3 h-3"
              />
              Find tickets
            </button>
          )}
          {Filters}
        </div>
      </div>
      {children}
      {/* </div> */}
    </div>
  );
};

export default Card;
