import clsx from "clsx";
import { useEffect, useState } from "react";
import LockSVG from "../../assets/media/icons/other_icons/LockIcon";
import UnlockSVG from "../../assets/media/icons/other_icons/UnLockIcon";

const BlockContainer = ({
  title,
  lockStatus,
  handleLocking,
  children,
  isLockIconNeeded = true,
  isTitleBorder = true,
}: any) => {
  const [lockState, setLockState] = useState<boolean>(true);

  useEffect(() => {
    setLockState(lockStatus);
  }, [lockStatus]);

  // HANDLE LOCKING
  const handleLockingInternally = () => {
    setLockState((current: boolean) => {
      const updatedLock = !current;
      handleLocking(updatedLock);
      return updatedLock;
    });
  };

  return (
    <div
      className={clsx(
        "flex flex-wrap  border rounded relative mb-5 last-of-type:mb-0",
        isLockIconNeeded && !lockState && "shadow-op2 border-transparent"
      )}
    >
      <div
        className={clsx(
          "title_block w-full flex justify-between min-h-[2.5rem]",
          isTitleBorder && "border-b"
        )}
      >
        <h5 className="text-sm13 px-3.5 pt-[0.7188rem] pb-[0.7813rem] leading-4 flex items-center flex-1 font-semibold">
          {title}
        </h5>

        {isLockIconNeeded ? (
          <button
            type="button"
            className="w-10 flex items-center justify-center group border-l"
            onClick={handleLockingInternally}
          >
            {lockState ? (
              <LockSVG className="group-hover:fill-indigo-500 h-[.9375rem] w-3 fill-violet-500" />
            ) : (
              <UnlockSVG className="group-hover:fill-indigo-500 h-[.9375rem] w-[.8438rem] ml-[.1875rem] fill-indigo-500" />
            )}
          </button>
        ) : null}
      </div>

      <div
        className={clsx(
          "inner-part flex flex-wrap w-full",
          !isTitleBorder && "pt-0.5 px-3.5 pb-[0.9375rem]"
        )}
      >
        {lockState ? (
          children
        ) : (
          <div
            className={clsx(
              "flex flex-wrap form_cols attendee-form lock_form w-full"
            )}
          >
            <div
              className={clsx(
                "formBlock flex flex-wrap w-full",
                !isTitleBorder && "gap-y-[.9375rem] gap-x-3.5"
              )}
            >
              {children}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlockContainer;
