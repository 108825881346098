import { useContext } from "react";
import { PersonalDetailsContext } from "../core/PersonalDetailsProvider";

const Tab = ({ imgPath, title, handleTab }: any) => {
  const layout = useContext(PersonalDetailsContext);
  const isTabActive: any = layout?.personalDetailsTabs?.activeTab
    .trim()
    ?.toLowerCase();
  const isTitle: any =
    isTabActive?.trim()?.toLowerCase() === title?.trim()?.toLowerCase();
  return (
    <>
      <div
        className={`${
          isTabActive === "orders" && isTitle
            ? "bg-opacity-100 z-10 after:bg-menuCorner after:bg-contain after:absolute after:left-full after:bottom-0 after:w-2 after:h-2 after:transition after:duration-200 after:brightness-0 after:invert after:-rotate-90 before:bg-menuCorner before:bg-contain before:absolute before:right-full before:bottom-0 before:w-2 before:h-2 before:transition before:duration-200 before:brightness-0 before:invert before:rotate-180"
            : isTitle
            ? "bg-opacity-100 z-10 after:bg-menuCorner after:bg-contain after:absolute after:left-full after:bottom-0 after:w-2 after:h-2 after:transition after:duration-200 after:brightness-0 after:invert after:-rotate-90 before:bg-menuCorner before:bg-contain before:absolute before:right-full before:bottom-0 before:w-2 before:h-2 before:transition before:duration-200 before:brightness-0 before:invert before:rotate-180"
            : "bg-opacity-60"
        } relative single-tab lg:px-4 px-2.5 py-[.5625rem] lg2:max-w-[14.9375rem] min-w-[10.625rem lg:whitespace-normal whitespace-nowrap flex justify-between items-center bg-white rounded-t-lg cursor-pointer mr-[1px] w-full gap-2 hover:bg-opacity-100`}
        onClick={() => handleTab()}
      >
        <h4 className={`lg:text-sm15 text-sm14 font-semibold ${isTitle && ""}`}>
          {title}
        </h4>
        {/* <IKTSVG
          className={`123 flex items-center justify-center fill-${
            isTitle ? "indigo" : "violet"
          }-500 transition`}
          path={imgPath}
          svgClassName={
            title === "orders" ? "w-[.9375rem] h-[.9375rem]" : " w-4 h-4"
          }
        /> */}
      </div>
    </>
  );
};

const Tabs = () => {
  const layout = useContext(PersonalDetailsContext);

  return (
    <div className="tab-panel  flex relative max-md:overflow-x-auto flex-nowrap max-md:overflow-hidden">
      <Tab
        title="Personal Details"
        handleTab={() =>
          layout.setPersonalDetailsTabs((pre: any) => {
            return {
              ...pre,
              activeTab: "Personal Details",
            };
          })
        }
      />
      <Tab
        title="Seller Level Configuration"
        handleTab={() =>
          layout.setPersonalDetailsTabs((pre: any) => {
            return {
              ...pre,
              activeTab: "Seller Level Configuration",
            };
          })
        }
      />
    </div>
  );
};

export default Tabs;
