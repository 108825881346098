import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import DropDownField from "../../../../../components/formComponent/DropDownField";
import TextInput from "../../../../../components/formComponent/TextInput";
import { KTSVG } from "../../../../../components/ui/KTSVG";
import ProgressBardLoader from "../../../../../components/ui/ProgressBardLoader";
import Messages from "../../../../../helpers/Messages";
import { showAlert } from "../../../../../helpers/ShowAlert";
import { updateAddress } from "../../../../settings/core/_request";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// ACCOUNT SCHEMA
const addressSchema = Yup.object().shape({
  firstname: Yup.string().required("First Name is required"),
  address_1: Yup.string().required("Address 1 is required"),
  address_2: Yup.string(),
  address_3: Yup.string().nullable(),
  address_name: Yup.string().required("Address title is required"),
  city: Yup.string().required("City is required"),
  company: Yup.string().nullable(),
  postcode: Yup.string().required("Postcode is required"),
  telephone: Yup.string()
    .required("Phone Number is required")
    // .matches(phoneRegExp, "Phone number is not valid")
    .min(6, "Too short")
    .max(20, "Too long"),
  lastname: Yup.string().required("Last Name is required"),
  country: Yup.mixed().required("Country name is required"),
});

const AddressPopup = ({ data, handleCloseForm, refetchAddress }: any) => {
  const [loader, setLoader] = useState<boolean>(false);

  let initialState = {
    team_id: data?.data?.team_id,
    address_name: "",
    firstname: "",
    lastname: "",
    company: "",
    telephone: "",
    address_1: "",
    address_2: "",
    address_3: "",
    city: "",
    postcode: "",
    country_id: null,
    country: "",
    default: data?.data?.default ? true : false,
  };

  if (data?.formType === "Edit") {
    initialState = {
      ...initialState,
      ...data?.data,
      country: data?.country_list?.find(
        (item: any) => item?.name === data?.data?.country
      ),
    };
  }

  // FORMIK CALL
  const formik = useFormik({
    initialValues: initialState,
    validationSchema: addressSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: (values: any) => {
      setLoader(true);

      let payload = {
        ...values,
        country_id: values?.country?.id,
        use_as_default: values?.default,
      };
      delete payload.country;
      delete payload.default;

      updateAddress(payload).then((response: any) => {
        if (response?.data?.status === true) {
          setLoader(false);
          handleCloseForm();
          showAlert(response?.message, false, 1500);
          refetchAddress();
        } else {
          setLoader(false);

          showAlert(
            Array.isArray(response?.errors)
              ? response?.errors.join("<br/>")
              : Messages?.someWrong,
            true
          );
        }
      });
    },
  });

  return (
    <div
      data-te-modal-init
      className={`${
        data?.status ? "show" : ""
      } offcanvas fill_canvas offcanvas-end fixed bottom-0 top-[3.75rem] right-0 shadow-lg bg-clip-padding outline-none transition duration-300 ease-in-out font-medium  w-full upload-ticket-popup`}
      tabIndex={-1}
      id={`address_edit`}
      aria-labelledby="fillCanvasLabel"
    >
      <form className="h-full md:max-w-[43.125rem] max-w-full ml-auto">
        <div className="  bg-white shadow-3xl w-full h-full ml-auto relative flex flex-col">
          <div className="offcanvas-header flex justify-between border-t border-b">
            <h5
              className="offcanvas-title mb-0 leading-normal text-sm15 font-semibold py-2 px-5"
              id="offcanvasRightLabel"
            >
              {data?.formType} address
            </h5>
            <div className="buttons flex flex-wrap">
              <button
                type="button"
                className="flex items-center justify-center w-9 border-l box-content group"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={() => {
                  formik.resetForm();
                  handleCloseForm();
                }}
              >
                <KTSVG
                  className="fill-violet-500 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
                  path="standard_icons/cross.svg"
                />
              </button>
            </div>
          </div>
          <div className="offcanvas-body h-full w-full flex-row overflow-y-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full px-5 py-8 mb-1">
            <div className="address_fields flex flex-wrap -mx-2.5 inner-inp">
              <div className="fieldset w-full flex flex-wrap">
                <div className="w-full sm:w-1/2 md:w-1/2 mb-5 px-2.5 relative">
                  <TextInput
                    type="text"
                    label="Address title"
                    name="address_name"
                    id="address_title"
                    required={false}
                    value={formik.values?.address_name}
                    handleOnChange={(data: any) =>
                      formik.setFieldValue("address_name", data?.target?.value)
                    }
                    errorClass={
                      formik.touched.address_name &&
                      formik.errors.address_name &&
                      "border-rose-500"
                    }
                    handleClearValue={() =>
                      formik.setFieldValue("address_name", "")
                    }
                  />
                  {formik.touched.address_name &&
                    formik.errors.address_name && (
                      <div className="text-xs text-rose-500 error-msg">
                        {formik.errors.address_name as string}
                      </div>
                    )}
                </div>
              </div>
              <div className="fieldset w-full flex flex-wrap">
                <div className="w-full sm:w-1/2 md:w-1/2 mb-5 px-2.5 relative">
                  <TextInput
                    label="First name"
                    name="firstname"
                    id="first_name"
                    required={false}
                    value={formik.values?.firstname}
                    errorClass={
                      formik.touched.firstname &&
                      formik.errors.firstname &&
                      "border-rose-500"
                    }
                    handleOnChange={(data: any) =>
                      formik.setFieldValue("firstname", data?.target?.value)
                    }
                    handleClearValue={() =>
                      formik.setFieldValue("firstname", "")
                    }
                  />
                  {formik.touched.firstname && formik.errors.firstname && (
                    <div className="text-xs text-rose-500 error-msg">
                      {formik.errors.firstname as string}
                    </div>
                  )}
                </div>
                <div className="w-full sm:w-1/2 md:w-1/2 mb-5 px-2.5 relative">
                  <TextInput
                    name="lastname"
                    type="text"
                    label="Last name"
                    id="last_name"
                    required={false}
                    value={formik.values?.lastname}
                    errorClass={
                      formik.touched.lastname &&
                      formik.errors.lastname &&
                      "border-rose-500"
                    }
                    handleOnChange={(data: any) =>
                      formik.setFieldValue("lastname", data?.target?.value)
                    }
                    handleClearValue={() =>
                      formik.setFieldValue("lastname", "")
                    }
                  />
                  {formik.touched.lastname && formik.errors.lastname && (
                    <div className="text-xs text-rose-500 error-msg">
                      {formik.errors.lastname as string}
                    </div>
                  )}
                </div>
              </div>
              <div className="fieldset w-full flex flex-wrap">
                <div className="w-full sm:w-1/2 md:w-1/2 mb-5 px-2.5 relative">
                  <TextInput
                    name="company"
                    type="text"
                    label="Company name"
                    id="company_name"
                    required={false}
                    value={formik.values?.company}
                    errorClass={
                      formik.touched.company &&
                      formik.errors.company &&
                      "border-rose-500"
                    }
                    handleOnChange={(data: any) =>
                      formik.setFieldValue("company", data?.target?.value)
                    }
                    handleClearValue={() => formik.setFieldValue("company", "")}
                  />
                  {formik.touched.company && formik.errors.company && (
                    <div className="text-xs text-rose-500 error-msg">
                      {formik.errors.company as string}
                    </div>
                  )}
                </div>
              </div>
              <div className="fieldset w-full flex flex-wrap">
                <div className="w-full mb-5 px-2.5 relative">
                  <TextInput
                    name="address_1"
                    type="text"
                    label="Address Line 1"
                    id="address_1"
                    required={false}
                    value={formik.values?.address_1}
                    errorClass={
                      formik.touched.address_1 &&
                      formik.errors.address_1 &&
                      "border-rose-500"
                    }
                    handleOnChange={(data: any) =>
                      formik.setFieldValue("address_1", data?.target?.value)
                    }
                    handleClearValue={() =>
                      formik.setFieldValue("address_1", "")
                    }
                  />
                  {formik.touched.address_1 && formik.errors.address_1 && (
                    <div className="text-xs text-rose-500 error-msg">
                      {formik.errors.address_1 as string}
                    </div>
                  )}
                </div>
                <div className="w-full mb-5 px-2.5">
                  <TextInput
                    name="address_2"
                    type="text"
                    label="Address Line 2"
                    id="address_2"
                    required={false}
                    value={formik.values?.address_2}
                    handleOnChange={(data: any) =>
                      formik.setFieldValue("address_2", data?.target?.value)
                    }
                    handleClearValue={() =>
                      formik.setFieldValue("address_2", "")
                    }
                  />
                </div>
                <div className="w-full mb-5 px-2.5">
                  <TextInput
                    name="address_3"
                    type="text"
                    label="Address Line 3"
                    id="address_3"
                    required={false}
                    value={formik.values?.address_3}
                    handleOnChange={(data: any) =>
                      formik.setFieldValue("address_3", data?.target?.value)
                    }
                    handleClearValue={() =>
                      formik.setFieldValue("address_3", "")
                    }
                  />
                </div>

                <div className="w-full sm:w-1/2 md:w-1/2 mb-5 px-2.5 relative">
                  <TextInput
                    name="city"
                    type="text"
                    label="Town/City/State"
                    id="city"
                    value={formik.values?.city}
                    required={false}
                    errorClass={
                      formik.touched.city &&
                      formik.errors.city &&
                      "border-rose-500"
                    }
                    handleOnChange={(data: any) =>
                      formik.setFieldValue("city", data?.target?.value)
                    }
                    handleClearValue={() => formik.setFieldValue("city", "")}
                  />
                  {formik.touched.city && formik.errors.city && (
                    <div className="text-xs text-rose-500 error-msg">
                      {formik.errors.city as string}
                    </div>
                  )}
                </div>
                <div className="w-full sm:w-1/2 md:w-1/2 mb-5 px-2.5 relative">
                  <TextInput
                    name="postcode"
                    type="text"
                    label="Postcode/Zip"
                    id="postcode"
                    required={false}
                    value={formik.values?.postcode}
                    errorClass={
                      formik.touched.postcode &&
                      formik.errors.postcode &&
                      "border-rose-500"
                    }
                    handleOnChange={(data: any) =>
                      formik.setFieldValue("postcode", data?.target?.value)
                    }
                    handleClearValue={() =>
                      formik.setFieldValue("postcode", "")
                    }
                  />{" "}
                  {formik.touched.postcode && formik.errors.postcode && (
                    <div className="text-xs text-rose-500 error-msg">
                      {formik.errors.postcode as string}
                    </div>
                  )}
                </div>
                <div className="w-full sm:w-1/2 md:w-1/2 mb-5 px-2.5 relative">
                  <DropDownField
                    options={data?.country_list}
                    placeholder="Country"
                    name="country"
                    getOptionLabel="name"
                    className={`h-fourty ${
                      formik.touched?.country &&
                      formik.errors?.country &&
                      "all-dropdown-errorField"
                    }`}
                    getOptionValue="id"
                    value={formik.values.country}
                    handleOnChange={(data: any) => {
                      formik.setFieldValue("country", data?.target?.value);
                    }}
                    required={true}
                  />
                  {formik.touched.country && formik.errors.country && (
                    <div className="text-xs text-rose-500 error-msg">
                      {formik.errors.country as string}
                    </div>
                  )}
                </div>
              </div>
              <div className="fieldset w-full flex flex-wrap">
                <div className="w-full sm:w-1/2 md:w-1/2 mb-5 px-2.5 relative">
                  <TextInput
                    name="telephone"
                    type="text"
                    label="Phone number"
                    id="telephone"
                    className="bg-gray-100"
                    required={false}
                    value={formik.values?.telephone}
                    errorClass={
                      formik.touched.telephone &&
                      formik.errors.telephone &&
                      "border-rose-500"
                    }
                    handleOnChange={(data: any) =>
                      formik.setFieldValue("telephone", data?.target?.value)
                    }
                    handleClearValue={() =>
                      formik.setFieldValue("telephone", "")
                    }
                  />
                  {formik.touched.telephone && formik.errors.telephone && (
                    <div className="text-xs text-rose-500 error-msg">
                      {formik.errors.telephone as string}
                    </div>
                  )}
                </div>
              </div>
              {!data?.data?.default && (
                <div className="fieldset">
                  <div className="inline-flex items-center text-violet-800transition font-medium rounded px-2.5 py-0.5">
                    <input
                      id="default-checkbox"
                      type="checkbox"
                      checked={formik.values?.default}
                      name="default"
                      className="w-4 h-4 text-violet-500 bg-gray-100 border-gray-300 rounded focus:ring-violet-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      onChange={(data: any) =>
                        formik.setFieldValue("default", data.target.checked)
                      }
                    />
                    <label
                      htmlFor="default-checkbox"
                      className="ml-2 text-sm13 font-medium text-gray-900 dark:text-gray-300"
                    >
                      {" "}
                      Use as default address?
                    </label>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className=" offcanvas-footer bottom-0 right-0 w-full flex justify-start px-5 py-3 bg-white z-10 border-t">
            <div className="l_btn gap-2.5 flex flex-wrap items-center">
              <button
                type="button"
                className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
                onClick={() => {
                  formik.resetForm();
                  handleCloseForm();
                }}
              >
                Cancel
              </button>

              <button
                type="button"
                className={`${
                  loader && "pointer-events-none opacity-50"
                } relative z-10 border border-green-600 hover:text-green-600 active:text-white text-white hover:bg-white bg-green-600 active:bg-green-600 transition font-semibold rounded text-sm13 px-2.5 py-0.5 `}
                onClick={() => {
                  formik.handleSubmit();
                }}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </form>
      {loader && <ProgressBardLoader LoadingText="" secondCounter={0.5} />}
    </div>
  );
};

export default AddressPopup;
