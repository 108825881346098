import axios from "axios";
import moment from "moment";

const sellerLevelConfiguration = async (): Promise<any> => {
  const response = await axios.post(`seller-level/lists`);
  return response.data;
};

const sellerLevelMovementList = async (data: any): Promise<any> => {
  let start_date = data?.filters?.dateRange
    ? moment(data?.filters?.dateRange?.startDate).format("YYYY-MM-DD")
    : undefined;
  let end_date = data?.filters?.dateRange
    ? moment(data?.filters?.dateRange?.endDate).format("YYYY-MM-DD")
    : undefined;

  const team_id = data?.filters?.team_id;

  const payload = {
    page: data?.filters?.page,
    per_page: data?.filters?.per_page,
    ...(data?.filters?.order_by &&
      data?.filters?.sort_order && { order_by: data?.filters?.order_by }),
    ...(data?.filters?.order_by &&
      data?.filters?.sort_order && { sort_order: data?.filters?.sort_order }),

    ...(team_id && { team_id: team_id?.map((team: any) => team?.id) }),
    ...(start_date && { start_date: start_date }),
    ...(end_date && { end_date: end_date }),
  };
  const response = await axios.post(`seller-level/config`, payload);
  return response.data;
};

const sellerLevelMovementAction = async (data: any): Promise<any> => {
  const payload = {
    team_id: data?.team_id,
    seller_level_id: data?.seller_level_id,
    persist_custom_seller_level_config:
      data?.persist_custom_seller_level_config,

    ...(data?.team_config && {
      team_config: data?.team_config?.map((item: any) => {
        return {
          ...item,
          value:
            typeof item?.value === "object" ? item?.value?.id : item?.value,
        };
      }),
    }),
  };
  const response = await axios.post(`seller-level/action`, payload);
  return response.data;
};

const editSellerLevel = async (data: any): Promise<any> => {
  const response = await axios.post(`seller-level/edit`, data);
  return response.data;
};

const sellerLevelOptionsWithConfig = async (): Promise<any> => {
  const response = await axios.post(`seller-level/options`);
  return response.data;
};

const addSellerLevel = async (data: any): Promise<any> => {
  const response = await axios.post(`seller-level/add`, data);
  return response.data;
};

const deleteSellerLevel = async (data: any): Promise<any> => {
  const response = await axios.post(`seller-level/delete`, data);
  return response.data;
};

export {
  addSellerLevel,
  deleteSellerLevel,
  editSellerLevel,
  sellerLevelConfiguration,
  sellerLevelMovementAction,
  sellerLevelMovementList,
  sellerLevelOptionsWithConfig,
};
