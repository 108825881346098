import clsx from "clsx";
import React, { useState } from "react";
import Collapse from "../../../../../../assets//media/icons/other_icons/Collapse.svg";
import { IKTSVG } from "../../../../../../components/ui/IKTSVG";
import TooltipPortal from "../../../../../tx_trade/table/component/TooltipPortal";

interface AccordionItemProps {
  orderDate: string;
  orderID: string;
  earnings: string;
  eventName: string;
  details: { label: string; value: string }[];
  loader: boolean;
}

const AccordionItem: React.FC<AccordionItemProps> = ({
  orderDate,
  orderID,
  earnings,
  eventName,
  details,
  loader,
}) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  return (
    <div
      className={`w-full p-0 m-0 accordion-item hover:bg-indigo-500/[0.07] !rounded-b-none border-b last-of-type:border-b-0 
        ${isCollapsed ? "bg-white" : "bg-indigo-500/[0.07]"} 
        ${loader && "shimmer-effect mb-2.5"} `}
    >
      {/* Accordion Header */}
      <div
        className={clsx(
          "flex accordion-header w-full",
          loader && "shimmer-effect"
        )}
        id={`heading-${orderID}`}
        onClick={() => setIsCollapsed((current: boolean) => !current)}
      >
        <div className="flex w-[calc(100%-2.5rem)] px-[1.0625rem] pt-3 pb-[0.8125rem]">
          <div className="flex w-full gap-x-[0.875rem]">
            <p
              className="text-sm12 font-medium leading-[0.9375rem] min-w-[6.625rem] ellips-text"
              title={orderDate}
            >
              {orderDate}
            </p>
            <p
              className="text-sm12 font-medium leading-[0.9375rem] min-w-[6.625rem] underline ellips-text"
              title={orderID}
            >
              {orderID}
            </p>
            <p
              className="text-sm12 font-medium leading-[0.9375rem] min-w-[6.625rem] ellips-text"
              title={earnings}
            >
              {earnings}
            </p>
            <p
              className="text-sm12 font-medium leading-[0.9375rem] ellips-text"
              title={eventName}
            >
              {eventName}
            </p>
          </div>
        </div>
        {/* Expand/Collapse Button */}
        <button
          className="group flex items-center py-4 px-[.9375rem] w-10 border-l"
          type="button"
          aria-expanded={!isCollapsed}
        >
          <TooltipPortal
            className="text-center !bg-white !opacity-100 shadow !px-1.5 !py-0.5 font-medium  !text-xs z-[99999] max-w-[9rem] cursor-default"
            id={`tooltip-${orderID}`}
            content={isCollapsed ? "Expand" : "Collapse"}
          />
          <IKTSVG
            className={`transform transition duration-300 ${
              isCollapsed ? "rotate-180" : "fill-violet-500"
            }`}
            path={Collapse}
          />
        </button>
      </div>

      {/* Accordion Content */}
      <div
        className={`accordion-collapse transition-all duration-300 border-t ${
          isCollapsed ? "hidden" : "block"
        }`}
      >
        {details?.map((detail, index) => (
          <div
            key={`${index}-${orderID}`}
            className="flex w-full gap-x-[0.875rem] px-[1.0625rem] py-3 border-b last-of-type:border-b-0"
          >
            <p className="text-sm12 text-gray-500 min-w-[6.625rem] max-w-[6.625rem] ellips-text">
              &nbsp;
            </p>
            <p className="text-sm12 text-gray-500 min-w-[6.625rem] max-w-[6.625rem] ellips-text">
              &nbsp;
            </p>
            <p
              className="text-sm12 text-gray-500 min-w-[6.625rem] max-w-[6.625rem] ellips-text"
              title={detail.value}
            >
              {detail.value}
            </p>
            <p
              className="text-sm12 text-gray-500 ellips-text"
              title={detail.label}
            >
              {detail.label}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccordionItem;
