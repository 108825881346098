import _, { size } from "lodash";
import React, { useContext, useState } from "react";
import { Tooltip } from "react-tooltip";
import HaxPublishGray from "../../../../../assets/media/icons/other_icons/hax-Publish-Gray.svg";
import unpublish from "../../../../../assets/media/icons/other_icons/new-unpublish.svg";
// import PPPIcon from "../../../../../assets/media/icons/other_icons/PPPIcon.svg";
import clsx from "clsx";
import Messages from "helpers/Messages";
import CrosHandIcon from "../../../../../assets/media/icons/other_icons/CrosHandIcon";
import HandSVG from "../../../../../assets/media/icons/other_icons/HandSVG";
import LogIcon from "../../../../../assets/media/icons/other_icons/LogIcon";
import PPPIcon from "../../../../../assets/media/icons/other_icons/PPPIconn";
import Upload from "../../../../../assets/media/icons/other_icons/Upload";
import Warning1 from "../../../../../assets/media/icons/standard_icons/warning1.svg";
import { LayoutContext } from "../../../../../components/core/LayoutProvider";
import PriceField from "../../../../../components/formComponent/PriceField";
import NoJSXComponent from "../../../../../components/noJSX/NoJSXComponent";
import { IKTSVG } from "../../../../../components/ui/IKTSVG";
import {
  colorForMeter,
  decimalNumber,
  isDateBeforeValidLogDate,
  isValidPriceFieldNumber,
  priceCalculation,
  tooltipForListingMeter,
} from "../../../../../helpers/Functions";
import { showAlert } from "../../../../../helpers/ShowAlert";
import { TicketError } from "../../../../add_inventory/core/_functions";
import { display } from "../../../../tx_trade/helper/Assist";
import TooltipPortal from "../../../../tx_trade/table/component/TooltipPortal";
import {
  categoryExist,
  setEventRecordPayload,
  visiblityMeterForBelowListing,
} from "../../../core/_functions";
import { UpdateInventory } from "../../../core/_request";
import InventoryNoJSX from "../../../core/InventoryNoJSX";
import { InventoryContext } from "../../../core/InventoryProvider";
import ButtonSVGTooltip from "./ButtonSVGTooltip";
import CancelUpdateButton from "./CancelUpdateButton";

type TEventRecordStatus = {
  event: Record<string, any>;
  eventRecord: Record<string, any>;
  eIndex: string | number;
  rIndex: string | number;
  tableRefSide: any;
  editData: any;
  list: any;
  setList: React.Dispatch<React.SetStateAction<any[]>>;
  setEventRecord: React.Dispatch<React.SetStateAction<any[]>>;
  MemoizedHandleTableField: (data: any, eIndex: any, rIndex: any) => void;
  updateVisibility: (data: any) => void;
  handleEticket: (event: any, eventRecord: any) => void;
  setEditData: (event: any) => void;
  setIsOpenTicketPopUp: (data: boolean) => void;
  setIsOpenListingQualityPopup: (data: boolean) => void;
  handleListingAction: (action: string, id: string) => void;
  firstSelectedIndex: any;
  ppeErrosValidation: any;
  isGodAdmin: any;
  ppeAction: any;
};

const ActionButtonsWrapper = ({
  event,
  eventRecord,
  eIndex,
  rIndex,
  tableRefSide,
  editData,
  list,
  setList,
  setEventRecord,
  MemoizedHandleTableField,
  updateVisibility,
  handleEticket,
  setEditData,
  setIsOpenTicketPopUp,
  setIsOpenListingQualityPopup,
  handleListingAction,
  firstSelectedIndex,
  ppeErrosValidation,
  ppeAction,
}: TEventRecordStatus) => {
  // STATES
  const globalLayout = useContext(LayoutContext);
  const layout = useContext(InventoryContext);
  const [priceFiledFocusedSticky, setPriceFieldFocusedSticky] = useState<any>();
  const [singleRecordLoader, setSetSingleRecordLoader] = useState(false);
  const { isGodAdmin } = NoJSXComponent();
  const {
    disabledAll,
    isEventRecordError,
    isEventRecordBEError,
    isEventRecordHoldTickets,
  } = InventoryNoJSX({
    event,
    eventRecord,
  });
  const priceFactor = Number(
    globalLayout?.allowedAccess?.price_suggestion_factor
  );

  //   VARIABLES
  let singleEdit = clsx(
    "accordion-body relative inline-flex w-full justify-start !rounded-none h-[2.5rem] border-l st_icons left-shad",
    eventRecord?.loader && "pointer-events-none opacity-40",
    layout?.isBulkActionLoader && "pointer-events-none opacity-40"
  );

  let multiEdit = clsx(
    "bg-white border-l st_icons left-shad",
    _.size(layout.isTableEditable) > 0 &&
      !eventRecord?.selected &&
      "row-disabled",
    eventRecord?.loader && "pointer-events-none opacity-20",
    eventRecord?.selected &&
      !(
        isEventRecordError ||
        eventRecord?.status === "failed" ||
        isEventRecordBEError
      ) &&
      "!bg-indigo-500 !bg-opacity-[7%]",
    isEventRecordHoldTickets && "row-hold-new group-hover:bg-sky-blue-300",
    (isEventRecordError || isEventRecordBEError) &&
      "row-error-new group-hover:bg-rose-550",
    eventRecord?.processing && "row-processing"
  );

  // FUNCTIONS

  function checkIsFullfillUploadExist(event: any, eventRecord: any) {
    const selectedEventRecords = list
      ?.flatMap((item: any) =>
        item?.eventRecords?.filter(
          (record: any) =>
            record?.selected &&
            (record?.ticket_type === "eticket" ||
              record?.ticket_type?.id === "eticket" ||
              record?.ticket_type?.id === "pdf" ||
              record?.ticket_type?.id === "mobile-qr" ||
              record?.ticket_type?.id === "mobile-link" ||
              record?.ticket_type?.id === "mobile-link")
        )
      )
      .filter((obj: any) => obj !== undefined);

    const flag =
      selectedEventRecords?.length > 1
        ? selectedEventRecords?.filter(
            (obj: any) =>
              obj?.quantity_available ===
                (obj?.listing_ticket_files?.length ||
                  obj?.mobile_links?.length) && obj?.status === "yes"
          )
        : [];
    return flag || "";
  }

  //   SET LOCAL VALIDATIONS
  const setLocalValidationToEventRecords = async (id: any, isPublish: any) => {
    let success = true;
    setList((events: any) => {
      return events.map((inEvent: any) => {
        if (inEvent?.id === event?.id) {
          return {
            ...inEvent,
            eventRecords: Array.isArray(inEvent["eventRecords"])
              ? event["eventRecords"].map(
                  (eventRecord: any, eventRecordKey: any) => {
                    if (eventRecord?.id === id) {
                      let fieldErrors: any = {};

                      if (eventRecord?.price_per_exchange) {
                        if (Array.isArray(eventRecord?.ppePartners)) {
                          eventRecord.ppePartners.forEach((partner: any) => {
                            if (
                              partner.value.value &&
                              Number(partner.value.value) <= 0 &&
                              partner?.is_hidden === false
                            ) {
                              fieldErrors[
                                "PPP"
                              ] = `PPP fields are required more than zero`;
                              fieldErrors[
                                partner?.name
                              ] = `${partner?.name} value field required more than zero`;
                            }
                          });
                        }
                      }

                      const faceValueCheck =
                        Number(eventRecord?.face_value) <= 0 ||
                        (typeof eventRecord?.face_value === "object" &&
                          Number(eventRecord?.face_value?.value) <= 0);

                      const processValueCheck =
                        Number(eventRecord?.sell_price) <= 0 ||
                        (typeof eventRecord?.sell_price === "object" &&
                          Number(eventRecord?.sell_price?.value) <= 0 &&
                          !eventRecord?.ppe);

                      const isHoldTicket =
                        eventRecord?.hold_tickets?.length > 0 &&
                        (eventRecord?.listing_ticket_files?.length > 0 ||
                          eventRecord?.mobile_links?.length > 0) &&
                        Number(eventRecord?.quantity_available) !==
                          (Number(eventRecord?.listing_ticket_files?.length) ||
                            Number(eventRecord?.mobile_links?.length));

                      const isUploadedTicketLessWithQuantity = isPublish
                        ? (eventRecord?.listing_ticket_files?.length > 0 ||
                            eventRecord?.mobile_links?.length > 0) &&
                          eventRecord?.quantity_available !==
                            (eventRecord?.listing_ticket_files?.length ||
                              eventRecord?.mobile_links?.length)
                        : eventRecord?.quantity_available &&
                          eventRecord?.quantity_available <
                            (eventRecord?.listing_ticket_files?.length ||
                              eventRecord?.mobile_links?.length);

                      !eventRecord?.ticket_type &&
                        (fieldErrors = {
                          ...fieldErrors,
                          ticket_type: "Ticket type field is required",
                        });

                      (eventRecord?.ticket_type === "paper" ||
                        eventRecord?.ticket_type?.id === "paper") &&
                        eventRecord?.delivery_options?.length > 0 &&
                        eventRecord?.delivery_options?.map(
                          (singleDelivery: any) => {
                            let price =
                              eventRecord?.[singleDelivery?.id] != undefined
                                ? Number(eventRecord?.[singleDelivery?.id])
                                : Number(singleDelivery?.price);
                            (!price || price <= 0) &&
                              (fieldErrors = {
                                ...fieldErrors,
                                [singleDelivery?.id]:
                                  layout.deliveryType?.find(
                                    (item: any) =>
                                      singleDelivery?.id === item?.id
                                  )?.type + " field is required more than zero",
                              });
                          }
                        );

                      (!eventRecord?.quantity_available ||
                        isUploadedTicketLessWithQuantity ||
                        isHoldTicket) &&
                        (fieldErrors = {
                          ...fieldErrors,
                          quantity_available:
                            isUploadedTicketLessWithQuantity || isHoldTicket
                              ? TicketError
                              : "Quantity field is required",
                        });

                      !eventRecord?.category &&
                        event?.categories &&
                        Object.keys(event?.categories).length > 0 &&
                        (fieldErrors = {
                          ...fieldErrors,
                          category: "Category value field is required",
                        });

                      (!eventRecord?.face_value ||
                        (typeof eventRecord?.face_value === "object" &&
                          eventRecord?.face_value?.value === "")) &&
                        (fieldErrors = {
                          ...fieldErrors,
                          face_value: "Face value field is required",
                        });

                      //  new
                      faceValueCheck &&
                        (fieldErrors = {
                          ...fieldErrors,
                          face_value:
                            "Face value field is required more than zero",
                        });

                      (!eventRecord?.sell_price ||
                        (typeof eventRecord?.sell_price === "object" &&
                          eventRecord?.sell_price?.value === "")) &&
                        !eventRecord?.ppe &&
                        (fieldErrors = {
                          ...fieldErrors,
                          sell_price: "Proceed value field is required",
                        });

                      // new

                      processValueCheck &&
                        (fieldErrors = {
                          ...fieldErrors,
                          sell_price:
                            "Proceed value field is required more than zero",
                        });

                      // !eventRecord?.section &&
                      //   eventRecord?.category?.sections &&
                      //   Object.keys(eventRecord?.category?.sections).length > 0 &&
                      //   (fieldErrors = {
                      //     ...fieldErrors,
                      //     section: "Section value field is required",
                      //   });
                      !eventRecord?.split_type &&
                        (fieldErrors = {
                          ...fieldErrors,
                          splitType: "Split Type value field is required",
                        });
                      !eventRecord?.MULTIPLES &&
                        eventRecord?.splitType?.id === "MULTIPLES" &&
                        (fieldErrors = {
                          ...fieldErrors,
                          MULTIPLES: "Sell in Multiples Field is required",
                        });

                      !eventRecord?.sub_ticket_type?.id &&
                        eventRecord?.ticketType?.id === "eticket" &&
                        (fieldErrors = {
                          ...fieldErrors,
                          sub_ticket_type: "Sub Ticket type Field is required",
                        });

                      // eventRecord?.date_to_ship &&
                      //   moment(event?.date).valueOf() <
                      //   moment(eventRecord?.date_to_ship).valueOf() &&
                      //   (fieldErrors = {
                      //     ...fieldErrors,
                      //     date_to_ship:
                      //       "Date of ship should not be greater than the event date",
                      //   });

                      Object.keys(fieldErrors).length > 0 && (success = false);

                      let errors: any = [
                        {
                          errors: [
                            ...(eventRecord?.errors &&
                            _.size(eventRecord?.errors) > 0
                              ? eventRecord?.errors?.[0]?.errors
                              : []),
                            // ...(fieldErrors && _.size(fieldErrors) > 0
                            //   ? Object.keys(fieldErrors)
                            //   : []),
                            //****** code commented because it is causing multiple errors even if it is not be error */
                          ],
                          exchange: eventRecord?.errors?.[0]?.exchange,
                        },
                      ];
                      let [firstKey] = Object.keys(fieldErrors);
                      setTimeout(() => {
                        let inputFields: any = document.getElementById(
                          `${firstKey}-${event.id}-${eventRecordKey}`
                        );
                        if (inputFields) {
                          inputFields.scrollIntoView({
                            behavior: "auto",
                            inline: "center",
                            block: `center`,
                          });
                          showAlert(
                            "Please fix the errors on the listings",
                            true
                          );
                        }
                      }, 500);

                      let finalData = {
                        ...eventRecord,
                        loader: size(fieldErrors) > 0 ? false : true,
                        fieldErrors: fieldErrors,
                        ...(_.size(errors?.[0]?.errors) > 0 && {
                          errors: errors,
                        }),
                      };
                      return finalData;
                    } else {
                      return eventRecord;
                    }
                  }
                )
              : inEvent["eventRecords"],
          };
        } else {
          return inEvent;
        }
      });
    });
    return success;
  };

  //SET EVENT RECORD LOADER
  const setEventRecordLoader = async (eventRecord: any, status: boolean) => {
    setList((events: any) => {
      return events.map((inEvent: any) => {
        if (inEvent.id === event.id) {
          return {
            ...inEvent,
            eventRecords: Array.isArray(inEvent["eventRecords"])
              ? inEvent["eventRecords"].map((inEventRecord: any) => {
                  if (eventRecord?.id === inEventRecord?.id) {
                    return {
                      ...inEventRecord,
                      loader: status,
                    };
                  } else {
                    return inEventRecord;
                  }
                })
              : [],
          };
        } else {
          return inEvent;
        }
      });
    });
  };

  //CANCEL EVENT RECORD
  const cancelRecord = (currentEventRecord: any) => {
    let tempErecord = {
      ...eventRecord,
      ...eventRecord?.replacible,
      isInputChange: false,
      loader: false,
      selected: false,
      replacible: {},
      fieldErrors: {},
    };
    setEventRecord(tempErecord);

    setList((prev: any) => {
      return prev.map((event: any, inEIndex: any) => {
        if (inEIndex === eIndex) {
          return {
            ...event,
            eventRecords:
              event?.eventRecords &&
              event?.eventRecords.map((eventRecord: any) => {
                if (eventRecord?.id === currentEventRecord?.id) {
                  let tempErecord = {
                    ...eventRecord,
                    ...eventRecord?.replacible,
                    isInputChange: false,
                    loader: false,
                    selected: false,
                    replacible: {},
                    fieldErrors: {},
                  };
                  return tempErecord;
                } else {
                  return eventRecord;
                }
              }),
          };
        } else {
          return event;
        }
      });
    });
  };

  // UPDATE EVENT RECORD
  const updateRecord = async (
    data: any,
    eventRecord: any,
    onlyUpdate: Boolean = false,
    evIndex: any,
    rcInxex: any
  ) => {
    //CHECK LOCAL VALIDATION
    if (!(await setLocalValidationToEventRecords(eventRecord?.id, false))) {
      setEventRecordLoader(eventRecord, false);
      return true;
    } else {
      setTimeout(() => {
        setLocalValidationToEventRecords(eventRecord?.id, false);
        eventRecord.status = onlyUpdate
          ? eventRecord?.status
          : eventRecord?.status === "no"
          ? "yes"
          : "no";
        setSetSingleRecordLoader(true);
        layout.setAnimationForVisibility({
          eIndex: evIndex,
          rIndex: rcInxex,
          flag: true,
        });
        UpdateInventory(
          setEventRecordPayload(
            event,
            { ...eventRecord } //REMOVE PPE CHANGE
          )
        ).then((response: any) => {
          let responseData = response?.data?.payload?.post_data;
          responseData && responseData.status
            ? showAlert(response.message, false)
            : showAlert(Object.values(response?.errors)?.toString(), true);

          setTimeout(() => {
            setSetSingleRecordLoader(false);
            layout.setAnimationForVisibility({
              eIndex: evIndex,
              rIndex: rcInxex,
              flag: false,
            });
          }, 3000);

          //UPDATE VISBILITY STATE
          updateVisibility({
            ...eventRecord,
            ...(response?.data?.payload?.is_ticket_type_change === 1
              ? {
                  listing_ticket_files: [],
                  ticketFiles: [],
                  mobile_links: [],
                }
              : {}),
          });

          if (eventRecord?.ppeIsOpen === true) {
            PPPToggle();
          }
        });
      }, 600);
    }
  };

  // HANDLE PPP TOGGLE
  const PPPToggle = () => {
    setList((events: any) => {
      return events.map((event: any, eventIndex: any) => {
        if (eventIndex === eIndex) {
          return {
            ...event,
            eventRecords: Array.isArray(event["eventRecords"])
              ? event["eventRecords"]?.map((eventRecord: any, erIndex) => {
                  let toggleValue = !eventRecord?.ppeIsOpen;
                  if (
                    layout.isTableEditable.length > 0 &&
                    eventRecord.selected
                  ) {
                    toggleValue = !firstSelectedIndex.ppeIsOpen;
                  }
                  if (
                    erIndex === rIndex ||
                    (layout.isTableEditable.length > 0 &&
                      eventRecord.selected &&
                      (eventRecord?.price_per_exchange === 1 ||
                        eventRecord?.price_per_exchange === true))
                  ) {
                    // if(eventRecord?.price_per_exchange === 1){
                    //   return{
                    //     ...eventRecord,
                    //   ppeIsOpen: !eventRecord?.ppeIsOpen,

                    //   }
                    // }
                    const final = {
                      ...eventRecord,
                      ppeIsOpen: toggleValue,
                    };
                    return final;
                  } else {
                    return eventRecord;
                  }
                })
              : [],
          };
        } else {
          return event;
        }
      });
    });

    // MemoizedHandleTableField(
    //   {
    //     target: {
    //       value: !eventRecord?.ppeIsOpen,
    //       name: "ppeIsOpen",
    //     },
    //   },
    //   eIndex,
    //   rIndex
    // );
  };

  // HANDLE PUBLISH / UNPUBLISH
  const handlePublishUnpublish = async (data: any) => {
    if (
      eventRecord?.status !== "yes" &&
      (eventRecord?.listing_ticket_files?.length > 0 ||
        eventRecord?.mobile_links?.length > 0) &&
      Number(eventRecord?.quantity_available) !==
        Number(
          eventRecord?.listing_ticket_files?.length ||
            eventRecord?.mobile_links?.length
        ) &&
      (eventRecord?.ticket_type?.id === "mobile-qr" ||
        eventRecord?.ticket_type?.id === "pdf" ||
        eventRecord?.ticket_type === "eticket" ||
        eventRecord?.ticket_type?.id === "mobile-link" ||
        eventRecord?.ticket_type === "mobile-link")
    ) {
      const ifError = await setLocalValidationToEventRecords(
        eventRecord?.id,
        true
      );

      if (ifError === false) {
        showAlert(TicketError, true, 3000);
      }
    } else {
      handleListingAction(
        eventRecord?.status === "yes" ? "unpublish-all" : "publish-all",
        eventRecord?.id
      );
    }
  };

  // HANDLE ETICKET
  const handleETicketLocal = (e: any) => {
    const isUploaded = checkIsFullfillUploadExist(event, eventRecord);
    if (
      eventRecord?.ticket_type?.id !== "mobile-qr" &&
      eventRecord?.ticket_type?.id !== "pdf" &&
      eventRecord?.ticket_type !== "eticket" &&
      eventRecord?.ticket_type !== "mobile-link" &&
      eventRecord?.ticket_type?.id !== "mobile-link"
    ) {
      e.preventDefault();
    } else {
      if (isUploaded?.length === 0) {
        handleEticket(event, eventRecord);
        setIsOpenTicketPopUp(true);
        if (editData?.status === true) {
          display(false, tableRefSide?.current?.style);
          setEditData({ status: false });
        }
      } else {
        showAlert(
          "Bulk upload selection can not include listings with tickets already uploaded",
          true,
          3000
        );
      }
    }
  };

  interface ButtonClasses {
    className: string;
    tooltipClassName: string;
    SVGSpanClassName: string;
    SVGClassName: string;
  }

  const isTablet = window.innerWidth < 1000;
  const BtnClasses = (btnName: string): ButtonClasses | "" | undefined => {
    switch (btnName) {
      case "PPP":
        return {
          className: `roup flex flex-auto items-center justify-center w-3.5 h-4  ${
            // layout?.isTableEditable?.length > 0 &&
            // !ppeAction?.allPPE &&
            // ppeAction?.somePPE &&
            ppeErrosValidation && "pointer-events-none"
          }`,
          tooltipClassName: `!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10 ${
            rIndex === 0 || rIndex === 1 ? "mt-0 ml-2" : "mt-2"
          }    `,
          SVGSpanClassName: `${
            eventRecord?.price_per_exchange
              ? "fill-green-500"
              : "fill-violet-400"
          }  ${ppeErrosValidation && "!fill-gray-500"}`,
          SVGClassName: `w-3.5 h-4 ${
            !isTablet ? "group-hover:fill-indigo-500" : ""
          }`,
        };
      case "ticketsInHands":
        return {
          className: clsx(
            "group flex flex-auto items-center justify-center w-3.5 h-4",
            (Number(eventRecord?.quantity_available) ===
              Number(
                eventRecord?.listing_ticket_files?.length ||
                  eventRecord?.mobile_links?.length
              ) ||
              eventRecord?.isDisabled ||
              isEventRecordHoldTickets) &&
              "pointer-events-none"
          ),
          tooltipClassName:
            "!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10",
          SVGSpanClassName: "",
          SVGClassName: eventRecord?.ticketsInHands
            ? ` ${
                Number(eventRecord?.quantity_available) ===
                Number(
                  eventRecord?.listing_ticket_files?.length ||
                    eventRecord?.mobile_links?.length
                )
                  ? "fill-gray-400"
                  : "fill-green-600"
              }  w-3.5 h-4 group-hover:fill-indigo-500`
            : "fill-violet-500 w-3.5 h-4 group-hover:fill-indigo-500",
        };
      case "eticket":
        return {
          className: `
          flex flex-auto items-center justify-center w-[.8125rem] h-[.6875rem] ${
            isEventRecordError && "border-t-rose-500"
          } ${
            (disabledAll || isEventRecordHoldTickets) && "pointer-events-none"
          }`,
          tooltipClassName:
            "!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10",
          SVGSpanClassName: `flex items-center justify-center  hover:fill-indigo-500 transition ${
            Number(eventRecord?.quantity_available) > 0 &&
            Number(eventRecord?.quantity_available) ===
              Number(
                eventRecord?.listing_ticket_files?.length ||
                  eventRecord?.mobile_links?.length
              ) &&
            (eventRecord?.ticket_type?.id === "mobile-qr" ||
              eventRecord?.ticket_type?.id === "pdf" ||
              eventRecord?.ticket_type === "eticket" ||
              eventRecord?.ticket_type === "mobile-link" ||
              eventRecord?.ticket_type?.id === "mobile-link")
              ? "!fill-green-600"
              : "fill-violet-500"
          } 
            
                ${
                  eventRecord?.ticket_type?.id !== "mobile-qr" &&
                  eventRecord?.ticket_type?.id !== "pdf" &&
                  eventRecord?.ticket_type !== "eticket" &&
                  eventRecord?.ticket_type !== "mobile-link" &&
                  eventRecord?.ticket_type?.id !== "mobile-link"
                    ? "!fill-gray-400"
                    : ""
                }                          
      
                ${
                  eventRecord?.fieldErrors?.["quantity_available"] ===
                    TicketError && "!fill-rose-500"
                }
                `,
          SVGClassName: "w-[.8125rem] h-[.6875rem]",
        };
      case "logging":
        return {
          className: clsx(
            "group flex flex-auto items-center justify-center p-1 w-10 max-w-[2.5rem] h-[2.5rem] border-l",
            isEventRecordError && "border-t-rose-500",
            isDateBeforeValidLogDate(eventRecord?.created_at) &&
              !isGodAdmin &&
              "pointer-events-none"
          ),
          tooltipClassName:
            "!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10",
          SVGSpanClassName: `flex items-center justify-center ${
            isDateBeforeValidLogDate(eventRecord?.created_at) && !isGodAdmin
              ? "fill-gray-500"
              : "fill-violet-500"
          }  group-hover:fill-indigo-500 transition`,
          SVGClassName: "w-4 h-4",
        };
      default:
        break;
    }
  };

  return (
    <div
      className={clsx(
        "p-0 z-[2] md:sticky relative right-0 min-w-[14rem] max-w-[14rem] ml-auto bg-white sticky-icons focus-within:z-[3]",
        isGodAdmin && eventRecord?.isLock === true && "pointer-events-none",
        singleRecordLoader && "!pointer-events-none",
        layout.proceedPriceToggle && "!min-w-[22.125rem] !max-w-[22.125rem]"
      )}
    >
      <React.Fragment key={"eventFieldIn" + eventRecord?.id + rIndex}>
        <div
          className={
            eventRecord?.isInputChange && layout?.isTableEditable?.length === 0
              ? singleEdit
              : multiEdit
          }
          id="left-shad-list"
        >
          <div
            className={`${
              eventRecord?.isInputChange &&
              layout?.isTableEditable?.length === 0
                ? "flex"
                : "flex z-[1]"
            }  `}
          >
            {/* STICKY - PRICE FIELD */}
            {layout?.proceedPriceToggle && (
              <div
                className={clsx(
                  "flex items-center proceed-price inv_pri border-r px-2.5",
                  eventRecord?.isInputChange &&
                    layout?.isTableEditable?.length === 0
                    ? null
                    : "max-w-[8.1819rem]",
                  "relative"
                )}
                id={`sell_price-${event.id}-${rIndex}`}
              >
                <PriceField
                  value={
                    typeof eventRecord?.sell_price === "object"
                      ? eventRecord?.sell_price?.value
                      : decimalNumber(eventRecord?.sell_price)
                  }
                  id={`${event?.id}${rIndex}sell_price`}
                  currenncySymbol={
                    eventRecord?.sell_price_currency
                      ? eventRecord?.sell_price_currency
                      : eventRecord?.sell_price?.symbol
                  }
                  placeholder={"Proceed Price"}
                  className={`${
                    eventRecord?.errors?.sell_price &&
                    "focus:border-rose-500 border-rose-500 text-rose-500"
                  }`}
                  disablePrice={eventRecord.price_per_exchange}
                  ppeOpen={eventRecord?.ppeIsOpen}
                  textFielClassName={`${
                    eventRecord?.errors?.sell_price &&
                    "focus:border-rose-500 border-rose-500 text-rose-500"
                  } truncate`}
                  name="sell_price"
                  handleOnChange={(data: any) => {
                    data?.target?.value?.event?.preventDefault();
                    MemoizedHandleTableField(data, eIndex, rIndex);
                  }}
                  handleOnBlur={(data: any) => {
                    const value = decimalNumber(data.target?.value?.value);
                    let event = {
                      target: {
                        name: data.target.name,
                        value: {
                          name: data.target.name,
                          value: !isValidPriceFieldNumber(
                            data.target.value.value
                          )
                            ? decimalNumber(0)
                            : value,
                          symbol: data?.target?.value?.symbol,
                        },
                      },
                    };
                    MemoizedHandleTableField(event, eIndex, rIndex);
                  }}
                  errorClass={`${
                    eventRecord?.fieldErrors?.sell_price
                      ? eventRecord?.fieldErrors?.sell_price
                      : ""
                  }`}
                  errorMessage={`${
                    eventRecord?.fieldErrors?.sell_price
                      ? eventRecord?.fieldErrors?.sell_price
                      : ""
                  }`}
                  errorEventIndex={
                    eventRecord?.fieldErrors?.sell_price &&
                    `sell_price-${eventRecord?.id}`
                  }
                  handleOnFocus={(data: any) => {
                    setPriceFieldFocusedSticky({
                      data: data,
                      eIndex: eIndex,
                      rIndex: rIndex,
                    });
                  }}
                  isPriceField={
                    priceFiledFocusedSticky?.data &&
                    priceFiledFocusedSticky?.eIndex === eIndex &&
                    priceFiledFocusedSticky?.rIndex === rIndex
                      ? {
                          withoutCategory: categoryExist(
                            event,
                            eventRecord,
                            "withoutcategory"
                          ),
                          withCategory: categoryExist(
                            event,
                            eventRecord,
                            "withcategory"
                          ),
                          isTableField: false,
                          isFocused: priceFiledFocusedSticky?.data,
                        }
                      : false
                  }
                  fieldMessage={
                    eventRecord?.avg_price &&
                    categoryExist(event, eventRecord, "withcategory")
                      ? priceCalculation(
                          typeof eventRecord?.sell_price === "object"
                            ? eventRecord?.sell_price?.value
                            : eventRecord?.sell_price,
                          eventRecord?.avg_price
                            ? Number(eventRecord?.avg_price)
                            : null,
                          priceFactor
                        )
                      : false
                  }
                  isValidationPopupVisible={
                    categoryExist(event, eventRecord, "withcategory") &&
                    !eventRecord?.avg_price
                      ? true
                      : false
                  }
                  isPriceTable={true}
                  indexPlusLength={{
                    rIndex: rIndex,
                    recordLength: event?.eventRecords?.length,
                  }}
                  avgPrice={eventRecord?.avg_price}
                  isDisabled={
                    (isEventRecordHoldTickets &&
                      eventRecord?.quantity_available <
                        eventRecord?.listing_ticket_files?.length) ||
                    eventRecord?.price_per_exchange ||
                    (layout?.isTableEditable?.length > 0 &&
                      (ppeAction?.somePPE ||
                        (isGodAdmin && ppeAction?.godAdminError)))
                  }
                />
                {ppeErrosValidation &&
                  firstSelectedIndex?.id === eventRecord?.id && (
                    <>
                      <TooltipPortal
                        id={`ppe-error-${rIndex}`}
                        content={
                          ppeAction?.godAdminError
                            ? Messages?.bulkEditRestrictDiffTeam
                            : Messages?.bulkEditRestrictPPE
                        }
                        className="text-center !bg-white !opacity-100 shadow px-1.5 py-0.5 font-medium  text-xs z-[999999999] max-w-[9rem] cursor-default !text-rose-500"
                      />
                      <div
                        id={`ppe-error-${rIndex}`}
                        data-tooltip-id={`ppe-error-${rIndex}`}
                        className="absolute top-1/2 -translate-y-1/2 right-4 "
                      >
                        <IKTSVG
                          className="flex items-center justify-center h-3.5 w-3.5 fill-rose-500 hover:fill-rose-500 transition"
                          path={Warning1}
                        />
                      </div>
                    </>
                  )}
              </div>
            )}

            {/* CANCEL AND UPDATE - BUTTON */}
            {eventRecord?.isInputChange &&
            layout?.isTableEditable?.length === 0 ? (
              <CancelUpdateButton
                event={event}
                eventRecord={eventRecord}
                eIndex={eIndex}
                rIndex={rIndex}
                cancelRecord={cancelRecord}
                updateRecord={updateRecord}
              />
            ) : (
              <div className="icon-wrap flex">
                <div className="flex justify-between items-center min-w-[6.375rem] p-3 border-r">
                  {/* PPE */}
                  <ButtonSVGTooltip
                    btnClassName={
                      (BtnClasses("PPP") as ButtonClasses)?.className
                    }
                    btnID={`price-per-hand-tooltip${eIndex}${rIndex}`}
                    // dataBsToggle="collapse"
                    onClick={PPPToggle}
                    tooltipID={`price-per-hand-tooltip${eIndex}${rIndex}`}
                    tooltipContent="Price per partner"
                    tooltipClassName={
                      (BtnClasses("PPP") as ButtonClasses)?.tooltipClassName
                    }
                    tooltipPlace={rIndex === 0 || rIndex === 1 ? "left" : "top"}
                    SVGPath={PPPIcon}
                    SVGSpanClassName={
                      (BtnClasses("PPP") as ButtonClasses)?.SVGSpanClassName
                    }
                    SVGClassName={
                      (BtnClasses("PPP") as ButtonClasses)?.SVGClassName
                    }
                  />
                  {/* TICKETS IN HAND */}
                  <ButtonSVGTooltip
                    btnClassName={
                      (BtnClasses("ticketsInHands") as ButtonClasses)?.className
                    }
                    btnID={`tickets-in-hand-tooltip${eIndex}${rIndex}`}
                    onClick={() => {
                      let target = {
                        name: "ticketsInHands",
                        checked: !eventRecord?.ticketsInHands,
                        type: "checkbox",
                      };
                      MemoizedHandleTableField({ target }, eIndex, rIndex);
                    }}
                    tooltipID={`tickets-in-hand-tooltip${eIndex}${rIndex}`}
                    tooltipContent={
                      eventRecord?.ticketsInHands
                        ? "Remove tickets in hand"
                        : "Tickets in hand"
                    }
                    tooltipClassName={
                      (BtnClasses("ticketsInHands") as ButtonClasses)
                        ?.tooltipClassName
                    }
                    tooltipPlace={rIndex === 0 || rIndex === 1 ? "left" : "top"}
                    SVGPath={
                      eventRecord?.ticketsInHands ? HandSVG : CrosHandIcon
                    }
                    SVGClassName={
                      (BtnClasses("ticketsInHands") as ButtonClasses)
                        ?.SVGClassName
                    }
                  />
                  {/* ETICKET */}
                  <ButtonSVGTooltip
                    btnClassName={
                      (BtnClasses("eticket") as ButtonClasses)?.className
                    }
                    btnID={`eTicket-upload-tooltip${event?.id}-${eventRecord?.id}`}
                    onClick={handleETicketLocal}
                    tooltipID={`eTicket-upload-tooltip${event?.id}-${eventRecord?.id}`}
                    tooltipContent={
                      eventRecord?.quantity_available ===
                      (eventRecord?.listing_ticket_files?.length ||
                        eventRecord?.mobile_links?.length)
                        ? "Ticket(s) uploaded"
                        : eventRecord?.ticket_type?.id !== "mobile-qr" &&
                          eventRecord?.ticket_type?.id !== "pdf" &&
                          eventRecord?.ticket_type !== "eticket" &&
                          eventRecord?.ticket_type !== "mobile-link" &&
                          eventRecord?.ticket_type?.id !== "mobile-link"
                        ? "Not available"
                        : "Upload ticket"
                    }
                    tooltipClassName={
                      (BtnClasses("eticket") as ButtonClasses)?.tooltipClassName
                    }
                    tooltipPlace={rIndex === 0 || rIndex === 1 ? "left" : "top"}
                    SVGPath={Upload}
                    SVGSpanClassName={
                      (BtnClasses("eticket") as ButtonClasses)?.SVGSpanClassName
                    }
                    SVGClassName={
                      (BtnClasses("eticket") as ButtonClasses)?.SVGClassName
                    }
                  />
                </div>
                {/* --------------------------------------------- */}
                {/* METER */}
                <button
                  className={`flex flex-auto items-center justify-center p-1 w-10 md:max-w-[2.5rem] h-[2.5rem] border-r ${
                    (isEventRecordError || isEventRecordBEError) &&
                    "border-t-rose-500"
                  }`}
                  type="button"
                  id="visibility_addInventory"
                  onClick={() => {
                    setIsOpenListingQualityPopup(true);
                  }}
                >
                  <div
                    data-tooltip-id={`eTicket-upload-tooltip-visibility${eIndex}${rIndex}`}
                    className={clsx(
                      "table-meter flex gap-[.0875rem] svg-wrap",
                      categoryExist(event, eventRecord, "withcategory") &&
                        colorForMeter(
                          eventRecord?.avg_price
                            ? priceCalculation(
                                typeof eventRecord?.sell_price === "object"
                                  ? eventRecord?.sell_price?.value
                                  : eventRecord?.sell_price,
                                eventRecord?.avg_price
                                  ? Number(eventRecord?.avg_price)
                                  : null,
                                priceFactor
                              )
                            : false
                        ),
                      layout.animationForVisibility?.eIndex === eIndex &&
                        layout.animationForVisibility?.rIndex === rIndex &&
                        layout.animationForVisibility?.flag
                        ? `visibility-case${
                            eventRecord?.oldVisibility > 2
                              ? eventRecord?.oldVisibility - 2
                              : eventRecord?.oldVisibility
                          }-${visiblityMeterForBelowListing(
                            eventRecord?.oldVisibility,
                            eventRecord?.visibility
                          )}`
                        : `visibility-case${visiblityMeterForBelowListing(
                            eventRecord?.oldVisibility,
                            eventRecord?.visibility
                          )}`
                    )}
                  >
                    <span className="s_block w-[.1563rem] h-3 bg-gray-300 rounded-l-[1px]"></span>
                    <span className="s_block w-[.1563rem] h-3 bg-gray-300"></span>
                    <span className="s_block w-[.1563rem] h-3 bg-gray-300"></span>
                    {/* <span className="s_block w-[.1563rem] h-3 bg-gray-300"></span> */}
                    <span className="s_block w-[.1563rem] h-3 bg-gray-300 rounded-r-[1px]"></span>
                  </div>
                </button>
                <TooltipPortal
                  id={`eTicket-upload-tooltip-visibility${eIndex}${rIndex}`}
                  content={tooltipForListingMeter(
                    eventRecord?.visibility,
                    eventRecord?.avg_price
                      ? priceCalculation(
                          typeof eventRecord?.sell_price === "object"
                            ? eventRecord?.sell_price?.value
                            : eventRecord?.sell_price,
                          eventRecord?.avg_price
                            ? Number(eventRecord?.avg_price)
                            : null,
                          priceFactor
                        )
                      : false,
                    event
                  )}
                  positionStrategy="fixed"
                  place={rIndex === 0 || rIndex === 1 ? "left" : "top"}
                  variant="light"
                  className="text-center !bg-white !opacity-100 shadow !px-1.5 !py-0.5 font-medium  !text-xs z-20 max-w-[9rem] cursor-default"
                />
                {/* PUBLISH & PUBLISH */}
                <button
                  className={`relative group flex flex-auto items-center justify-center p-1 w-10 max-w-[2.5rem] h-[2.5rem] ${
                    (isEventRecordError || isEventRecordBEError) &&
                    "border-t-rose-500"
                  } group collapsed ${disabledAll && "pointer-events-none"} ${
                    event?.eventRecords?.length > 15 && ""
                  }`}
                  type="button"
                  id={`status-${event?.id}-${eventRecord?.id}`}
                  onClick={async (data: any) => {
                    handlePublishUnpublish(data);
                  }}
                >
                  <Tooltip
                    anchorId={`status-${event?.id}-${eventRecord?.id}`}
                    content={`${
                      eventRecord?.status === "yes" ? "Unpublish" : "Publish"
                    }`}
                    place={rIndex === 0 ? "left" : "top"}
                    variant="light"
                    className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                  />
                  {eventRecord?.status === "yes" ? (
                    <>
                      <IKTSVG
                        className="group-hover:hidden flex items-center justify-center fill-green-500 group-hover:fill-indigo-500 transition"
                        path={HaxPublishGray}
                        svgClassName="w-[1rem] h-[1.155rem]"
                      />
                      <IKTSVG
                        className="hoverIcon group-hover:flex hidden items-center justify-center fill-gray-400 group-hover:fill-indigo-500 transition"
                        path={unpublish}
                        svgClassName="w-[1rem] h-[1.155rem]"
                      />
                    </>
                  ) : (
                    <>
                      <IKTSVG
                        className="hoverIcon group-hover:flex hidden items-center justify-center fill-green-500 group-hover:fill-indigo-500 transition"
                        path={HaxPublishGray}
                        svgClassName="w-[1rem] h-[1.155rem]"
                      />
                      <IKTSVG
                        className="group-hover:hidden flex items-center justify-center fill-gray-400 group-hover:fill-indigo-500 transition"
                        path={unpublish}
                        svgClassName="w-[1rem] h-[1.155rem]"
                      />
                    </>
                  )}
                </button>

                {/* LOGGING */}
                <ButtonSVGTooltip
                  btnClassName={
                    (BtnClasses("logging") as ButtonClasses)?.className
                  }
                  btnID={`logs-tooltip${event?.id}-${eventRecord?.id}`}
                  isDiabled={
                    isDateBeforeValidLogDate(eventRecord?.created_at) &&
                    !isGodAdmin
                  }
                  onClick={() =>
                    layout.setLogsData({
                      ...eventRecord,
                      eventId: event?.id,
                    })
                  }
                  tooltipID={`logs-tooltip${event?.id}-${eventRecord?.id}`}
                  tooltipContent={"Log"}
                  tooltipClassName={
                    (BtnClasses("logging") as ButtonClasses)?.tooltipClassName
                  }
                  tooltipPlace={rIndex === 0 || rIndex === 1 ? "left" : "top"}
                  SVGPath={LogIcon}
                  SVGSpanClassName={
                    (BtnClasses("logging") as ButtonClasses)?.SVGSpanClassName
                  }
                  SVGClassName={
                    (BtnClasses("logging") as ButtonClasses)?.SVGClassName
                  }
                />
              </div>
            )}
          </div>
        </div>

        {isEventRecordError ||
        isEventRecordBEError ||
        isEventRecordHoldTickets ||
        eventRecord?.processing ? (
          <div
            className={`
  ${isEventRecordHoldTickets && "group-hover:bg-sky-blue-300"}
  ${
    isEventRecordError ||
    (isEventRecordBEError && "row-error-new group-hover:bg-rose-550")
  }
  ${eventRecord?.processing && "row-processing"} 
  `}
          ></div>
        ) : (
          ""
        )}
      </React.Fragment>
    </div>
  );
};

export default ActionButtonsWrapper;
