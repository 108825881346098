/* eslint-disable react-hooks/exhaustive-deps */
import Intercom from "@intercom/messenger-js-sdk";
import Cookies from "js-cookie";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Outlet, useLocation } from "react-router-dom";
import { existInLocalstorage } from "../../../helpers/Functions";
import { QUERIES, SYMBOLS } from "../../../helpers/const";
import {
  getAccountInfo,
  getGlobalCount,
} from "../../../pages/settings/core/_request";
import { LayoutContext } from "../../core/LayoutProvider";

import AsideDefault from "../aside/AsideDefault";
import Header from "../header/Header";
import { getSellerLevelConfiguration } from "helpers/AssetHelpers";

const MasterLayout = ({ handleLoading }: any) => {
  const location = useLocation();
  const layout = useContext(LayoutContext);
  const { asideMenuOpen, referralPopup } = layout;
  let cookieTtoken = Cookies.get("authTokens") as string;
  const settingOptions = layout.settingOptions;
  const notificationStatus = layout?.notifications;
  const pusher = layout?.isPusherCall;
  const teamInfo = layout?.allowedAccess?.team_data;
  const interComAPPID: string = process.env.REACT_APP_INTERCOM_APP_ID as string;
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  // const [userInfoToggle, setUserInfoToggle] = useState(false);
  const { userInfo: userInfoToggle, setUserInfo: setUserInfoToggle } = layout;

  const userInfo: any = {
    id: layout?.allowedAccess?.id,
    name:
      layout?.allowedAccess?.account_info?.firstname +
      " " +
      layout?.allowedAccess?.account_info?.surname,
    phone: `${layout?.allowedAccess?.account_info?.country_code}${layout?.allowedAccess?.account_info?.phone}`,
    email: layout?.allowedAccess?.account_info?.email
  };

  // ISMOBILE VIEW
  useEffect(() => {
    const handleResize = () => {
      layout.setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    if (teamInfo?.id && teamInfo?.id !== "") {
      const sanitizedPhone: any =
        userInfo?.phone?.replace(/[^0-9+ ]/g, "") || "";
      Intercom({
        app_id: interComAPPID,
        user_id: "user_" + userInfo.id,
        name: userInfo.name,
        phone: sanitizedPhone,
        company: {
          id: teamInfo.id,
          name: teamInfo.name,
          sellerLevel: getSellerLevelConfiguration(teamInfo?.seller_level?.title, "sellerLevelInterCom") 
        },
        isTxtradeUser:
          localStorage.getItem("isTxTradeUser") == "0" ? false : true,
        email: userInfo.email,
        created_at: Math.floor(new Date().getTime() / 1000),
        user_hash: layout?.allowedAccess?.intercom_hash,
      });
    }
  }, [teamInfo]);

  if (cookieTtoken) {
    cookieTtoken = JSON.parse(cookieTtoken);
  }
  // let authData =   Cookies.get("authData") as string;
  let authData: any = localStorage.getItem("authData") as string;
  if (authData) {
    authData = JSON.parse(authData);
  }

  // GET ALLOWANCE
  const { data, isFetching, refetch } = useQuery(
    [QUERIES.ACCESS],
    () => getAccountInfo(),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      onError: (err) => {},
      onSettled(data, error) {
        layout.setUserDetailShow({
          txPayStatus: data?.data?.tx_pay_info_status,
          txPayData: data?.data?.tx_pay_info_data,
        });
        localStorage.setItem(
          "txPayStatus",
          JSON.stringify({
            txPayStatus: data?.data?.tx_pay_info_status,
            txPayData: data?.data?.tx_pay_info_data,
          })
        );
      },
    }
  );

  // GET sales count
  useQuery(["global-count"], () => getGlobalCount(), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onError: (err) => {},
    onSettled(data, error) {
      layout.setGlobalCount(data?.data);
    },
  });

  useEffect(() => {
    layout.setIsFetching(isFetching);
  }, [isFetching]);

  // CALL API
  useEffect(() => {
    if (cookieTtoken) {
      refetch();
      layout.setIsPusherCall(false);
    }
  }, [
    cookieTtoken,
    location.pathname,
    settingOptions,
    notificationStatus,
    pusher,
    layout.infoAPIRefetch,
  ]);

  useEffect(() => {
    if (authData) {
      layout.setAuthUser(authData);
    }
  }, []);
  let checkLocalStorage = existInLocalstorage("access")?.app_version;

  useEffect(() => {
    if (data?.data) {
      layout.SetAllowedAccess(data?.data);
      localStorage.setItem("access", JSON.stringify(data?.data));
      localStorage.setItem("isTxTradeUser", data?.data?.tx_trade_user);
      // if (data?.data?.app_version === checkLocalStorage) {
      // } else {
      //   let body: any = document.querySelector("body");
      //   body?.classList.add("overflow-hidden");
      //   layout.setIsNotificationPopupActive(true);
      // }
    }
    const currency: string = data?.data?.account_info?.base_currency;
    layout.setCurrencyLabel(currency);
    layout.setCurrencySymbol(SYMBOLS?.[currency]);
    layout.setUsername(
      data?.data?.account_info?.firstname +
        " " +
        data?.data?.account_info?.surname
    );
    layout.setUserEmail(data?.data?.account_info?.email);

    const currencyRate = data?.data?.currency_rate?.GBP;
    const conversation_rate = currencyRate?.[currency];
    const value = data?.data?.account_info?.is_god_admin
      ? currencyRate
      : conversation_rate;
    layout.setConversationRate(value);
    if (data?.data?.ui_field_settings) {
      layout.setHeaderDraggableFields(data?.data?.ui_field_settings);
    }
  }, [data, checkLocalStorage]);

  const isTxtradeuser =
    layout?.isTxTradeUser === 1
      ? //&& layout.userDetailShow === true
        location.pathname === "/dashboard"
      : location.pathname === "/";

  return (
    <div
      className={`wrapper ${
        isTxtradeuser || location.pathname === "/static"
          ? "bg-violet-300"
          : location.pathname === "/typography"
          ? ""
          : location.pathname === "/tx-trade" ||
            location.pathname === "/notifications"
          ? "bg-gray-650"
          : "bg-gray-200"
      }`}
    >
      {/* <AsideDefault /> */}
      <AsideDefault userInfo={userInfoToggle} setUserInfo={setUserInfoToggle} />
      <div className="main-container transition-all duration-200 md:ml-[3.75rem]">
        <div>
          {!isOnline && (
            <p className="fixed top-0 left-0 bg-rose-500 text-white w-full z-[99] text-sm13 py-[3px] text-center">
              Network connection lost. Please check your internet connection.
            </p>
          )}
          {/* Your other components or UI elements */}
        </div>
        <Header />
        <div
          className={` ${
            isTxtradeuser || location.pathname === "/static"
              ? "page-content md:px-5 main-content  bg-no-repeat h-auto"
              : "flex flex-col justify-between page-content"
          } ${location.pathname === "/tx-trade" && "trade-body"}`}
        >
          <Outlet />
          {/* <ReferralPopup /> */}
        </div>
        {/* <Footer />} */}
      </div>
      {userInfoToggle || layout?.notificationId ? (
        <div className="w-[calc(100vw--3.75rem)] z-[1047] bg-black bg-opacity-70 fixed top-0 left-0 h-full"></div>
      ) : null}
    </div>
  );
};

export default MasterLayout;
