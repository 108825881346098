/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react";

import _ from "lodash";
import { getSelectedTeamMember } from "pages/inventory/core/_functions";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { LayoutContext } from "../../components/core/LayoutProvider";
import PageTitle from "../../components/core/PageTitle";
import { KTSVG } from "../../components/ui/KTSVG";
import ProgressBardLoader from "../../components/ui/ProgressBardLoader";
import { groupTeamMemberCommon } from "../../helpers/Functions";
import { QUERIES } from "../../helpers/const";
import useDebounce from "../add_inventory/core/useDebounce";
import ActivityFilters from "./component/ActivityFilters";
import HeaderfilterContainer from "./component/HeaderfilterContainer";
import NotificationsFilter from "./component/NotificationsFilter";
import TabBody from "./component/TabBody";
import TabHeader from "./component/TabHeader";
import { NotificationsContext } from "./core/NotificationsProvider";
import {
  getNotificationListing,
  getNotificationOptions,
} from "./core/requests";
import ActivityLogDataTable from "./table/ActivityLogDataTable";
import NotificationDataTable from "./table/NotificationDataTable";

const Notifications = () => {
  const [queryNotifications, setQueryNotifications] = useState<any>("");
  const [queryActivity, setQueryActivity] = useState<any>("");
  const [notificationOptions, setNotificationOptions] = useState<any>();
  const [activityOptions, setActivityOptions] = useState<any>();
  const debouncedSearchNotifications = useDebounce(queryNotifications, 1200);
  const debouncedSearchActivity = useDebounce(queryActivity, 1200);
  const [headerOptions, setHeaderOptions] = useState<any>();
  const navigate = useNavigate();
  const location = useLocation();
  const [groupTeamMember, setGroupTeamMember] = useState<any>();

  const layout = useContext(NotificationsContext);
  const globalLayout = useContext(LayoutContext);
  const isGodAdmin = globalLayout?.allowedAccess?.account_info?.is_god_admin;
  const userDetailInfo = globalLayout?.allowedAccess?.teams;
  let filters = layout.notificationsFilterHandle;
  let activityFilters = layout.activitiesFilterHandle;
  let tabValue = layout.tabValue;
  let dateRange = layout.dateRange;
  const authUser = globalLayout.authUser;
  const access = globalLayout.allowedAccess?.allowed_access;
  const userSpecific = access?.some(
    (item: any) => item.id === "user-specific-data" && item.status === true
  );
  const isAddInventory = access?.some(
    (item: any) => item.id === "inventory" && item.status === true
  );
  const bulkActionRef: any = useRef(null);

  //HANDLE OUTSIDE CLICK FOR DRAGGABLE FILTERS & COLUMNS
  const outSideClick = (e: any) => {
    if (!bulkActionRef?.current?.contains(e.target)) {
      let bulkAction: any = document.querySelectorAll(
        'span[data-te-target="#bulkaction"]:not([data-te-collapse-collapsed])'
      )?.[0];
      if (bulkAction) {
        bulkAction.click();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", outSideClick);
    return () => {
      document.removeEventListener("mousedown", outSideClick);
    };
  }, []);

  // useEffect(() => {
  //   if (userSpecific) {
  //     if (layout.tabValue === "notification") {
  //       layout.setNotificationsFilterHandle((prev: any) => {
  //         return {
  //           ...prev,
  //           ...(userSpecific && { specific: authUser?.id }),
  //         };
  //       });
  //     } else if (layout.tabValue === "activity") {
  //       layout.setActivitiesFilterHandle((prev: any) => {
  //         return {
  //           ...prev,
  //           ...(userSpecific && { specific: authUser?.id }),
  //         };
  //       });
  //     }
  //   }
  // }, [authUser?.id, userSpecific]);
  // PAGINATION SET TO FIRST PAGE ON FILTER CHANGE
  useEffect(() => {
    if (layout.tabValue === "notification") {
      layout.setNotificationsFilterHandle((prev: any) => {
        return {
          ...prev,
          page: 1,
          per_page: 20,
          ...(userSpecific && { specific: authUser?.id }),
        };
      });
    } else if (layout.tabValue === "activity") {
      layout.setActivitiesFilterHandle((prev: any) => {
        return {
          ...prev,
          page: 1,
          per_page: 20,
          ...(userSpecific && { specific: authUser?.id }),
        };
      });
    }
  }, [
    layout.notificationsFilterHandle.teammembers,
    layout?.dateRange,
    layout.activitiesFilterHandle.teammembers,
    userSpecific,
    layout.notificationsFilterHandle?.notification_type,
  ]);

  // GET NOTIFICATIONS LISTING
  const {
    isLoading: notificationsLoading,
    isFetching: notificationsIsFetching,
    data: listingData,
  } = useQuery(
    [
      `${QUERIES.NOTIFICATIONS_LISTING}`,
      tabValue,
      debouncedSearchNotifications,
      debouncedSearchActivity,
      filters,
      activityFilters,
      dateRange,
      layout.refetchNotification,
    ],
    () =>
      getNotificationListing({
        tabValue,
        debouncedSearchNotifications,
        debouncedSearchActivity,
        filters,
        activityFilters,
        dateRange,
        // ...(userSpecific && { team_member: [authUser?.id] }),
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: true,
      onError: (err) => {},
    }
  );

  // TAB SWITCH VIA DASHBOARD
  useEffect(() => {
    if (location && location?.state === "notification") {
      layout.setTabValue(location?.state);
    } else if (location && location?.state === "activity") {
      layout.setTabValue(location?.state);
    }
  }, [location]);

  // LOAD HEADER COUNTS
  useEffect(() => {
    if (listingData?.data && listingData?.meta?.current_page === 1) {
      setHeaderOptions(listingData?.data);
    }
  }, [listingData, tabValue]);

  // RESET TAB
  useEffect(() => {
    if (tabValue !== "notification")
      layout.setNotificationsFilterHandle({
        page: 1,
        per_page: 20,
        teammembers: getSelectedTeamMember(globalLayout?.allowedAccess),
      });
    layout.setActivitiesFilterHandle({ page: 1, per_page: 20 });
    layout.setDateRange({});
  }, [tabValue]);

  // GET NOTIFICATIONS FOR LISTING
  useEffect(() => {
    if (listingData?.data?.notifications) {
      layout.setNotifications((pre: any) => {
        const updatedNotifications = (
          listingData?.data?.notifications || []
        )?.map((notification: any) => ({
          ...notification,
        }));
        if (listingData?.meta?.current_page > 1) {
          return [...pre, ...updatedNotifications];
        } else {
          return [...updatedNotifications];
        }
      });

      // layout.setNotifications(
      //   listingData?.data?.notifications.map((notification: any) => {
      //     return {
      //       ...notification,
      //     };
      //   })
      // );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingData?.data?.notifications]);

  useEffect(() => {
    if (listingData?.data?.activities) {
      // layout.setActivities(
      //   listingData?.data?.activities.map((activity: any) => {
      //     return {
      //       ...activity,
      //     };
      //   })
      // );

      layout.setActivities((pre: any) => {
        const updatedNotifications = (listingData?.data?.activities || [])?.map(
          (activity: any) => ({
            ...activity,
          })
        );
        if (listingData?.meta?.current_page > 1) {
          return [...pre, ...updatedNotifications];
        } else {
          return [...updatedNotifications];
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingData?.data?.activities]);

  // GET NOTIFICATIONS OPTIONS
  const { data: optionsData } = useQuery(
    QUERIES.NOTIFICATIONS_OPTIONS,
    () => getNotificationOptions(),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      onError: (err) => {},
    }
  );

  // GET ACTIVITIES OPTIONS
  const { data: optionsData2 } = useQuery(
    QUERIES.ACTIVITIES_OPTIONS,
    () => getNotificationOptions(),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      onError: (err) => {},
    }
  );

  // GROUPING ALL TEAM TO WITH TEAM MEMBER
  useEffect(() => {
    if (_.size(userDetailInfo) > 0 && isGodAdmin) {
      const options = groupTeamMemberCommon(userDetailInfo);
      setGroupTeamMember(options);
    }
  }, [userDetailInfo, isGodAdmin]);

  // WHEN ISGODADMIN THEN BASED ON TEEAM TEAM MEMBER GROUPING ELSE NORMAL LISTING
  useEffect(() => {
    if (isGodAdmin) {
      setNotificationOptions({
        ...optionsData?.data,
        team_members:
          _.size(layout.notificationsFilterHandle?.team_id) > 0
            ? groupTeamMemberCommon(layout.notificationsFilterHandle?.team_id)
            : groupTeamMember,
      });
    } else {
      setNotificationOptions(optionsData?.data);
    }
  }, [optionsData, isGodAdmin, layout.notificationsFilterHandle?.team_id]);

  useEffect(() => {
    setActivityOptions(optionsData2?.data);
  }, [optionsData2]);

  // WHEN ISGODADMIN THEN BASED ON TEEAM TEAM MEMBER GROUPING ELSE NORMAL LISTING FOR ACTIVITY
  useEffect(() => {
    if (isGodAdmin) {
      setActivityOptions({
        ...optionsData2?.data,
        team_members:
          _.size(layout.activitiesFilterHandle?.team_id) > 0
            ? groupTeamMemberCommon(layout.activitiesFilterHandle?.team_id)
            : groupTeamMember,
      });
    } else {
      setActivityOptions(optionsData2?.data);
    }
  }, [optionsData2, isGodAdmin, layout.activitiesFilterHandle?.team_id]);

  const [secondCounter, setSecondCounter] = useState(3);
  const [notificationFirstTimeLoader, setNotificationFirstTimeLoader] =
    useState<any>(false);

  const [activityFirstTimeLoader, setactivityFirstTimeLoader] =
    useState<any>(false);

  useEffect(() => {
    if (notificationsLoading || notificationsIsFetching) {
      setSecondCounter((pre: any) => pre + 1);
    } else {
      setSecondCounter(0);
    }
  }, [notificationsLoading, notificationsIsFetching]);

  useEffect(() => {
    if (
      layout.notifications?.length > 0 &&
      notificationFirstTimeLoader === false &&
      notificationsLoading === false
    ) {
      setNotificationFirstTimeLoader(true);
    }
    if (
      layout.activities?.length > 0 &&
      activityFirstTimeLoader === false &&
      notificationsLoading === false
    ) {
      setactivityFirstTimeLoader(true);
    }
  }, [layout.notifications, notificationsLoading, layout.activities]);

  /*for sticky event bar*/
  const heightRef1: any = useRef();
  const heightRef2: any = useRef();
  const toggleRef: any = useRef();
  const [IsToggle, setIsToggle] = useState<any>(true);

  const toggle = document.querySelector(
    '[data-te-target="#inventoryaccordion"]'
  );

  const [overViewHeight, setOverViewHeight] = useState<any>();
  useEffect(() => {
    let height3: any;
    const element: any = document.querySelector("#inventoryaccordion");
    setTimeout(() => {
      height3 = IsToggle === true ? element?.offsetHeight : 0;
      setOverViewHeight(height3);
    }, 400);
  }, [IsToggle, layout.tabValue]);

  // useEffect(() => {
  //   const stickyElement = document.getElementById("topFilters") as HTMLElement;
  //   const stickyElementStyle = window.getComputedStyle(stickyElement);
  //   const stickyElementheight: any =
  //     stickyElementStyle.getPropertyValue("height");

  //   const filter = document.getElementById("filters_block") as HTMLElement;
  //   const heightheightRef2 = filter?.offsetHeight;

  //   // const panels = document.getElementById("panels");
  //   // const panelHeight = panels?.offsetHeight;

  //   if (stickyElement) {
  //     const heightheightRef1 = heightRef1?.current?.offsetHeight;
  //     // const heightheightRef2 = heightRef2?.current?.offsetHeight;

  //     const MarginTopTotle =
  //       IsToggle === true
  //         ? heightheightRef1 + overViewHeight - 60
  //         : heightheightRef1 - 60;

  //     const initialPosition = stickyElement.getBoundingClientRect().top;
  //     console.log(heightheightRef1, overViewHeight, heightheightRef2);

  //     const makeSticky = function () {
  //       if (window.scrollY + 60 >= initialPosition) {
  //         stickyElement.classList.add("sticky");
  //         stickyElement.style.top = `-${MarginTopTotle}px`;
  //       } else {
  //         stickyElement.classList.remove("sticky");
  //         stickyElement.style.top = `0px`;
  //       }
  //     };

  //     if (window.scrollY > 200) {
  //       makeSticky();
  //     }

  //     window.addEventListener("scroll", makeSticky);
  //     window.addEventListener("change", makeSticky);
  //     window.addEventListener("blur", makeSticky);
  //     return () => {
  //       window.removeEventListener("scroll", makeSticky);
  //     };
  //   }
  //   // });
  // }, [toggleRef, IsToggle, overViewHeight]);

  useEffect(() => {
    const stickyElement = document.getElementById("topFilters") as HTMLElement;
    const stickyElementStyle = window.getComputedStyle(stickyElement);
    const stickyElementheight: any =
      stickyElementStyle.getPropertyValue("height");

    const filter = document.getElementById("filters_block") as HTMLElement;
    const heightheightRef2 = filter.offsetHeight;

    if (stickyElement) {
      // const heightheightRef2 = heightRef2?.current?.offsetHeight;

      const initialPosition = stickyElement.getBoundingClientRect().top;
      const accordion = document.getElementById("inventoryaccordion");

      const accorAttr = accordion?.getAttribute("data-te-collapse-show");

      let MarginTopTotle = 0;
      // const MarginTopTotle =
      //     IsToggle === true
      //       ? heightheightRef1 + overViewHeight - HeaderHeight
      //       : heightheightRef1 - HeaderHeight;

      const recalculateMarginTopTotle = () => {
        const accordion = document.getElementById(
          "inventoryaccordion"
        ) as HTMLElement;
        const accordionStyle = window.getComputedStyle(accordion);
        const accordionheight: any = parseFloat(
          accordionStyle.getPropertyValue("height")
        );
        // Recalculate MarginTopTotle based on updated heights or values
        const heightheightRef1 = heightRef1?.current?.offsetHeight;
        const HeaderHeight: any =
          document.querySelector("header")?.clientHeight;
        MarginTopTotle =
          IsToggle === true
            ? heightheightRef1 + accordionheight - HeaderHeight
            : HeaderHeight - heightheightRef1;
      };

      const makeSticky = function () {
        const HeaderHeight: any =
          document.querySelector("header")?.clientHeight;
        recalculateMarginTopTotle();
        if (window.scrollY + HeaderHeight >= initialPosition) {
          stickyElement.classList.add("sticky");
          stickyElement.style.top =
            IsToggle === true ? `-${MarginTopTotle}px` : `${MarginTopTotle}px`;
        } else {
          stickyElement.classList.remove("sticky");
          stickyElement.style.top = `0px`;
        }
      };
      const handleWindowResize = () => {
        // Handle logic when the window is resized
        makeSticky(); // Update sticky behavior based on new values
        recalculateMarginTopTotle(); // Recalculate MarginTopTotle on window resize
      };

      // if (window.scrollY > 200) {
      //   makeSticky();
      // }

      window.addEventListener("scroll", makeSticky);
      window.addEventListener("change", makeSticky);
      window.addEventListener("blur", makeSticky);

      setTimeout(() => {
        document.addEventListener("resize", handleWindowResize);
      }, 200);
      return () => {
        window.removeEventListener("scroll", makeSticky);
        window.removeEventListener("change", makeSticky);
        window.removeEventListener("blur", makeSticky);
        document.removeEventListener("resize", handleWindowResize);
      };
    }
    // });
  }, [toggleRef, IsToggle, overViewHeight]);
  /* end sticky event */

  return (
    <>
      <PageTitle title="Notifications & Activity" />
      <div className="main-content-wrap">
        <div
          className={`topFilters sticky-element top-[60px] z-[11] max-md:!static`}
          id="topFilters"
        >
          <div className="accordion font-medium shadow-op2">
            <div
              className="accordion-item !rounded-none bg-white border-gray-200"
              ref={heightRef1}
            >
              <div
                className="flex  justify-between accordion-header mb-0"
                id="headingOne"
              >
                <div className="flex flex-wrap justify-between w-full pl-2 pr-5 py-2">
                  {/* <div className="flex flex-wrap" id="filters">
                    <div className="ba_item relative" ref={bulkActionRef}>
                      <span
                        className="sel_item block py-0.5 pr-6 pl-3 text-sm13 font-medium [&[aria-expanded='true']]:text-indigo-500 cursor-pointer
"
                        data-te-collapse-init
                        data-te-collapse-collapsed
                        data-te-target="#bulkaction"
                        aria-expanded="false"
                        aria-controls="bulkaction"
                      >
                        Filters
                      </span>
                      <div
                        id="bulkaction"
                        className="!visible ba_dd absolute top-full bg-white rounded shadow-lt left-0 min-w-[15rem] w-full hidden z-20"
                        data-te-collapse-item
                        aria-labelledby="bulkaction"
                        data-te-parent="#filters"
                      >
                        <DraggableFilters />
                      </div>
                    </div>
                  </div> */}
                  <HeaderfilterContainer />
                </div>
                <div>
                  <button
                    className="group relative flex items-center py-4 px-[.9375rem] text-base text-gray-800 text-left bg-white border-l  transition focus:outline-none group h-full md:h-auto [&[aria-expanded='false'] > .icon ]:rotate-180"
                    type="button"
                    data-te-collapse-init
                    data-te-toggle="collapse"
                    data-te-target="#inventoryaccordion"
                    aria-expanded="true"
                    aria-controls="inventoryaccordion"
                    onClick={() =>
                      setIsToggle(IsToggle === false ? true : false)
                    }
                  >
                    <KTSVG
                      className="flex items-center justify-center group-[.collapsed]:rotate-180  fill-violet-500 group-hover:fill-indigo-500 transition group-[[data-te-collapse-collapsed]]:rotate-180"
                      path="other_icons/Collapse.svg"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div
              className="bg-white accordion-collapse font-medium collapse show border-t"
              id="inventoryaccordion"
              data-te-collapse-item
              data-te-collapse-show
              aria-labelledby="headingOne"
            >
              {/* TAB HEADER */}
              <div
                className="tab-panel bg-no-repeat bg-tradepattern bg-cover bg-violet-300 p-5 pb-0 flex after:content after:absolute after:bottom-0 after:left-0 after:w-full after:h-2 after:bg-gradient-to-b after:from-black/0 after:to-black/5 after:z-0 relative overflow-hidden overflow-x-auto flex-nowrap"
                id={`panels`}
              >
                <TabHeader
                  tabId="notification"
                  name={
                    headerOptions?.new_notification > 1
                      ? "Notifications"
                      : "Notification"
                  }
                  count={headerOptions?.new_notification}
                  countColor={
                    tabValue === "notification"
                      ? "bg-indigo-500"
                      : "bg-violet-500"
                  }
                  postKey="notification"
                  handleTabClick={(data: any) => layout.setTabValue(data)}
                />
                <TabHeader
                  tabId="activity"
                  name={
                    headerOptions?.new_activities > 1
                      ? "Activities"
                      : "Activity"
                  }
                  count={headerOptions?.new_activities}
                  countColor={
                    tabValue === "activity" ? "bg-indigo-500" : "bg-violet-500"
                  }
                  postKey="activity"
                  handleTabClick={(data: any) => layout.setTabValue(data)}
                />
              </div>
              <TabBody
                bodyId="notification"
                isFirst={true}
                data={headerOptions}
                loading={notificationsLoading || notificationsIsFetching}
              ></TabBody>

              <TabBody
                bodyId="activity"
                isFirst={true}
                data={headerOptions}
                options={activityOptions}
                loading={notificationsLoading}
              ></TabBody>
            </div>
            {tabValue === "notification" && (
              <NotificationsFilter
                queryNotifications={queryNotifications}
                options={notificationOptions}
                debouncedSearchNotifications={debouncedSearchNotifications}
                paginateData={listingData?.meta}
                handleQuery={(data: any) => setQueryNotifications(data)}
                userDetailInfo={userDetailInfo}
                notificationsLoading={
                  notificationsLoading || notificationsIsFetching
                }
              />
            )}
            {tabValue === "activity" && (
              <ActivityFilters
                queryActivity={queryActivity}
                options={activityOptions}
                debouncedSearchActivity={debouncedSearchActivity}
                paginateData={listingData?.meta}
                handleQuery={(data: any) => setQueryActivity(data)}
                userDetailInfo={userDetailInfo}
                notificationsLoading={notificationsLoading}
              />
            )}
          </div>
        </div>

        {/*table */}
        {/* {headerOptions?.notifications &&
        headerOptions?.notifications.length > 0 &&
        !notificationsLoading ? (
          <NotificationDataTable
            data={
              headerOptions?.notifications &&
              headerOptions?.notifications > 0 &&
              headerOptions?.notifications
            }
            tabValue={tabValue}
          />
        ) : headerOptions?.activities &&
          headerOptions?.activities.length > 0 &&
          !notificationsLoading ? (
          <ActivityLogDataTable
            data={
              headerOptions?.activities &&
              headerOptions?.activities > 0 &&
              headerOptions?.activities
            }
            tabValue={tabValue}
          />
        ) : headerOptions?.notifications ? (
          <div className="text-sm font-medium text-center my-4 p-2 bg-white rounded">
            No Notification available
          </div>
        ) : (
          <div className="text-sm font-medium text-center my-4 p-2 bg-white rounded">
            No Activity available
          </div>
        )} */}

        {notificationsLoading &&
        layout?.notificationsFilterHandle?.page === 1 &&
        layout.tabValue === "notification" ? (
          Array.from({ length: 10 }, (v, i) => (
            <div
              className={`accordion font-medium  mb-2.5 mx-5 ${
                i === 0 && "mt-2.5"
              } `}
            >
              <div
                key={i}
                className={`accordion-item bg-white !rounded overflow-hidden ${
                  notificationsLoading && "shimmer-effect"
                } `}
                style={{ height: "40px" }}
              ></div>
            </div>
          ))
        ) : headerOptions?.notifications &&
          layout.notifications?.length > 0 &&
          layout.tabValue === "notification" ? (
          <NotificationDataTable
            data={
              headerOptions?.notifications &&
              headerOptions?.notifications > 0 &&
              headerOptions?.notifications
            }
            tabValue={tabValue}
            paginateData={listingData?.meta}
            notificationsLoading={notificationsLoading}
          />
        ) : (
          headerOptions?.notifications &&
          layout.tabValue === "notification" && (
            <div className="text-sm font-medium text-center my-4 p-2 bg-white rounded">
              No Notification available
            </div>
          )
        )}

        {notificationsLoading &&
        layout?.activitiesFilterHandle?.page === 1 &&
        layout.tabValue === "activity" ? (
          Array.from({ length: 10 }, (v, i) => (
            <div
              className={`accordion font-medium  mb-2.5 ${
                i === 0 && "mt-2.5"
              } `}
            >
              <div
                key={i}
                className={`accordion-item bg-white !rounded overflow-hidden ${
                  notificationsLoading && "shimmer-effect"
                } `}
                style={{ height: "40px" }}
              ></div>
            </div>
          ))
        ) : headerOptions?.activities &&
          layout.activities?.length > 0 &&
          layout.tabValue === "activity" ? (
          <ActivityLogDataTable
            data={
              headerOptions?.activities &&
              headerOptions?.activities > 0 &&
              headerOptions?.activities
            }
            tabValue={tabValue}
            paginateData={listingData?.meta}
            notificationsLoading={notificationsLoading}
          />
        ) : (
          headerOptions?.activities &&
          layout.tabValue === "activity" && (
            <div className="text-sm font-medium text-center my-4 p-2 bg-white rounded">
              No Activity available
            </div>
          )
        )}
      </div>

      {notificationsLoading &&
        layout.tabValue === "notification" &&
        layout?.notificationsFilterHandle?.page === 1 && (
          <ProgressBardLoader
            LoadingText="Loading your notifications data"
            secondCounter={secondCounter}
          />
        )}
      {notificationsLoading &&
        layout.tabValue === "activity" &&
        layout?.activitiesFilterHandle?.page === 1 && (
          <ProgressBardLoader
            LoadingText=" Loading your activity data"
            secondCounter={secondCounter}
          />
        )}
      {/* {notificationsLoading && <Loader />} */}
    </>
  );
};
export default Notifications;
