import clsx from "clsx";
import { useContext } from "react";
import { Tooltip } from "react-tooltip";
import EDITSVG from "../../../../../assets/media/icons/other_icons/edit-square.svg";
import EyeSVG from "../../../../../assets/media/icons/other_icons/Eye.svg";
import { LayoutContext } from "../../../../../components/core/LayoutProvider";
import DropDownField from "../../../../../components/formComponent/DropDownField";
import { IKTSVG } from "../../../../../components/ui/IKTSVG";

const ActionButton = ({
  sIndex,
  list,
  SVGIconName,
  handleViewForm,
  matchedView,
}: any) => {
  return (
    <button
      className={clsx(
        SVGIconName === "edit"
          ? `group inline-flex group items-center justify-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 gap-2 p-1 w-7 h-7`
          : `group my-button flex items-center justify-center p-1 md:p-1 w-full md:w-10`,
        matchedView && "pointer-events-none"
      )}
      type="button"
      id={`record-view-tooltip${sIndex}`}
      onClick={() => {
        handleViewForm(list, sIndex);
      }}
    >
      <Tooltip
        anchorId={`record-view-tooltip${sIndex}`}
        content={
          matchedView ? "Close" : SVGIconName === "edit" ? "Edit" : "View"
        }
        place={`${sIndex === 0 ? "left" : "top"}`}
        variant="light"
        className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  ${
          sIndex === 0 ? "mt-0" : "mt-1.5"
        }`}
      />
      <IKTSVG
        className={
          SVGIconName === "edit"
            ? `flex items-center justify-center fill-white group-hover:fill-white transition`
            : `flex items-center justify-center  group-hover:fill-indigo-500 ${
                matchedView ? "fill-indigo-500" : "fill-violet-500"
              }`
        }
        svgClassName="w-4 h-[.6875rem]"
        path={SVGIconName === "edit" ? EDITSVG : EyeSVG}
      />
    </button>
  );
};

const TableActionButtons = ({
  list,
  sIndex,
  matchedView,
  handleViewForm,
  handleOnChangeStatus,
  SVGIconName = "edit",
  mainOptions,
  shadowRequired,
}: any) => {
  const findOption = (options: any, value: any) => {
    return options.find((option: any) => option?.name === value) || null;
  };
  const globalLayout = useContext(LayoutContext);
  const currentStatusValue = list?.status?.name;
  const selectedOption = findOption(mainOptions, currentStatusValue);

  return (
    <>
      <td
        className={`md:sticky relative right-0 ml-auto p-0 icon_td md:w-[10.75rem] md:max-w-[10.75rem] md:min-w-[10.75rem] w-[8rem] max-w-[8rem] min-w-[8rem]`}
      >
        <div
          className={` bg-opacity-[7%] ${
            matchedView && "bg-indigo-500 bg-opacity-[7%]"
          }`}
        >
          <div
            className={clsx(
              "flex relative w-full h-full border-l left-shad",
              shadowRequired && "left-shad"
            )}
            id="left-shad-list"
          >
            <div
              id={sIndex}
              className="w-[8.1875rem] max-w-[8.1875rem] min-w-[8.1875rem] border-r subscription_drop p-[.625rem]  py-2 "
            >
              <DropDownField
                options={mainOptions}
                placeholder={selectedOption ? "" : "Action"}
                name={`Status`}
                getOptionLabel="label"
                value={selectedOption}
                isMenuPortalTarget={true}
                isClearable={false}
                getOptionValue="name"
                handleOnChange={(data: any) => {
                  handleOnChangeStatus(data, list, sIndex);
                }}
                isSearchable={false}
              />
            </div>
            <div className="flex items-center justify-center p-1 md:p-1 w-8  md:flex-auto flex-1 max-md:hidden">
              <ActionButton
                sIndex={sIndex}
                list={list}
                SVGIconName={SVGIconName}
                handleViewForm={handleViewForm}
                matchedView={matchedView}
              />
            </div>
          </div>
        </div>
      </td>
      {globalLayout?.isMobile && (
        <td
          className={`sticky right-0 ml-auto p-0 icon_td w-[2.5rem] max-w-[2.5rem] min-w-[2.5rem]`}
        >
          <div
            className={` bg-opacity-[7%] ${
              matchedView && "bg-indigo-500 bg-opacity-[7%]"
            }`}
          >
            <div
              className={clsx(
                "flex relative w-full h-full border-l",
                shadowRequired && "left-shad"
              )}
              id="left-shad-list"
            >
              <div className="flex items-center justify-center p-1 md:p-1 w-8  md:flex-auto flex-1">
                <ActionButton
                  sIndex={sIndex}
                  list={list}
                  SVGIconName={SVGIconName}
                  handleViewForm={handleViewForm}
                  matchedView={matchedView}
                />
              </div>
            </div>
          </div>
        </td>
      )}
    </>
  );
};

export default TableActionButtons;
