import { KTSVG } from "components/ui/KTSVG";
import ProgressBardLoader from "components/ui/ProgressBardLoader";
import { QUERIES } from "helpers/const";
import { errorReflect } from "helpers/Functions";
import { showAlert } from "helpers/ShowAlert";
import _ from "lodash";
import { useState } from "react";
import { useQuery } from "react-query";
import {
  getMarketPlacePersonalData,
  saveUpdatedMarketplace,
} from "../../core/requests";
import MarketplaceEditable from "../MarketplaceEditable";

const UserMarketplaceEdit = ({ data, handleCloseForm = () => {} }: any) => {
  const [marketplaceData, setMarketplaceData] = useState<any>();
  const [marketPlaceSaveData, setMarketPlaceSaveData] = useState<any>();

  const { isLoading, refetch } = useQuery(
    [`${QUERIES.USER_CONTECTED_MARKETPLACE}`, data],
    () => getMarketPlacePersonalData(data),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: data?.isTxtrade === false && data?.status === true,
      onError: () => {},
      onSettled: (data) => {
        setMarketplaceData(data?.data?.connected_marketplace);
      },
    }
  );

  // const handleOnSubmit = () => {

  const handleOnSubmit = async () => {
    if (!_.isEmpty(marketPlaceSaveData)) {
      const updatedData = _.filter(
        marketPlaceSaveData,
        (obj) => obj?.value === true
      );

      const payload = {
        user_id: data?.user_id,
        reseller_ids: _.map(updatedData, "id"),
      };

      try {
        const res = await saveUpdatedMarketplace(payload);
        const { data, message } = res;
        if (data?.status === 200) {
          showAlert(message, false);
          refetch();
        } else {
          errorReflect(res);
        }
      } catch (error) {
        console.error("Error updating seller level:", error);
        showAlert("Failed to update seller level", false);
      } finally {
      }
    }
  };

  return (
    <div
      data-te-modal-init
      className={`${
        data?.status ? "show" : ""
      } offcanvas fill_canvas offcanvas-end fixed bottom-0 top-[3.75rem] right-0 shadow-lg bg-clip-padding outline-none transition duration-300 ease-in-out font-medium  w-full upload-ticket-popup`}
      tabIndex={-1}
      id={`address_edit`}
      aria-labelledby="fillCanvasLabel"
    >
      <form className="h-full md:max-w-[43.125rem] max-w-full ml-auto">
        <div className="  bg-white shadow-3xl w-full h-full ml-auto relative flex flex-col">
          <div className="offcanvas-header flex justify-between border-t border-b">
            <h5
              className="offcanvas-title mb-0 leading-normal text-sm15 font-semibold py-2 px-5"
              id="offcanvasRightLabel"
            >
              Connected marketplaces
            </h5>
            <div className="buttons flex flex-wrap">
              <button
                type="button"
                className="flex items-center justify-center w-9 border-l box-content group"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={() => {
                  handleCloseForm();
                }}
              >
                <KTSVG
                  className="fill-violet-500 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
                  path="standard_icons/cross.svg"
                />
              </button>
            </div>
          </div>
          <div className="offcanvas-body h-full w-full flex-row overflow-y-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full px-5 py-8 mb-1 p-5 pt-3">
            <div className="address_fields flex flex-wrap -mx-2.5 inner-inp">
              <MarketplaceEditable
                fillData={marketplaceData}
                data={marketPlaceSaveData}
                setData={setMarketPlaceSaveData}
              />
            </div>
          </div>

          <div className=" offcanvas-footer bottom-0 right-0 w-full flex justify-start px-5 py-3 bg-white z-10 border-t">
            <div className="l_btn gap-2.5 flex flex-wrap items-center">
              <button
                type="button"
                className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
                onClick={() => {
                  handleCloseForm();
                }}
              >
                Cancel
              </button>

              <button
                type="button"
                className={` relative z-10 border border-green-600 hover:text-green-600 active:text-white text-white hover:bg-white bg-green-600 active:bg-green-600 transition font-semibold rounded text-sm13 px-2.5 py-0.5 `}
                onClick={handleOnSubmit}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </form>
      {isLoading && <ProgressBardLoader secondCounter={2} />}
    </div>
  );
};

export default UserMarketplaceEdit;
