import { LayoutContext } from "components/core/LayoutProvider";
import { LISTINGS_UTILS } from "helpers/const";
import _, { isArray } from "lodash";
import { memo, useContext, useEffect, useState } from "react";
import { KTSVG } from "../../../components/ui/KTSVG";
import ProgressBardLoader from "../../../components/ui/ProgressBardLoader";
import { InventoryContext } from "../core/InventoryProvider";
import { getInventoryRecordListing } from "../core/_request";

const CollapseButton = memo(
  ({
    list,
    setList,
    isDisabled,
    handleLoader,
    publishStatus,
    setSelectAll,
    checkEventIsOpen,
  }: any) => {
    const layout = useContext(InventoryContext);
    const globalLayout = useContext(LayoutContext);
    const [collapse, setCollapse] = useState(false);
    const [shimmreLoader, setShimmerLoader] = useState<any>(false);
    const events: any = list;
    let publishStatusId = publishStatus;
    const [isCollapse, setIsCollapse] = useState(false);
    const listingIds = globalLayout?.editListings;

    const [loader, setLoader] = useState(false);

    // const eventid = layout.eventId;
    // const setEventId = layout.setEventId;

    // console.log(layout.customLoader, "eventid");
    // let eventLength = events && events.length > 0;

    // useEffect(() => {
    //   if (eventid?.length === layout.events?.length) {
    //     setCollapse(!eventid);
    //   } else if (eventid?.length === 0 && collapse === false) {
    //     setCollapse(!eventid);
    //   } else if (eventid?.length === 0 && collapse === true) {
    //     setCollapse(false);
    //   } else {
    //     setCollapse(true);
    //   }

    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const handleCollapseAll = async () => {
      // Get all elements with the class "below-hidden-events"
      var elements = document.getElementsByClassName("below-hidden-events");

      // Convert the HTMLCollection to an array for easier manipulation
      var elementsArray = Array.from(elements);

      // Remove the "below-hidden-events" class from each element
      elementsArray.forEach(function (element) {
        element.classList.remove("below-hidden-events");
      });

      const mainCollapseBodies: any = document.querySelectorAll(
        "#table-shrink .bottom_acc"
      );
      if (!collapse) {
        //load all accordion listing if is not loaded.
        // setList((events: any) => {
        //   return events.map((event: any) => {
        //     if (
        //       event.eventRecords === undefined ||
        //       _.size(event.eventRecords) === 0
        //     ) {
        //       return {
        //         ...event,
        //         eventLoader: true,
        //       };
        //     } else {
        //       return event;
        //     }
        //   });
        // });

        setLoader(true);
        setShimmerLoader(true);
        setTimeout(async () => {
          mainCollapseBodies.forEach((body: any) => {
            if (body.classList.contains("hidden")) {
              body.classList.remove("hidden");
            }
            body.classList.add("visible");
            body.setAttribute("data-te-collapse-show", "true");
          });
          let updatedEvents = await Promise.all(
            events.map(async (event: any) => {
              if (
                event.eventRecords === undefined ||
                _.size(event.eventRecords) === 0
              ) {
                layout.setCustomLoader(true);
                let userId =
                  layout.users &&
                  Array.isArray(layout.users) &&
                  layout.users.length > 0 &&
                  _.map(layout.users, "id");
                let eventListing: any = await getInventoryRecordListing({
                  id: event?.id,
                  userId: userId,
                  publishStatusId,
                  teamsSelected: layout?.teamSelectedGlobal,
                  orderState: layout.orderState,
                  listingIds,
                }).then((response) => {
                  return response;
                });

                let final = {
                  ...event,
                  // loadFlagStatus: false,
                  isOpen: event.isOpen ? false : true,
                  loadFlagStatus: event?.loadFlagStatus ? false : true,
                  eventRecords: eventListing?.data?.map((item: any) => {
                    let visibility: number = 0;
                    let noOfField: number = 8;

                    if (item?.ticket_type) {
                      visibility += 5 / noOfField;
                    }
                    if (item?.quantity_available) {
                      visibility += 5 / noOfField;
                    }
                    if (item?.split_type) {
                      visibility += 5 / noOfField;
                    }

                    if (item?.category) {
                      visibility += 5 / noOfField;
                    }
                    if (item?.section) {
                      visibility += 5 / noOfField;
                    }
                    if (item?.row) {
                      visibility += 5 / noOfField;
                    }
                    if (item?.first_seat) {
                      visibility += 5 / noOfField;
                    }
                    if (item?.in_hand === "yes") {
                      visibility += 5 / noOfField;
                    }
                    return {
                      ...item,
                      benefits: item?.restrictions,
                      ticketsInHands: item?.in_hand === "no" ? false : true,
                      visibility: Math.round(visibility),
                      oldVisibility: 0,
                      exchanges: isArray(event?.selected_event_exchanges)
                        ? event?.selected_event_exchanges
                        : [],
                      isLock: true,
                    };
                  }),
                  meta: eventListing?.meta,
                  eventLoader: false,
                };
                return final;
              } else {
                let final = {
                  ...event,
                  loadFlagStatus: event?.loadFlagStatus ? false : true,
                  isOpen: event.isOpen ? false : true,
                };
                return final;
                // return event;
              }
            })
          );
          setLoader(false);
          layout.setCustomLoader(false);
          setTimeout(() => {
            setShimmerLoader(false);
          }, 4000);

          setList(updatedEvents);
        }, 500);
      } else {
        setList((prev: any) => {
          return prev.map((event: any) => {
            let final = {
              ...event,
              requireSelectAll: {},
              eventRecords: event?.eventRecords?.map((item: any) => {
                return {
                  ...item,
                  selected: false,
                };
              }),
              isOpen: event.isOpen && false,
              loadFlagStatus: event?.loadFlagStatus && false,
            };
            return final;
          });
        });
        setSelectAll(false);
      }
      setCollapse(!collapse);
      setIsCollapse(false);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    // const [secondCounter, setSecondCounter] = useState(5);

    // useEffect(() => {
    //   setTimeout(() => {
    //     setTimeout(() => {
    //       setShimmerLoader(false);
    //     }, 200);
    //     setSecondCounter(1);
    //   }, 7000);

    //   // setSecondCounter((pre: any) => pre + 1);
    // }, [loader]);

    // let buttonText = collapsedAllBtn ? collapsedAllBtn.textContent : "";

    // COMMENT CODE OPEN IF REQUIRED
    // useEffect(() => {
    //   let collapsedAllBtn: any = document.getElementById("collapsed");
    //   let isButtonExpanded = false;
    //   const accordions: any = document.querySelectorAll(
    //     "#table-shrink .title-accordion"
    //   );
    //   accordions.forEach((accordion: any) => {
    //     if (accordion.getAttribute("aria-expanded") === "true") {
    //       isButtonExpanded = true;
    //     }
    //   });
    //   if (collapsedAllBtn) {
    //     setCollapse(isButtonExpanded ? true : false);
    //   }
    // }, [list]);

    // NEW CODE ADDED
    useEffect(() => {
      setCollapse(checkEventIsOpen?.length > 0 ? true : false);
    }, [checkEventIsOpen]);

    return (
      <>
        {collapse && (
          <button
            type="button"
            className={`
          ${isCollapse ? "pointer-events-none" : ""}
          ${
            loader || isDisabled || layout?.selectAllLoader
              ? `pointer-events-none text-gray-400`
              : `text-violet-800`
          } inline-flex group items-center  hover:text-white bg-gray-100 
    hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 `}
            id="collapsed"
            data-te-target={`.bottom_acc${
              collapse ? `[data-te-collapse-show]` : ""
            }`}
            data-te-collapse-init
            data-te-ripple-init
            data-te-ripple-color="light"
            onClick={() => {
              setIsCollapse(true);
              setTimeout(() => {
                handleCollapseAll();
              }, 700);
            }}
          >
            {/* {loader && (
            <IKTSVG
              className={`flex items-center justify-center  transition animate-spin mr-2 fill-violet-500`}
              path={processSVG}
            />
          )} */}

            {/* <KTSVG
        className={`${
          !eventLength ? "fill-gray-500" : "fill-violet-500 "
        } min-w-[0.625rem] max-w-[0.63rem] mr-2 flex items-center justify-center fill-violet-500 group-hover:fill-white 
      transition-all duration-300 ${!collapse && `rotate-180`}`}
        path="standard_icons/Collapse.svg"
      />
      {eventid?.length === layout.events?.length && "abc"}
      {collapse ? "Collapse all" : "Expand all"} */}

            <KTSVG
              className={`${
                loader || isDisabled || layout?.selectAllLoader
                  ? "fill-gray-400"
                  : "fill-violet-500 "
              } min-w-[0.625rem] max-w-[0.63rem] mr-2 flex items-center justify-center group-hover:fill-white 
      transition-all duration-300 ${!collapse && `rotate-180`} `}
              path="standard_icons/Collapse.svg"
            />
            {collapse ? "Collapse all" : "Expand all"}
          </button>
        )}

        {shimmreLoader && (
          <ProgressBardLoader
            LoadingText={`Loading your ${LISTINGS_UTILS?.myListings.name} data`}
            secondCounter={7}
          />
        )}
      </>
    );
  }
);

export default CollapseButton;
