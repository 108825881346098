import TextInput from "components/formComponent/TextInput";

import { IKTSVG } from "components/ui/IKTSVG";
import { KTSVG } from "components/ui/KTSVG";
import ProgressBardLoader from "components/ui/ProgressBardLoader";
import { useFormik } from "formik";
import { errorReflect } from "helpers/Functions";
import { showAlert } from "helpers/ShowAlert";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import * as Yup from "yup";
import DeleteSVG from "../../../../assets/media/icons/other_icons/Delete.svg";
import AddSVG from "../../../../assets/media/icons/other_icons/add_icon.svg";
import { SellerLevelsProviderContex } from "../core/SellerLevelsProvider";
import { addSellerLevel, editSellerLevel } from "../core/request";
import CommonDynamicComponent from "./CommonDynamicComponent";

const LevelConfigurationPopup = ({ refetch, configData }: any) => {
  const layout: any = useContext(SellerLevelsProviderContex);
  const [levelData, setLevelData] = useState<any>();
  const [OptionData, setOptionData] = useState<any>();
  const [submitLoader, setSubmitLoader] = useState<any>(false);

  function convertToId(value: any) {
    return value
      ?.toLowerCase()
      ?.replace(/\s+/g, "_")
      ?.replace(/[^\w_]/g, "");
  }

  useEffect(() => {
    const editData = layout?.sellerLevelEditData;

    const options = configData?.data?.options;
    if (editData?.config?.length > 0) {
      const newData = editData?.config?.map((obj: any) => {
        if (obj?.component === "dropdown" && obj?.value && _.size(obj?.value)) {
          return {
            ...obj,
            value: { id: convertToId(obj?.value), name: obj?.value },
          };
        }
        return obj;
      });
      setLevelData(newData);
    } else if (configData?.data?.config?.length > 0) {
      const newData = configData?.data?.config?.map((obj: any) => {
        if (obj?.component === "radio")
          return {
            ...obj,
            value: true,
          };
        return obj;
      });
      setLevelData(newData);
    }
    if (options) {
      setOptionData(options);
    }
  }, [configData, layout?.sellerLevelEditData]);

  const initialValues = {
    Seller_level_name: layout?.sellerLevelEditData?.title || "",
    weightage: layout?.sellerLevelEditData?.weightage || "",
    ...levelData?.reduce((acc: any, field: any) => {
      acc[field?.key] = field?.value !== undefined ? field?.value : "";
      return acc;
    }, {}),
    benefits:
      layout?.sellerLevelEditData?.benefits?.length > 0
        ? layout?.sellerLevelEditData?.benefits
        : [""],
  };
  const validationSchema = Yup.object().shape({
    Seller_level_name: Yup.string()
      .trim()
      .required("Seller Level Name is Required"),
    weightage: Yup.number()
      .required("Seller Level Weightage is Required")
      .typeError("Weightage must be a number")
      .min(0, "Weightage cannot be negative"),

    ...levelData?.reduce((acc: any, field: any) => {
      if (field.component !== "dropdown") {
        if (field.component === "string") {
          acc[field?.key] = Yup.string()
            .trim()
            .required(`${field?.label} is Required`);
        } else if (field.component !== "radio") {
          acc[field?.key] = Yup.number()
            .required(`${field?.label} is Required`)
            .typeError(`${field?.label} must be a number`)
            .min(0, `${field?.label} cannot be negative`);
        }
      } else if (field.component !== "radio") {
        acc[field?.key] = Yup.object()
          .nullable()
          .required(`${field?.label} is Required`);
      }
      return acc;
    }, {}),

    benefits: Yup.array()
      .of(
        Yup.string()
          .trim()
          .test("non-empty", "Benefits can not be empty", function (value) {
            if (this.parent.length > 1) {
              return (value || "").trim() !== "";
            }
            return true;
          })
      )
      .test(
        "non-empty-if-multiple",
        "Each benefit must have a value",
        function (benefits) {
          if (benefits && benefits.length > 1) {
            return benefits.every((benefit) => (benefit || "").trim() !== "");
          }
          return true;
        }
      ),
  });

  async function handleSellerLevelUpdate(isEdit: any, payload: any) {
    const apiCall = isEdit ? editSellerLevel : addSellerLevel;

    try {
      const res = await apiCall(payload);

      setSubmitLoader(false);

      if (res?.data?.status) {
        refetch();
        showAlert(res?.message, !res?.data?.status);
        const cancelBtn = document.getElementById("close-btn");
        if (cancelBtn) {
          cancelBtn.click();
        }
      } else {
        errorReflect(res);
      }
    } catch (error) {
      showAlert("An error occurred while updating the seller level", true);
    }
  }
  // Initialize useFormik
  const formik: any = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setSubmitLoader(true);
      const config = levelData?.map((item: any) => {
        if (values.hasOwnProperty(item?.key)) {
          return {
            ...item,
            value: values[item?.key]?.id
              ? values[item?.key]?.name
              : values[item?.key],
          };
        } else {
          return item;
        }
      });
      const payload = {
        ...(layout?.sellerLevelEditData?.id && {
          id: layout?.sellerLevelEditData?.id,
        }),
        title: values?.Seller_level_name || layout?.sellerLevelEditData?.title,
        weightage: values?.weightage || layout?.sellerLevelEditData?.weightage,
        config: config,
        benefits: values?.benefits?.filter((obj: any) => obj !== "") || [],
      };

      // ADD / EDIT SELLER LEVEL API CALLING
      if (layout?.sellerLevelEditData?.id) {
        await handleSellerLevelUpdate(true, payload);
      } else {
        await handleSellerLevelUpdate(false, payload);
      }
    },
  });

  const handleOnClose = () => {
    formik.resetForm();
    layout.setSellerLevelEditData({});
  };

  return (
    <div
      data-te-modal-init
      className={`offcanvas add_teammember fill_canvas offcanvas-end fixed bottom-0 top-[3.75rem] right-0 shadow-lg bg-clip-padding outline-none transition duration-300 ease-in-out font-medium  w-full upload-ticket-popup z-40`}
      tabIndex={-1}
      id="level_confi_popup"
      aria-labelledby="level_confi_popup_label"
    >
      <div className="h-full md:max-w-[43.125rem] max-w-full ml-auto">
        <div className="  bg-white shadow-3xl w-full h-full ml-auto relative flex flex-col">
          <form onSubmit={formik.handleSubmit} className="flex flex-col h-full">
            <div className="offcanvas-header flex justify-between border-t border-b">
              <h5
                className="offcanvas-title mb-0 leading-normal text-sm15 font-semibold py-2 px-5"
                id="offcanvasRightLabel"
              >
                {layout?.sellerLevelEditData?.id ? "Edit" : "Add"} level
              </h5>
              <div className="buttons flex flex-wrap">
                <button
                  type="button"
                  className="flex items-center justify-center w-9 border-l box-content group"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  onClick={handleOnClose}
                >
                  <KTSVG
                    className="fill-violet-500 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
                    path="standard_icons/cross.svg"
                    svgClassName="w-2.5 h-2.5"
                  />
                </button>
              </div>
            </div>

            <div className="offcanvas-body h-full w-full flex-row overflow-y-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full px-5 py-8 mb-1">
              <div className="address_fields flex flex-wrap -mx-2.5  inner-inp pb-10">
                <div className="fieldset w-full flex flex-wrap">
                  {/* USERNAME */}
                  <div className="w-full sm:w-1/2 md:w-1/2 mb-5 px-2.5 relative">
                    <div className="relative">
                      <TextInput
                        name="Seller_level_name"
                        type={"text"}
                        label="Seller level name"
                        id="Seller_level_name"
                        required={false}
                        className={`
                        !bg-white  `}
                        value={formik?.values?.Seller_level_name}
                        handleOnChange={formik?.handleChange}
                        // disabled={layout?.sellerLevelEditData?.title}
                        errorClass={
                          formik.touched?.Seller_level_name &&
                          formik.errors?.Seller_level_name &&
                          "border-rose-500"
                        }
                        handleClearValue={() =>
                          formik.setFieldValue("Seller_level_name", "")
                        }
                      />

                      {formik.touched.Seller_level_name &&
                        formik.errors.Seller_level_name && (
                          <div className="text-xs text-rose-500 error-msg">
                            {formik.errors.Seller_level_name as string}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="w-full sm:w-1/2 md:w-1/2 mb-5 px-2.5 relative">
                    <div className="relative">
                      <TextInput
                        name="weightage"
                        type={"number"}
                        label="Seller level weightage"
                        id="weightage"
                        required={false}
                        className={`!bg-white  `}
                        value={formik?.values?.weightage}
                        handleOnChange={formik?.handleChange}
                        // disabled={layout?.sellerLevelEditData?.title}
                        errorClass={
                          formik.touched?.weightage &&
                          formik.errors?.weightage &&
                          "border-rose-500"
                        }
                        handleClearValue={() =>
                          formik.setFieldValue("weightage", "")
                        }
                      />

                      {formik.touched.weightage && formik.errors.weightage && (
                        <div className="text-xs text-rose-500 error-msg">
                          {formik.errors.weightage as string}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="  px-2.5">
                  <div className="fieldset">
                    <h5 className="text-sm13 font-semibold mb-5">
                      Please fill in the configuration you wish to add at this
                      level
                    </h5>
                  </div>
                </div>
                <div className=" w-full flex flex-wrap hideddlabel">
                  {levelData?.map((obj: any, index: any) => (
                    <CommonDynamicComponent
                      index={index}
                      obj={obj}
                      OptionData={OptionData}
                      formik={formik}
                      ClassName={"max-md:flex-wrap w-full"}
                    />
                  ))}
                </div>
                <div className="  px-2.5 max-md:flex-wrap w-full flex items-center">
                  <div className="fieldset w-full md:w-1/3">
                    <p className="text-sm13">Benefits :</p>
                  </div>
                  <div className="flex w-full items-center gap-2 flex-col ">
                    {formik?.values?.benefits?.map((obj: any, index: any) => {
                      const isLastIndex =
                        index === formik.values.benefits.length - 1;
                      return (
                        <div className="flex w-full items-center gap-2 mb-3 relative">
                          <TextInput
                            name={`benefits[${index}`}
                            type={"string"}
                            placeholder="Benefits"
                            id={`benefits-${index}`}
                            required={false}
                            className={`!bg-white  `}
                            value={formik.values.benefits?.[index]}
                            handleOnChange={formik?.handleChange}
                            errorClass={
                              formik.touched?.benefits?.[index] &&
                              formik.errors?.benefits?.[index] &&
                              "border-rose-500"
                            }
                            handleClearValue={() =>
                              formik.setFieldValue(`benefits[${index}`, "")
                            }
                          />
                          <div className="flex gap-2">
                            <button
                              type="button"
                              className={`${
                                !isLastIndex &&
                                "!bg-gray-500 pointer-events-none"
                              }   group inline-flex group items-center bg-violet-500 hover:bg-indigo-500 text-white   transition font-medium rounded text-sm13 px-2.5 py-0.5 false h-7 `}
                              onClick={() => {
                                formik.setFieldValue("benefits", [
                                  ...formik.values.benefits,
                                  "",
                                ]);
                              }}
                            >
                              <IKTSVG
                                className={`min-w-[0.75rem] max-w-[0.75rem] mr-1 flex items-center justify-center  
                            fill-violet-500 group-hover:fill-white
                        `}
                                path={AddSVG}
                                svgClassName={`fill-violet-500`}
                              />
                              ADD
                            </button>

                            <button
                              className={`${
                                index === 0 &&
                                formik.values.benefits.length === 1 &&
                                "!bg-gray-500 pointer-events-none"
                              }   group inline-flex group items-center justify-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 gap-2 p-1 w-7 h-7  `}
                              type="button"
                              onClick={() => {
                                const newBenefits = [...formik.values.benefits];
                                newBenefits.splice(index, 1);
                                formik.setFieldValue("benefits", newBenefits);
                              }}
                            >
                              <Tooltip
                                anchorId={`delete-${index}`}
                                content={`${"Delete"}`}
                                place={`top`}
                                variant="light"
                                className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  `}
                              />

                              <IKTSVG
                                className=" flex items-center justify-center fill-white group-hover:fill-white"
                                path={DeleteSVG}
                                svgClassName="w-2.5 h-[.8125rem]"
                              />
                            </button>
                          </div>
                          {formik.touched.benefits?.[index] &&
                            formik.errors.benefits?.[index] && (
                              <div className="text-xs text-rose-500 error-msg">
                                {formik.errors.benefits[index] as string}
                              </div>
                            )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="fixed-bottom md:max-w-[43.125rem] max-w-full ml-auto   offcanvas-footer bottom-0 right-0 w-full flex justify-start px-5 py-3 bg-white z-10 border-t">
              <div className="justify-end w-full l_btn gap-2.5 flex flex-wrap items-center">
                <button
                  type="button"
                  id="close-btn"
                  className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
                  data-bs-dismiss="offcanvas"
                  onClick={handleOnClose}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="relative z-10 border border-green-600 active:text-white text-white hover:bg-indigo-500 hover:border-indigo-500 bg-green-600 active:bg-green-600 transition font-semibold rounded text-sm13 px-2.5 py-0.5 "
                >
                  {layout?.sellerLevelEditData?.id ? "Edit" : "Add"} level
                </button>
              </div>
            </div>
          </form>
        </div>
        {submitLoader && <ProgressBardLoader secondCounter={2} />}
      </div>
    </div>
  );
};

export default LevelConfigurationPopup;
