import { IKTSVG } from "components/ui/IKTSVG";

import TableBody from "components/fullPopup/TableBody";
import { KTSVG } from "components/ui/KTSVG";
import { SYMBOLS } from "helpers/const";
import { size } from "lodash";
import moment from "moment";
import { useEffect } from "react";
import MinusOutline from "../../../assets/media/icons/other_icons/minus-outline.svg";
import PlusOutLine from "../../../assets/media/icons/other_icons/plus-outline.svg";
import UploadOutOutline from "../../../assets/media/icons/other_icons/upload-out-outline.svg";
import UploadOutline from "../../../assets/media/icons/other_icons/upload-outline.svg";
import Cross from "../../../assets/media/icons/standard_icons/cross.svg";
import TableHeader from "../table/components/TableHeader";

const VirtualCardTranscationViewPopup = ({
  mainData,
  setMainData,
  isGodAdmin,
  list,
}: any) => {
  const handleOnClosePop = () => {
    display(false);
    setMainData({
      status: false,
      index: null,
      data: null,
    });
  };

  const display = (isOpen: any) => {
    var body = document.querySelector("body");
    var html = document.querySelector("html");
    var pop_width =
      document.getElementsByClassName("pay-detail")[0]?.clientWidth;
    if (isOpen) {
      // @ts-ignore
      document.getElementById("TXAccordion").style.marginRight = `${
        pop_width - 20
      }px`;
      // document.body.style.overflow = 'hidden';
      body?.classList.add("scrollbar-none");
      html?.classList.add("scrollbar-none");
    } else if (!isOpen) {
      // @ts-ignore
      document.getElementById("TXAccordion").style.marginRight = "0px";
      // document.body.style.overflow = 'unset';
      body?.classList.add("scrollbar-none");
      html?.classList.add("scrollbar-none");
    }
  };
  const NavigationButtons = ({
    handleNavigation,
    imgPath,
    className,
    id,
    disabled,
  }: any) => {
    return (
      <button
        className={`group relative p-1 md:p-1 w-6 h-6 rounded-l ${className} ${
          id === "next" && "rotate-180"
        } ${disabled ? "pointer-events-none " : ""}`}
        type="button"
        id={id}
        onClick={() => handleNavigation(id)}
      >
        <KTSVG
          className={`flex items-center justify-center ${
            disabled ? `fill-gray-400` : `fill-violet-500`
          } transition group-hover:fill-indigo-500`}
          path={imgPath}
          svgClassName="w-1.5 h-[.5625rem]"
        />
      </button>
    );
  };

  const handleNavigation = (data: any) => {
    let newIndex = mainData?.index;

    if (data === "back") {
      newIndex = newIndex === 0 ? list.length - 1 : newIndex - 1;
    } else {
      newIndex = newIndex === list.length - 1 ? 0 : newIndex + 1;
    }

    const preRecord = list[newIndex];

    setMainData({
      status: true,
      data: preRecord,
      index: newIndex,
    });
  };

  useEffect(() => {
    if (mainData?.status) {
      display(mainData?.status);
    }
  }, [mainData]);

  return (
    <div
      className={` ${
        mainData?.status && "show"
      }  offcanvas upload-ticket-popup pay-detail offcanvas-end  fixed bottom-0 top-[3.75rem] right-0 shadow-3xl bg-clip-padding outline-none transition duration-300 ease-in-out font-medium md:max-w-[45.25rem] max-w-full w-full `}
      tabIndex={-1}
      id="pooopip"
      //   aria-labelledby="offcanvasTransferLabel"
      // data-bs-backdrop="false"
    >
      <div className="relative   flex flex-col h-full bg-white ml-auto">
        <div className="offcanvas-header flex justify-between border-t border-b">
          <h5 className="offcanvas-title mb-0 leading-normal font-semibold py-2 px-5 text-sm15">
            {mainData?.data?.description}
          </h5>
          <button
            type="button"
            className="flex items-center justify-center h-10 w-10 border-l box-content group"
            onClick={handleOnClosePop}
          >
            <IKTSVG
              className="fill-violet-500   group-hover:fill-indigo-500 transition"
              path={Cross}
              svgClassName="w-[.6875rem] h-[.6875rem]"
            />
          </button>
        </div>

        <div className="offcanvas-body max-h-full w-full flex flex-col overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full p-5 h-full">
          <div className="card_options">
            <TableBody
              border="border"
              renderArray={mainData?.data?.subTransaction}
            >
              <thead className="text-xs text-gray-400">
                <tr className="border-b bg-white">
                  {/* Team name */}
                  {isGodAdmin && (
                    <>
                      <TableHeader
                        title="Team name"
                        cellWidth="min-w-[11.25rem]"
                        isArrow={false}
                      />
                      <TableHeader
                        title="Card number"
                        cellWidth="min-w-[11.25rem]"
                        isArrow={false}
                      />
                    </>
                  )}

                  {/* Date */}
                  <TableHeader
                    title="Date"
                    cellWidth="min-w-[7.5rem]"
                    isArrow={false}
                    postKey="date"
                  />

                  {/* Type */}
                  <TableHeader
                    title="Type"
                    cellWidth="min-w-[6.25rem]"
                    isArrow={false}
                    postKey="type"
                  />

                  {/* In */}
                  <TableHeader
                    title="In"
                    cellWidth="min-w-[9.375rem]"
                    isArrow={false}
                    postKey="in"
                  />

                  {/* Out */}
                  <TableHeader
                    title="Out"
                    cellWidth="min-w-[9.375rem]"
                    isArrow={false}
                    postKey="out"
                  />

                  {/* Description */}
                  <TableHeader
                    title="Description"
                    cellWidth="lg:w-full w-[20rem] max-lg:max-w-[20rem] max-lg:min-w-[20rem]"
                  />
                  {/* <th className="sticky right-0 ml-auto  p-0 w-[2.5625rem]">
                    <div className="flex justify-end h-[2.5rem]  bg-white w-[2.5625rem]"></div>
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {mainData?.data?.subTransaction &&
                  size(mainData?.data?.subTransaction) > 0 &&
                  mainData?.data?.subTransaction?.map(
                    (list: any, index: number) => {
                      const date = moment(list?.date, "DD/MM/YYYY");
                      const formatedDate = date?.format("DD MMM YYYY");
                      return (
                        <tr className="border-b">
                          {isGodAdmin && (
                            <>
                              <td
                                style={{ height: "2.5625rem" }}
                                className="py-1.5 px-2.5 font-medium whitespace-nowrap ellips-text"
                              >
                                <p className="w-[9.0625rem]">
                                  {list?.team_name ? `${list?.team_name}` : "-"}
                                </p>
                              </td>
                              <td
                                style={{ height: "2.5625rem" }}
                                className="py-1.5 px-2.5 font-medium whitespace-nowrap ellips-text"
                              >
                                <p className="w-[9.0625rem]">
                                  {list?.card_number
                                    ? `${list?.card_number}`
                                    : "-"}
                                </p>
                              </td>
                            </>
                          )}
                          <td
                            style={{ height: "2.5625rem" }}
                            className="py-1.5 px-2.5 font-medium whitespace-nowrap ellips-text"
                          >
                            <p className="w-[6.25rem]">{formatedDate}</p>
                          </td>
                          <td
                            style={{ height: "2.5625rem" }}
                            className="py-1.5 px-2.5 font-medium whitespace-nowrap"
                          >
                            <div className="credit flex items-center">
                              <IKTSVG
                                className="flex items-center justify-center fill-violet-800 transition w-[12px] h-[12px] mr-1"
                                path={
                                  list?.type === "Credit"
                                    ? PlusOutLine
                                    : list?.type === "Debit"
                                    ? MinusOutline
                                    : list?.in
                                    ? UploadOutline
                                    : UploadOutOutline
                                }
                                svgClassName="w-3 h-3"
                              />
                              {list?.type}
                            </div>
                          </td>
                          <td
                            style={{ height: "2.5625rem" }}
                            className="py-1.5 px-2.5 font-medium whitespace-nowrap text-sm"
                          >
                            {list.in
                              ? `${SYMBOLS?.[list?.currency]}${list.in}`
                              : "-"}
                          </td>
                          <td
                            style={{ height: "2.5625rem" }}
                            className="py-1.5 px-2.5 font-medium whitespace-nowrap text-sm"
                          >
                            {list.out
                              ? `${SYMBOLS?.[list?.currency]}${list.out}`
                              : "-"}
                          </td>
                          <td
                            style={{ height: "2.5625rem" }}
                            className="py-1.5 px-2.5 font-medium ellips-text"
                          >
                            <p
                              className=" whitespace-nowrap text-ellipsis md:max-w-[31.25rem] max-w-[18.75rem]"
                              title={list.description}
                            >
                              {list.description ? `${list.description}` : "-"}
                            </p>
                          </td>
                        </tr>
                      );
                    }
                  )}
              </tbody>
            </TableBody>
          </div>
        </div>
        <div className="offcanvas-footer border-t py-3 px-5 false text-right">
          <div className="r_btn gap-2.5 flex flex-wrap items-center">
            <div className="pop_nav flex items-center bg-gray-100 rounded">
              {/* BACK BUTTON */}
              <NavigationButtons
                id="back"
                imgPath="standard_icons/angle_left.svg"
                handleNavigation={(data: any) => handleNavigation(data)}
                disabled={mainData?.index === 0}
                svgClassName={mainData?.index === 0 ? "!fill-gray-400" : "enn"}
              />
              {/* NEXT BUTTON */}
              <NavigationButtons
                id="next"
                imgPath="standard_icons/angle_left.svg"
                handleNavigation={(data: any) => handleNavigation(data)}
                disabled={list.length - 1 === mainData?.index}
                svgClassName={
                  list.length - 1 === mainData?.index ? "!fill-gray-400" : "enn"
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VirtualCardTranscationViewPopup;
