import { size } from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { TableVirtuoso } from "react-virtuoso";
import angleLeftSVG from "../../../../assets/media/icons/standard_icons/angle_left.svg";
import angleRightSVG from "../../../../assets/media/icons/standard_icons/angle_right.svg";
import cross from "../../../../assets/media/icons/standard_icons/cross.svg";
import { LayoutContext } from "../../../../components/core/LayoutProvider";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import ProgressBardLoader from "../../../../components/ui/ProgressBardLoader";
import { addCommaInNumber } from "../../../../helpers/Functions";
import { showAlert } from "../../../../helpers/ShowAlert";
import {
  SYMBOLS,
  TX_ACCOUNT_INVOICE_VIEW_DATA,
} from "../../../../helpers/const";
import TooltipPortal from "../../../tx_trade/table/component/TooltipPortal";
import HeaderCell from "../../Table/component/HeaderCell";
import TableRow from "../../Table/component/TableRow";
import { TxAccountContext } from "../../core/TxAccountProvider";
import {
  downloadPDF,
  invoiceViewAPI,
  saveInvoiceCommission,
} from "../../core/_request";
import CommissionPDFView from "../CommissionPDFView";
import DropdownViewPopup from "../DropdownViewPopup";
import NoDataAvailable from "../NoDataAvailable";
import PDFView from "../PDFView";
import PopupDetailBox from "../PopupDetailBox";
import TableShimmer from "../TableShimmer";
import DraftInvoiceItemContent from "./DraftInvoiceItemContent";
import { MultiExportToCsv } from "./MultiExportToCsv/MultiExportToCsv";
import RemoveOrders from "./RemoveOrders";

const Table = ({ style, ...props }: any) => (
  <table
    {...props}
    className="w-full text-xs text-left  min-w-[87.5rem]"
    style={{
      ...style,
      //width: 700
    }}
  />
);
const TableHead = ({ style, ...props }: any) => (
  <thead
    {...props}
    className="text-xs text-gray-400 "
    style={{
      ...style,
      // position: "static",
    }}
  />
);

const DraftViewInvoice = React.memo(() => {
  const layout = useContext(TxAccountContext);
  const globalLayout = useContext(LayoutContext);
  let invoiceView = layout.invoicesViewData;
  const { data, meta, id } = invoiceView;
  let isDisabled = data?.invoice_status !== "Draft" ? true : false;
  const [PDFToggle, setPDFToggle] = useState<string>("");
  const [loading, setIsLoading] = useState<any>("");
  const [exportLoader, setExportLoader] = useState<boolean>(false);
  const [isCommissionLoading, setIsCommissionLoading] =
    useState<boolean>(false);

  let excludeOrderInitial = {
    removeOrder: "",
    invoice_id: id,
    order_ids: [],
    allOrderIds: [],
  };
  const [state, setState] = useState<any>(excludeOrderInitial);

  const scroll = (scrollOffset: any) => {
    let replaceTable: any = document.getElementById("invoice-table-lazyload");
    replaceTable && (replaceTable.scrollLeft += scrollOffset);
  };

  // PDF HANDLE
  const handlePDFDownload = async (data: any, type: string, name: string) => {
    layout.setCustomLoader(true);
    const response = await downloadPDF(data).then((response: any) => {
      return new Blob([response], { type: "application/pdf" });
    });

    const url = window.URL.createObjectURL(response);

    if (type === "view") {
      setPDFToggle(url);
      window.open(url, "_blank");
      layout.setCustomLoader(false);
    } else {
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.setAttribute("download", name);
      anchor.click();
      window.URL.revokeObjectURL(url);
      layout.setCustomLoader(false);
    }
  };

  let { order_by, sort_order } = layout.invoicesFilter;

  const fetchMoreData = async (data: any, isSort: boolean) => {
    try {
      const response = await invoiceViewAPI({
        ...data,
        id,
      });
      if (response) {
        setIsLoading("");
        if (response?.data && size(response?.data) > 0) {
          layout.setInvoicesViewData((pre: any) => ({
            ...pre,
            data: {
              ...pre.data,
              ...response?.data,
              orders: isSort
                ? response?.data?.orders
                : [...pre.data.orders, ...response?.data?.orders],
            },
            meta: response?.meta,
          }));
        } else if (response?.errors) {
          if ("title" in response?.errors) {
            showAlert(response?.errors?.title, true);
          } else {
            showAlert("Something went wrong", true);
          }
        }
      }
    } catch (error) {
      // Handle error here if needed
      console.error("Error fetching more data:", error);
      throw error; // Rethrow the error to be caught by the caller
    }
  };

  useEffect(() => {
    if (order_by) {
      setIsLoading("sorting");
      fetchMoreData({ page: 1, per_page: 20, order_by, sort_order }, true);
    }
  }, [order_by, sort_order]);

  const [height, setHeight] = useState<any | undefined>(undefined);
  // let timeout: any;
  // const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  // const renderCall = () => {
  //   timeout = setTimeout(() => {
  //     const divElement: any = document.querySelector("#invoice-table-lazyload");
  //     const thead = document.querySelector("#tableId11 table thead");
  //     const theadHeight: any = thead?.scrollHeight;

  //     const dataHeight = theadHeight + data?.orders?.length * 45;
  //     console.log(dataHeight, data?.orders?.length, "dataHeight");

  //     // setTimeout(() => {
  //     //   nomoreListingHeight = nomoreListing?.clientHeight;
  //     // }, 2000);
  //     if (divElement && isMobile) {
  //       if (divElement.offsetHeight) {
  //         console.log("object");
  //         let elemHeight = dataHeight;
  //         setHeight(elemHeight);
  //       } else {
  //         setTimeout(renderCall, 100);
  //       }
  //     }
  //   }, 1000);
  // };
  // useLayoutEffect(() => {
  //   renderCall();
  //   window.addEventListener("scroll", renderCall);
  //   // Clear the timeout in case the component unmounts or the effect re-runs
  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, []);

  useEffect(() => {
    const thead = document.querySelector("#tableId11 table thead");
    const theadHeight: any = thead?.scrollHeight;

    const dataHeight = theadHeight + data?.orders?.length * 45;
    setHeight(dataHeight);
  });

  const createCommissionInvoice = (e: any) => {
    e.preventDefault();
    setIsCommissionLoading(true);
    saveInvoiceCommission(data?.id).then((response: any) => {
      if (response?.data?.status) {
        fetchMoreData({ page: 1, per_page: 20, order_by, sort_order }, true);
        setIsCommissionLoading(false);
      } else if (size(response?.errors) > 0) {
        showAlert(response?.errors?.[0], true);
        setIsCommissionLoading(false);
      } else {
        showAlert("Something went wrong", true);
        setIsCommissionLoading(false);
      }
    });
  };

  return (
    <div className="main-content-wrap bg-white">
      <div className="inner_wrap flex flex-col lg2:h-[calc(100vh-3.8125rem)]">
        <div className="offcanvas-header flex justify-between border-b">
          <h5
            className="offcanvas-title mb-0 leading-normal text-sm15 font-semibold py-2 pr-5 pl-[1.875rem]"
            id="offcanvasRightLabel"
          >
            {data?.invoice_status === "Draft" ? "Draft invoice" : "Invoice"} :{" "}
            {data?.invoice_number}
          </h5>

          {/* CLOSE DRAFT */}
          <button
            type="button"
            className="flex items-center justify-center w-9 border-l box-content group"
            data-tooltip-id={`close-draft`}
            onClick={() => {
              layout?.setInvoicesViewData(TX_ACCOUNT_INVOICE_VIEW_DATA);
              setState(excludeOrderInitial);
              layout?.setInvoicesFilter((current: any) => {
                return {
                  ...current,
                  page: 1,
                  per_page: 20,
                  order_by: "",
                  sort_order: "asc",
                };
              });
            }}
          >
            <TooltipPortal
              id={`close-draft`}
              content={"Close"}
              place="left"
              className="text-center  !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-100 max-w-[9rem] cursor-default "
            />
            <IKTSVG
              path={cross}
              className="fill-violet-500 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
              svgClassName="w-2.5 h-2.5"
            />
          </button>
        </div>
        <div className="offcanvas-body max-h-full w-full flex flex-col overflow-hidden h-full">
          <div className="inner_body flex flex-wrap h-full">
            {/* LEFT SIDE - TABLE */}
            <div className="left_body border-r lg2:w-1/2 w-full h-full scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full overflow-auto p-5 flex flex-col">
              <div className="totle_order mb-5">
                <h5 className="font-semibold text-sm14 leading-4">
                  {data?.total_orders
                    ? addCommaInNumber(data?.total_orders)
                    : "0"}{" "}
                  orders
                </h5>
              </div>
              {data?.orders?.length === 0 ? (
                <NoDataAvailable />
              ) : (
                <div className="left_table border h-full rounded overflow-hidden">
                  {loading === "sorting" ? (
                    <TableShimmer loader={loading ? true : false} />
                  ) : (
                    <div className="accordion-body relative h-full">
                      <div
                        className={`order-replacement-table  overflow-auto scrollbar-thin scrollbar-thumb-violet-800
         scrollbar-thumb-rounded-full hover-style2 md:!h-full invoice-table`}
                        id={`tableId11`}
                        // style={{ height: {`isMobile ? "${height}px" : ""} }}
                        style={{
                          height: globalLayout?.isMobile ? `${height}px` : "",
                        }}
                      >
                        <TableVirtuoso
                          data={data?.orders}
                          height={data?.orders?.length + "px"}
                          endReached={() => {
                            if (
                              meta?.last_page > meta?.current_page &&
                              !loading
                            ) {
                              let nextPage: number = meta?.current_page + 1;
                              setIsLoading("lazyLoad");
                              if (nextPage) {
                                fetchMoreData(
                                  { page: nextPage, per_page: 20 },
                                  false
                                );
                              }
                            }
                          }}
                          overscan={200}
                          totalCount={data?.orders?.length}
                          // atBottomThreshold={100}
                          id={"invoice-table-lazyload"}
                          components={{
                            Table: Table,
                            TableHead: TableHead,
                            TableRow: (props) => {
                              return (
                                <TableRow
                                  {...props}
                                  excludeOrders={state?.order_ids}
                                />
                              );
                            },
                            TableFoot: (props) => {
                              if (
                                data?.orders?.length === meta?.total &&
                                meta?.current_page > 1
                              ) {
                                return (
                                  <tfoot
                                    className={`text-center text-sm13 py-3 px-2.5 bg-white border-t`}
                                    id={`NoMoreListing`}
                                  >
                                    <tr className="border-b bg-white">
                                      <td
                                        colSpan={11}
                                        align="center"
                                        className="h-[2.8125rem]"
                                      >
                                        No more items to show
                                      </td>
                                    </tr>
                                  </tfoot>
                                );
                              } else {
                                return null;
                              }
                            },
                          }}
                          fixedHeaderContent={() => (
                            <tr className="border-b bg-white">
                              <HeaderCell
                                title="Team"
                                width="min-w-[7.5rem]"
                                arrow={true}
                                postKey="name"
                                padding="pl-4"
                              />

                              <HeaderCell
                                title="Team member"
                                width="min-w-[7.5rem]"
                                arrow={true}
                                postKey="username"
                              />

                              <HeaderCell
                                title="Marketplace"
                                width="min-w-[7.5rem]"
                                arrow={true}
                                postKey="exchange_title"
                              />

                              <HeaderCell
                                title="Order ID"
                                width="min-w-[7.5rem]"
                                arrow={true}
                                postKey="order_id"
                              />

                              <HeaderCell
                                title="Marketplace ID"
                                width="min-w-[7.5rem]"
                                arrow={true}
                                postKey="order_id"
                              />

                              <HeaderCell
                                title={"Order date"}
                                width="min-w-[7.5rem]"
                                arrow={true}
                                postKey="order_date_time"
                              />

                              <HeaderCell
                                title="Proceed price"
                                width="min-w-[7.5rem]"
                                arrow={true}
                                postKey="transaction_value"
                              />

                              <HeaderCell
                                // title="Order value"
                                title="Marketplace …	"
                                width="min-w-[7.5rem]"
                                arrow={false}
                                postKey="transaction_value"
                              />

                              <HeaderCell
                                title="Seller payout"
                                width="min-w-[7.5rem]"
                                arrow={false}
                                postKey="tixstock_revenue"
                              />

                              <HeaderCell
                                title="Tixstock rev …"
                                width="min-w-[7.5rem]"
                                arrow={false}
                                postKey="event_description"
                              />

                              <HeaderCell
                                title="Event"
                                width="min-w-[14.375rem]"
                                arrow={true}
                                postKey="event_description"
                              />

                              <HeaderCell
                                title="Venue"
                                width="min-w-[10.625rem]"
                                arrow={true}
                                postKey="event_venue"
                              />

                              <HeaderCell
                                title="Event date"
                                width="min-w-[7.5rem]"
                                arrow={true}
                                postKey="event_date"
                              />

                              <HeaderCell
                                title="Ticket details"
                                width="min-w-[10.625rem]"
                                arrow={true}
                                postKey="ticket_section"
                              />

                              <HeaderCell
                                title="Qty"
                                width="min-w-[3.4375rem]"
                                arrow={true}
                                postKey="ticket_quantity"
                              />

                              <HeaderCell
                                title="Ticket type"
                                width="min-w-[7.5rem]"
                                arrow={true}
                                postKey="ticket_type"
                              />

                              <HeaderCell
                                title="Order status	"
                                width="min-w-[7.5rem]"
                                arrow={true}
                                postKey="order_status"
                              />

                              <HeaderCell
                                title="Internal orde …"
                                width="min-w-[7.5rem]"
                                arrow={false}
                                postKey="paid"
                              />

                              <HeaderCell
                                title="Payment status"
                                width="min-w-[7.5rem]"
                                arrow={true}
                                postKey="paid"
                              />

                              <th className="md:sticky max-md:hidden relative right-0 ml-auto  p-0 w-[5.0625rem]">
                                <div
                                  className="py-[.4375rem] px-4 flex justify-end h-[2.5rem] left-shad border-l bg-white "
                                  id="left-shad-list"
                                >
                                  <div className="arrow_buttons flex rounded ">
                                    <button
                                      className="group rounded-l  transition px-[.5625rem] py-2"
                                      type="button"
                                      onClick={(e: any) => scroll(-120)}
                                    >
                                      <IKTSVG
                                        className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                                        path={angleLeftSVG}
                                        svgClassName="w-1.5 h-[.5625rem]"
                                      />
                                    </button>
                                    <button
                                      className="group rounded-r  transition px-[.5625rem] py-2"
                                      type="button"
                                      onClick={(e: any) => scroll(120)}
                                    >
                                      <IKTSVG
                                        className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                                        path={angleRightSVG}
                                        svgClassName="w-1.5 h-[.5625rem]"
                                      />
                                    </button>
                                  </div>
                                </div>
                              </th>
                            </tr>
                          )}
                          itemContent={(sIndex: any, list: any) => {
                            return (
                              <DraftInvoiceItemContent
                                sIndex={sIndex}
                                list={list}
                              />
                            );
                          }}
                          // useWindowScroll={isMobile}
                        />
                      </div>
                      {loading === "lazyLoad" && (
                        <div
                          id={`child2`}
                          className={`order-[10000000] m-1 z-99999999 draft_invoice_lazyload
    `}
                        >
                          <div className="bg-gray-100 rounded mr-1 mb-2 w-full shimmer-effect append-child h-[1.875rem] !overflow-hidden"></div>
                          <div className="bg-gray-100 rounded mr-1 mt-1 mb-2 w-full shimmer-effect append-child h-[1.875rem]  !overflow-hidden"></div>
                          {/* <div className="bg-gray-100 rounded mr-1 mb-2 w-full shimmer-effect append-child h-[1.875rem]  !overflow-hidden"></div> */}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>

            {/* RIGHT SIDE -  DETAILS */}
            <div className="right_body border-r lg2:w-1/2 w-full h-full scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full overflow-auto p-5">
              <div className="totle_order mb-5 flex justify-between">
                <h5 className="font-semibold text-sm14 leading-4">
                  Invoice details
                </h5>
              </div>
              <div className="right_block ">
                <div className=" after_border flex flex-wrap pb-5 -mx-2.5">
                  <PopupDetailBox
                    label="Invoice status"
                    headerValue={
                      data?.invoice_status ? data?.invoice_status : "-"
                    }
                    isDisabled={data?.invoice_status === "Draft" ? true : false}
                    disabledColor={
                      data?.invoice_status === "Awaiting Payment"
                        ? "danger"
                        : data?.invoice_status === "Paid"
                        ? "success"
                        : ""
                    }
                    successValues={data?.payment_date}
                  />
                </div>
                <div className={`flex flex-wrap gap-y-5 pt-5 -mx-2.5`}>
                  {/* MARKETPLACE */}
                  <PopupDetailBox
                    label="Marketplace"
                    headerValue={data?.marketplace_name}
                    isDisabled={isDisabled}
                  />

                  {/* TEAM DROPDOWN */}
                  <DropdownViewPopup
                    isDisabled={isDisabled}
                    options={data?.teams}
                    value={data?.total_teams_count}
                  />

                  {/* EVENT DATE */}
                  <PopupDetailBox
                    label="Event date"
                    headerValue={`${
                      data?.min_event_date
                        ? moment(data?.min_event_date).format("DD/MM/YYYY") +
                          " - "
                        : "N/A"
                    } ${
                      data?.max_event_date
                        ? moment(data?.max_event_date).format("DD/MM/YYYY")
                        : ""
                    }`}
                    isDisabled={isDisabled}
                  />

                  {/* ORDER STATUS */}
                  <PopupDetailBox
                    label="Order status"
                    headerValue={data?.order_status}
                    isDisabled={isDisabled}
                  />

                  {/* ORDERS */}
                  <PopupDetailBox
                    label="Orders"
                    headerValue={data?.total_orders}
                    countValue={""}
                    isDisabled={isDisabled}
                  />

                  {/* INVOICE VALUE */}
                  <PopupDetailBox
                    label="Invoice value"
                    headerValue={`${SYMBOLS[data?.currency]}${addCommaInNumber(
                      data?.invoice_value
                    )}`}
                    countValue=""
                    isDisabled={isDisabled}
                  />
                </div>
              </div>

              {/* REMOVE ORDERS */}
              {data?.total_orders > 1 && data?.invoice_status !== "Paid" && (
                <RemoveOrders
                  state={state}
                  setState={setState}
                  executeListAPI={() =>
                    fetchMoreData({ page: 1, per_page: 20 }, true)
                  }
                />
              )}

              {/* PDF COMPONENT */}
              <PDFView
                fileName={`tixstock-invoice-${
                  data?.invoice_number
                }-${data?.invoice_status
                  ?.toLowerCase()
                  .replace(/ /g, "-")}.pdf`}
                isLoading={false}
                draftKey={data?.xero_invoice_id}
                isDownloadBtn={true}
                downloadPDF={(data: any, type: string, name: string) => {
                  handlePDFDownload(data, type, name);
                }}
              />

              {/* Commission PDF COMPONENT */}
              <CommissionPDFView
                fileName={
                  data?.is_commission_invoice
                    ? `tixstock-commission-invoice-${
                        data?.invoice_number
                      }-${data?.invoice_status
                        ?.toLowerCase()
                        .replace(/ /g, "-")}.pdf`
                    : "Save commission invoice"
                }
                isLoading={isCommissionLoading}
                draftKey={data?.xero_commission_invoice_id}
                isDownloadBtn={data?.is_commission_invoice}
                downloadPDF={(data: any, type: string, name: string) => {
                  handlePDFDownload(data, type, name);
                }}
                saveCommissionInvoice={createCommissionInvoice}
                title={
                  data?.is_commission_invoice
                    ? "View commission invoice"
                    : "Commission invoice"
                }
                isGenerate={!data?.is_commission_invoice}
              />
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <footer className="sticky bottom-0 border-b flex md:flex-nowrap flex-wrap items-center justify-between px-5 py-1 bg-white border border-t z-20 min-h-[3.125rem]">
          <div className="flex flex-wrap items-center justify-between gap-2.5 my-2 w-full">
            <MultiExportToCsv />
          </div>
        </footer>
      </div>

      {/* LOADER */}
      {layout?.customLoader && <ProgressBardLoader secondCounter={2.5} />}
    </div>
  );
});

export default DraftViewInvoice;
