export const SELLER_LEVEL_KEY: any = {
  LEVEL3: "Level 3",
  LEVEL2: "Level 2",
  LEVEL1: "Level 1",
  SUPER_SELLER: "Super Seller",
};

export const NEXT_LEVEL_KEY: any = {
  [SELLER_LEVEL_KEY.LEVEL3]: SELLER_LEVEL_KEY.LEVEL2,
  [SELLER_LEVEL_KEY.LEVEL2]: SELLER_LEVEL_KEY.LEVEL1,
  [SELLER_LEVEL_KEY.LEVEL1]: SELLER_LEVEL_KEY.SUPER_SELLER,
  [SELLER_LEVEL_KEY.SUPER_SELLER]: SELLER_LEVEL_KEY.SUPER_SELLER,
};

export const ZERO_WIDTH_KEYS: any = {
  [SELLER_LEVEL_KEY.LEVEL3]: [
    SELLER_LEVEL_KEY.LEVEL2,
    SELLER_LEVEL_KEY.LEVEL1,
    SELLER_LEVEL_KEY.SUPER_SELLER,
  ],
  [SELLER_LEVEL_KEY.LEVEL2]: [
    SELLER_LEVEL_KEY.LEVEL1,
    SELLER_LEVEL_KEY.SUPER_SELLER,
  ],
  [SELLER_LEVEL_KEY.LEVEL1]: [SELLER_LEVEL_KEY.SUPER_SELLER],
  [SELLER_LEVEL_KEY.SUPER_SELLER]: [],
};

export const FULL_WIDTH_KEYS: any = {
  [SELLER_LEVEL_KEY.LEVEL3]: [],
  [SELLER_LEVEL_KEY.LEVEL2]: [SELLER_LEVEL_KEY.LEVEL3],
  [SELLER_LEVEL_KEY.LEVEL1]: [SELLER_LEVEL_KEY.LEVEL3, SELLER_LEVEL_KEY.LEVEL2],
  [SELLER_LEVEL_KEY.SUPER_SELLER]: [
    SELLER_LEVEL_KEY.LEVEL3,
    SELLER_LEVEL_KEY.LEVEL2,
    SELLER_LEVEL_KEY.LEVEL1,
  ],
};

export const PROGRESS_TITLE = {
  [SELLER_LEVEL_KEY.LEVEL3]: 3,
  [SELLER_LEVEL_KEY.LEVEL2]: 2,
  [SELLER_LEVEL_KEY.LEVEL1]: 1,
  [SELLER_LEVEL_KEY.SUPER_SELLER]: "S",
};

export const PROGRESS_COLOR = {
  [SELLER_LEVEL_KEY.LEVEL3]: {
    dark: "bg-green-600/30",
    light: "#00D900",
    medium: "bg-green-600",
  },
  [SELLER_LEVEL_KEY.LEVEL2]: {
    dark: "bg-green-600/30",
    light: "#00D900",
    medium: "bg-green-600",
  },
  [SELLER_LEVEL_KEY.LEVEL1]: {
    dark: "bg-green-600/30",
    light: "#00D900",
    medium: "bg-green-600",
  },
  [SELLER_LEVEL_KEY.SUPER_SELLER]: {
    dark: "bg-yellow-100/30",
    light: "#FFBD00",
    medium: "bg-yellow-100",
  },
};

export const SORT_ORDER = [
  SELLER_LEVEL_KEY.LEVEL3,
  SELLER_LEVEL_KEY.LEVEL2,
  SELLER_LEVEL_KEY.LEVEL1,
  SELLER_LEVEL_KEY.SUPER_SELLER,
];

// Define the quarters with their start and end months (0-based index)
export const quarterStartEndMonth: any = {
  1: { start: 0, end: 2 }, // Q1: January (0), February (1), March (2)
  2: { start: 3, end: 5 }, // Q2: April (3), May (4), June (5)
  3: { start: 6, end: 8 }, // Q3: July (6), August (7), September (8)
  4: { start: 9, end: 11 }, // Q4: October (9), November (10), December (11)
};

export const quartersMonthList: any = {
  1: [0, 1, 2], // Q1: January (0), February (1), March (2)
  2: [3, 4, 5], // Q2: April (3), May (4), June (5)
  3: [6, 7, 8], // Q3: July (6), August (7), September (8)
  4: [9, 10, 11], // Q4: October (9), November (10), December (11)
};

export const LEVEL_CHECK_COLOR = [
  {
    level: SELLER_LEVEL_KEY.LEVEL3,
    getBgColor: () => `bg-green-600 border-green-600`,
  },
  {
    level: SELLER_LEVEL_KEY.LEVEL2,
    getBgColor: (activeLevel: any) =>
      activeLevel
        ? `bg-green-600 border-green-600`
        : `bg-green-600/25 border-green-600/25`,
  },
  {
    level: SELLER_LEVEL_KEY.LEVEL1,
    getBgColor: (activeLevel: any) =>
      activeLevel
        ? `bg-green-600 border-green-600`
        : `bg-green-600/25 border-green-600/25`,
  },
  {
    level: SELLER_LEVEL_KEY.SUPER_SELLER,
    getBgColor: (activeLevel: any) =>
      activeLevel
        ? `bg-yellow-100 border-yellow-100`
        : `bg-yellow-100/25 border-yellow-100/25`,
  },
];
