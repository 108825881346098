import DatePickerField from "components/formComponent/DatePickerField";
import BlockContainer from "components/sidePopup/BlockContainer";
import HeaderDetail from "components/sidePopup/HeaderDetail";
import InputFieldWrapper from "components/sidePopup/InputFieldWrapper";
import { useFormik } from "formik";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import NavigationButtons from "../../../../../Sales/component/NavigationButtons";
import AccordionItem from "./AccordionItem";
// import angleLeftSVG from "../../../assets/media/icons/standard_icons/angle_left.svg";
import clsx from "clsx";
import { QUERIES } from "helpers/const";
import { decimalNumber, getCurrencySymbol } from "helpers/Functions";
import { showAlert } from "helpers/ShowAlert";
import { size } from "lodash";
import { PersonalDetailsContext } from "pages/subscribe/personal_details/core/PersonalDetailsProvider";
import {
  referralViewData,
  updateReferralAction,
} from "pages/subscribe/personal_details/core/requests";
import { useQuery } from "react-query";
import angleLeftSVG from "../../../../../../assets/media/icons/standard_icons/angle_left.svg";

const LabelInput = ({ label, value, halfWidth = false, loader }: any) => {
  return (
    <div
      className={clsx(
        "singleFOrmBLock w-full",
        loader && "shimmer-effect h-[1.875rem] md:!w-[calc(50%-0.4375rem)]",
        {
          "md:w-[calc(25%-0.4375rem)]": halfWidth,
          "md:w-[calc(50%-0.4375rem)]": !halfWidth,
        }
      )}
    >
      <label className="text-sm12 font-medium mb-[.3125rem] text-gray-500 block leading-4">
        {label}
      </label>
      <div
        className={`!h-[1.875rem] border-0 px-2.5 py-2 text-xs bg-gray-100/50 rounded ${
          !value && "text-gray-400"
        }`}
      >
        {value}
      </div>
    </div>
  );
};

const validationSchema = Yup.object().shape({
  endDate: Yup.date()
    .required("End date is required")
    .typeError("Must be a valid date")
    .test(
      "is-greater",
      "End date cannot be before the start date",
      function (value) {
        const { startDate } = this.parent;
        if (!startDate || !value) {
          return true;
        }
        const start = new Date(startDate);
        const end = new Date(value);

        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);
        return end >= start;
      }
    ),
});

const ReferralViewPopup = ({
  viewForm,
  handleClosePopup,
  refetch,
  setViewForm,
}: any) => {
  const { referralListings: orders, setReferralListings } = useContext(
    PersonalDetailsContext
  );
  const [formState, setFormState] = useState<any>({
    startDate: null,
    endDate: null,
  });
  const { referralDetails, referralEarnings } = viewForm?.data;

  // UPDATE THE EXPIRY DATE
  useEffect(() => {
    if (viewForm?.status) {
      setFormState({
        startDate: referralDetails?.referral_start_date
          ? new Date(referralDetails?.referral_start_date)
          : null,
        endDate: referralDetails?.referral_expiry_date
          ? new Date(referralDetails?.referral_expiry_date)
          : new Date(),
      });
    }
  }, [
    referralDetails?.referral_expiry_date,
    referralDetails?.referral_start_date,
    viewForm,
  ]);

  // GET THE DETAILS VIEW DATA
  const { isFetching: tableLoader } = useQuery(
    [QUERIES.SUBSCRIBE_REFERRAL_DETAILS, viewForm?.data?.referralDetails],
    () => referralViewData({ team_id: referralDetails?.referee_id }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      onSettled(data, error) {
        if (data?.data) {
          setViewForm((current: any) => ({
            ...current,
            data: {
              ...current?.data,
              referralEarnings: data?.data?.length
                ? data.data.map((item: any) => {
                    const currencySymbol = getCurrencySymbol(
                      item?.referral_currency
                    );
                    const formattedOrderDate = item?.order_date
                      ? moment(item.order_date).format("DD/MM/YYYY")
                      : null;

                    return {
                      orderDate: formattedOrderDate,
                      orderID: item?.order_id,
                      earnings: `${currencySymbol}${decimalNumber(
                        item?.referral_earning_value ?? "0"
                      )}`,
                      eventName: item?.event_name,
                      details: [
                        {
                          label: "Order value",
                          value: `${currencySymbol}${decimalNumber(
                            item?.total_order_value
                          )}`,
                        },
                        {
                          label: `Tixstock commission - ${item?.tixstock_commission}%`,
                          value: `${currencySymbol}${decimalNumber(
                            item?.tixstock_revenue
                          )}`,
                        },
                        {
                          label: `Referral value - ${item?.referral_margin}% of Tixstock commission`,
                          value: `${currencySymbol}${decimalNumber(
                            item?.referral_earning_value
                          )}`,
                        },
                      ],
                    };
                  })
                : [],
            },
          }));
        }
      },
    }
  );

  // FORMIk
  const formik: any = useFormik({
    initialValues: formState,
    validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values: any) => {
      const payload = {
        team_id: referralDetails?.referee_id,
        expiry_date: values?.endDate,
      };
      const response = await updateReferralAction(payload);

      if (response?.data && size(response?.data) > 0) {
        showAlert(response?.message, false);
        setReferralListings((current: any) => {
          const final = current?.map((item: any) => {
            if (
              item?.referee_id === referralDetails?.referee_id &&
              item?.referrer_id === referralDetails?.referrer_id
            ) {
              return { ...item, referral_expiry_date: payload?.expiry_date };
            }
            return item;
          });
          return final;
        });
      } else {
        showAlert(
          response?.errors?.title
            ? response?.errors?.title
            : Array.isArray(response?.errors)
            ? response?.errors?.join("<br/>")
            : "Something went wrong!",
          true
        );
      }
      setFormState((current: any) => {
        return {
          ...current,
          endDate: values?.endDate,
        };
      });
    },
  });

  // HANDLE NAVIGATION OF ORDER VIEW PAGE
  const handleNavigation = (nav: string) => {
    let newIndex = viewForm?.index;

    if (nav === "back") {
      newIndex = newIndex === 0 ? orders.length - 1 : newIndex - 1;
    } else {
      newIndex = newIndex === orders.length - 1 ? 0 : newIndex + 1;
    }
    const preRecord = orders[newIndex];
    setViewForm((current: any) => {
      return {
        ...current,
        data: {
          ...current?.data,
          referralDetails: preRecord,
        },
        index: newIndex,
      };
    });
  };

  return (
    <div
      className={`${
        viewForm?.status && "show"
      }  offcanvas buy_canvas s_ticket popupinput-style2 offcanvas-end  fixed bottom-0 top-[3.75rem] right-0 shadow-3xl bg-clip-padding outline-none transition duration-300 ease-in-out font-medium lg:max-w-[43.125rem] md:max-w-[calc(100%-3.75rem)] max-w-full w-full `}
      tabIndex={-1}
      id="singleTicket"
      aria-labelledby="singleTicketLabel"
    >
      <div className="bg-white border-t w-full h-full ml-auto flex flex-col">
        <HeaderDetail
          label={referralDetails?.referrer_name}
          labelClass={`capitalize`}
          title=""
          handleClosePopup={handleClosePopup}
        />

        {/* BODY */}
        <div className="p-5 h-full overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
          {/* REFERRAL DETAILS */}
          <BlockContainer
            title="Referral details"
            isLockIconNeeded={false}
            isTitleBorder={false}
          >
            <LabelInput
              label="Referrer team name"
              value={referralDetails?.referrer_name}
              loader={tableLoader}
            />
            <LabelInput
              label="Referred team name"
              value={referralDetails?.referee_name}
              loader={tableLoader}
            />
            <LabelInput
              label="Start date"
              value={
                referralDetails?.referral_start_date
                  ? moment(referralDetails?.referral_start_date).format(
                      "DD/MM/YYYY"
                    )
                  : "-"
              }
              loader={tableLoader}
            />

            <InputFieldWrapper
              label="End date"
              className={clsx(
                "relative referralPopUpDatePicker md:!w-[calc(50%-0.4375rem)] !px-0",
                tableLoader &&
                  "shimmer-effect h-[1.875rem] md:!w-[calc(50%-0.4375rem)] !px-0"
              )}
            >
              <DatePickerField
                name={`endDate`}
                placeholderMoveTop={false}
                height="!h-[1.875rem]"
                disabledKeyboardNavigation={false}
                value={formik?.values?.endDate}
                id={"endDate"}
                error={
                  formik?.touched?.endDate && formik?.errors?.endDate
                    ? "true"
                    : ""
                }
                isDateRange={false}
                // IsIconRequire={false}
                IconColor={true}
                iconWidth={`z-10 !bg-transparent group-[.dp-focused]:!bg-indigo-500`}
                handleOnChange={(data: any) => {
                  formik.handleChange(data);
                }}
                placeholder="DD/MM/YYYY"
                format="dd/MM/yyyy"
              />
              {formik?.touched?.endDate && formik?.errors?.endDate && (
                <div className="text-xs leading-[0.875rem] text-rose-500 error-msg left-[2.4375rem]">
                  {formik.errors?.endDate as string}
                </div>
              )}
            </InputFieldWrapper>
          </BlockContainer>

          {/* REFERRAL EARNINGS */}
          <BlockContainer title="Referral earnings" isLockIconNeeded={false}>
            {tableLoader ? (
              Array.from({ length: 7 }, (_v, i) => (
                <AccordionItem
                  key={i}
                  loader={tableLoader}
                  {...referralEarnings}
                />
              ))
            ) : referralEarnings && referralEarnings?.length > 0 ? (
              referralEarnings?.map((item: any, index: number) => (
                <AccordionItem key={index} {...item} />
              ))
            ) : (
              <div className="text-sm font-medium text-center p-2 bg-white rounded w-full">
                No data available
              </div>
            )}
          </BlockContainer>
        </div>

        {/* FOOTER */}
        <div className="w-full flex items-center justify-between px-5 py-3 bg-white z-10 border-t min-h-[3.1875rem]">
          <div className="arrow_buttons flex items-center bg-gray-100 rounded ">
            {/* {/ BACK BUTTON /} */}
            <NavigationButtons
              id="back"
              imgPath={angleLeftSVG}
              className={
                viewForm?.index === 0
                  ? "pointer-events-none"
                  : orders.length - 1 === viewForm?.index
              }
              svgClassName={
                viewForm?.index === 0
                  ? "!fill-gray-400"
                  : orders.length - 1 === viewForm?.index
              }
              handleNavigation={(data: any) => handleNavigation(data)}
              disabled={false}
            />
            {/* {/ NEXT BUTTON /} */}
            <NavigationButtons
              id="next"
              imgPath={angleLeftSVG}
              className={
                orders.length - 1 === viewForm?.index
                  ? "pointer-events-none"
                  : ""
              }
              svgClassName={
                orders.length - 1 === viewForm?.index ? "!fill-gray-400" : "enn"
              }
              handleNavigation={(data: any) => handleNavigation(data)}
              disabled={false}
            />
          </div>

          {/* SUBMIT BUTTONS */}
          <div className="btns flex flex-wrap items-center tx-pay-module gap-3.5">
            <button
              type="button"
              className="btn flex-1 text-sm13 leading-[1.375rem] font-medium px-2.5 !py-0"
              onClick={handleClosePopup}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="text-sm13 font-medium leading-[1.5rem] px-2.5 bg-green-600 hover:bg-indigo-500 !text-white rounded border-hidden hover:border-hidden flex"
              onClick={() => formik.handleSubmit()}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralViewPopup;
