import _ from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import Cross from "../../../assets/media/icons/standard_icons/cross.svg";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import DropDownField from "../../../components/formComponent/DropDownField";
import PriceField from "../../../components/formComponent/PriceField";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import ProgressBardLoader from "../../../components/ui/ProgressBardLoader";
import { decimalNumber, errorReflect } from "../../../helpers/Functions";
import { showAlert } from "../../../helpers/ShowAlert";
import { SYMBOLS } from "../../../helpers/const";
import { isCardPending } from "../core/_functions";
import { postVcMoneyTrasnferData } from "../core/requests";

const handleTransferMoney =
  (
    formData: any,
    setTransferData: Function,
    setSubmitLoader: Function,
    setVirtualCardList: Function,
    refetch: Function
  ) =>
  async () => {
    setSubmitLoader(true);
    const convertedData = {
      card_id: formData?.to?.id,
      amount: formData?.amount?.value,
      currency: formData?.from?.base_currency,
    };

    await postVcMoneyTrasnferData(convertedData)
      .then((res) => {
        if (res?.data?.status) {
          showAlert(res?.data?.message, false);
          setTransferData({});
          let btn: any = document.getElementById("close-btn");
          btn.click();

          setVirtualCardList((prevCards: any) =>
            prevCards.map((card: any) =>
              card?.id === formData?.to?.id
                ? { ...card, active: true }
                : { ...card, active: false }
            )
          );
          refetch();
        } else {
          errorReflect(res?.errors ? res : res?.data);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      })
      .finally(() => setSubmitLoader(false));
  };

const TransferPopup = ({
  data,
  options,
  virtualCardList,
  setVirtualCardList,
  refetch,
  activeVirtualCard,
}: any) => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const [transferData, setTransferData] = useState<any>({});
  const coreLayout = useContext(LayoutContext);

  const handleOnChange = (e: any) => {
    const newData = {
      ...transferData,
      [e?.target?.name]: e?.target?.value,
    };
    setTransferData(newData);
  };

  const from_option = options?.from_options?.map((obj: any) => {
    return {
      ...obj,
      name: obj?.title,
      label: obj?.title,
      value: obj?.title,
    };
  });
  const to_options = virtualCardList
    ?.filter((card: any) => !isCardPending(card) && card.status !== "Inactive")
    .map((obj: any) => {
      return {
        ...obj,
        name: obj?.cardName,
        label: obj?.cardName,
        value: obj?.cardName,
      };
    });

  const fromAvailableBalance = useMemo(
    () =>
      transferData?.from
        ? SYMBOLS[transferData?.from?.base_currency] +
          decimalNumber(transferData?.from?.available_amount)
        : "0",
    [transferData?.from]
  );

  const toAvailableBalance = useMemo(
    () =>
      transferData?.to
        ? transferData?.to?.currency +
          decimalNumber(transferData?.to?.totalBalance)
        : "0",
    [transferData?.to]
  );

  const amountError = useMemo(
    () =>
      _.gt(transferData?.amount?.value, transferData?.from?.available_amount)
        ? "Amount must not be greater than from wallet balance"
        : "",
    [transferData?.amount, transferData?.from]
  );

  const isDisabledBtn = useMemo(
    () =>
      _.size(transferData?.to) === 0 ||
      _.size(transferData?.from) === 0 ||
      _.size(transferData?.amount) === 0 ||
      _.gt(transferData?.amount?.value, transferData?.from?.available_amount) ||
      transferData?.amount?.value <= 0 ||
      submitLoader,
    [submitLoader, transferData?.amount, transferData?.from, transferData?.to]
  );

  useEffect(() => {
    if (activeVirtualCard?.id) {
      let toInitialValue = {
        ...activeVirtualCard,
        name: activeVirtualCard?.cardName,
        label: activeVirtualCard?.cardName,
        value: activeVirtualCard?.id,
      };
      setTransferData((prev: any) => ({
        ...prev,
        to: toInitialValue,
        from: from_option?.[0],
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVirtualCard]);

  return (
    <div
      className="offcanvas upload-ticket-popup offcanvas-end  fixed bottom-0 top-[3.75rem] right-0 shadow-3xl bg-clip-padding outline-none transition duration-300 ease-in-out font-medium md:max-w-[45.25rem] max-w-full w-full "
      tabIndex={-1}
      id="offcanvasTransfer"
      aria-labelledby="offcanvasTransferLabel"
      // data-bs-backdrop="false"
    >
      <div className="relative   flex flex-col h-full bg-white ml-auto">
        <div className="offcanvas-header flex justify-between border-t border-b">
          <h5 className="offcanvas-title mb-0 leading-normal font-semibold py-2 px-5 text-sm15">
            Transfer
          </h5>
          <button
            type="button"
            className="flex items-center justify-center h-10 w-10 border-l box-content group"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              setTransferData("");
            }}
            id="close-btn"
          >
            <IKTSVG
              className="fill-violet-500   group-hover:fill-indigo-500 transition"
              path={Cross}
              svgClassName="w-[.6875rem] h-[.6875rem]"
            />
          </button>
        </div>

        <div className="offcanvas-body max-h-full w-full flex flex-col overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full p-5 h-full">
          <div className="card_options">
            <div className="formField whiteFormFields pb-5 flex flex-wrap flex-col border-b">
              <div className="flex justify-between leading-4 mb-2.5">
                <label className="text-sm13 font-semibold">From</label>
              </div>
              <DropDownField
                // className="block overflow-ellipsis overflow-hidden w-auto py-1 text-xs font-medium text-gray-900 border-1.5 border-gray-300 rounded focus:ring-indigo-300 focus:border-indigo-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                className={`!h-10`}
                name={"from"}
                placeholder={``}
                options={from_option}
                value={transferData?.from ?? {}}
                isClearable={true}
                handleOnChange={handleOnChange}
              />
              <span className="text-sm13 text-gray-500 font-medium mt-2.5">
                Available: {fromAvailableBalance}
              </span>
            </div>
            <div className="formField whiteFormFields py-5 flex flex-wrap flex-col border-b">
              <div className="flex justify-between leading-4 mb-2.5">
                <label className="text-sm13 font-semibold">To</label>
              </div>
              <DropDownField
                // className="block overflow-ellipsis overflow-hidden w-auto py-1 text-xs font-medium text-gray-900 border-1.5 border-gray-300 rounded focus:ring-indigo-300 focus:border-indigo-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                className={`!h-10`}
                name={"to"}
                placeholder={``}
                options={to_options}
                value={transferData?.to ?? {}}
                isClearable={true}
                isCapital={true}
                isValueCapital={true}
                handleOnChange={handleOnChange}
              />
              <span className="text-sm13 text-gray-500 font-medium mt-2.5">
                Balance: {toAvailableBalance}
              </span>
            </div>
            <div className="formField whiteFormFields py-5 flex flex-wrap flex-col [&_input]:h-10 focus:[&_input]:text-indigo-500 [&_a]:min-w-[2.5rem]">
              <div className="flex justify-between leading-4 mb-2.5">
                <label className="text-sm13 font-semibold">Amount</label>
              </div>
              <div className="relative">
                <PriceField
                  textfieldType="number"
                  name="amount"
                  value={
                    transferData?.amount
                      ? typeof transferData?.amount === "object"
                        ? transferData?.amount?.value
                        : transferData?.amount
                      : ""
                  }
                  handleOnChange={(data: any) => {
                    let event = {
                      target: {
                        name: data.target.name,
                        value: {
                          name: data.target.name,
                          value: data.target?.value?.value,
                          symbol: data?.target?.value?.symbol,
                        },
                      },
                    };
                    handleOnChange(event);
                  }}
                  currenncySymbol={coreLayout?.currencyLabel}
                />
                {amountError && (
                  <div className="text-xxs text-rose-500 italic error-msg left-11">
                    {amountError}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="offcanvas-footer border-t py-3 px-5 false text-right">
          <button
            type="button"
            className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white 
        bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13  py-[.1875rem] leading-[1rem] px-2.5 mr-2"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              setTransferData({});
            }}
          >
            Cancel
          </button>

          <button
            type="submit"
            className={` ${
              isDisabledBtn &&
              "!bg-gray-100 !border-gray-300 pointer-events-none !border-0 !text-gray-400"
            } transition py-[.1875rem] leading-[1rem] px-2.5 text-sm13  border border-violet-500 bg-violet-500 text-white hover:bg-indigo-500 hover:border-indigo-500 rounded`}
            onClick={handleTransferMoney(
              transferData,
              setTransferData,
              setSubmitLoader,
              setVirtualCardList,
              refetch
            )}
          >
            Confirm
          </button>
        </div>
        {submitLoader && <ProgressBardLoader secondCounter={2} />}
      </div>
    </div>
  );
};

export default TransferPopup;
