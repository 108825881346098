import React from "react";

interface InputFieldWrapperProps {
  label: string;
  className?: string;
  children?: React.ReactNode;
}

const InputFieldWrapper: React.FC<InputFieldWrapperProps> = ({
  label,
  className,
  children,
}) => {
  return (
    <div
      className={`flex flex-col md:w-1/2 sm:w-1/2 w-full px-[0.4375rem] ${className}`}
    >
      <label className="text-sm12 font-medium mb-[.3125rem] text-gray-500 block leading-4">
        {label}
      </label>
      {/* <div className="min-w-[6.25rem] text-sm12 label_text text-gray-400">
        {label}
      </div> */}
      {children}
    </div>
  );
};

export default InputFieldWrapper;
