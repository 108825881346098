const Collapse = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.5"
      height="6"
      viewBox="0 0 10.5 6"
      {...props}
    >
      <path
        id="Path_49112"
        data-name="Path 49112"
        d="M4.5,9.75a.748.748,0,0,1-.53-.22l-4.5-4.5a.75.75,0,0,1,0-1.061l4.5-4.5a.75.75,0,0,1,1.061,0A.75.75,0,0,1,5.03.53L1.061,4.5,5.03,8.47A.75.75,0,0,1,4.5,9.75Z"
        transform="translate(9.75 0.75) rotate(90)"
      />
    </svg>
  );
};

export default Collapse;
