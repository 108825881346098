import { NEXT_LEVEL_KEY } from "../const";
import { getSellerLevel } from "../utils";
import { useQuarterData } from "./useQuarterData";

export const useTargetGTV = (
  sellerLevels: any,
  currentDate: any,
  nextLevelGtv: any,
  level: any
) => {
  const { totalDays, daysPassedInQuater } = useQuarterData(currentDate);

  // Calculate the daily GTV required to reach the next level by dividing by total days in the quarter
  const dailyGtv = nextLevelGtv / totalDays;

  // Calculate the GTV for the days passed in the current quarter
  const targetAmount = dailyGtv * daysPassedInQuater;

  const targetLevel = getSellerLevel(targetAmount, sellerLevels, level) ?? "";

  const targetLevelGTV = sellerLevels[targetLevel] ?? 0;

  const targetLevelNextGTV =
    sellerLevels[NEXT_LEVEL_KEY[targetLevel] ?? ""] ?? 0;

  // Calculate the percentage of the target reached based on the days passed
  const targetPercentage =
    ((targetAmount - targetLevelGTV) / (targetLevelNextGTV - targetLevelGTV)) *
    100;

  // The total target amount is the sum of the current level's GTV and the GTV accumulated so far

  return { targetAmount, targetLevel, targetPercentage };
};
