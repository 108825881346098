import { LayoutContext } from "components/core/LayoutProvider";
import { IKTSVG } from "components/ui/IKTSVG";
import { SYMBOLS } from "helpers/const";
import { addCommaInNumber } from "helpers/Functions";
import { useContext, useMemo } from "react";
import check from "../../../assets/media/icons/other_icons/check.svg";
import {
  NEXT_LEVEL_KEY,
  PROGRESS_COLOR,
  SELLER_LEVEL_KEY,
} from "./UserSellerInfo/const";
import { getSellerTitle } from "./UserSellerInfo/utils";

const SellerLevelBenefitsSlider = ({ data, level, sellerLevels }: any) => {
  const { currencyLabel } = useContext(LayoutContext);

  const nextLevel = useMemo(
    () => NEXT_LEVEL_KEY[data?.title] ?? "",
    [data?.title]
  );

  const sellerTitle = useMemo(() => getSellerTitle(data?.title), [data?.title]);

  const isCurrentLevel = useMemo(
    () => level === data?.title,
    [data?.title, level]
  );

  const checkBgColor = useMemo(
    () => (isCurrentLevel ? PROGRESS_COLOR[level]?.medium : "bg-gray-400"),
    [isCurrentLevel, level]
  );

  const quarterlyGTV = useMemo(
    () =>
      data?.title === SELLER_LEVEL_KEY.LEVEL3
        ? `up to ${SYMBOLS[currencyLabel]}${addCommaInNumber(
            sellerLevels[nextLevel]
          )}`
        : `${SYMBOLS[currencyLabel]}${addCommaInNumber(
            sellerLevels[data?.title]
          )}+`,
    [currencyLabel, data?.title, nextLevel, sellerLevels]
  );
  return (
    <div className="p-[.9375rem]">
      <h4 className="title text-sm15 font-medium leading-[1.125rem] mb-1.5">
        {sellerTitle}
        {isCurrentLevel ? " - Current" : ""}
      </h4>
      <span className="text-sm12 font-normal text-gray-500 leading-[.875rem] block mb-3.5">
        Quarterly GTV {quarterlyGTV}
      </span>

      <div className="benifitList gap-y-[.5625rem] flex flex-wrap">
        {data?.benefits?.map((obj: any, index: any) => {
          return (
            <div key={index} className="singleList w-full">
              <div className="iconContent flex">
                <IKTSVG
                  path={check}
                  className={`w-3 min-w-3 h-3 rounded-sm ${checkBgColor} flex items-center justify-center mt-[.0625rem] mr-1.5`}
                  svgClassName="fill-white w-[.4375rem] h-1.5"
                />
                <span
                  className={`text-sm12 font-normal ${
                    !isCurrentLevel && "text-gray-400"
                  } leading-[.9375rem]`}
                >
                  {obj}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SellerLevelBenefitsSlider;
