/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { memo, useContext, useEffect, useRef, useState } from "react";
import Select, { ClearIndicatorProps } from "react-select";
// import "./styles.css";
import { size } from "lodash";
import { components } from "react-select";
import {
  CAPITAL_OPTION_NOT_REQUIRED,
  CAPITAL_OPTIONS_IN_MULTIDROPDOWN,
  MULTI_SELECTION_ALLOWED,
} from "../../helpers/const";
import { capitalizedWords, sentenceCase } from "../../helpers/Functions";
import TooltipPortal from "../../pages/tx_trade/table/component/TooltipPortal";
import { LayoutContext } from "../core/LayoutProvider";

const InputOption = ({
  handleSelectAll,
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  noCapitalize,
  ...rest
}: any) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);
  const [prevIsSelected, setPrevIsSelected] = useState(false);
  const layout = useContext(LayoutContext);

  useEffect(() => {
    // Check if the isSelected state has changed from previously selected
    if (
      prevIsSelected &&
      !isSelected &&
      rest?.selectProps?.name === "category_name"
    ) {
      layout?.setRemoveFilterTag(children);
    }
    setPrevIsSelected(isSelected);
  }, [isSelected]);

  // styles
  let bg = "transparent";
  let fontweight = children === "Select All | De-select All" ? "bold" : "500";
  let padding = children === "Select All | De-select All" ? "0" : "8px 12px";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
    justifyContent: "space-between",
    cursor: "pointer",
    fontWeight: fontweight,
    padding: padding,
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
    handleSelectAll,
    noCapitalize,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      {children !== "Select All | De-select All" ? (
        CAPITAL_OPTION_NOT_REQUIRED?.includes(rest?.selectProps?.name) ||
        CAPITAL_OPTIONS_IN_MULTIDROPDOWN?.includes(rest?.selectProps?.name) ? (
          children
        ) : (
          <span
            className="ellips-text"
            title={capitalizedWords(children, noCapitalize)}
          >
            {capitalizedWords(children, noCapitalize)}
          </span>
        )
      ) : (
        <div className="flex flex-inline min-h-[2rem] w-full border-b sticky top-0">
          <button
            type="button"
            className={`bg-white !opacity-100 border-gray-300 border-r hover:border-indigo-500 text-violet-800 hover:bg-indigo-500 hover:text-white w-1/2 items-center text-sm13 p-1 rounded-tl 
          transition font-medium`}
            onClick={() => handleSelectAll(true)}
          >
            Select All
          </button>
          <button
            type="button"
            className={`bg-white !opacity-100 border-gray-300 hover:border-indigo-500 text-violet-800 hover:bg-indigo-500 hover:text-white w-1/2 items-center text-sm13 p-1 rounded-tr 
          transition font-medium`}
            onClick={() => handleSelectAll(false)}
          >
            De-select All
          </button>
        </div>
      )}
      <div className="flex items-center gap-2">
        {rest?.data?.icon && <img src={rest?.data?.icon} alt="" />}

        {/* @ts-ignore */}
        {children != "Select All | De-select All" ? (
          <input type="checkbox" checked={isSelected} readOnly tabIndex={-1} />
        ) : (
          ""
        )}
      </div>
    </components.Option>
  );
};

const CustomMenuList = memo(({ ...props }: any) => {
  const { onButtonClick } = props.selectProps;
  const visibleOptions = React.Children.map(props.children, (child) => {
    return child.props.data;
  });
  let str: string = props.selectProps?.inputValue;
  let labelBasedOptions = visibleOptions
    .filter((item: any) => item.label && item.options)
    .flatMap((item: any) => item.options);

  const matchedObjects = labelBasedOptions.filter(
    (item: any) =>
      item.name.toLowerCase().includes(str?.toLowerCase()) ||
      item.name.toLowerCase().includes(str?.toLowerCase().trim())
  );

  let finalArray =
    matchedObjects && size(matchedObjects) > 0
      ? matchedObjects
      : visibleOptions;

  return (
    <components.MenuList
      {...props}
      className="scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full !pt-0"
    >
      {MULTI_SELECTION_ALLOWED?.includes(props.selectProps?.name) &&
        props?.children?.props?.children !== "No options" &&
        !props.selectProps?.isLoading && (
          <div className="flex flex-inline min-h-[2rem] w-full border-b sticky top-0">
            <button
              className={`bg-white !opacity-100 border-gray-300 border-r hover:border-indigo-500 text-violet-800 hover:bg-indigo-500 hover:text-white w-1/2 items-center text-sm13 p-1 rounded-tl 
          transition font-medium text-center`}
              type="button"
              id="select-all"
              onClick={(e: any) => {
                onButtonClick(e, finalArray);
              }}
              tabIndex={-1}
            >
              Select all
            </button>
            <button
              className={`bg-white !opacity-100 border-gray-300 hover:border-indigo-500 text-violet-800 hover:bg-indigo-500 hover:text-white w-1/2 items-center text-sm13 p-1 rounded-tr 
  transition font-medium text-center`}
              type="button"
              id="deselect-all"
              onClick={(e: any) => {
                onButtonClick(e, finalArray);
              }}
              tabIndex={-1}
            >
              Deselect all
            </button>
          </div>
        )}
      {props.children}
    </components.MenuList>
  );
});

const MultiDropDownField = memo(
  ({
    selectedRef,
    name,
    handleOnChange,
    classNamePrefix,
    options,
    value,
    defaultValue,
    placeholder,
    isLoading = false,
    isSearchable,
    getOptionLabel = "name",
    noOptionsMessageText = "No Options",
    getOptionValue = "id",
    isMenuPortalTarget = false,
    handleSelectAll,
    handleOnSelection,
    handleInputChangeCallback = (input: string) => {},
    isMultiSelection = false,
    isCapital = false,
    isNone = true,
    isDisabled = false,
    menuPosition = "absolute",
    minMenuHeight = undefined,
    noCapitalize = false,
  }: any) => {
    const { MultiValueContainer, Placeholder } = components;
    const [inputValue, setInputValue] = useState("");
    const [menuOpenCloseStatus, setMenuOpenCloseStatus] =
      useState<boolean>(false);

    const handleInputChange = (value: any, { action }: any) => {
      if (action === "input-change") {
        // handleInputChangeForParent(value);
        setInputValue(value);
        handleInputChangeCallback(value);
      }
    };
    const selectRef: any = useRef<any>(null);
    const customStyle = {
      menuPortal: (provided: any) => ({
        ...provided,
        zIndex: 9999,
      }),
      control: (base: any, state: any) => {
        return {
          ...base,
          cussor: "pointer",
          minHeight: "1px",
          minWidth: "9.375rem",
        };
      },
      placeholder: (base: any, state: any) => {
        return {
          ...base,
        };
      },
    };

    const MultiValue = (props: any) => {
      if (props.index > 0) return null;
      const { length } = props.getValue();
      let isAllNone = props
        .getValue()
        ?.every((item: any) => item?.name === "None");

      let displayPlaceholder = isNone ? true : !isAllNone ? true : false;

      return length > 0 && !props.isFocused ? (
        <div className="option-label close-datepicker-12212121">
          {displayPlaceholder && (
            <Placeholder {...props}>{placeholder}</Placeholder>
          )}
          {props?.selectProps?.menuIsOpen
            ? ""
            : isAllNone && !props?.selectProps?.menuIsOpen
            ? isNone
              ? "None"
              : placeholder
            : `${length} selected`}
        </div>
      ) : (
        <MultiValueContainer {...props} />
      );
    };

    const getOptions = () => {
      return options;
    };

    // Remove Searched value on reset filter.
    useEffect(() => {
      if (size(value) === 0 && inputValue) {
        setInputValue("");
      }
    }, [value]);

    const ClearIndicator = (props: ClearIndicatorProps<any, true>) => {
      const {
        innerProps: { ref, ...restInnerProps },
      } = props;
      let isAllNone = props
        .getValue()
        ?.every((item: any) => item?.name === "None");
      if (!isAllNone) {
        return (
          <components.ClearIndicator {...props}>
            <TooltipPortal
              id={`clear`}
              content={"Reset"}
              place="top"
              variant="light"
              className="text-center !bg-white !opacity-100 shadow !px-1.5 !py-0.5 font-medium text-xs z-100 max-w-[9rem] mt-1.5"
              positionStrategy="fixed"
            />
            <span
              data-tooltip-id="clear"
              className="text-sm15 text-violet-500 hover:text-indigo-500"
            >
              &times;
            </span>
          </components.ClearIndicator>
        );
      } else {
        return null;
      }
    };

    const NoOptionsMessage = (props: any) => {
      const { noOptionsMessageText } = props.selectProps;
      return (
        <components.NoOptionsMessage {...props} className="normal-case">
          {sentenceCase(noOptionsMessageText)}
        </components.NoOptionsMessage>
      );
    };

    return (
      <div className="App multi-select-dropdown min-w-[6.875rem]">
        <Select
          // @ts-ignore
          onButtonClick={(data: any, visibleArray: any) => {
            handleOnSelection(data, visibleArray);
            if (data?.target?.id !== "select-all") {
              setInputValue("");
              handleInputChangeCallback("");
            }
          }}
          // value={name === "teamMembers" ? value : undefined}
          openMenuOnFocus
          value={size(value) > 0 ? value : [{ id: "None", name: "None" }]}
          inputValue={menuOpenCloseStatus ? inputValue : ""}
          onInputChange={handleInputChange}
          isLoading={isLoading}
          // menuIsOpen={true}
          menuIsOpen={menuOpenCloseStatus}
          onMenuClose={() => setMenuOpenCloseStatus(false)}
          onMenuOpen={() => setMenuOpenCloseStatus(true)}
          isMulti
          noOptionsMessageText={noOptionsMessageText}
          ref={selectedRef}
          classNamePrefix={`${
            isSearchable === true
              ? "NoSearchable " + classNamePrefix
              : classNamePrefix
          }`}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isClearable={false}
          getOptionLabel={(option) => option[getOptionLabel]}
          getOptionValue={(option) => option[getOptionValue]}
          onChange={(selectedOption: any, actionMeta: any) => {
            let event = {
              target: {
                name: name,
                value: selectedOption?.filter(
                  (item: any) => item?.name !== "None"
                ),
              },
            };
            if (actionMeta?.action !== "pop-value") {
              handleOnChange(event, actionMeta);

              // don't reset searched text on manually deselecte and value length 1 for "Remove Searched value".
              if (
                actionMeta?.action === "deselect-option" &&
                size(value) === 1 &&
                inputValue
              ) {
                setTimeout(() => {
                  setInputValue(inputValue);
                }, 300);
              }
            }
          }}
          name={name}
          isSearchable={isSearchable}
          components={{
            //Option: InputOption,
            Option: (props) => (
              <InputOption
                {...props}
                handleSelectAll={handleSelectAll}
                noCapitalize={noCapitalize}
              />
            ),
            MultiValue: MultiValue,
            ClearIndicator,
            NoOptionsMessage,
            MenuList: CustomMenuList,
          }}
          //menuPortalTarget={document.body} // solve issue of position of dropdown.
          menuPortalTarget={isMenuPortalTarget ? document.body : null}
          styles={customStyle}
          // className={`${isSearchable === true && 'NoSearchable'}`}
          //menuIsOpen={true}
          //@ts-ignore
          // options={options}
          options={getOptions()}
          placeholder={placeholder}
          tabSelectsValue={false}
          isDisabled={isDisabled}
          menuPosition={menuPosition}
          minMenuHeight={minMenuHeight}
        />
      </div>
    );
  }
);

export default MultiDropDownField;
