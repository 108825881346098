import { useFormik } from "formik";
import { size } from "lodash";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import * as Yup from "yup";
import txIcon from "../../../assets/media/icons/other_icons/tx-page-logo.svg";
import KYCInstructions from "../../../components/core/KYCInstructions";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import TextInput from "../../../components/formComponent/TextInput";
import { getListingData } from "../../../components/layout/auth/core/_request";
import FileUpload from "../../../components/layout/auth/sign_up/component/FileUpload";
import FormRow from "../../../components/layout/auth/sign_up/component/FormRow";
import FormSubHeader from "../../../components/layout/auth/sign_up/component/FormSubHeader";
import SignUpRadio from "../../../components/layout/auth/sign_up/component/SignUpRadio";
import SubFormHeader from "../../../components/layout/auth/sign_up/component/SubFormHeader";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { KTSVG } from "../../../components/ui/KTSVG";
import ProgressBardLoader from "../../../components/ui/ProgressBardLoader";
import { FileToObjectConversion } from "../../../helpers/AssetHelpers";
import {
  checkCompleteKYC,
  maxFileUploadCount,
} from "../../../helpers/Functions";
import { showAlert } from "../../../helpers/ShowAlert";
import { QUERIES } from "../../../helpers/const";
import { userDetailSubmit } from "../../dashboard/core/requests";
import { getAccountInfo } from "pages/settings/core/_request";

// VALIDATION SCHEMA
const validationSchema = Yup.object().shape({
  use_as_same: Yup.boolean().required("required"),

  // FUNDING CHECKED
  funding_bank_name: Yup.string().when("use_as_same", {
    is: false,
    then: Yup.string().trim().required("Bank name is required"),
  }),
  funding_ac_holder_name: Yup.string().when("use_as_same", {
    is: false,
    then: Yup.string().trim().required("Account name is required"),
  }),
  funding_swift: Yup.string().when(["funding_bank_identifier", "use_as_same"], {
    is: (funding_bank_identifier: any, use_as_same: any) => {
      return !funding_bank_identifier && !use_as_same;
    },
    then: Yup.string().trim().required("SWIFT is required"),
    otherwise: Yup.string().nullable(),
  }),

  // WITHDRAW
  withdrawal_bank_name: Yup.string().trim().required("Bank name is required"),

  withdrawal_ac_holder_name: Yup.string()
    .trim()
    .required("Account name is required"),

  withdrawal_swift: Yup.string().when("withdrawal_bank_identifier", {
    is: (withdrawal_bank_identifier: any) => !withdrawal_bank_identifier,
    then: Yup.string().trim().required("SWIFT is required"),
    otherwise: Yup.string().nullable(),
  }),

  identity_proof: Yup.array().when("seller_type", {
    is: "Individual",
    then: Yup.array()
      .min(2, "At least 2 documents are required")
      .nullable(true),
    otherwise: Yup.array()
      .min(3, "At least 3 documents are required")
      .nullable(true),
  }),
});

const CompleteSignUp = () => {
  const globalLayout = useContext(LayoutContext);
  let userDetailsShow = globalLayout?.userDetailShow;
  let baseCurrency = globalLayout?.allowedAccess?.account_info?.base_currency;
  const [customLoader, setCustomLoader] = useState<boolean>(false);
  const [KYCData, setKYCData] = useState<any>({
    ...userDetailsShow?.txPayData,
    use_as_same: true,
    funding_bank_name: "",
    funding_ac_holder_name: "",
    funding_iban: "",
    funding_swift: "",
    withdrawal_bank_name: "",
    withdrawal_ac_holder_name: "",
    withdrawal_iban: "",
    withdrawal_swift: "",
    withdrawal_ac_number: "",
    withdrawal_branch_code: "",
    withdrawal_bank_ac_currency: baseCurrency,
    // withdrawal_bank_ac_country_code: null,
    // recipient_country_code: null,
    // recipient_street: "",
    // recipient_city: "",
    // recipient_state: "",
    // recipient_postcode: "",
    withdrawal_bank_identifier: false,
    funding_bank_identifier: false,
  });

  // IDENTITY PROOF ARRAY
  // const identityProof = [
  //   {
  //     key: "ProofBankAccount",
  //     title:
  //       KYCData?.seller_type === "Business"
  //         ? "Proof of business bank account"
  //         : "Proof of bank account",
  //     subTitle:
  //       "First page of bank statement from the last 3 months (blank out balance and transactions)",
  //     content:
  //       "Must match the Tixstock account name and bank account assigned below",
  //   },
  //   ...(KYCData?.seller_type === "Business"
  //     ? [
  //         {
  //           key: "CertificateOfIncorporation",
  //           title: "Certificate of incorporation",
  //           subTitle: "",
  //           content: "",
  //         },
  //         {
  //           key: "CompanyShareRegister",
  //           title: "Company shareholder register",
  //           subTitle: "",
  //           content: "",
  //         },
  //         {
  //           key: "RegulatoryFillings",
  //           title: "Regulatory filings",
  //           subTitle: "",
  //           content: "",
  //         },
  //         {
  //           key: "UltimateBeneficial",
  //           title: "Ultimate beneficial owner(s) verification",
  //           subTitle: "",
  //           content: "",
  //         },
  //       ]
  //     : [
  //         {
  //           key: "PhotoID",
  //           title: "Photo ID",
  //           subTitle: "Copy of passport, identity card or driver’s license",
  //           content: "",
  //         },
  //       ]),
  // ];

  // DELETE DOCUMENTS
  const deleteFiles = (file: any, index: number) => {
    setKYCData((currentPDF: any) => {
      return {
        ...formik?.values,
        identity_proof: currentPDF?.identity_proof?.filter(
          (item: any, filterIndex: number) => index !== filterIndex
        ),
      };
    });
  };

  useQuery(QUERIES.SIGN_UP, () => getListingData(), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    onError: (err) => {},
    onSettled(data, error) {
      globalLayout.setListingData(data?.data);
    },
  });

  // FORMIK CONFIGURATION
  const formik = useFormik({
    initialValues: KYCData,
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (values: any) => {
      setCustomLoader(true);
      let identity_proof = FileToObjectConversion(values?.identity_proof);
      let final = {
        ...values,
        identity_proof,
        ...(checkCompleteKYC(globalLayout?.userDetailShow)?.status && {
          [Object.keys(checkCompleteKYC(globalLayout?.userDetailShow))?.[1]]:
            identity_proof,
        }),
        proofTaken: Object.keys(
          checkCompleteKYC(globalLayout?.userDetailShow)
        )?.[1],
      };

      userDetailSubmit(final)
        .then((response: any) => {
          if (
            response?.data &&
            size(response?.data) > 0 &&
            response?.data?.status
          ) {
            globalLayout.setUserDetailShow(async (current: any) => {
              let finalResult = {
                ...current,
                txPayData: {
                  ...final,
                },
              };

              let localFinal = {
                ...finalResult,
              };

              delete localFinal?.txPayData?.identity_proof;
              delete localFinal?.txPayData?.business_proof;

              const retchedInfoData = await getAccountInfo();

              const updated = {
                txPayStatus: retchedInfoData?.data?.tx_pay_info_status,
                txPayData: retchedInfoData?.data?.tx_pay_info_data,
              };
              localStorage.setItem("txPayStatus", JSON.stringify(updated));

              return updated;
            });
            showAlert(response?.data?.message, false);
            setCustomLoader(false);
          } else if (
            response?.data &&
            size(response?.data) === 0 &&
            size(response?.errors) > 0
          ) {
            setCustomLoader(false);

            showAlert(response?.errors?.join("<br/>"), true);
          } else {
            setCustomLoader(false);
          }
        })
        .catch(() => {
          setCustomLoader(false);
          showAlert("Something went wrong!", true);
        });
    },
  });

  // HANDLE CLOSE
  const handleClose = () => {
    globalLayout?.setKYCPopup(false);
    formik.resetForm();
  };

  useEffect(() => {
    const body: any = document.querySelector("body");
    if (globalLayout?.KYCPopup) {
      body?.classList.add("kycActive");
    } else {
      body?.classList.remove("kycActive");
    }
  }, [globalLayout?.KYCPopup]);

  return (
    <div
      className={`offcanvas upload-ticket-popup offcanvas-end fixed bottom-0 top-[3.875rem] right-0 shadow-lg bg-clip-padding outline-none transition duration-300 ease-in-out font-medium md:max-w-[45.25rem] max-w-full w-full bg-white ${
        globalLayout?.KYCPopup ? "show" : ""
      }`}
    >
      <div className="relative flex flex-col h-full bg-white ml-auto">
        {/* HEADER */}
        <div className="offcanvas-header flex justify-between border-t border-b">
          <h5 className="offcanvas-title mb-0 leading-normal text-sm15 font-semibold py-2 px-5">
            Complete sign up
          </h5>
          <div className="buttons flex flex-wrap">
            <button
              type="button"
              className="flex items-center justify-center w-9 border-l box-content group"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={() => handleClose()}
            >
              <KTSVG
                className="fill-violet-500 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
                path="standard_icons/cross.svg"
                svgClassName="w-2.5 h-2.5"
              />
            </button>
          </div>
        </div>

        {/* BODY */}
        <div className="offcanvas-body max-h-full w-full flex flex-col overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full p-5 md:pt-[1.875rem] h-full">
          {!userDetailsShow?.txPayStatus &&
          size(userDetailsShow?.txPayData?.identity_proof) > 0 ? (
            <>
              {/* AFTER COMPLETION OF FORM */}
              <div className="flex justify-center items-center h-screen">
                <div className="text-center">
                  <h5 className="text-sm15 font-semibold mb-2.5">
                    Thank you for providing your KYC docuements
                  </h5>
                  <p className="text-sm13">
                    One of our experts will notify you when your TX Pay wallet
                    is available
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="logo_content flex flex-wrap bg-gray-100 rounded-lg mb-[1.875rem]">
                <div className="left flex flex-none items-center justify-center px-5 border-r">
                  <IKTSVG
                    path={txIcon}
                    className="w-20 h-11 flex items-center justify-center fill-white"
                    svgClassName="w-20 h-11"
                  />
                </div>
                <div className="right flex-1 p-5 text-sm12 font-normal">
                  <p>
                    TX Pay is Europe’s first integrated payment product for the
                    ticketing industry. We have partnered with a fully regulated
                    European banking and financial services organisation to
                    create a real-time, multi-currency and cross-border payment
                    solution.
                  </p>
                </div>

                {/* <div className={`bottom w-full border-t p-5 pb-0 `}>
                  <h4 className="text-sm13 font-basier_med leading-4 mb-4">
                    We require the following documents to process your application:
                  </h4>
                  <div className="inner_need flex flex-wrap -mx-2.5">
                    {KYCData?.seller_type === "Business"
                      ? [identityProof?.[0]].map((item: any, index: number) => (
                          <InfoList
                            key={item.key}
                            item={item}
                            index={index % 2 !== 0}
                            isPopup={true}
                          />
                        ))
                      : identityProof?.map((item: any, index: number) => (
                          <InfoList
                            key={item.key}
                            item={item}
                            index={index % 2 !== 0}
                            isPopup={true}
                          />
                        ))}
                  </div>
                  {KYCData?.seller_type === "Business" && (
                    <>
                      <h4 className="text-sm13 font-basier_med leading-4 mb-4">
                        Plus any 2 of the following business documents:
                      </h4>
                      <div className="inner_need flex flex-wrap -mx-2.5 mb-2.5">
                        {identityProof?.map((item: any, index: number) => {
                          if (index !== 0) {
                            return (
                              <InfoList
                                key={item.key}
                                className="px-2.5 flex flex-wrap md:w-1/2 mb-2.5"
                                item={item}
                                index={index % 2 !== 0}
                                isPopup={true}
                              />
                            );
                          }
                          return null;
                        })}
                      </div>
                    </>
                  )}
                </div> */}
                <KYCInstructions
                  userType={KYCData?.seller_type}
                  error={
                    // formik.touched?.identity_proof &&
                    formik.errors?.identity_proof
                  }
                  errorHighLight={
                    formik.touched?.identity_proof &&
                    formik.errors?.identity_proof
                  }
                  useSmall={true}
                />
              </div>

              {/* FILE UPLOAD */}
              <div className="flex flex-wrap mb-[1.875rem]">
                <h4 className="text-sm13 w-full font-basier_med leading-4 flex-1 mb-4">
                  {maxFileUploadCount(
                    KYCData?.seller_type,
                    formik.values?.identity_proof
                  )}{" "}
                  of {KYCData?.seller_type === "Business" ? "3" : "2"} files
                  uploaded
                </h4>

                <fieldset className="new-inp w-full [&_.upload-label]:text-sm13">
                  <FileUpload
                    userDetail={KYCData}
                    formikValues={formik.values}
                    // fileLimit={KYCData?.seller_type === "Business" ? 3 : 2}
                    deleteFiles={(file: any, index: any) => {
                      if (file?.base64) {
                        deleteFiles(file, index);
                      } else {
                      }
                    }}
                    setState={setKYCData}
                    handleFiles={(data: any) => {
                      formik.setFieldValue("identity_proof", data);
                    }}
                  />
                  {formik.touched.identity_proof &&
                    formik.errors.identity_proof && (
                      <div className="text-xs text-rose-500 mt-1 ml-4">
                        {formik.errors.identity_proof as string}
                      </div>
                    )}
                </fieldset>
              </div>

              {/* BANK DETAILS */}
              <div className="[&_h4]:text-sm13">
                <FormSubHeader
                  tooltipRequire={false}
                  title={`${
                    formik.values.use_as_same
                      ? "Bank account details"
                      : "Withdraw bank account details"
                  }`}
                  error={
                    formik.touched?.base_currency &&
                    formik.errors?.base_currency
                  }
                />
              </div>
              <SubFormHeader title="Must match the Tixstock account name and proof of bank account document" />
              {/* WITHDRAW BANK  */}
              <fieldset className={`new-inp flex flex-wrap -mx-2.5 `}>
                {/* WITHDRAWAL BANK NAME */}
                <div className={`w-full px-2.5 mb-[.9375rem] relative `}>
                  <TextInput
                    required={false}
                    name="withdrawal_bank_name"
                    type="text"
                    label="Name of bank"
                    textFielClassName={
                      formik.values.use_as_same &&
                      "pointer-events-none opacity-50"
                    }
                    errorClass={
                      formik.touched.withdrawal_bank_name &&
                      formik.errors.withdrawal_bank_name &&
                      "border-rose-500"
                    }
                    id="withdrawal_bank_name"
                    value={formik.values.withdrawal_bank_name}
                    handleOnChange={(data: any) =>
                      formik.setFieldValue(
                        data?.target?.name,
                        data?.target?.value
                      )
                    }
                    handleClearValue={() =>
                      formik.setFieldValue("withdrawal_bank_name", "")
                    }
                  />
                  {formik.touched.withdrawal_bank_name &&
                    formik.errors.withdrawal_bank_name && (
                      <div className="text-xs text-rose-500 error-msg">
                        {formik.errors.withdrawal_bank_name as string}
                      </div>
                    )}
                </div>

                {/* WITHDRAWAL ACCOUNT NAME */}
                <div className={`w-full px-2.5 mb-[.9375rem] relative `}>
                  <TextInput
                    required={false}
                    name="withdrawal_ac_holder_name"
                    type="text"
                    label="Account name"
                    errorClass={
                      formik.touched.withdrawal_ac_holder_name &&
                      formik.errors.withdrawal_ac_holder_name &&
                      "border-rose-500"
                    }
                    id="withdrawal_ac_holder_name"
                    value={formik.values.withdrawal_ac_holder_name}
                    handleOnChange={(data: any) =>
                      formik.setFieldValue(
                        data?.target?.name,
                        data?.target?.value
                      )
                    }
                    handleClearValue={() =>
                      formik.setFieldValue("withdrawal_ac_holder_name", "")
                    }
                  />

                  {formik.touched.withdrawal_ac_holder_name &&
                    formik.errors.withdrawal_ac_holder_name && (
                      <div className="text-xs text-rose-500 error-msg">
                        {formik.errors.withdrawal_ac_holder_name as string}
                      </div>
                    )}
                </div>

                {/* WITHDRAWAL BANK IDENTIFIER */}
                <div className={`w-full px-2.5 mb-5 relative `}>
                  {/* SUB FROM HEADER */}
                  <SubFormHeader title="Select which identifier applies to this bank account" />
                  <FormRow className="seller_info" titleNedeed={false}>
                    <SignUpRadio
                      title="IBAN/SWIFT/BIC"
                      id="withdrawal_iban_swift_bic"
                      name="withdrawal_bank_identifier"
                      postKey={false}
                      value={formik?.values?.withdrawal_bank_identifier}
                      handleSeller={(data: any) => {
                        formik.setFieldValue(
                          "withdrawal_bank_identifier",
                          false
                        );
                        formik.setFieldValue("withdrawal_ac_number", "");
                        formik.setFieldValue("withdrawal_branch_code", "");
                      }}
                    />
                    <SignUpRadio
                      title="Account number"
                      id="withdrawal_acc_number"
                      name="withdrawal_bank_identifier"
                      postKey={true}
                      value={formik?.values?.withdrawal_bank_identifier}
                      handleSeller={(data: any) => {
                        formik.setFieldValue(
                          "withdrawal_bank_identifier",
                          true
                        );
                        formik.setFieldValue("withdrawal_iban", "");
                        formik.setFieldValue("withdrawal_swift", "");
                      }}
                    />
                  </FormRow>
                </div>

                {formik?.values?.withdrawal_bank_identifier ? (
                  <>
                    {/* WITHDRAWAL ACCOUNT NUMBER */}
                    <div
                      className={`md:w-1/2 w-full px-2.5 mb-[.9375rem] relative`}
                    >
                      <TextInput
                        required={false}
                        name="withdrawal_ac_number"
                        type="text"
                        label="Account number"
                        errorClass={
                          formik.touched.withdrawal_ac_number &&
                          formik.errors.withdrawal_ac_number &&
                          "border-rose-500"
                        }
                        id="withdrawal_ac_number"
                        value={formik.values.withdrawal_ac_number}
                        handleOnChange={(data: any) =>
                          formik.setFieldValue(
                            data?.target?.name,
                            data?.target?.value
                          )
                        }
                        handleClearValue={() =>
                          formik.setFieldValue("withdrawal_ac_number", "")
                        }
                      />

                      {formik.touched.withdrawal_ac_number &&
                        formik.errors.withdrawal_ac_number && (
                          <div className="text-xs text-rose-500 error-msg">
                            {formik.errors.withdrawal_ac_number as string}
                          </div>
                        )}
                    </div>

                    {/* BRANCH / SORT CODE */}
                    <div className="md:w-1/2 w-full px-2.5 mb-[.9375rem] relative">
                      <TextInput
                        required={false}
                        name="withdrawal_branch_code"
                        type="text"
                        label="Sort code/Routing Number"
                        value={formik.values.withdrawal_branch_code}
                        errorClass={
                          formik.touched.withdrawal_branch_code &&
                          formik.errors.withdrawal_branch_code &&
                          "border-rose-500"
                        }
                        id="withdrawal_branch_code"
                        handleOnChange={(data: any) =>
                          formik.setFieldValue(
                            data?.target?.name,
                            data?.target?.value
                          )
                        }
                        handleClearValue={() =>
                          formik.setFieldValue("withdrawal_branch_code", "")
                        }
                      />
                      {formik.touched.withdrawal_branch_code &&
                        formik.errors.withdrawal_branch_code && (
                          <div className="text-xs text-rose-500 error-msg">
                            {formik.errors.withdrawal_branch_code as string}
                          </div>
                        )}
                    </div>
                  </>
                ) : (
                  formik?.values?.withdrawal_bank_identifier === false && (
                    <>
                      {/* IBAN */}
                      <div className="md:w-1/2 w-full px-2.5 mb-[.9375rem] relative">
                        <TextInput
                          required={false}
                          name="withdrawal_iban"
                          type="text"
                          label="IBAN"
                          value={formik.values.withdrawal_iban}
                          errorClass={
                            formik.touched.withdrawal_iban &&
                            formik.errors.withdrawal_iban &&
                            "border-rose-500"
                          }
                          id="withdrawal_iban"
                          handleOnChange={(data: any) =>
                            formik.setFieldValue(
                              data?.target?.name,
                              data?.target?.value
                            )
                          }
                          handleClearValue={() =>
                            formik.setFieldValue("withdrawal_iban", "")
                          }
                        />
                        {formik.touched.withdrawal_iban &&
                          formik.errors.withdrawal_iban && (
                            <div className="text-xs text-rose-500 error-msg">
                              {formik.errors.withdrawal_iban as string}
                            </div>
                          )}
                      </div>

                      {/* ACCOUNT SWIFT */}
                      <div className="md:w-1/2 w-full px-2.5 mb-[.9375rem] relative">
                        <TextInput
                          required={false}
                          name="withdrawal_swift"
                          type="text"
                          label="SWIFT/BIC"
                          value={formik.values.withdrawal_swift}
                          errorClass={
                            formik.touched.withdrawal_swift &&
                            formik.errors.withdrawal_swift &&
                            "border-rose-500"
                          }
                          id="withdrawal_swift"
                          handleOnChange={(data: any) =>
                            formik.setFieldValue(
                              data?.target?.name,
                              data?.target?.value
                            )
                          }
                          handleClearValue={() =>
                            formik.setFieldValue("withdrawal_swift", "")
                          }
                        />
                        {formik.touched.withdrawal_swift &&
                          formik.errors.withdrawal_swift && (
                            <div className="text-xs text-rose-500 error-msg">
                              {formik.errors.withdrawal_swift as string}
                            </div>
                          )}
                      </div>
                    </>
                  )
                )}

                {/* <div className="flex w-full px-2.5 mb-[.9375rem] relative">
                  <IKTSVG
                    className="w-4 h-4 mr-1.5 flex items-center justify-center fill-gray-200 bg-white rounded-full [&_text]:fill-violet-800"
                    path={info}
                    svgClassName="w-4 h-4"
                  />
                  <div className="flex-1">
                    <p className="font-basier text-sm12 ">
                      This bank account must support the base currency selected
                      for Tixstock
                    </p>
                  </div>
                </div>

                <div className="whiteFormFields md:w-1/2 w-full [&_>div]:mb-[.9375rem] px-2.5 relative">
                  <DropDownField
                    options={getCountryCodeOptions(
                      globalLayout.listingData?.country_list
                    )}
                    menuPlacement="top"
                    placeholder={
                      formik.values.withdrawal_bank_ac_country_code
                        ? ""
                        : "Bank country"
                    }
                    isCapital={true}
                    isValueCapital={true}
                    isSearchable={true}
                    divClassName={`md:mb-5 mb-[.9375rem] relative`}
                    className={` ${
                      formik.touched.withdrawal_bank_ac_country_code &&
                      formik.errors?.withdrawal_bank_ac_country_code &&
                      "all-dropdown-errorField"
                    }`}
                    name="withdrawal_bank_ac_country_code"
                    value={formik.values.withdrawal_bank_ac_country_code}
                    handleOnChange={(data: any) => {
                      formik.setFieldValue(
                        data?.target?.name,
                        data?.target?.value
                      );
                    }}
                  >
                    {formik.touched.withdrawal_bank_ac_country_code &&
                      formik.errors.withdrawal_bank_ac_country_code && (
                        <div
                          tabIndex={-1}
                          className="text-xxs text-rose-500 error-msg left-3"
                        >
                          {
                            formik.errors
                              .withdrawal_bank_ac_country_code as string
                          }
                        </div>
                      )}
                  </DropDownField>
                  {formik.touched.withdrawal_bank_ac_country_code &&
                    formik.errors.withdrawal_bank_ac_country_code && (
                      <div className="text-xs text-rose-500 error-msg">
                        {
                          formik.errors
                            .withdrawal_bank_ac_country_code as string
                        }
                      </div>
                    )}
                </div>

                <div className="md:w-1/2 w-full px-2.5 mb-[.9375rem] relative">
                  <TextInput
                    required={false}
                    disabled={true}
                    name="withdrawal_bank_ac_currency"
                    type="text"
                    label="Bank account currency"
                    value={formik.values.withdrawal_bank_ac_currency}
                    errorClass={
                      formik.touched.withdrawal_bank_ac_currency &&
                      formik.errors.withdrawal_bank_ac_currency &&
                      "border-rose-500"
                    }
                    id="withdrawal_bank_ac_currency"
                  />
                  {formik.touched.withdrawal_bank_ac_currency &&
                    formik.errors.withdrawal_bank_ac_currency && (
                      <div className="text-xs text-rose-500 error-msg">
                        {formik.errors.withdrawal_bank_ac_currency as string}
                      </div>
                    )}
                </div>

                <div className="flex w-full px-2.5 mb-[.9375rem] relative">
                  <IKTSVG
                    className="w-4 h-4 mr-1.5 flex items-center justify-center fill-gray-200 bg-white rounded-full [&_text]:fill-violet-800"
                    path={info}
                    svgClassName="w-4 h-4"
                  />
                  <div className="flex-1">
                    <p className="font-basier text-sm12 ">
                      Please supply the account holder's address
                    </p>
                  </div>
                </div>

                <div className="whiteFormFields w-full [&_>div]:mb-[.9375rem] px-2.5 relative">
                  <DropDownField
                    options={getCountryCodeOptions(
                      globalLayout.listingData?.country_list
                    )}
                    menuPlacement="top"
                    placeholder={
                      formik.values.recipient_country_code
                        ? ""
                        : "Select account holder country"
                    }
                    isCapital={true}
                    isValueCapital={true}
                    isSearchable={true}
                    divClassName={`md:mb-5 mb-[.9375rem] relative`}
                    className={` ${
                      formik.touched.recipient_country_code &&
                      formik.errors?.recipient_country_code &&
                      "all-dropdown-errorField"
                    }`}
                    name="recipient_country_code"
                    value={formik.values.recipient_country_code}
                    handleOnChange={(data: any) => {
                      formik.setFieldValue(
                        data?.target?.name,
                        data?.target?.value
                      );
                    }}
                  >
                    {formik.touched.recipient_country_code &&
                      formik.errors.recipient_country_code && (
                        <div
                          tabIndex={-1}
                          className="text-xxs text-rose-500 error-msg left-3"
                        >
                          {formik.errors.recipient_country_code as string}
                        </div>
                      )}
                  </DropDownField>
                  {formik.touched.recipient_country_code &&
                    formik.errors.recipient_country_code && (
                      <div className="text-xs text-rose-500 error-msg">
                        {formik.errors.recipient_country_code as string}
                      </div>
                    )}
                </div>

                <div className="md:w-1/2 w-full px-2.5 mb-[.9375rem] relative">
                  <TextInput
                    required={false}
                    name="recipient_street"
                    type="text"
                    label="Street"
                    value={formik.values.recipient_street}
                    errorClass={
                      formik.touched.recipient_street &&
                      formik.errors.recipient_street &&
                      "border-rose-500"
                    }
                    id="recipient_street"
                    handleOnChange={(data: any) =>
                      formik.setFieldValue(
                        data?.target?.name,
                        data?.target?.value
                      )
                    }
                    handleClearValue={() =>
                      formik.setFieldValue("recipient_street", "")
                    }
                  />
                  {formik.touched.recipient_street &&
                    formik.errors.recipient_street && (
                      <div className="text-xs text-rose-500 error-msg">
                        {formik.errors.recipient_street as string}
                      </div>
                    )}
                </div>

                <div className="md:w-1/2 w-full px-2.5 mb-[.9375rem] relative">
                  <TextInput
                    required={false}
                    name="recipient_city"
                    type="text"
                    label="City"
                    value={formik.values.recipient_city}
                    errorClass={
                      formik.touched.recipient_city &&
                      formik.errors.recipient_city &&
                      "border-rose-500"
                    }
                    id="recipient_city"
                    handleOnChange={(data: any) =>
                      formik.setFieldValue(
                        data?.target?.name,
                        data?.target?.value
                      )
                    }
                    handleClearValue={() =>
                      formik.setFieldValue("recipient_city", "")
                    }
                  />
                  {formik.touched.recipient_city &&
                    formik.errors.recipient_city && (
                      <div className="text-xs text-rose-500 error-msg">
                        {formik.errors.recipient_city as string}
                      </div>
                    )}
                </div>

                <div className="md:w-1/2 w-full px-2.5 mb-[.9375rem] relative">
                  <TextInput
                    required={false}
                    name="recipient_state"
                    type="text"
                    label="State"
                    value={formik.values.recipient_state}
                    errorClass={
                      formik.touched.recipient_state &&
                      formik.errors.recipient_state &&
                      "border-rose-500"
                    }
                    id="recipient_state"
                    handleOnChange={(data: any) =>
                      formik.setFieldValue(
                        data?.target?.name,
                        data?.target?.value
                      )
                    }
                    handleClearValue={() =>
                      formik.setFieldValue("recipient_state", "")
                    }
                  />
                  {formik.touched.recipient_state &&
                    formik.errors.recipient_state && (
                      <div className="text-xs text-rose-500 error-msg">
                        {formik.errors.recipient_state as string}
                      </div>
                    )}
                </div>

                <div className="md:w-1/2 w-full px-2.5 mb-[.9375rem] relative">
                  <TextInput
                    required={false}
                    name="recipient_postcode"
                    type="text"
                    label="Postcode/Zip Code"
                    value={formik.values.recipient_postcode}
                    errorClass={
                      formik.touched.recipient_postcode &&
                      formik.errors.recipient_postcode &&
                      "border-rose-500"
                    }
                    id="recipient_postcode"
                    handleOnChange={(data: any) =>
                      formik.setFieldValue(
                        data?.target?.name,
                        data?.target?.value
                      )
                    }
                    handleClearValue={() =>
                      formik.setFieldValue("recipient_postcode", "")
                    }
                  />
                  {formik.touched.recipient_postcode &&
                    formik.errors.recipient_postcode && (
                      <div className="text-xs text-rose-500 error-msg">
                        {formik.errors.recipient_postcode as string}
                      </div>
                    )}
                </div> */}

                {/* SAME USE FOR WHTDRAWALS */}
                <div
                  className={`inline-flex w-full items-center text-violet-800transition font-medium rounded px-2.5 mb-5 md:mt-2.5 mt-[.3125rem]`}
                >
                  <input
                    id="default-checkbox"
                    type="checkbox"
                    name="use_as_same"
                    checked={formik.values.use_as_same}
                    className={`cursor-pointer  w-5 h-5 text-indigo-500 bg-gray-100 border-gray-300 rounded focus:ring-0 focus:ring-offset-0   dark:bg-gray-700 dark:border-gray-600 `}
                    onChange={(e: any) => {
                      formik.setFieldValue("use_as_same", e.target.checked);
                      if (!e.target.checked) {
                        formik.setFieldTouched("funding_bank_name", false);
                        formik.setFieldTouched("funding_ac_holder_name", false);
                        formik.setFieldTouched("funding_iban", false);
                        formik.setFieldTouched("funding_swift", false);
                      }
                    }}
                  />
                  <label
                    htmlFor="default-checkbox"
                    className={`ml-2 !text-sm13 font-basier_med  `}
                  >
                    Use the same account for funding and withdrawals
                  </label>
                </div>
              </fieldset>

              {/* FUNDING */}
              {!formik.values.use_as_same && (
                <div className="[&_h4]:text-sm13">
                  <FormSubHeader
                    tooltipRequire={false}
                    title="Funding bank account details"
                    error={
                      formik.touched?.base_currency &&
                      formik.errors?.base_currency
                    }
                  />
                </div>
              )}
              <fieldset
                className={`flex new-inp flex-wrap -mx-2.5 ${
                  formik.values.use_as_same && "hidden"
                }`}
              >
                {/* BANK NAME */}
                <div className="w-full px-2.5 mb-[.9375rem] relative">
                  <TextInput
                    name="funding_bank_name"
                    type="text"
                    label="Name of bank"
                    id="funding_bank_name"
                    required={false}
                    value={formik.values.funding_bank_name}
                    errorClass={
                      formik.touched.funding_bank_name &&
                      formik.errors.funding_bank_name &&
                      "border-rose-500"
                    }
                    handleOnChange={(data: any) =>
                      formik.setFieldValue(
                        data?.target?.name,
                        data?.target?.value
                      )
                    }
                    handleClearValue={() =>
                      formik.setFieldValue("funding_bank_name", "")
                    }
                  />
                  {formik.touched.funding_bank_name &&
                    formik.errors.funding_bank_name && (
                      <div className="text-xs text-rose-500 error-msg">
                        {formik.errors.funding_bank_name as string}
                      </div>
                    )}
                </div>

                {/* Account holder name */}
                <div className="w-full px-2.5 mb-[.9375rem] relative">
                  <TextInput
                    name="funding_ac_holder_name"
                    type="text"
                    label="Account name"
                    id="funding_ac_holder_name"
                    required={false}
                    value={formik.values.funding_ac_holder_name}
                    errorClass={
                      formik.touched.funding_ac_holder_name &&
                      formik.errors.funding_ac_holder_name &&
                      "border-rose-500"
                    }
                    handleOnChange={(data: any) =>
                      formik.setFieldValue(
                        data?.target?.name,
                        data?.target?.value
                      )
                    }
                    handleClearValue={() =>
                      formik.setFieldValue("funding_ac_holder_name", "")
                    }
                  />

                  {formik.touched.funding_ac_holder_name &&
                    formik.errors.funding_ac_holder_name && (
                      <div className="text-xs text-rose-500 error-msg">
                        {formik.errors.funding_ac_holder_name as string}
                      </div>
                    )}
                </div>

                {/* FUNDING BANK IDENTIFIER */}
                <div className={`w-full px-2.5 mb-5 relative `}>
                  {/* SUB FROM HEADER */}
                  <SubFormHeader title="Select which identifier applies to this bank account" />
                  <FormRow className="seller_info" titleNedeed={false}>
                    <SignUpRadio
                      title="IBAN/SWIFT/BIC"
                      id="funding_iban_swift_bic"
                      name="funding_bank_identifier"
                      postKey={false}
                      value={formik?.values?.funding_bank_identifier}
                      handleSeller={(data: any) => {
                        formik.setFieldValue("funding_bank_identifier", false);
                        formik.setFieldValue("funding_ac_number", "");
                        formik.setFieldValue("funding_branch_code", "");
                      }}
                    />
                    <SignUpRadio
                      title="Account number"
                      id="funding_acc_number"
                      name="funding_bank_identifier"
                      postKey={true}
                      value={formik?.values?.funding_bank_identifier}
                      handleSeller={(data: any) => {
                        formik.setFieldValue("funding_bank_identifier", true);
                        formik.setFieldValue("funding_iban", "");
                        formik.setFieldValue("funding_swift", "");
                      }}
                    />
                  </FormRow>
                </div>

                {formik?.values?.funding_bank_identifier ? (
                  <>
                    {/* FUNDING ACCOUNT NUMBER */}
                    <div
                      className={`md:w-1/2 w-full px-2.5 mb-[.9375rem] relative`}
                    >
                      <TextInput
                        required={false}
                        name="funding_ac_number"
                        type="text"
                        label="Account number"
                        errorClass={
                          formik.touched.funding_ac_number &&
                          formik.errors.funding_ac_number &&
                          "border-rose-500"
                        }
                        id="funding_ac_number"
                        value={formik.values.funding_ac_number}
                        handleOnChange={(data: any) =>
                          formik.setFieldValue(
                            data?.target?.name,
                            data?.target?.value
                          )
                        }
                        handleClearValue={() =>
                          formik.setFieldValue("funding_ac_number", "")
                        }
                      />

                      {formik.touched.funding_ac_number &&
                        formik.errors.funding_ac_number && (
                          <div className="text-xs text-rose-500 error-msg">
                            {formik.errors.funding_ac_number as string}
                          </div>
                        )}
                    </div>

                    {/* BRANCH / SORT CODE */}
                    <div className="md:w-1/2 w-full px-2.5 mb-[.9375rem] relative">
                      <TextInput
                        required={false}
                        name="funding_branch_code"
                        type="text"
                        label="Sort code/Routing Number"
                        value={formik.values.funding_branch_code}
                        errorClass={
                          formik.touched.funding_branch_code &&
                          formik.errors.funding_branch_code &&
                          "border-rose-500"
                        }
                        id="funding_branch_code"
                        handleOnChange={(data: any) =>
                          formik.setFieldValue(
                            data?.target?.name,
                            data?.target?.value
                          )
                        }
                        handleClearValue={() =>
                          formik.setFieldValue("funding_branch_code", "")
                        }
                      />
                      {formik.touched.funding_branch_code &&
                        formik.errors.funding_branch_code && (
                          <div className="text-xs text-rose-500 error-msg">
                            {formik.errors.funding_branch_code as string}
                          </div>
                        )}
                    </div>
                  </>
                ) : (
                  formik?.values?.funding_bank_identifier === false && (
                    <>
                      {/* ACCOUNT IBAN */}
                      <div className="md:w-1/2 w-full px-2.5 mb-[.9375rem] relative">
                        <TextInput
                          required={false}
                          name="funding_iban"
                          type="text"
                          label="IBAN"
                          id="funding_iban"
                          value={formik.values.funding_iban}
                          errorClass={
                            formik.touched.funding_iban &&
                            formik.errors.funding_iban &&
                            "border-rose-500"
                          }
                          handleOnChange={(data: any) =>
                            formik.setFieldValue(
                              data?.target?.name,
                              data?.target?.value
                            )
                          }
                          handleClearValue={() =>
                            formik.setFieldValue("funding_iban", "")
                          }
                        />

                        {formik.touched.funding_iban &&
                          formik.errors.funding_iban && (
                            <div className="text-xs text-rose-500 error-msg">
                              {formik.errors.funding_iban as string}
                            </div>
                          )}
                      </div>

                      {/* ACCOUNT SWIFT */}
                      <div className="md:w-1/2 w-full px-2.5 mb-[.9375rem] relative">
                        <TextInput
                          required={false}
                          name="funding_swift"
                          type="text"
                          label="SWIFT/BIC"
                          id="funding_swift"
                          value={formik.values.funding_swift}
                          errorClass={
                            formik.touched.funding_swift &&
                            formik.errors.funding_swift &&
                            "border-rose-500"
                          }
                          handleOnChange={(data: any) =>
                            formik.setFieldValue(
                              data?.target?.name,
                              data?.target?.value
                            )
                          }
                          handleClearValue={() =>
                            formik.setFieldValue("funding_swift", "")
                          }
                        />

                        {formik.touched.funding_swift &&
                          formik.errors.funding_swift && (
                            <div className="text-xs text-rose-500 error-msg">
                              {formik.errors.funding_swift as string}
                            </div>
                          )}
                      </div>
                    </>
                  )
                )}
              </fieldset>
            </>
          )}
        </div>

        {/* FOOTER */}
        {!userDetailsShow?.txPayStatus &&
          size(userDetailsShow?.txPayData?.identity_proof) === 0 && (
            <div className=" offcanvas-footer bottom-0 right-0 w-full flex flex-wrap justify-between px-5 py-3 bg-white z-10 border-t">
              <div className="l_btn gap-2.5 flex flex-wrap items-center">
                <button
                  type="button"
                  className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
                  data-bs-dismiss="offcanvas"
                  aria-label="close"
                  onClick={() => handleClose()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="border border-indigo-500 hover:border-gray-300 text-white hover:text-violet-800 bg-indigo-500 hover:bg-white transition font-medium rounded text-sm13 px-2.5 py-0.5"
                  onClick={() => formik.handleSubmit()}
                >
                  Submit
                </button>
              </div>
            </div>
          )}
      </div>
      {customLoader && <ProgressBardLoader secondCounter={1.5} />}
    </div>
  );
};

export default CompleteSignUp;
