import CopyLink from "assets/media/icons/other_icons/CopyLink";
import clsx from "clsx";
import { referralLinkGenerator } from "helpers/AssetHelpers";
import { copy } from "pages/add_inventory/core/_functions";
import { useState } from "react";
import { Tooltip } from "react-tooltip";

interface ReferralEarningsCardProps {
  earnings: string;
  referralLink: string;
}

const ReferralEarningsCard: React.FC<ReferralEarningsCardProps> = ({
  earnings,
  referralLink,
}) => {
  const [isCopy, setIsCopy] = useState<string>("");

  return (
    <div className="w-full border border-gray-200 rounded mb-[0.9375rem]">
      <div className="flex w-full items-center justify-between px-[0.8125rem] pt-2.5 pb-[0.6875rem] border-b">
        <p className="text-sm12 leading-[0.9375rem] font-medium">
          Referral earnings
        </p>
        <p className="text-sm15 leading-[1.1875rem] font-medium">{earnings}</p>
      </div>
      <div className="flex w-full items-center justify-between px-[0.8125rem] py-[0.6875rem]">
        <p className="text-sm12 leading-[0.9375rem] font-medium">
          {referralLinkGenerator(referralLink)}
        </p>

        <span data-tooltip-id="referral-popup-my-account">
          <CopyLink
            className={clsx(
              `w-[1.125rem] h-[1.125rem] cursor-pointer transition`,
              isCopy
                ? "stroke-green-500"
                : "stroke-violet-500 hover:stroke-indigo-500"
            )}
            onClick={(e: any) => {
              const copiedData: string = copy(
                `Maximise your inventory's impact with Tixstock's smart distribution technology. Register now on ${
                  referralLinkGenerator(referralLink) ?? ""
                }`
              );
              setIsCopy(copiedData);
              setTimeout(() => {
                setIsCopy("");
              }, 800);
            }}
          />
          <Tooltip
            id={`referral-popup-my-account`}
            content={isCopy ? "Copied" : "Copy"}
            variant="light"
            className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-[10000] "
          />
        </span>
      </div>
    </div>
  );
};

export default ReferralEarningsCard;
