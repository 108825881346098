export const CrossIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9.546"
    height="9.546"
    viewBox="0 0 9.546 9.546"
    {...props}
  >
    <g
      id="Group_3154"
      data-name="Group 3154"
      transform="translate(-1081.227 -498.227)"
    >
      <g id="Group_350" data-name="Group 350" transform="translate(-548 158)">
        <rect
          id="Rectangle_15"
          data-name="Rectangle 15"
          width="12"
          height="1.5"
          transform="translate(1630.288 340.227) rotate(45)"
        />
        <rect
          id="Rectangle_173"
          data-name="Rectangle 173"
          width="12"
          height="1.5"
          transform="translate(1638.773 341.288) rotate(135)"
        />
      </g>
    </g>
  </svg>
);
