/* eslint-disable react-hooks/exhaustive-deps */
import { debounce, isObject, isString } from "lodash";
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Tooltip } from "react-tooltip";
import { TableVirtuoso } from "react-virtuoso";
import CollapseSVG from "../../../assets/media/icons/other_icons/Collapse.svg";
import EyeSVG from "../../../assets/media/icons/other_icons/Eye.svg";
import MinusOutline from "../../../assets/media/icons/other_icons/minus-outline.svg";
import PlusOutLine from "../../../assets/media/icons/other_icons/plus-outline.svg";
import ReferralSVG from "../../../assets/media/icons/other_icons/referral.svg";
import UploadOutOutline from "../../../assets/media/icons/other_icons/upload-out-outline.svg";
import UploadOutline from "../../../assets/media/icons/other_icons/upload-outline.svg";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import {
  isChildInViewport,
  isMobileView,
  scrollToVisible,
} from "../../../helpers/Functions";
import { SYMBOLS } from "../../../helpers/const";
import TooltipPortal from "../../tx_trade/table/component/TooltipPortal";
import EventDetailView from "../component/EventDetailView";
import { TXContext } from "../core/TXProvider";
import { display } from "../core/_functions";
import TableHeader from "./components/TableHeader";
import TableRow from "./components/TableRow";

const Table = ({ style, ...props }: any) => (
  <table
    {...props}
    className="w-full text-xs text-left"
    style={{
      ...style,
    }}
  />
);
const TableHead = ({ style, ...props }: any) => {
  return (
    <thead
      {...props}
      className="text-xs text-gray-400 "
      style={{
        ...style,
        position: "static",
      }}
    />
  );
};

const DataTable = ({
  ledgerData,
  paginateData,
  txPayLoading,
  settab2Active,
}: any) => {
  const globalLayout: any = useContext(LayoutContext);
  const isGodAdmin = globalLayout?.allowedAccess?.account_info?.is_god_admin;

  const [showLedger, setShowLedger] = useState<any>({
    show: false,
    data: "",
    index: "",
    ledgerIndex: "",
    files: [],
  });

  const layout = useContext(TXContext);
  // HANDLE LEDGER VIEW PAGE
  const handleOrderView = (
    index: number,
    ledgerIndex: number,
    id: any,
    files: any
  ) => {
    if (showLedger?.index === index) {
      setShowLedger({
        show: false,
      });
      display(false);
    } else {
      setShowLedger({
        show: true,
        index,
        ledgerIndex,
        id,
        files: files,
      });
    }
  };

  const [hoveredId, setHoveredId] = useState(null);

  // SMOOTH SCROLL IN TX-PAY
  useEffect(() => {
    const isInViewport = isChildInViewport(
      `tx-pay-item-${showLedger?.ledgerIndex}-${showLedger?.index}`
    );
    if (!isInViewport) {
      scrollToVisible(
        `tx-pay-item-${showLedger?.ledgerIndex}-${showLedger?.index}`
      );
    }
  }, [showLedger.index]);

  // new one - 12-12
  useLayoutEffect(() => {
    display(false);
    setShowLedger({ show: false });
    let timeout: any;
    const renderCall = () => {
      timeout = setTimeout(() => {
        ledgerData?.forEach((obj: any, index: any) => {
          const elementId = `txPay-lazyload-${index}`;
          const divElement: any = document.querySelector(`#${elementId}`);

          if (divElement) {
            const elemHeight =
              divElement.offsetHeight + divElement.scrollHeight;
            if (divElement.scrollHeight) {
              divElement.style.height = divElement.scrollHeight + "px";
            } else {
              // setTimeout(renderCall, 100);
              setTimeout(() => {
                divElement.style.height = divElement.scrollHeight + "px";
              }, 100);
            }
          }
        });
      }, 500);
    };
    renderCall();
    const pmicons = document.querySelectorAll(".PMicons button");
    pmicons.forEach((button, index) => {
      if (pmicons) {
        button.addEventListener("click", function () {
          setTimeout(() => {
            renderCall();
          });
        });
      }
    });

    // Clear the timeout in case the component unmounts or the effect re-runs
    return () => {
      clearTimeout(timeout);
    };
  }, [ledgerData]);

  const ScrollSeekPlaceholder = ({
    height,
    index,
  }: // context: { randomHeights },
  any) => (
    <div
      key={index}
      style={{
        height,
        padding: "8px",
        boxSizing: "border-box",
        overflow: "hidden",
      }}
      className=" abc"
    >
      <div
        className=" shimmer-effect "
        style={{
          height: "40px",
        }}
      ></div>
    </div>
  );

  useEffect(() => {
    const handleScroll = debounce(() => {
      if (
        window.innerHeight + window.scrollY + 1 >=
        document.body.offsetHeight
      ) {
        if (paginateData?.current_page < paginateData?.last_page) {
          layout.setTXPayFilterHandle((current: any) => {
            let nextPage: number = paginateData?.current_page + 1;

            return {
              ...current,
              page: nextPage,
              per_page: 50,
            };
          });
        }
      }
    }, 300); // Adjust the debounce delay as needed

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [paginateData]);

  useEffect(() => {
    const parentDiv: HTMLElement | null =
      document.getElementById("custom-loader");

    const hideLoader = () => {
      parentDiv?.classList.add("hidden");
      parentDiv?.classList.remove("visible");
    };

    const showLoader = () => {
      parentDiv?.classList.add("visible");
      parentDiv?.classList.remove("hidden");
    };

    if (
      paginateData?.current_page !== undefined &&
      paginateData?.last_page !== undefined
    ) {
      if (paginateData.current_page === paginateData.last_page) {
        hideLoader();
      } else if (paginateData.current_page < paginateData.last_page) {
        showLoader();
      }
    }

    let count = 0;
    let open = 0;

    ledgerData?.forEach((obj: any, index: any) => {
      const elementId = `EventHeading${index}`;
      const divElement: HTMLElement | null = document.querySelector(
        `#${elementId}`
      );
      if (divElement?.getAttribute("aria-expanded") === "false") {
        count++;
      }
      if (divElement?.getAttribute("aria-expanded") === "true") {
        open++;
      }
    });

    if (count === ledgerData?.length) {
      hideLoader();
    }
    if (globalLayout?.isMobile && open < 2) {
      hideLoader();
    }
  }, [paginateData, ledgerData, globalLayout?.isMobile]);

  const handleCollapseAll = (idx: any) => {
    const updatedData = ledgerData?.map((obj: any, index: any) =>
      index === idx ? { ...obj, isOpen: !obj?.isOpen } : obj
    );
    layout.setItems(updatedData);
  };

  useEffect(() => {
    display(true);
  }, [globalLayout.htmlFontSize]);

  // const closeEvent = ledgerData?.filter((obj: any) => !obj?.isOpen);

  // useEffect(() => {
  //   const handleScrollTable = () => {
  //     const HeaderHeight: any = document.querySelector("header")?.clientHeight;
  //     const inventoryaccordion: any =
  //       document.getElementById("inventoryaccordion")?.clientHeight;
  //     const headingOne: any =
  //       document.getElementById("headingOne")?.clientHeight;
  //     const windScroll: number = window.scrollY;
  //     const totleScroll = HeaderHeight + inventoryaccordion + headingOne;
  //     const loadHeads: NodeListOf<Element> =
  //       document.querySelectorAll(".tableHead");

  //     // Loop through each loadHead element
  //     loadHeads.forEach((loadHead) => {
  //       if (windScroll > totleScroll) {
  //         loadHead.classList.add("-mt-10");
  //         loadHead.classList.remove("opacity-0");
  //         loadHead.classList.remove("h-0");
  //       } else if (windScroll < totleScroll) {
  //         loadHead.classList.remove("-mt-10");
  //         loadHead.classList.add("opacity-0");
  //         loadHead.classList.add("h-0");
  //       }
  //     });
  //   };
  //   document.addEventListener("scroll", handleScrollTable);
  //   document.addEventListener("resize", handleScrollTable);
  // }, []);
  useEffect(() => {
    const handleScrollTable = () => {
      // Select all elements with class 'loadHead'
      const loadHeads: NodeListOf<Element> =
        document.querySelectorAll(".tableHead");
      const HeaderHeight: any = document.querySelector("header")?.clientHeight;
      const filters_block: any =
        document.getElementById("filters_block")?.clientHeight;
      const txFilter: any = document.getElementById("txFilter")?.clientHeight;
      const EventHeading: any =
        document.querySelector(".event__header")?.clientHeight;

      const stickyElement = document.getElementById(
        "topFilters"
      ) as HTMLElement;
      var computedStyle = window.getComputedStyle(stickyElement);
      var paddingBottom = computedStyle.paddingBottom;
      var paddingBottomValue = parseInt(paddingBottom, 10);

      const headingOne: any =
        document.getElementById("headingOne")?.clientHeight;
      const inventoryaccordion: any =
        document.getElementById("inventoryaccordion")?.clientHeight;
      const totalScroll: any =
        // HeaderHeight + filters_block + txFilter + EventHeading + 22;
        headingOne + inventoryaccordion;

      // Loop through each loadHead element
      loadHeads.forEach((loadHead) => {
        const loadHeadTop = loadHead.getBoundingClientRect().top;
        // Check if loadHead is 300px from the top of the window
        if (loadHeadTop <= totalScroll) {
          loadHead.classList.add("-mt-10");
          loadHead.classList.remove("opacity-0");
          loadHead.classList.remove("h-0");
        } else if (loadHeadTop >= totalScroll) {
          loadHead.classList.remove("-mt-10");
          loadHead.classList.add("opacity-0");
          loadHead.classList.add("h-0");
        }
      });
    };

    // Add scroll event listener
    document.addEventListener("resize", handleScrollTable);
    document.addEventListener("scroll", handleScrollTable);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("resize", handleScrollTable);
      document.removeEventListener("scroll", handleScrollTable);
    };
  }, []);

  const tableRefs = useRef<(HTMLDivElement | null)[]>(
    Array.from({ length: ledgerData }, () => null)
  );
  const stickyTableRefs = useRef<(HTMLDivElement | null)[]>(
    Array.from({ length: ledgerData }, () => null)
  );

  // On scroll virtuoso list scroll stickyhead
  const handleScrollVirtuoso = () => {
    tableRefs.current.forEach((tableRef, index) => {
      if (tableRef && stickyTableRefs.current[index]) {
        const scrollLeft = tableRef.scrollLeft;
        stickyTableRefs.current[index]!.scrollLeft = scrollLeft;
      }
    });
  };

  // On scroll sticky head list scroll virtuoso
  const handleScrollHead = () => {
    stickyTableRefs.current.forEach((stickyTableRef, index) => {
      if (stickyTableRef && tableRefs.current[index]) {
        const scrollLeft = stickyTableRef.scrollLeft;
        tableRefs.current[index]!.scrollLeft = scrollLeft;
      }
    });
  };
  return (
    <>
      <div className="hidden">
        <IKTSVG
          className="flex items-center justify-center fill-violet-800 transition w-[12px] h-[12px] mr-1"
          path={PlusOutLine}
          svgClassName="w-3 h-3"
        />
        <IKTSVG
          className="flex items-center justify-center fill-violet-800 transition w-[12px] h-[12px] mr-1"
          path={MinusOutline}
          svgClassName="w-3 h-3"
        />
        <IKTSVG
          className="flex items-center justify-center fill-violet-800 transition w-[12px] h-[12px] mr-1"
          path={UploadOutline}
          svgClassName="w-3 h-3"
        />
        <IKTSVG
          className="flex items-center justify-center fill-violet-800 transition w-[12px] h-[12px] mr-1"
          path={UploadOutOutline}
          svgClassName="w-3 h-3"
        />
      </div>
      <div
        className={`accordion_wrap md:px-5 transition-all duration-300 ${
          settab2Active ? "hidden" : "block"
        }`}
        id="TXAccordion"
      >
        {ledgerData &&
          Array.isArray(ledgerData) &&
          ledgerData.map((ledger: any, index: any) => (
            <div
              className="accordion my-4 font-medium first:mt-0 inventory-list"
              id={`TXAccordion${index}`}
            >
              <div className="accordion-item bg-white  border-gray-200 !rounded">
                <div
                  className="flex accordion-header mb-0 event__header bg-violet-700 rounded-t text-white title-accordion [&[data-te-collapse-collapsed]]:rounded-b"
                  id={`EventHeading${index}`}
                  data-te-collapse-init
                  data-te-toggle="collapse"
                  data-te-target={`#EventCollapseBody${index}`}
                  aria-expanded="true"
                  aria-controls={`EventCollapseBody${index}`}
                  onClick={() => handleCollapseAll(index)}
                >
                  <div className="flex md:flex-nowrap flex-wrap w-full items-center pl-4">
                    <span className="text-sm14 font-medium">
                      {ledger.month}
                    </span>
                    <button
                      className="group relative flex items-center py-4 text-base text-gray-800 text-left border-l border-white/20 !transition-all duration-300 focus:outline-none group ml-auto w-[2.5rem] justify-center"
                      type="button"
                      data-te-collapse-init
                      data-tooltip-id={`collapseIcon-${ledger?.month}`}
                      data-te-toggle="collapse"
                      data-te-target={`#EventCollapseBody${index}`}
                      aria-expanded="false"
                      aria-controls={`EventCollapseBody${index}`}
                    >
                      {!isMobileView && (
                        <TooltipPortal
                          id={`collapseIcon-${ledger?.month}`}
                          content={`${ledger?.isOpen ? `Collapse` : "Expand"}`}
                          place="top"
                          variant="light"
                          className="!bg-white !opacity-100 !py-0.5 !px-1 !text-xxs font-medium  z-10 mt-3 "
                        />
                      )}
                      <IKTSVG
                        className="flex items-center justify-center group-[.collapsed]:rotate-180 fill-white group-[[data-te-collapse-collapsed]]:rotate-180 !transition-all duration-300"
                        path={CollapseSVG}
                      />
                    </button>
                  </div>
                </div>
                <div
                  className="tableHead opacity-0 h-0 123 overflow-auto scrollbar-none"
                  id={`loadHead`}
                  ref={(el) => (stickyTableRefs.current[index] = el)}
                  onScroll={handleScrollHead}
                >
                  <table className="w-full text-xs text-left">
                    <thead className="text-xs text-gray-400">
                      <tr className="border-b bg-white">
                        {/* Team Name */}
                        {isGodAdmin && (
                          <TableHeader
                            title="Team Name"
                            width="6.25rem"
                            isArrow={false}
                            cellWidth="min-w-[10.625rem]"
                            postKey="team_name"
                          />
                        )}

                        {/* Date */}
                        <TableHeader
                          title="Date"
                          width="7.5rem"
                          cellWidth="min-w-[7.5rem]"
                          isArrow={false}
                          postKey="payout_date"
                        />

                        {/* Type */}
                        <TableHeader
                          title="Type"
                          width="6.25rem"
                          isArrow={false}
                          cellWidth="min-w-[6.25rem]"
                          postKey="type"
                        />

                        {/* In */}
                        <TableHeader
                          title="In"
                          width="9.375rem"
                          isArrow={false}
                          cellWidth="min-w-[9.375rem]"
                          postKey="in"
                        />

                        {/* Out */}
                        <TableHeader
                          title="Out"
                          isArrow={false}
                          width="9.375rem"
                          cellWidth="min-w-[9.375rem]"
                          postKey="out"
                        />
                        {/* PayOut Reference */}
                        <TableHeader
                          title="Payout reference"
                          width="9.375rem"
                          cellWidth="min-w-[9.375rem]"
                        />
                        {/* Description */}
                        <TableHeader
                          title="Description"
                          cellWidth="lg:w-full w-[20rem] min-w-[20rem] overflow-hidden white-space-nowrap text-ellipsis"
                        />
                        <th className="sticky right-0 ml-auto  p-0 w-[2.5625rem]">
                          <div className="flex justify-end h-[2.5rem]  bg-white w-[2.5625rem]"></div>
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>

                <div
                  id={`EventCollapseBody${index}`}
                  className="accordion-collapse collapse show accordion-cont"
                  aria-labelledby={`EventCollapseBody${index}`}
                  data-te-collapse-item
                  data-te-collapse-show
                >
                  <div className="accordion-body relative bottom_acc">
                    {/* <div className="absolute bg-white left-0 top-0 w-full h-full gap-y-2.5 flex flex-wrap mr-1 z-[0] scrollbar-none flex-col">
                      {Array.from(
                        { length: ledger.transactions?.length },
                        (v, i) => (
                          <div className="bg-gray-100 rounded mr-1 h-10 w-full shimmer-effect"></div>
                        )
                      )}
                    </div> */}
                    <div
                      className="sales-table overflow-auto scrollbar-none scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full hover-style2"
                      ref={(el) => (tableRefs.current[index] = el)}
                      onScroll={handleScrollVirtuoso}
                    >
                      <TableVirtuoso
                        data={ledger.transactions}
                        useWindowScroll
                        overscan={200}
                        totalCount={ledger.transactions}
                        id={`txPay-lazyload-${index}`}
                        className="transition-all duration-300"
                        fixedHeaderContent={() => (
                          <tr className="border-b bg-white">
                            {/* Team Name */}
                            {isGodAdmin && (
                              <TableHeader
                                title="Team Name"
                                width="6.25rem"
                                isArrow={false}
                                cellWidth="min-w-[10.625rem]"
                                postKey="team_name"
                              />
                            )}

                            {/* Date */}
                            <TableHeader
                              title="Date"
                              width="6.25rem"
                              cellWidth="min-w-[7.5rem]"
                              isArrow={false}
                              postKey="payout_date"
                            />

                            {/* Type */}
                            <TableHeader
                              title="Type"
                              width="6.25rem"
                              isArrow={false}
                              cellWidth="min-w-[6.25rem]"
                              postKey="type"
                            />

                            {/* In */}
                            <TableHeader
                              title="In"
                              width="9.375rem"
                              isArrow={false}
                              cellWidth="min-w-[9.375rem]"
                              postKey="in"
                            />

                            {/* Out */}
                            <TableHeader
                              title="Out"
                              width="9.375rem"
                              isArrow={false}
                              cellWidth="min-w-[9.375rem]"
                              postKey="out"
                            />

                            {/* PayOut Reference */}
                            <TableHeader
                              title="Payout reference"
                              width="9.375rem"
                              cellWidth="min-w-[9.375rem]"
                            />

                            {/* Description */}
                            <TableHeader
                              title="Description"
                              cellWidth="lg:w-full w-[20rem] max-lg:max-w-[20rem] max-lg:min-w-[20rem] overflow-hidden white-space-nowrap text-ellipsis"
                            />
                            <th className="sticky right-0 ml-auto  p-0 w-[2.5625rem]">
                              <div className="flex justify-end h-[2.5rem]  bg-white w-[2.5625rem]"></div>
                            </th>
                          </tr>
                        )}
                        components={{
                          Table: Table,
                          TableHead: TableHead,
                          TableRow: (props) => {
                            return (
                              <TableRow
                                {...props}
                                hoveredId={hoveredId}
                                setHoveredId={setHoveredId}
                                showLedger={showLedger}
                                index={index}
                                txPayLoading={txPayLoading}
                              />
                            );
                          },
                          ScrollSeekPlaceholder,
                        }}
                        itemContent={(tIndex: any, transaction: any) => {
                          const matchedView =
                            showLedger?.index === tIndex &&
                            showLedger?.ledgerIndex === index;
                          const description: string = (
                            transaction.description as string
                          )?.toLowerCase();

                          return (
                            <>
                              <React.Fragment key={tIndex}>
                                {isGodAdmin && (
                                  <td
                                    style={{ height: "2.5625rem" }}
                                    className="py-1.5 px-2.5 font-medium whitespace-nowrap ellips-text"
                                  >
                                    <p className="w-[9.0625rem]">
                                      {isString(transaction?.team_name)
                                        ? transaction?.team_name
                                        : isObject(transaction?.team_name)
                                        ? transaction?.team_name?.team?.name
                                        : ""}
                                    </p>
                                  </td>
                                )}
                                <td
                                  style={{ height: "2.5625rem" }}
                                  className="py-1.5 px-2.5 font-medium whitespace-nowrap ellips-text"
                                >
                                  <p className="w-[6.25rem]">
                                    {transaction?.date}
                                  </p>
                                </td>
                                <td
                                  style={{ height: "2.5625rem" }}
                                  className="py-1.5 px-2.5 font-medium whitespace-nowrap"
                                >
                                  <div className="credit flex items-center">
                                    <IKTSVG
                                      className="flex items-center justify-center fill-violet-800 transition w-[12px] h-[12px] mr-1"
                                      path={
                                        transaction?.type === "Credit"
                                          ? PlusOutLine
                                          : transaction?.type === "Debit"
                                          ? MinusOutline
                                          : transaction?.in
                                          ? UploadOutline
                                          : UploadOutOutline
                                      }
                                      svgClassName="w-3 h-3"
                                    />
                                    {transaction?.type}
                                  </div>
                                </td>
                                <td
                                  style={{ height: "2.5625rem" }}
                                  className="py-1.5 px-2.5 font-medium whitespace-nowrap text-sm"
                                >
                                  {transaction.in
                                    ? `${SYMBOLS?.[transaction?.currency]}${
                                        transaction.in
                                      }`
                                    : "-"}
                                </td>
                                <td
                                  style={{ height: "2.5625rem" }}
                                  className="py-1.5 px-2.5 font-medium whitespace-nowrap text-sm"
                                >
                                  {transaction.out
                                    ? `${
                                        SYMBOLS?.[transaction?.currency]
                                          ? SYMBOLS?.[transaction?.currency]
                                          : transaction?.currency
                                      }${transaction.out}`
                                    : "-"}
                                </td>
                                <td
                                  style={{ height: "2.5625rem" }}
                                  className="py-1.5 px-2.5 font-medium"
                                >
                                  <span className="max-w-[130px] block overflow-hidden whitespace-nowrap text-ellipsis">
                                    {" "}
                                    {transaction?.payout_reference || "-"}
                                  </span>
                                </td>
                                <td
                                  style={{ height: "2.5625rem" }}
                                  className="py-1.5 px-2.5 font-medium ellips-text"
                                >
                                  {/* {!transaction.description && (
                                    <button className="group flex items-center justify-center pl-2 pr-2.5 py-1 bg-green-600 bg-opacity-[7%] rounded hover:bg-indigo-500 hover:text-white">
                                      <IKTSVG
                                        className={`fill-violet-800 transition mr-[.4375rem] group-hover:fill-white`}
                                        path={ReferralSVG}
                                        svgClassName="w-3.5 h-3.5"
                                      />
                                      Referral earnings
                                    </button>
                                  )} */}
                                  <p
                                    className=" whitespace-nowrap text-ellipsis md:max-w-[31.25rem] max-w-[18.75rem]"
                                    title={description}
                                  >
                                    {description === "referral earnings" ? (
                                      <button className="group cursor-default flex items-center justify-center pl-2 pr-2.5 py-1 bg-green-600 bg-opacity-[7%] rounded">
                                        <IKTSVG
                                          className={`fill-violet-800 transition mr-[.4375rem]`}
                                          path={ReferralSVG}
                                          svgClassName="w-3.5 h-3.5"
                                        />
                                        Referral earnings
                                      </button>
                                    ) : (
                                      transaction?.description ?? ""
                                    )}
                                  </p>
                                </td>

                                {/* EYE BUTTON */}
                                {(isGodAdmin ||
                                  description !== "referral earnings") && (
                                  <td className="group sticky right-0 ml-auto p-0 icon_td  border-b">
                                    <React.Fragment key={tIndex}>
                                      <div
                                        className={`${
                                          matchedView
                                            ? `bg-indigo-500 bg-opacity-[7%]`
                                            : ""
                                        }`}
                                      >
                                        <div
                                          className={`flex  relative w-full h-full border-l `}
                                        >
                                          <button
                                            className={`group flex items-center justify-center p-1 md:p-1 w-10 md:w-10 h-[2.5625rem]  `}
                                            type="button"
                                            id={`view-tx-pay-${tIndex}`}
                                            onClick={() => {
                                              handleOrderView(
                                                tIndex,
                                                index,
                                                transaction?.id,
                                                transaction?.transfer_file
                                              );
                                              isMobileView &&
                                                (document.body.style.overflow =
                                                  "hidden");
                                            }}
                                          >
                                            <Tooltip
                                              anchorId={`view-tx-pay-${tIndex}`}
                                              content={`${
                                                showLedger?.index === tIndex
                                                  ? "Close"
                                                  : "View"
                                              }`}
                                              place="top"
                                              variant="light"
                                              className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                                            />
                                            <IKTSVG
                                              className={`flex items-center justify-center ${
                                                matchedView
                                                  ? "fill-indigo-500"
                                                  : "fill-violet-500"
                                              } ${
                                                matchedView
                                                  ? "group-hover:fill-indigo-500"
                                                  : "group-hover:fill-indigo-500"
                                              } transition`}
                                              path={EyeSVG}
                                              svgClassName="w-4 h-[.6875rem]"
                                            />
                                          </button>
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  </td>
                                )}
                              </React.Fragment>
                            </>
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

        {/* {closeEvent?.length !== 0 && ( */}
        <div
          id="custom-loader"
          className={`accordion bg-white font-medium -mt-4 border relative z-[1] bg-indigo-500 px-2.5 pt-2.5 `}
        >
          <div id="custom-loader" className={`accordion bg-white font-medium `}>
            <div
              className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
              style={{ height: "30px" }}
            ></div>
            <div
              className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
              style={{ height: "30px" }}
            ></div>
            {/* <div
              className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
              style={{ height: "30px" }}
            ></div> */}
          </div>
        </div>
        {/* )} */}
        {showLedger?.show && (
          <EventDetailView
            show={showLedger}
            handleShow={(data: any) => setShowLedger(data)}
            handleClose={() =>
              setShowLedger({
                show: false,
              })
            }
            paginateData={paginateData}
          />
        )}
      </div>
    </>
  );
};

export default DataTable;
