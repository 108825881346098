/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import Card from "../../../components/ui/Card";
import { KTSVG } from "../../../components/ui/KTSVG";
import { addCommaInNumber } from "../../../helpers/Functions";
import { DashboardContext } from "../core/DashboardProvider";
import { getSalesListing } from "../core/requests";
import DashBoardShimmerLoader from "./DashBoardShimmerLoader";

const SalesCard = ({
  salesCardData,
  salesMetaData,
  salesLoading,
  selectedTeams,
}: any) => {
  const infiniteScrollRef = useRef<any>(null);
  const layout = useContext(DashboardContext);
  const coreLayout = useContext(LayoutContext);
  const access = coreLayout.authUser?.allowed_access;
  const [customLoader, setCustomLoader] = useState(false);

  const sales = access?.some(
    (item: any) => item.id === "shipping" && item.status === true
  );
  const [metaData, setMetaData] = useState(salesMetaData);
  const [cardData, setCardData] = useState(salesCardData);
  const [items, setItems] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>();
  const [page, setPage] = useState(1);
  const observerTarget = useRef(null);
  const [paid, setPaid] = useState<any>();
  // const [customLoader, setCustomLoader] = useState<boolean>(false);
  let optionsdata = [
    "today",
    "last_login",
    "last_7day",
    "last_15day",
    "last_30day",
    "last_6month",
  ];

  const options = [
    { id: "today", name: "Today" },
    { id: "last_login", name: "Last Login" },
    { id: "last_7day", name: "Last 7 days" },
    { id: "last_15day", name: "Last 15 days" },
    { id: "last_30day", name: "Last 30 days" },
    { id: "last_6month", name: "Last 6 months" },
  ];

  let name = "duration";
  let placeholder = "Duration";

  // const loading = async () => { //new
  //   const newCardData = [cardData]
  //   if (data?.length <= 10) { //new
  //     await setData((data: any) => [...data, ...newCardData])
  //   }
  // }

  //FUNCTION TO FIND AND SET THE VALUE
  let value = options?.find((item: any) => layout?.salesOptions === item.id);

  const handleOnChange = (event: any) => {
    setIsLoading(true);

    layout?.setSalesOptions(event?.target?.value?.id);
    // layout.salesOptions,
    getSalesListing({
      optionsData: event?.target?.value?.id,
      page: 1,
      team_id: selectedTeams,
    }).then((response: any) => {
      setCardData(response?.data);
      setItems((prevItems: any) => response?.data?.orders);
      setPage(1);
      setIsLoading(false);
      setMetaData(response?.meta);
    });
    value = layout?.salesOptions
      ? layout?.salesOptions
      : options?.find((item: any) => layout?.salesOptions === item.id);
  };

  //FUNCTION TO REDIRECT TO SALES PAGE
  const navigate = useNavigate();
  const handleClick = async (tabValue: any, paid: any, data: any = "") => {
    if (tabValue === "Sale on Hold")
      navigate("../sales/pending", { replace: true, state: "new" });
    else if (tabValue === "Approved")
      navigate("../sales/awaiting-delivery", { replace: true, state: "to_be_fulfilled" });
    else if (tabValue === "Commissionable") {
      if (!paid) navigate("../sales/delivered", { replace: true, state: "fulfilled" });
      else navigate("../sales/completed", { replace: true, state: "completed" });
    } else if (tabValue === "Cancelled" || tabValue === "Rejected")
      navigate("../sales/cancelled", { replace: true, state: "cancelled" });
    else {
      navigate("../sales", { replace: true, state: data });
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      let prevPage: number = page + 1;
      getSalesListing({
        optionsData: layout.salesOptions,
        page: prevPage,
        team_id: selectedTeams,
      }).then((response: any) => {
        if (response) {
          // setData(response.data)
          setItems((prevItems: any) => [
            ...prevItems,
            ...response?.data?.orders,
          ]);
          setPage(prevPage);
          setIsLoading(false);
          setMetaData(response?.meta);
        }
      });
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const scrollTop = () => {
    if (infiniteScrollRef.current) {
      infiniteScrollRef.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    setItems(cardData?.orders);
  }, [cardData]);

  useEffect(() => {
    setMetaData(salesMetaData);
    setCardData(salesCardData);
  }, [salesMetaData, salesCardData]);

  useEffect(() => {
    setPage(1);
    scrollTop();
  }, [layout.salesOptions]);

  function emptyRecorsMessage(name: any) {
    if (name === "today") {
      return "No sales today";
    }
    if (name === "last_7day") {
      return "No sales last 7 days";
    }
    if (name === "last_15day") {
      return "No sales last 15 days";
    }
    if (name === "last_30day") {
      return "No sales last 30 days";
    }
    if (name === "last_6month") {
      return "No sales last 6 months";
    }
    if (name === "last_login") {
      return "No sales last login";
    }
  }

  return (
    <div className="xl:w-1/2 lg2:w-1/2 w-full md:px-2 md:mb-4 mb-2">
      <Card
        title="Sales"
        name={name}
        placeholder=""
        data={optionsdata}
        options={options}
        handleOnChange={handleOnChange}
        value={value}
        threeDot={true}
        handleClick={handleClick}
        cardTitle="Sales"
      >
        {isLoading ? (
          <DashBoardShimmerLoader
            mainLoading={true}
            headerShimmer={false}
            isLoading={customLoader}
            width={true}
          />
        ) : (
          <div className="divide-gray-200 divide-y-2 ">
            <div className="flex flex-nowrap justify-between p-5 -mx-2.5 overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
              <div className="px-2.5 max-md:min-w-[50%] md:w-1/2 w-full">
                <div className=" border bg-white rounded px-2.5 py-2">
                  <div className="flex flex-wrap sm:flex-nowrap justify-between">
                    <h3 className="md:text-lg md:leading-[1.3125rem] ">
                      {cardData?.new_sales
                        ? addCommaInNumber(cardData?.new_sales)
                        : 0}
                    </h3>
                    {/* {sales && (
                    <h5>
                      <button
                        type="button"
                        className="bg-violet-500 text-white text-xxs font-medium px-2 py-0.5 rounded hover:bg-indigo-500"
                        onClick={() => handleClick("", "")}
                        title="Sales"
                      >
                        <span>View</span>
                      </button>
                    </h5>
                  )} */}
                  </div>
                  <p className="flex items-center text-xs w-full sm:w-auto font-medium text-gray-500 pt-1.5 pr-2">
                    <span className="flex-none sm:flex-1 sm:w-full items-center mr-2">
                      Sales
                    </span>
                  </p>
                </div>
              </div>
              <div className="px-2.5 max-md:min-w-[50%] md:w-1/2 w-full">
                <div className=" border bg-white rounded px-2.5  py-2">
                  <h3 className="md:text-lg md:leading-[1.3125rem]">
                    {cardData?.ticket_sold
                      ? addCommaInNumber(cardData?.ticket_sold)
                      : 0}
                  </h3>
                  <p className="text-xs font-medium text-gray-500 pt-1.5 leading-[.9375rem]">
                    Tickets
                  </p>
                </div>
              </div>
            </div>
            {items?.length > 0 ? (
              <div
                className={`flow-root max-h-48 outline-black overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full 
              ${salesLoading && `pointer-events-none opacity-30`}
              `}
                id="salsCard"
                ref={infiniteScrollRef}
              >
                <div className="px-5 sticky top-0 bg-white text-gray-400 z-10">
                  <div className="flex items-center border-b border-gray-100 py-1.5 space-x-2.5">
                    <div className="flex-1">
                      <p className="text-xs font-medium">Event description</p>
                    </div>
                    <div className="inline-flex items-center text-xs font-medium">
                      Revenue
                    </div>
                  </div>
                </div>
                <InfiniteScroll
                  dataLength={items?.length ? items?.length : 0}
                  next={() => {
                    fetchData();
                  }}
                  hasMore={metaData?.current_page < metaData?.last_page}
                  loader={Array.from({ length: 3 }, (v, i) => (
                    <div
                      className={`accordion font-medium px-5 mb-2 ${
                        i === 0 && "mt-2"
                      } `}
                      key={i}
                    >
                      <div
                        className={`accordion-item bg-white !rounded overflow-hidden shimmer-effect`}
                        style={{ height: "30px" }}
                      ></div>
                    </div>
                  ))}
                  scrollThreshold="20px"
                  scrollableTarget="salsCard"
                >
                  <ul className="max-h-50 overflow-y-auto">
                    {items?.map((order: any, index: any) => (
                      <li
                        key={index}
                        className="hover:bg-indigo-500/5 px-5 transition cursor-pointer"
                        onClick={() => {
                          coreLayout.setGlobalSales(items[index].id);
                          layout.setTabValue(items[index].status);
                          setPaid(items[index].paid);
                          handleClick(items[index].status, items[index].paid);
                        }}
                      >
                        <div className="flex items-center border-b border-gray-100 py-1.5 space-x-2.5">
                          <div className="flex-1">
                            <p
                              className="text-xs font-medium line-clamp-1"
                              title={`${order?.event}, ${order.venue}`}
                            >
                              {order?.event}, {order.venue}
                            </p>
                          </div>
                          <div
                            className="inline-flex items-center text-xs font-medium"
                            dangerouslySetInnerHTML={{
                              __html: order?.transaction_value,
                            }}
                          ></div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </InfiniteScroll>
                {error && <p>Error: {error.message}</p>}
              </div>
            ) : salesLoading ? (
              <div className="text-center">
                <KTSVG
                  className="text-center inline-block fill-violet-500 transition animate-spin"
                  path="other_icons/process.svg"
                />
              </div>
            ) : (
              <div className="text-center text-sm py-4">
                {emptyRecorsMessage(layout.salesOptions)}
              </div>
            )}
          </div>
        )}
      </Card>
    </div>
  );
};

export default SalesCard;
