import React, { useMemo } from "react";
import { Tooltip } from "react-tooltip";
import { PROGRESS_COLOR, PROGRESS_TITLE, SELLER_LEVEL_KEY } from "../const";
import { useProcessInfo } from "../hooks/useProcessInfo";
import {
  calculateSellerLevelPercentage,
  formatCurrencyAmount,
  getDarkBgColor,
} from "../utils";

export const ProgressBlock = (props: any) => {
  const { level, amount, currentDate } = props;

  const {
    currencyLabel,
    activeSellerLevel,
    levelProgressPercentage: percentage,
    targetPercentage = 0,
    targetAmount = 12000,
    showTargetGTV,
    userSellerLevel,
    targetLevel,
  } = useProcessInfo(amount, currentDate);

  // Get background color using seller level
  const darkBgColor = useMemo(
    () =>
      getDarkBgColor({
        level,
        userSellerLevel,
        activeSellerLevel,
      }),
    [activeSellerLevel, level, userSellerLevel]
  );

  const showTargetArrow = useMemo(
    () =>
      level === targetLevel &&
      level !== SELLER_LEVEL_KEY.SUPER_SELLER &&
      showTargetGTV,
    [level, showTargetGTV, targetLevel]
  );

  const sellerLevelPercentage = calculateSellerLevelPercentage(
    level,
    activeSellerLevel,
    percentage
  );

  return (
    <div
      className={`relative w-full ${
        level === SELLER_LEVEL_KEY.SUPER_SELLER && "max-w-[1.125rem]"
      } `}
    >
      {/* Target arrow */}
      {showTargetArrow && (
        <div
          className="targetgtv absolute top-0 h-full flex flex-col items-center z-20"
          style={
            {
              "--targetgtv": `${targetPercentage}%`, // Dynamic width value
            } as React.CSSProperties
          }
          id={`seller-current-level-gtv-${level}`}
        >
          <div className="bg-white w-[1px] h-full"></div>
          <div className="absolute -top-1.5 w-0 h-0 border-l-[0.3125rem] border-r-[0.3125rem] border-b-[0.375rem] border-l-transparent border-r-transparent border-b-violet-500 rotate-180"></div>
          <Tooltip
            anchorId={`seller-current-level-gtv-${level}`}
            content={`Target GTV ${formatCurrencyAmount(
              currencyLabel,
              targetAmount
            )}`}
            place="top"
            variant="light"
            className="!bg-white max-w-[4.375rem] text-center !opacity-100 !py-0.5 !px-[0.3125rem] !text-xxs font-medium z-10 mt-1.5"
          />
        </div>
      )}
      {/* Target arrow */}
      <div
        className={`gtvprogress rounded h-[1.125rem] overflow-hidden  ${darkBgColor} ${
          sellerLevelPercentage === 100 && "radius"
        } relative before:absolute before:content-[''] before:h-full before:left-0 before:top-0 before:rounded-l`}
        style={
          {
            "--progress-width": `${sellerLevelPercentage}%`, // Dynamic width value
            "--progress-color": PROGRESS_COLOR[level]?.light,
          } as React.CSSProperties
        }
      >
        <span className="flex items-center justify-center text-white w-full h-full text-xxs leading-3 font-medium relative z-10">
          {PROGRESS_TITLE[level]}
        </span>
      </div>
    </div>
  );
};
